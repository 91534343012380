import styled from "styled-components";
import { Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useCompensations } from "./provider";
import months from '../../data/months.json'

const cols = [
  { name: "amount", label: "Amount" },
  { name: "notes", label: "Notes" },
  { name: "month", fn: (v) => months[v - 1]?.long, label: "Month" },
  { name: "year", label: "Year" },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function ListCompensations() {
  // const {id} = useParams();

  const {
    state: { list, status },
    fetchList,
    remove,
  } = useCompensations();

  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleClick = () => {
    navigate("/Compensations/new");
  };

  const handleView = (id) => {
    navigate(`/Compensations/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/Compensations/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("A compensation has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

    // if (!list) return;

  return (
    <>
      <Grid>
        <h2>Compensations List</h2>
        {/* <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button> */}
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Compensations table"
        cols={cols}
        data={list}
        checkbox
        showButton={handleView}
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
    </>
  );
}

export default ListCompensations;
