import styled from "styled-components";
import { Button, Select } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import { useDepartments } from "./provider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import DepartmentCompanyCell from "./listDepartment/DepartmentCompanyCell";
import { useCompanies } from "../companies/provider";
import CompanyNameCell from "../companies/listCompanies/CompanyNameCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function ListDepartments() {
  const [search, setSearch] = useState("");

  const { cid } = useParams();
  const navigate = useNavigate();
  const { notify , alertUser } = useUtils();

  const {
    state: { list, status },
    fetchList,
    remove,
  } = useDepartments();

  const {
    state: {
      list: companies,
      // selected: { company },
    },
    fetchList: fetchCompanies,
    // dispatch,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // useEffect(() => {
  //   if (!companies.length) return;
  //   dispatch({ type: "set_selected", payload: companies[0], keep: true });
  // }, [companies, dispatch]);

  useEffect(() => {
    if (cid) fetchList(cid);
  }, [cid, fetchList]);

  const cols = [
    { name: "departmentName", label: "Department Name" },
    {
      name: "companyId",
      label: "Company",
      render: CompanyNameCell,
      renderParams: companies.find((c) => c.id === Number(cid)),
    },
    { name: "employeesCount", label: "Employees" },
  ];

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  const handleClick = () => {
    navigate(`/departments/company/${cid}/new`);
  };

  const handleEdit = (id) => {
    navigate(`/departments/company/${cid}/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Changes saved successfully");
    } catch (e) {
      if (e.status === 400) alertUser(e.errors);
      else if (e.status === 405)
      alertUser( `The department can’t be deleted because of dependent records`);
      console.log(e);
    }
  };

  const handleCompanyChange = useCallback(
    (item) => {
      navigate(`/departments/company/${item.value}`);
    },
    [navigate]
  );

  if(!list) return
  return (
    <>
      <Grid>
        <h2>Departments List</h2>
        {companies.length && (
          <Select
            data={companies}
            mapOptions={mapCompanyOptions.current}
            onChange={handleCompanyChange}
            defaultValue={Number(cid || companies[0].id)}
            inputStyle={{ height: "40px", maxWidth: "280px" }}
          />
        )}
        <SearchInput onChange={setSearch} value={search} />
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Department table"
        cols={cols}
        data={list.filter((item) =>
          item.departmentName.toLowerCase().includes(search.toLowerCase(), 0)
        )}
        checkbox
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      {/* <Pagination count={5} /> */}
    </>
  );
}

export default ListDepartments;
