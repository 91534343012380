import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useStats() {
  return useContext(Context);
}

const initState = {
  totalSalaries: [],
  departments: [],
  numberSalaries: [],
  holidays: [],
  attendance: [],
  totalSalariesStatus: "idle",
  departmentsStatus: "idle",
  numberSalariesStatus: "idle",
  holidaysStatus: "idle",
  attendanceStatus: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_total_salaries":
      return { ...state, totalSalaries: [...action.payload] };
    case "total_salaries_status":
      return { ...state, totalSalariesStatus: action.payload };
    case "set_departments":
      return { ...state, departments: [...action.payload] };
    case "departments_status":
      return { ...state, departmentsStatus: action.payload };
    case "set_number_salaries":
      return { ...state, numberSalaries: [...action.payload] };
    case "number_salaries_status":
      return { ...state, numberSalariesStatus: action.payload };
    case "set_holidays":
      return { ...state, holidays: [...action.payload] };
    case "holidays_status":
      return { ...state, holidaysStatus: action.payload };
    case "set_attendance":
      return { ...state, attendance: [...action.payload] };
    case "attendance_status":
      return { ...state, attendanceStatus: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function StatsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchTotalSalaries = useCallback(
    async (year, month) => {
      dispatch({ type: "total_salaries_status", payload: "fetching" });
      const resData = await req(
        `Statistic/TotalSalaries/${year}/${month}`,
        null,
        {},
        true
      );
      dispatch({ type: "total_salaries_status", payload: `idle` });
      dispatch({ type: "set_total_salaries", payload: resData.data });
    },
    [req]
  );

  const fetchDepartments = useCallback(async () => {
    dispatch({ type: "departments_status", payload: "fetching" });
    const resData = await req(
      "Statistic/NumberEmployeesDepartment",
      null,
      {},
      true
    );
    dispatch({ type: "departments_status", payload: `idle` });
    dispatch({ type: "set_departments", payload: resData.data });
  }, [req]);

  const fetchNumberSalaries = useCallback(
    async (year, month) => {
      dispatch({ type: "number_salaries_status", payload: "fetching" });
      const resData = await req(
        `Statistic/NumberEmployeesSalary/${year}/${month}`,
        null,
        {},
        true
      );
      dispatch({ type: "number_salaries_status", payload: `idle` });
      dispatch({ type: "set_number_salaries", payload: resData.data });
    },
    [req]
  );

  const fetchHolidays = useCallback(
    async (year) => {
      dispatch({ type: "holidays_status", payload: "fetching" });
      const resData = await req(
        `Statistic/TotalHolidays/${year}`,
        null,
        {},
        true
      );
      dispatch({ type: "holidays_status", payload: `idle` });
      dispatch({ type: "set_holidays", payload: resData.data });
    },
    [req]
  );

  const fetchAttendance = useCallback(
    async (year, month) => {
      dispatch({ type: "attendance_status", payload: "fetching" });
      const resData = await req(
        `Statistic/AttendancePercent/${year}/${month}`,
        null,
        {},
        true
      );
      dispatch({ type: "attendance_status", payload: `idle` });
      dispatch({ type: "set_attendance", payload: resData.data });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchTotalSalaries,
        fetchDepartments,
        fetchNumberSalaries,
        fetchHolidays,
        fetchAttendance
      }}
    >
      {children}
    </Context.Provider>
  );
}
