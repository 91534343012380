import { memo, useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useNotification } from "./provider";
import { Button, Card, Checkbox, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import { useCompanies } from "../companies/provider";
import TextArea from "../../ui/TextArea";

const Grid = styled.div`
  max-width: 854px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  .custom-select {
    width: 350px !important;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const schema = Yup.object().shape({
  companyId: Yup.string().required("required"),
  departmentId: Yup.string().nullable(),
  employeeId: Yup.string().nullable(),
  title: Yup.string().required("required"),
  body: Yup.string(),
});

function NotificationForm({ holiday = null, handleSuccess }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { create } = useNotification();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const companiesId = useRef([]);
  const departmentsId = useRef([]);


  const handleChange = useCallback((v) => {
    companiesId.current = v.map((v) => v.value);
  }, []);

  const handleDChange = useCallback((v) => {
    departmentsId.current = v.map((v) => v.value);

  }, []);

  //fetch companies

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  //fetch departments

  const {
    state: { list: departmentList },
    fetchList: fetchDepartments,
  } = useDepartments();

  const watchCompany = watch("companyId");

  const watchDepartment = watch("departmentId");

  const mapDepartmentOptions = useRef({ value: "id", label: "departmentName" });
  console.log(watchDepartment);
  useEffect(() => {
    if (watchCompany) fetchDepartments(getValues("companyId"));
    setValue("departmentId", "");
  }, [watchCompany]);

  //fetch employees
  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();



  useEffect(() => {
    if (getValues("companyId"))
      fetchEmployees({
        companyId: getValues("companyId"),
        departmentId: getValues("departmentId"),
      });
  }, [watchCompany, watchDepartment]);

  const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });

  const onSubmit = async (values) => {
    if (companiesId.current[0] === null) companiesId.current = null;
    if (departmentsId.current[0] === null)  departmentsId.current = null;

    try {
      await create({DepartmentIds : departmentsId.current , EmployeeIds: companiesId.current, ...values });
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status == 400) setError({ title: e.errors.message });
      console.log("e", e, error);
    }
  };

  //if(!holiday) return
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <div>
            <Select
              inputStyle={{
                height: "60px",
                width: "100%",
                marginBottom: "20px",
              }}
              data={companies}
              error={!!errors.companyId}
              msg={errors.companyId?.message}
              mapOptions={mapCompanyOptions.current}
              label={"companies"}
              onChange={useCallback(
                (data) => setValue("companyId", data.value),
                [setValue]
              )}
              inputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div>
            <Select
              style={{
                height: "60px",
                width: "100%",
                marginBottom: "20px",
              }}
              data={[ {id:null , departmentName:'All'} , ...departmentList]}
              mapOptions={mapDepartmentOptions.current}
              label={"department"}
              multi
              error={!!errors.departmentId}
              msg={errors.departmentId?.message}
              onChange={handleDChange}
              inputProps={{
                readOnly: true,
              }}
            />
          </div>
          <Select
            label="Employees"
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
            }}
            error={!!errors.employeeId}
            msg={errors.employeeId?.message}
            data={[{ id: null, fullName: "All" }, ...employees]}
            mapOptions={{ value: "id", label: "fullName" }}
            onChange={handleChange}
            multi
            inputProps={{
              name: "companyId",
            }}
          />
        </Grid>
        <TextField
          style={{
            marginTop: "50px",
            maxWidth: "350px",
          }}
          label="Title"
          name="title"
          error={!!errors.title}
          msg={errors.title?.message}
          inputProps={{
            ...register("title"),
            autoFocus: true,
            required: true,
          }}
        />

        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="Body"
          name="body"
          required
          rows="4"
          error={!!errors.body}
          msg={errors.body?.message}
          inputProps={{
            ...register("body"),
            autoFocus: true,
            required: true,
          }}
        />
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
           loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(NotificationForm);
