import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTransaction } from "../../transaction/provider";
import { useAccounts } from "../provider";
import { useYear } from "../../year/provider";
import ErrorsList from "../../../ui/ErrorsList";
import { Button, Card, Select, TextField } from "../../../ui";
import TextArea from "../../../ui/TextArea";
import { delay, firstUp } from "../../../utilities/functions";
import CreditForm from "./simpleentryforms/CreditForm";
import DebitForm from "./simpleentryforms/DebitForm";
import { useUtils } from "../../../services/utilsProvider";

const schema = Yup.object().shape({
  creditAccountId: Yup.number()
    .typeError("required")
    .nullable()
    .required("required"),
  debitAccountId: Yup.number()
    .typeError("required")
    .nullable()
    .required("required"),
  value: Yup.number().required("required").typeError("should be a number"),
});

const Grid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  padding-top: 30px;
  .leftSide {
    border-right: 3px solid #000;
  }
`;

function SimpleEntry({ selectedId, selected, isAcc }) {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("a Simple Entry has been created successfully.");
  };

  console.log(selectedId, "id");
  if (!isAcc) return <h3>you have no access to these section </h3>;
  return (
    <Grid>
      <div className="leftSide">
        <h3 style={{ marginTop: "0", marginBottom: "0" }}>
          {firstUp("From :")}
        </h3>
        <CreditForm
          selectedId={selectedId}
          selected={selected}
          handleSuccess={handleSuccess}
        />
      </div>
      <div>
        <h3 style={{ marginTop: "0", marginBottom: "0" }}>{firstUp("To :")}</h3>
        <DebitForm
          selectedId={selectedId}
          selected={selected}
          handleSuccess={handleSuccess}
        />
      </div>
    </Grid>
  );
}

export default memo(SimpleEntry);
