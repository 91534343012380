import { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Icon, Select } from "../../ui";
import SimpleEntryForm from "./SimpleEntryForm";
import { useTransaction } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddNewSimpleEntry() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("a new Journal has been created successfully.");
  };

  const {
    state: { simplejournalType },
    setSimpleJournalType,
  } = useTransaction();

  const data = [
    { label: "Receipt Voucher", value: 1 },
    { label: "Payment Voucher", value: 2 },
    { label: "Receipt Others", value: 3 },
  ];

  return (
    <div id="add-Journal">
      <Grid>
        <h2 style={{ marginTop: "0" }}>New Simple Entry</h2>
        <div>
          <div
            style={{ cursor: "pointer", marginLeft: "20px" }}
            onClick={() => navigate(-1)}
          >
            <span>Back</span>
            <Icon name="arrowLeft" />
          </div>
        </div>
      </Grid>

      <SimpleEntryForm handleSuccess={handleSuccess} type={simplejournalType} />
    </div>
  );
}

export default memo(AddNewSimpleEntry);
