import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Card = styled.div`
  box-shadow: ${props => props.elevation === 4 ? "0px 3px 6px #272d3b33" : "0px 4px 4px #0000000A"};
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  position: relative;
  @media only print {
    width: 90vw;
  }
  .floating-badge {
    position: absolute;
    top: 20px;
    right: -20px;
    background-color: ${props => props.theme.color.green};
    border-radius: 10px;
    color: white;
    text-align: center;
    min-width: 140px;
    padding: 10px;
    line-height: 20px;
    font-size: 15px;
  }
  ${stylesBlocks}
`;

export default Card;
