import countries from '../../data/countries.json';

function emptyDate(value, originalValue) {
	if (
		typeof originalValue === 'string' &&
		(originalValue === '' || originalValue === 'Invalid Date')
	) {
		return '';
	}
	return originalValue.split('T')[0];
}

function emptyStringToNull(value, originalValue) {
	if (typeof originalValue === 'string' && originalValue === '') {
		return null;
	}
	return Number(value);
}

const fields = [
	{
		name: 'workTime',
		type: 'text',
		label: 'Work Time',
		validationType: 'string',
		validations: [
			{
				type: 'required',
				params: ['this field is required'],
			},
		],
	},

	{
		name: 'overTime',
		type: 'text',
		label: 'Over Time',
		validationType: 'string',
		validations: [
			{
				type: 'date',
				params: ['this field is required'],
			},
		],
	},
	{
		name: 'delay',
		type: 'text',
		label: 'delay',
		validationType: 'string',
		validations: [
			{
				type: 'date',
				params: ['this field is required'],
			},
		],
	},

];

export default fields;
