import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Card, TextField } from "../../../ui";
import { useEffect, useState } from "react";
import { usePayrolls } from "../provider";
import ErrorsList from "../../../ui/ErrorsList";
import FileField from "../../../ui/FileField";
import { useUtils } from "../../../services/utilsProvider";

const schema = Yup.object().shape({
  title: Yup.string().required("Required"),
});

function UploadDocumentForm({ id }) {
  const [documentFile, setDocumentFile] = useState(null);
  const [error, setError] = useState(null);

  const { uploadDocument } = usePayrolls();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "document") setDocumentFile(value["document"][0]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSuccess = () => {
    reset();
    setDocumentFile(null);
    notify("Document has been uploaded successfully");
  };

  const onSubmit = async (values) => {
    try {
      await uploadDocument({ ...values, id });
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Card elevation={4}>
        <TextField
          label="Title"
          name="title"
          error={!!errors.businessName}
          msg={errors.businessName?.message}
          inputProps={{
            ...register("title"),
            autoFocus: true,
            required: true,
          }}
        />
        <FileField
          label="Document"
          buttonLabel="Upload Document"
          document
          documentFile={documentFile}
          onClose={() => {
            setValue("document", "");
            setDocumentFile(null);
          }}
          inputProps={{
            name: "document",
            ...register("document"),
            accept:
              ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }}
        />

        <Button
          style={{ marginTop: "30px" }}
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
      </Card>
    </form>
  );
}

export default UploadDocumentForm;
