import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./services/auth";
import Providers from "./services/providers";

import "./index.css";
import App from "./App";
import Login from "./views/Login";
import Dashboard from "./views/dashboard/";
import companyRoutes from "./views/companies/routes";
import departmentRoutes from "./views/departments/routes";
import attendancePatternRoutes from "./views/attendancePatterns/routes";
import employeeRoute from "./views/employees/routes";
import leavesRoute from "./views/leaves/routes";
import holidayRoutes from "./views/holidays/routes";
import userAccountRoutes from "./views/UserAccount/routes";
import attendanceRoutes from "./views/attendance/routes";
import payrollsRoutes from "./views/payrolls/routes";
import compensationsRoutes from "./views/compensations/routes";
import ReportRoute from "./views/Reports/routes";
import PublicHolidayRoute from "./views/publicHoliday/routes";
import EmployeeCompensationsRoute from "./views/employeeCompensations/routes";
import EmployeeDeductionsRoute from "./views/employeeDeductions/routes";
import notificationRoute from "./views/notifications/routes";
import purchesRoute from "./views/purches/routes";
import ordersRoute from "./views/orders/routes";
import currencyRoute from "./views/currency/routes";
import signersRoute from "./views/signers/routes";
import AccountRoute from "./views/accounts/routes";
import YearRoute from "./views/year/routes";
import TransactionRoute from "./views/transaction/routes";

import ContactUs from "./views/contactUs/ContactUs";
import PrivacyPolicy from "./views/privecyPolicy/PrivacyPolicy";
import ChangePassword from "./views/ChangePassword";
import SupliersRoute from "./views/supliers/routes";
import NoAaccess from "./views/NoAaccess";
import ProjectsRoutes from "./views/projects/routes";
import NewsRoutes from "./views/news/routes";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Providers>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="login" element={<Login />} />
          <Route path="no-access" element={<NoAaccess />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          {companyRoutes}
          {departmentRoutes}
          {attendancePatternRoutes}
          {employeeRoute}

          {leavesRoute}
          {holidayRoutes}
          {userAccountRoutes}
          {attendanceRoutes}
          {payrollsRoutes}
          {compensationsRoutes}
          {ReportRoute}
          {PublicHolidayRoute}
          {EmployeeCompensationsRoute}
          {EmployeeDeductionsRoute}
          {notificationRoute}
          {purchesRoute}
          {ordersRoute}
          {currencyRoute}
          {signersRoute}
          {SupliersRoute}
          {AccountRoute}
          {YearRoute}
          {TransactionRoute}
          {ProjectsRoutes}
          {NewsRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
