import React, { useEffect } from "react";
import { useAccounts } from "../provider";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../../services/utilsProvider";
import TableContainer from "../../../ui/TableContainer";
import NoteCell from "../tableCell/NoteCell";
import { Pagination } from "../../../ui";
import { useState } from "react";
import { useStatus } from "../../../services/StatusProvider";
import AccountType from "../tableCell/AccountType";
import SpecialType from "../tableCell/SpecialType";
import AccountStatus from "../tableCell/AccountStatus";
import IsActive from "../tableCell/IsActive";

const cols = [
  { name: "accountName", label: "Account Name" },
  { name: "accountNumber", label: "Account Number" },
  { name: "companyName", label: "Company Name" },
  { name: "accountType", label: "Account Type", render: AccountType },
  { name: "specialType", label: "Special Type", render: SpecialType },
  { name: "accountStatus", label: "Account Status", render: AccountStatus },
  { name: "baseAccountName", label: "Base Account" },
  { name: "isActive", label: "Is Active", render: IsActive },
  { name: "startBalance", label: "Start Balance" },
  { name: "balance", label: "Balance" },
  { name: "notes", label: "notes", render: NoteCell },
];
function ListView() {
  const [options, setOptions] = useState({});
  const {
    state: { list, status, count, page, perPage, filters },
    fetchList,
    remove,
    dispatch,
    setFilters,
  } = useAccounts();

  const {
    state: { isAcc },
  } = useStatus();
  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();
  const handleView = (id) => {
    navigate(`/accounting/view/${id.id}`);
  };
  const handleViewChildren = (id) => {
    navigate(`/accounting/view/children/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/accounting/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("account has been removed successfull.");
    } catch (error) {
      if (error.status === 406) {
        alertUser("this account has children");
      }
      console.log(error);
    }
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
  useEffect(() => {
    dispatch({ type: "set_selected", payload: null });
  }, []);

  useEffect(() => {
    !isAcc
      ? setOptions({
          viewChildren: {
            label: "Children",
            icon: "eye",
            fn: handleViewChildren,
          },
        })
      : setOptions({
          edit: handleEdit,
          viewChildren: {
            label: "Children",
            icon: "eye",
            fn: handleViewChildren,
          },
          delete: handleDelete,
        });
  }, [isAcc]);

  return (
    <div>
      {" "}
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Accounts table"
        cols={cols}
        data={list}
        showButton={handleView}
        moreOptions={options}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default ListView;
