import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import SignersList from "./SignersList";
import Signers from "./Signers";
import AddSigners from "./AddSigners";
import EditSigners from "./EditSigners";


export default (
  <Route
    path="signers"
    element={
      <RequireAuth>
        <Signers />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <SignersList />
        </RequireAuth>
      }
    />
    <Route
      path="new/:id"
      element={
        <RequireAuth>
          <AddSigners />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditSigners />
        </RequireAuth>
      }
    />
  </Route>
);
