import * as yup from "yup";
import { parseDate } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "holidayName",
    type: "text",
    label: "Holiday Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
  {
    name: "startDate",
    type: "date",
    required: "required",
    label: "Start Date",
    validationType: "date",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [parseDate],
      },
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
  {
    name: "endDate",
    type: "date",
    label: "End Date",
    validationType: "date",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "min",
        params: [yup.ref("startDate"), "End date has to be more than start date"]
      },
      {
        type: "transform",
        params: [parseDate],
      },
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
  {
    name: "companyIds",
    viewName: "companyName",
    newOnly: true,
    type: "number",
    resourceName: "companies",
    multi: true,
    data: [],
    mapData: { value: "id", label: "businessName" },
    label: "Company",
    validationType: "array",
    msg: " ",
    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "of",
        params: (yup) => yup.number().min(1),
      },
      // {
      //   type: "transform",
      //   params: [emptyStringToNull]
      // },
    ],
  },
  {
    name: "departmentIds",
    viewName: "departments",
    editOnly: true,
    type: "number",
    multi: true,
    resourceName: "departments",
    data: [],
    mapData: { value: "id", label: "departmentName" },
    label: "Department",
    validationType: "array",
    validations: [
      { type: "nullable", params: [true] },
      {
        type: "of",
        params: (yup) => yup.number().min(1),
      },
    ],
  },
];

export default fields;
