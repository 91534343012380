import * as yup from "yup";

// Deprecated!
// used before adding proper date validations, check parseDate
export function emptyDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return originalValue?.split("T")[0];
}

// return null if date is not set, else parse the string date to js date
export function parseDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return null;
  }
  return new Date(originalValue);
}
//convert time to UTC time

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

export function toUTC(value, originalValue) {
  let d = new Date(originalValue);

  return new Date(
    d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      d.getUTCHours().padLeft() +
      ":" +
      d.getUTCMinutes().padLeft()
  );
}

export const setDateFormat = (d) => {
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft()
  );
  // + ' '
  // + d.getHours().padLeft()+':'
  // + d.getMinutes().padLeft()
};

export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return Number(value);
}

export function emptyStringToZero(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return 0;
  }
  return Number(value);
}

// used as a reducer of a config array
export function createYupSchema(schema, config) {
  const { name, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (validationType === "array" && type === "of") {
      validator = validator[type](params(yup));
      return;
    }
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[name] = validator;
  return schema;
}
