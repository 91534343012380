import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import EditPayrollsForm from "./EditPayrollsForm";
import PayrollsForm from "./PayrollsForm";


const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 1AttendanceForm0px;
    }
  }
`;

function EditPayroll() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

  return (
    <div id="edit-leave">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Payroll</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <EditPayrollsForm  employeeIdforif={params} handleSuccess={handleSuccess}/>
    </div>
  );
}

export default EditPayroll;
