import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Flex, Pagination, Select } from "../../ui";
import SearchInput from "../../ui/SearchInput";
import styled, { useTheme } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useCompanies } from "../companies/provider";
import FilterForm from "./FilterForm";
import { useNews } from "./provider";
import TableContainer from "../../ui/TableContainer";
import { render } from "@testing-library/react";
import PublishDateCell from "./renderListNews/PublishDateCell";
import Label from "../../ui/InputLabel";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
const NewsList = () => {
  const navigate = useNavigate();
  const { cid } = useParams();
  const [companyId, setCompanyId] = useState(cid);
  const { notify } = useUtils();
  let theme = useTheme();

  const handleClick = () => {
    navigate(`/news/company/${cid}/new`);
  };
  const cols = [
    { name: "title", label: "Title" },
    { name: "description", label: "Description" },
    { name: "publishDate", label: "Publish Date", render: PublishDateCell },
    { name: "readingsCount", label: "Reading Count" },
    { name: "readingDuration", label: "Reading Duration" },
  ];

  const {
    state: {
      list,
      status,
      count,
      page,
      filters,
      // selected: { company },
    },
    fetchList,
    remove,
    dispatch,
    setFilters,
  } = useNews();

  useEffect(() => {
    fetchList({ ...filters, pagenumber: page + 1 }, companyId);
  }, [page, fetchList, filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Article has been removed successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Flex jc="space-between">
        <h2>News List</h2>

        <Flex jc="space-between" gap={"10px"}>
          <Button className="small" onClick={handleClick} iconName="invoice">
            New Entry
          </Button>
        </Flex>
      </Flex>

      <Card sx={{ mb: 40, pb: 30, mt: 20 }}>
        <FilterForm setFilters={setFilters} />
      </Card>

      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="News table"
        cols={cols}
        data={list || []}
        checkbox
        //showButton={handleView}
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />

      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={filters.pagesize}
        onPageChange={handleChangePage}
      />
    </>
  );
};

export default NewsList;
