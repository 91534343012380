import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useLeaves } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "../../data/countries.json";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import fields from "./fields";
import TextArea from "../../ui/TextArea";
import { dateToLocalConverter, setDateFormat } from "../../utilities/functions";
import InputDate from "../../ui/InputDate";
import Loading from "../../ui/Loading";

let day_for_hourly_absence = 0;
let final_abcence_from = 0;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(value, originalValue) {
  if(value.length == 0) return
  var d = new Date(value);

  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      d.getUTCHours().padLeft() +
      ":" +
      d.getMinutes().padLeft()
    );
  }

  if (d == "Invalid Date") {
    final_abcence_from = day_for_hourly_absence + " " + value;
    return final_abcence_from;
  }
}

const schema = Yup.object().shape({
  employeeId: Yup.string(),
  typeId: Yup.number(),
  absenceFrom: Yup.string().typeError('required'),
  absenceTo: Yup.string().typeError('required'),
  notes: Yup.string(),
});

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function LeaveForm({ employeeIdforif=null, handleSuccess }) {
  const types = [
    { typeId: "1", name: "annual" },
    { typeId: "2", name: "Sickness" },
    { typeId: "3", name: "Marriage" },
    { typeId: "4", name: "Maternity" },
    { typeId: "5", name: "WorkAccident" },
    { typeId: "6", name: "Death" },
    { typeId: "7", name: "Unpaid" },
    { typeId: "8", name: "Others" },
    { typeId: "9", name: "Hourly" },
  ];
  const [selectedTypeValue, setSelectedTypeValue] = useState(1);
  const [error, setError] = useState(null);
  const [time, setTime] = useState(null);

  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("sessionStorage"));
  const {
    state: {

      selected,
      companyID,
      departmentID,
      filters
    },
    create,
    fetchOne,
    edit,
    dispatch,

  } = useLeaves();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //fetching for filters
  const [departmentId, setDepartmentId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [absenceFrom, setAbsenceFrom] = useState(null);
  const [AbsenceTo, setAbsenceTo] = useState();
  const [loading , setLoading] = useState()

  //fetch companies

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const handleCompanyChange = useCallback((id) => {
    dispatch({ type: "compnayIDchange", payload: id.data.id });
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch departments

  const {
    state: { list: departmentList, status },
    fetchList: fetchDepartments,
  } = useDepartments();

  const handleDepartmentChange = useCallback(
    (id) => {
      setDepartmentId(id.data.id);
      dispatch({ type: "departmentIDchange", payload: id.data.id})
    },
    [departmentList]
  );

  const mapDepartmentOptions = useRef({ value: "id", label: "departmentName" });

  useEffect(() => {
    if (companyID) fetchDepartments(companyID);
  }, [companyID]);

  //fetch employees
  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  useEffect(() => {
    if (employees.length) setEmployeeId(employees[0].id);
  }, [employees]);

  const handleEmployeeChange = useCallback((id) => {
    setEmployeeId(id.data.id);
  }, []);

  useEffect(() => {
    if (companyID) fetchEmployees({companyId:companyID,departmentId: departmentID});
  }, [companyID, departmentID]);

  const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });


  const adjustTime = async (values) => {
    await setTimeNew()
    let from = absenceFrom;
    let d = new Date(from);
    let dd =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      (d.getUTCHours() + time  ).padLeft() +
      ":" +
      d.getMinutes().padLeft();

    setAbsenceTo(dd)
    return dd
  };


  const adjustTime2 = () => {
    let from = absenceFrom;
    let d = new Date(from);
    let dd =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      (d.getUTCHours()).padLeft() +
      ":" +
      d.getMinutes().padLeft();
    return dd
  };
  //submitting
  const setTimeto12=(x)=>{
    let d = new Date(x)
    let dd =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft() +
    " " +
    '00' +
    ":" +
    '00';
    return dd
  }

  const onSubmit = async (values) => {
    let d 
    let d2 
    if (selectedTypeValue == "9"){
    d=  await adjustTime();
    d2 = adjustTime2()
    
    delete  values.absenceTo}
      else{
       d= setTimeto12(AbsenceTo)
       d2 = setTimeto12(absenceFrom) 
      }


  
    try {
      if (employeeIdforif) {

        let typeId = selected["typeId"];
        let employeeId = selected["employeeId"];
        let dataa = { employeeId, 
        typeId: parseInt(selectedTypeValue),
          
          absenceTo:d, ...values };
        await edit(employeeIdforif, {employeeId,
          absenceTo:d,
          absenceFrom:d2,
          notes:values.notes,
          typeId: parseInt(selectedTypeValue)});
        reset();
      } else {



      await create({
        employeeId,
        absenceTo:d,
        absenceFrom:d2,
        notes:values.notes,
        typeId: parseInt(selectedTypeValue),
      });
      reset();

       }
       handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      setError({ title: e.errors.message });
      console.log("e", e);
    }
  };

  useEffect(() => {
    if (!departmentID)
      dispatch({ type: "departmentIDchange", payload: departmentList[0]?.id });
  }, [companies]);


  //fetch one for update

  useEffect(() => {
    if (employeeIdforif) fetchOne(employeeIdforif);
  }, [employeeIdforif, fetchOne]);

  useEffect(()=>{
    if (employeeIdforif&& selected === {}) setLoading(true);
    else setLoading(false);
    },[selected])



  useEffect(() => {
    if (employeeIdforif && selected.length != 0 && selected["absenceTo"]) {
      fields.forEach((f) => {
        if (f.name == "absenceFrom") {
          setValue(f.name, selected[f.name].split(" ")[0] || "");
        } else if (f.name == "absenceTo") {
          setValue(f.name, selected[f.name].split(" ")[0] || "");
        } else setValue(f.name, selected[f.name] || "");
      });
    }
  }, [selected]);

  const setTimeNew = ()=>{
    let from = new Date(absenceFrom);
    let to = new Date(dateToLocalConverter( AbsenceTo));
    setTime(to.getHours() - from.getHours())
  }

  useEffect(()=>{
  if (selectedTypeValue == "9")  {
    adjustTime();
    setTimeNew()
  }
  if (selectedTypeValue == "9")  setValue('absenceFrom',dateToLocalConverter(selected.absenceFrom));

  },[absenceFrom])
  useEffect(() => {
    if (selected?.typeId) setSelectedTypeValue(selected.typeId);
    if(selected.typeId != 9) setAbsenceFrom(selected?.absenceFrom)
    else setAbsenceFrom(dateToLocalConverter(selected.absenceFrom))
    if(selected?.absenceFrom) setValue('absenceFrom',selected.absenceFrom);
    if(selected?.absenceTo) setAbsenceTo(selected.absenceTo);
    
  }, [selected]);

  if(loading) return <Loading></Loading>  

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      {!employeeIdforif && (
        <>
          <Card>
            {/* filter grid*/}

            <Grid>
              <div>
                  <Select
                    style={{
                      height: "60px",
                      width: "100%",
                      marginBottom: "0px 75px",
                    }}
                    data={companies}
                    mapOptions={mapCompanyOptions.current}
                    onChange={handleCompanyChange}
                    defaultValue={filters?.companyId}
                    label={"companies"}
                    inputProps={{
                      value: companyID,
                      readOnly: true,
                    }}
                  />
  
              </div>
      
                  <div>
                    <Select
                      style={{
                        height: "60px",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                      data={departmentList}
                      mapOptions={mapDepartmentOptions.current}
                      onChange={handleDepartmentChange}
                      defaultValue={filters?.departmentId}
                      label={"department"}
                      inputProps={{
                        value: departmentID,
                        readOnly: true,
                      }}
                    />
                  </div>
                

            </Grid>
          </Card>
        </>
      )}

      <Card style={{ marginTop: "20px" }}>
        <Grid>
          {!employeeIdforif && (
            <>
              {employeeId && (
                <div>
                  <Select
                    style={{
                      height: "60px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    data={employees}
                    mapOptions={mapEmployeeOptions.current}
                    onChange={handleEmployeeChange}
                    defaultValue={employeeId}
                    label={"Employee"}
                    inputProps={{
                      value: companyID,
                      readOnly: true,
                    }}
                  />
                </div>
              )}
            </>
          )}

          {!employeeIdforif && (
            <>
              <div>
                <Select
                  style={{
                    height: "60px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  data={types}
                  mapOptions={{ value: "typeId", label: "name" }}
                  onChange={(v) => {
                    setSelectedTypeValue(v.value);
                  }}
                  defaultValue="1"
                  label={"Types"}
                  inputProps={{
                    value: companyID,
                    readOnly: true,
                  }}
                />
              </div>
            </>
          )}

          {employeeIdforif && (
            <TextField
              required
              label="employee name"
              name="employeeName"
              error={!!errors.note}
              msg={errors.note?.message}
              inputProps={{
                ...register("employeeName"),
                required: true,
                disabled: true,
                style: {
                  marginTop: "20px",
                },
              }}
            />
          )}
        </Grid>

        <TextArea
          style={{ maxWidth: "1200px" , marginTop:"20px" }}
          label="NOTES"
          name="NOTES"
          required
          rows="4"
          error={!!errors.notes}
          msg={errors.notes?.message}
          inputProps={{
            ...register("notes"),

            autoFocus: true,
            required: true,
          }}
        />

        <Grid>
        <InputDate
            label={selectedTypeValue == 9 ? "Absence Day" : "Absence From"}
            name="absenceFrom"
            error={!!errors.absenceFrom}
            msg={errors.absenceFrom?.message}
            value={new Date(absenceFrom)}
            type={selectedTypeValue == 9 ?"time" : 'date'}
            onDateChange={(v) => {
              day_for_hourly_absence = v;
              setAbsenceFrom(v);
              setValue('absenceFrom' , v)
            }}
          />


          {selectedTypeValue == 9 ? (
            <>
              <Select
              label="Absence"
                data={[{id:'1' , value:1} ,{id:'2' , value:2},{id:'3' , value:3},{id:'4' , value:4}]}
                mapOptions={{ label: "id", value: "value" }}
                onChange={(v) => setTime(v.value)}
                defaultValue={time}
                disabled
              />
            </>
          ) : (
            <>
            <InputDate
            label='Absence To'
            name='absenceTo'
            error={!!errors.absenceTo}
            msg={errors.absenceTo?.message}
            value={new Date(AbsenceTo)}
            type="date"
            onDateChange={(v) => {
              setAbsenceTo(v);
              setValue('absenceTo' , v)
            }}
            

          />
              
            </>
          )}
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default LeaveForm;
