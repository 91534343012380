import styled from "styled-components";

const Cell = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    font-size: 14px;
  }
`;

function EmployeeNameCell({ fullName, jobDescription }) {
  return (
    <Cell>
        <strong>{fullName}</strong>
        <span>{jobDescription}</span>
    </Cell>
  );
}

export default EmployeeNameCell;