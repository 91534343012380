import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Divider, Icon, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useOrders } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "../../data/countries.json";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import fields from "./fields";
import TextArea from "../../ui/TextArea";
import { useSupliers } from "../supliers/provider";
import { usePurches } from "../purches/provider";

const schema = Yup.object().shape({
  employeeId: Yup.string(),
  companyId: Yup.string(),
 departmentId: Yup.string(),
 taxRate:Yup.number().typeError('you must specify a number')
 .required("Required"),
 other:Yup.number().typeError('you must specify a number').required("Required"),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Required"),
      quantity: Yup.number().typeError('you must specify a number').required("Required"),
      unitPrice : Yup.number().typeError('you must specify a number').required("Required"),
    })
  ),
});

const ShiftsWrap = styled.div`
  > div {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 24px;
    gap: 20px;
    width: 1200px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    span.remove {
      margin-top: 30px;
      display: block;
      justify-self: center;
      cursor: pointer;
      svg {
        fill: ${(props) => props.theme.color.grayText};
      }
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
    }
    span.add {
      cursor: pointer;
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
    h4 {
      margin: 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.grayText};
    }
  }
`;

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  .
  
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  margin-buttom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function OrdersForm({ forEdit, handleSuccess ,selected ,supplierId , setSupplierId }) {
  let params = useParams();
  const [error, setError] = useState(null);

  const [items, setItems] = useState(
    Array(1)
      .fill(0)
      .map(() => ({ description: "", quantity: "" , unitPrice:"" }))
  );
  const navigate = useNavigate();
  const {
    state: { list: leave, emps: employee, deps: departments, page , pid },
    create,
    fetchOne,
    edit,
  } = useOrders();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues:selected,
  });

  const {
    state: { companyID  },
  } = usePurches();
  //fetching for filters
  const [departmentId, setDepartmentId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);

  const [writtenText , setWrittenText] = useState(null);


  const {
    state: { list},
    fetchList:fetchSupliers,
  } = useSupliers();

  //fetch companies
console.log( companyID  , 'companyId');
  useEffect(()=>{
  if(companyID)    fetchSupliers(companyID); 
  },[companyID])
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();




  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch departments

  const {
    state: { list: departmentList, status },
    fetchList: fetchDepartments,
  } = useDepartments();

  useEffect(() => {
    if (departmentList.length > 0) setDepartmentId(departmentList[0].id);
    else setDepartmentId(0);
  }, [fetchDepartments, departmentList]);

  const handleDepartmentChange = useCallback(
    (id) => {
      setDepartmentId(id.data.id ? id.data.id : 0);
    },
    [departmentList]
  );


  //fetch employees
  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  useEffect(() => {
    if (employees.length) setEmployeeId(employees[0].id);
  }, [employees]);


  const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });

  //items array
  useEffect(() => { 
    let id;
    if (selected?.items) {
      id = selected?.companyId;
      //console.log(selected ,'selected aaaaaaaaaaaaaaa');
      let sh = selected?.items.map((shifts, i) => {
        items.description = shifts.description;
        items.quantity = shifts.quantity;
        items.unitPrice = shifts.unitPrice;

        //console.log(items ,'items0');
        return shifts;
      });
      setItems(sh);
    } else {
      id = companyID;

      setValue("items", items);
    }
     if(forEdit)setSupplierId(selected?.supplierId)
    
    fetchCompanies(id);
  }, [selected, companyID, fetchCompanies]);

  console.log(supplierId, 'selected');
  const additem = () => {
    setItems((shifts) => {
      shifts.push({ description: "", quantity: "" ,unitPrice:"" });
      return [...shifts];
    });
  };

  const removeitem = (i) => {
    let newi = items.filter((s, i2) => {
      console.log(s, i2, i);
      return i !== i2;
    });
    setItems(newi);
  };

  useEffect(() => {
    if (selected) setValue("description", selected?.description);
  }, [selected , selected?.supplierId]);

  //submitting
  const onSubmit = async (values) => {
    //console.log(values , supplierId , writtenText, "onSubmit");
    //return;
    setValue("items", items);
    setValue("supplierId", supplierId);
    setValue("supplierName", writtenText);

    
    try {
      if (forEdit) {
        let dataa = null;
        if (params.id)

          dataa = {
            purchaseRequestId: params.id,
            description: values.description,
            supplierId:supplierId,
            supplierName:writtenText,
            other:getValues('other'),
            taxRate: getValues('taxRate'),
            items: items,
          };
        else
          dataa = {
            companyId: params.companyId,
            description: values.description,
            supplierId:supplierId,
            supplierName:writtenText,
            other:getValues('other'),
            taxRate: getValues('taxRate'),
            items: items,
          };

        await edit(forEdit, dataa);

        reset();
      } else {
        let dataa = null;
        if (params.id)
          dataa = {
            purchaseRequestId: params.id,
            description: values.description,
            supplierId:supplierId,
            supplierName:writtenText,
            other:getValues('other'),
            taxRate: getValues('taxRate'),
            items: items,
          };
        else
          dataa = {
            companyId: params.companyId,
            description: values.description,
            supplierId:supplierId,
            supplierName:writtenText,
            other:getValues('other'),
            taxRate: getValues('taxRate'),
            items: items,
          };

        await create(dataa);
        reset();
      }

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      setError({ title: e.errors.message || 'please provide a valid data'});
      console.log("e", e.errors);
    }
  };

useEffect(() => {
  forEdit&&isSubmitting && navigate('/orders/view/' +params.id )
  !forEdit&&isSubmitting && navigate('/orders/view/' +pid  )
},[pid , isSubmitting])

  //fetch one for update
  function displayShiftsFields() {
  
      return items.map((shift, i) => (
        <div key={i} data-count={i +1}>
          <TextField
            style={{ width: "100%" }}
            label=""
            error={!!errors?.items?.[i]?.description}
            msg={errors?.items?.[i]?.description?.message}
            inputProps={{
              value: shift.description,
              onChange: (e) => {
                setItems((shifts) => {
                  shifts[i].description = e.target.value;
                  setValue("items", [...shifts]);
                  return [...shifts];
                });
              },
              required: true,
              type: "string",
            }}
          />
          <TextField
            style={{ width: "100%" }}
            label=""
            error={!!errors?.items?.[i]?.quantity}
            msg={errors?.items?.[i]?.quantity?.message}
            inputProps={{
              value: shift.quantity,
              onChange: (e) => {
                setItems((shifts) => {
                  shifts[i].quantity = e.target.value;
                  setValue("items", [...shifts]);
                  return [...shifts];
                });
              },
              required: true,
              type: "string",
            }}
          />
        <TextField
            style={{ width: "100%" }}
            label=""
            error={!!errors?.items?.[i]?.unitPrice}
            msg={errors?.items?.[i]?.unitPrice?.message}
            inputProps={{
              value: shift.unitPrice,
              onChange: (e) => {
                setItems((shifts) => {
                  shifts[i].unitPrice = e.target.value;
                  setValue("items", [...shifts]);
                  return [...shifts];
                });
              },
              required: true,
              type: "string",
            }}
          />
          <Icon
            className="remove"
            onClick={() => removeitem(i)}
            name="trash"
            w={16}
          />
        </div>
      ));
  }
  const handleSuplierChange = useCallback((id) => {
     setSupplierId(id.data.id)
  }, []);
  const mapSuplierOptions = useRef({ value: "id", label: "name" });
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Card style={{ marginTop: "20px" }}>
        <Grid>
        <div>
            <Select
              data={list}
              mapOptions={mapSuplierOptions.current}
              onChange={handleSuplierChange}
              label={"supplier"}
              defaultValue={supplierId}
              getTextValue={(e)=> setWrittenText(e)}
              inputProps={{
                
                readOnly: true,
              }}
            >
            </Select>
            
          </div>
          <div>
          <TextField
            required
            label="Tax Rate"
            name="taxRate"
            error={!!errors.taxRate}
            msg={errors.taxRate?.message}
            defaultValue={selected?.taxRate}
            inputProps={{
              autoFocus:true,
              ...register("taxRate"),
              required: true,
            }}
          />
          </div>
          <div>
          <TextField
            required
            label="other"
            name="other"
            error={!!errors.other}
            msg={errors.other?.message}

            inputProps={{
              autoFocus:true,
              ...register("other"),
              required: true,
            }}
          />
          </div>


         
        </Grid>
        <div>
            <TextArea
            style={{maxWidth:'1200px'}}
              label="Description"
              name="description"
              rows="4"
              error={!!errors.description}
              msg={errors.description?.message}
              inputProps={{
                ...register("description"),
                autoFocus: true,
                required: true,
              }}
            />
          </div>
        <Grid>
          <div>
            <h3>Items</h3>
            <ShiftsWrap>
              <div>
                <h4>Description</h4>
                <h4>Quantity</h4>
                <h4>unitPrice</h4>
              </div>
              {displayShiftsFields()}

              <div
                style={{ textAlign: "right", border: "none" }}
              >
                <span />
                <span />
                <span />

                <Icon 
                onClick={additem}
                
                name="addSquare" w={24} className="add" />
              </div>
            </ShiftsWrap>
          </div>
          <span />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default OrdersForm;
