import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Layout from "../../layout/Layout";
import image from "../../assets/contact.svg";

import { Type, TextField, Icon, Checkbox, Button } from "../../ui";

const schema = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});

const Grid = styled.div`
  
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 65% 35%;
    height: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LeftCol = styled.div`
  background-color: #fff2d2;
  text-align: center;
  img {
    max-width: 90%;
  }
`;
const RightCol = styled.div`
  background-color: white;
  form {
    width: 100%;
    padding: 0 20px;
    @media (min-width: 600px) {
      padding: 0 75px;
    }
    h1 {
      text-align: center;
      margin-bottom: 80px;
    }
    label {
      font-size: 20px;
      font-weight: 600;
      color: #c7c7c7;
    }
    p {
      color: #202020;
      font-weight: 600;
      margin-top: 0;
      font-size: 20px;
    }
    hr {
      margin-top: 30px;
      margin-bottom: 30px;

      border-top: 1px solid #c7c7c7;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  a {
    padding-right: 8px;
  }
`;

function ContactUs() {
  const [hide, setHide] = useState(true);
  const [error, setError] = useState(null);

  let navigate = useNavigate();
  let location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    // try {
    //   await auth.signin({
    //     username: values["username"],
    //     password: values["password"],
    //   });
    //   navigate(from, { replace: true });
    // } catch (error) {
    //   console.log("error", error.status);
    //   if (error.status === 401) setError("Wrong email/password combination!");
    // }
  };

  return (
    <Layout variant="empty">
      <Grid>
        <LeftCol>
          <div>
            <Type var="display">
              <span style={{ fontSize: "50px" }}>Feel free to</span> <br />{" "}
              <em>Contact us</em>
            </Type>
            <img src={image} alt="user multitasking on laptop" />
          </div>
        </LeftCol>
        <RightCol>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {error && <p className="error">{error}</p>}
            <h1>Contact Us</h1>

            <div>
              <label>ِADDRESS</label>
              <p>
                al Mustaqbal St - trade centre - DIFC - Dubai- United Arab
                Emirates
              </p>
            </div>

            <hr></hr>

            <div>
              <label>PHONE NUMBER</label>
              <p>+971 562 312 811</p>
            </div>

            <hr></hr>

            <div>
              <label>FAX</label>
              <p>+971 562 312 811</p>
            </div>
            <hr></hr>

            <div>
              <label>Email</label>
              <p>techteam@stitchain.com</p>
            </div>
          </form>
        </RightCol>
      </Grid>
    </Layout>
  );
}

export default ContactUs;
