import React, { useEffect, useState } from "react";
import TableContainer from "../../../ui/TableContainer";
import FilterForm from "../FilterForm";
import { Button, Card, Flex, Pagination, TextField } from "../../../ui";
import styled from "styled-components";
import { useYear } from "../../year/provider";
import { useTransaction } from "../provider";
import { ceil, filter, floor } from "lodash";
import { useStatus } from "../../../services/StatusProvider";
import { useCompanies } from "../../companies/provider";
import DebitCell from "./GetJournalsCells/DebitCell";
import CreditCell from "./GetJournalsCells/CreditCell";
import {
  dateToLocalConverter,
  exporToExcle,
} from "../../../utilities/functions";
import CreatedDateCell from "../tableCells/CreatedDateCell";
import modifiedDateCell from "../tableCells/modifiedDateCell";
const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

const cols = [
  { name: "debit", label: "debit", render: DebitCell },
  { name: "credit", label: "credit", render: CreditCell },
  { name: "notes", label: "Notes" },
  { name: "accountName", label: "Account" },
  { name: "id", label: "ID" },
  { name: "createdDate", label: "createdDate", render: CreatedDateCell },
  { name: "modifiedDate", label: "modifiedDate", render: modifiedDateCell },
];
function GetJournals() {
  const [error, setError] = useState();
  const [companyId, setCompanyId] = useState();
  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: { journals, jstatus, jcount, jpage, perPage, filters, Alllist },
    fetchList,
    remove,
    dispatch,
    setFilters,
    getJournal,
    fetchAll,
  } = useTransaction();
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!years && companyId && !filters)
      setFilters({
        companyId: companyId,
        //financialYearId: years[0]?.id,
      });
  }, [companyId, years]);

  const {
    state: { isAcc },
  } = useStatus();

  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);
  let show = ["date"];

  const [journalEntries, setJournalEntries] = useState([]);
  const [resList, setResList] = useState([]);
  const getAllPages = async (
    url,
    filters,
    fullSize,
    accessPoint,
    pageSize = 150
  ) => {
    let pages = parseInt(fullSize / pageSize);
    for (let i = 0; i < pages + 1; i++) {
      await fetchAll(filters, url, accessPoint, i, pageSize);
    }
  };

  useEffect(() => {
    if (filters?.journalDate)
      getAllPages(`AccountingReports/GetJournal`, filters, "journals", jcount);
  }, [filters]);
  useEffect(() => {
    if (!journalEntries.length)
      journals.map((e) => setJournalEntries((d) => [...d, ...e.entries]));
  }, [journals]);

  const getExcelObj = (x) => {
    let newObj = new Array(Alllist.length);
    Alllist?.map((item, idx) => {
      item?.entries.map((i, index) => {
        newObj.push({
          Debit: i.debit,
          Credit: i.credit,
          Notes: i.notes,
          Account: i.accountName,
        });
      });
    });
    return newObj.filter((item) => {
      return item;
    });
  };

  const handleChangePage = (newPage) => {
    setJournalEntries([]);
    dispatch({ type: "set_journalpage", payload: newPage });
  };

  useEffect(() => {
    if (filters?.journalDate) getJournal(filters, jpage);
  }, [fetchList, filters, jpage, perPage]);

  return (
    <>
      <Grid>
        <h2>Get Journals</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            exporToExcle(
              filters.journalDate && getExcelObj(Alllist),
              "journal report  in: " + filters.journalDate
            )
          }
        >
          Export Excel
        </Button>
      </Grid>

      <FilterForm
        setFilters={setFilters}
        years={years}
        isAcc={isAcc}
        error={error}
        setError={setError}
        companyId={companyId}
        show={show}
        setJournalEntries={setJournalEntries}
      />
      {/* {journals.length ? (
        journals?.map((journal, index) => {
          return (
            <>
              <h3> journal {index + 1}</h3>
              <Flex jc={"left"} gap="30px">
                <TextField
                  label="Created Date"
                  style={{ width: "15%" }}
                  inputProps={{
                    value: dateToLocalConverter(journal.createdDate),
                    readOnly: true,
                    disabled: true,
                  }}
                />

                <TextField
                  label="Modified Date"
                  style={{ width: "15%" }}
                  inputProps={{
                    value: dateToLocalConverter(journal.modifiedDate),
                    readOnly: true,
                    disabled: true,
                  }}
                />

                <TextField
                  label="notes"
                  style={{ width: "70%" }}
                  inputProps={{
                    value: journal.notes,
                    readOnly: true,
                    disabled: true,
                  }}
                />
              </Flex>
              <TableContainer
                empty={journal?.entries?.length === 0}
                status={jstatus}
                aria-label="journal table"
                cols={cols}
                data={journal?.entries}
              />
            </>
          );
        })
      ) : (
        <>
          <TableContainer
            empty={true}
            status={jstatus}
            aria-label="journal table"
            cols={cols}
            data={[]}
            checkbox
          />
        </>
      )} */}

      <>
        <TableContainer
          empty={journalEntries.length == 0}
          status={jstatus}
          aria-label="journal table"
          cols={cols}
          data={journalEntries}
          checkbox
        />
      </>
      <Pagination
        data-testid="pagination"
        count={jcount}
        page={jpage}
        rowsPerPage={10}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default GetJournals;
