import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import CurrencyList from "./CurrenctList";
import Currency from "./Currency";
import AddCurrency from "./AddCurrency";
import EditCurrency from "./EditCurrency";
import ViewCurrency from "./ViewCurrency";

export default (
  <Route
    path="Currency"
    element={
      <RequireAuth>
        <Currency />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <CurrencyList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddCurrency />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditCurrency />
        </RequireAuth>
      }
    />
    <Route
      path="view"
      element={
        <RequireAuth>
          <ViewCurrency />
        </RequireAuth>
      }
    />
  </Route>
);
