import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import styled from "styled-components";
import { Button } from "../../ui";
import { useNotification } from "../../views/notifications/provider";
import Note from "./Notifications/Note";
// import SectionHeader from "./Notifications/SectionHeader";

// const Nav = styled.nav`
//   border-radius: 10px;
//   border: ${(props) => props.theme.color.gray} 1px solid;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   text-align: center;
//   margin: 35px 70px 0 70px;
//   a {
//     border-radius: 10px;
//     display: block;
//     text-align: center;
//     padding: 6px 0;
//     font-weight: bold;
//     font-size: 20px;
//     text-decoration: none;
//     color: ${(props) => props.theme.color.dark};
//     margin: 1px;
//     &.active {
//       background-color: ${(props) => props.theme.color.blue};
//       color: white;
//     }
//   }
// `;

function Notifications({ dispatch, notify }) {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/notification");
    dispatch({ type: "notify", payload: false });
  };
  const {
    state: { topFive },
    fetchFive,
  } = useNotification();

  useEffect(() => {
    fetchFive();
  }, [fetchFive, notify]);

  if (!topFive) return;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #e6e6e6",
        padding: "10px 0",
        borderRadius: "25px",
      }}
    >
      {topFive.map((noti, index) => {
        return (
          <div key={index} style={{ padding: "0px 10px" }}>
            <Note
              noti={noti}
              title={
                noti?.title.length > 25
                  ? noti.title.substr(0, 25) + "..."
                  : noti.title
              }
            >
              {noti?.body.length > 40
                ? noti.body.substr(0, 40) + "..."
                : noti.body}
            </Note>
          </div>
        );
      })}

      <div style={{ padding: "10px 0" }}>
        <Button
          className="small"
          onClick={handleClick}
          style={{ width: "90%", margin: "auto" }}
        >
          View All
        </Button>
      </div>
    </div>
  );
}

export default Notifications;
