import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Transaction from "./Transaction";
import TransactionList from "./TransactionList";
import AddTransaction from "./AddTransaction";
import AddNewSimpleEntry from "./AddNewSimpleEntry";

import EditTransaction from "./EditTransaction";
import ViewTransaction from "./ViewTransaction";

export default (
  <Route
    path="transaction"
    element={
      <RequireAuth>
        <Transaction />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <TransactionList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddTransaction />
        </RequireAuth>
      }
    />

    <Route
      path="newsimpleentry"
      element={
        <RequireAuth>
          <AddNewSimpleEntry />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditTransaction />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewTransaction />
        </RequireAuth>
      }
    />
  </Route>
);
