import React from 'react'

function CurrencyCell(row) {
  if(!row) return
    return (
    <div>
        {row?.currencyInfo.shortName}
    </div>
  )
}

export default CurrencyCell