import { memo, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, Divider, TextField } from "../../ui";
import { usePublicHoliday } from "./provider";
import { useNavigate, useParams } from "react-router-dom";
import ListAttendancePatterns from "../attendancePatterns/ListAttendancePatterns";
import Label from "../../ui/InputLabel";
import FileField from "../../ui/FileField";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  /* max-width: 710px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Wrap = styled.div`
  margin-left: 20px;
  .preview {
    width: 255px;
    min-height: 180px;
    background-color: ${(props) => props.theme.color.gray200};
    background-size: cover;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100%;
      margin: 0;
      display: block;
      border: ${props => props.theme.color.grayBg} 1px solid;
    }
  }
`;

// const ButtonWrapper = styled.div`
//   margin-top: 40px;
//   button {
//     float: right;
//     margin-left: 20px;
//     min-width: 180px;
//   }
// `;

function ViewPublicHoliday() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { selected },
    fetchOne,
  } = usePublicHoliday();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne]);

  if (!selected) return;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Company Information</h2>
        <div style={{ cursor: "pointer" }}>
          <Button
            onClick={() => navigate(`/departments/company/${params.id}`)}
            className="small"
            iconName="building"
            bg="rose"
          >
            Departments
          </Button>
          <Button
            style={{
              marginLeft: "30px",
            }}
            onClick={() => navigate("/employees/new/")}
            iconName="userAdd"
            className="small"
            bg="rose"
          >
            Employee
          </Button>
          <Button
            style={{
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/companies/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <Grid2>
            <TextField
              label="Business Name"
              value={selected.businessName}
              inputProps={{
                value: selected.businessName,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Email Address"
              inputProps={{
                value: selected.email,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Tax Number"
              inputProps={{
                value: selected.taxNumber,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Website"
              inputProps={{
                value: selected.webSite,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="registrationDate"
              inputProps={{
                value: selected.registrationDate,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Location"
              inputProps={{
                value: selected.location,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Business Type"
              inputProps={{
                value: selected.businessType,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Wage Tax"
              inputProps={{
                value: selected.wageTax,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Phone Number"
              inputProps={{
                value: selected.phone,
                readOnly: true,
                disabled: true,
              }}
            />
            {/* <DownLoadFile selectedFile={selectedFile}></DownLoadFile> */}
            <div />
            <FileField
              label="Contract"
              buttonLabel={"Company Contract"}
              readOnly
              document
              documentFile={{name: selected.contract, url: selected.contractUrl}}
              disabled
            />
          </Grid2>
          <Wrap>
            <Label>Company Logo</Label>
            <div className="preview">
              {Boolean(selected.logoUrl) && (
                <img src={selected.logoUrl} alt="preview uploaded file" />
              )}
            </div>
          </Wrap>
        </Grid2>
      </Card>
                <Divider h={50} />
      
      
    </div>
  );
}

export default memo(ViewPublicHoliday);
