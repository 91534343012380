import styled from "styled-components";
import { Button, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useHolidays } from "./provider";

const cols = [
  { name: "holidayName", label: "Holiday" },
  { name: "companyName", label: "Company Name" },
  {
    name: "departments",
    label: "Departments",
    render: HolidayDepartmentsCell,
  },
  { name: "startDate", fn: "date", label: "Start Date" },
  { name: "endDate", label: "Days", render: HolidayDaysCell },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function HolidayDaysCell({ startDate, endDate }) {
  function dateDiff(start, end) {
    var d1 = new Date(start);
    var d2 = new Date(end);
    var diff = d2.getTime() - d1.getTime();
    return diff / (1000 * 60 * 60 * 24) +1;
  }
  return dateDiff(startDate, endDate);
}

function HolidayDepartmentsCell({ departments }) {
  return (
    <div>
      {departments?.map((dep, i) => (
        <span key={i}>
          {dep.departmentName}
          {i + 1 < departments.length && ", "}
        </span>
      ))}
    </div>
  );
}

function ListHolidays() {
  const {id} = useParams();

  const {
    state: { list, status, count, page, perPage },
    fetchList,
    remove,
    dispatch,
  } = useHolidays();

  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    fetchList(id, page, perPage);
  }, [fetchList, id, page, perPage]);

  const handleClick = () => {
    navigate("/holidays/new");
  };

  const handleView = (id) => {
    navigate(`/holidays/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/holidays/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Holiday has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
    useEffect(()=>{
      dispatch({ type: "set_selected", payload: null });
    },[])
    
    // if (!list) return;
  return (
    <>
      <Grid>
        <h2>Holidays List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Holidays table"
        cols={cols}
        data={list}
        checkbox
        showButton={handleView}
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default ListHolidays;
