import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { usePublicHoliday } from "./provider";
import { Button, Card, Checkbox, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
// import UploadFile from "../../ui/UploadFile";
import FileField from "../../ui/FileField";
import InputDate from "../../ui/InputDate";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(originalValue) {
  originalValue = new Date(originalValue);
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return (
    originalValue.getFullYear() +
    "-" +
    (originalValue.getMonth() + 1).padLeft() +
    "-" +
    originalValue.getDate().padLeft()
  );
}

const schema = Yup.object().shape({
  holidayName: Yup.string().required(),
  countryCode: Yup.string().required(),
  startDate: Yup.string().required("required").transform(emptyDate),
  endDate: Yup.string().required("required").transform(emptyDate),
});

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 0;
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function PublicHolidayForm({ holiday = null, handleSuccess }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { create, edit } = usePublicHoliday();
  const [code, setCode] = useState(countries[0]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: holiday,
  });

  const onSubmit = async (values) => {
    if (
      new Date(values.endDate).getTime() < new Date(values.startDate).getTime()
    ) {
      setError({ title: "end date must be after start date" });
      return;
    }

    try {
      if (holiday) await edit(holiday.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status == 400)
        setError({ title: "Please fill all fields correctly" });
      if (e.status == 409) setError({ title: "Already exists" });

      console.log("e", e, error);
    }
  };
  // if(!holiday) return
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <TextField
            label="Holiday Name"
            name="holidayName"
            error={!!errors.holidayName}
            msg={errors.holidayName?.message}
            defaultValue={holiday?.holidayName}
            inputProps={{
              ...register("holidayName"),
              autoFocus: true,
              required: true,
            }}
          />
          <Select
            label="Country"
            data={countries}
            mapOptions={{ value: "code", label: "name" }}
            required={true}
            defaultValue={getValues("countryCode") ?? code}
            onChange={(e) => setValue("countryCode", e.value)}
            error={!!errors["countryCode"]}
            msg={errors["countryCode"]?.message}
            inputProps={{
              ...register("countryCode"),
            }}
          />

          <InputDate
            label="Start Date"
            name="startDate"
            error={!!errors.startDate}
            msg={errors.startDate?.message}
            value={new Date(getValues("startDate"))}
            type="date"
            onDateChange={(v) => {
              setValue("startDate", v);
            }}
          />

          <InputDate
            label="End Date"
            name="endDate"
            error={!!errors.endDate}
            msg={errors.endDate?.message}
            value={new Date(getValues("endDate"))}
            type="date"
            onDateChange={(v) => {
              setValue("endDate", v);
            }}
          />

          <Checkbox
            id="is-fixed"
            name="isFixed"
            label="Fixed"
            inputProps={{
              ...register("isFixed"),
            }}
          />
          <Checkbox
            id="is-active"
            name="isActive"
            label="Active"
            inputProps={{
              ...register("isActive"),
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(PublicHolidayForm);
