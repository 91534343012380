import React, { useEffect, useState } from "react";
import TableContainer from "../../ui/TableContainer";
import FilterForm from "./FilterForm";
import { Button, Card, Pagination } from "../../ui";
import styled from "styled-components";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "./provider";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import { useDepartments } from "../departments/provider";
import { rmValueFromObj } from "../../utilities/functions";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

function EmployeeReport() {
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch leaves
  const {
    state: { reportList: list, status, count, page, filters },
    fetchReportList: fetchLeaves,
    dispatch,
    setFilters,
  } = useEmployees();

  const {
    state: { list: departments },
    fetchList: fetchDepartments,
  } = useDepartments();

  // const [departmentList, setDepartmentList] = useState();

  // function DepartmentNameCell(data) {
  //   if (!data) return;
  //   return (
  //     <>
  //       {departments
  //         .filter((d) => d.id === data.departmentId)
  //         .map((d) => d.departmentName)}
  //     </>
  //   );
  // }

  //cols
  const cols = [
    { name: "fullName", label: "Full Name" },
    { name: "departmentName", label: "Department" },
    { name: "basicSalary", label: "Basic Salary" },
    { name: "hiringDate", label: "Hiring Date" },
    { name: "tenureYears", label: "Tenure Years" },
    { name: "tenureMonths", label: "Tenure Months" },
  ];

  useEffect(() => {
    if (companies.length && !filters.companyId)
      setFilters({ companyId: companies[0]?.id });
  }, [setFilters, filters, companies]);

  useEffect(() => {
    fetchLeaves({
      ...rmValueFromObj(filters, [0, null, ""]),
      pagenumber: page + 1,
    });
  }, [fetchLeaves, filters, page]);

  useEffect(() => {
    if (filters.companyId && !departments.length)
      fetchDepartments(filters.companyId);
  }, [fetchDepartments, filters, departments]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtention = ".xlsx";

  const exporToExcle = async (exdata) => {
    const ws = XLSX.utils.json_to_sheet(exdata);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "ُEmployee Report" + now + fileExtention);
  };

  return (
    <>
      <Grid>
        <h2>Employees Report</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() => exporToExcle(list)}
        >
          Export Excel
        </Button>
      </Grid>

      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        {filters.companyId && <FilterForm setFilters={setFilters}></FilterForm>}
      </Card>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={list}
        checkbox
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={filters.pagesize}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default EmployeeReport;
