import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useTransaction() {
  return useContext(Context);
}

const initState = {
  list: [],
  Alllist: [],
  AllGeneralLedger: [],

  filters: {},
  selected: null,
  formData: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
  simplejournalType: 1,
  //journals
  journals: [],
  jpage: 0,
  jstatus: "idle",
  jcount: 0,

  //general ledger
  generalLedger: [],
  generalLedgerfilters: null,
  generalLedgerpage: 0,
  generalLedgerstatus: "idle",
  generalLedgercount: 0,

  //trial balance
  trailBalance: [],
  trailBalancepage: 0,
  trailBalancestatus: "idle",
  trailBalancecount: 0,

  //income statment
  incomeStatment: [],
  incomeStatmentpage: 0,
  incomeStatmentstatus: "idle",
  incomeStatmentcount: 0,

  //balance sheet
  balanceSheet: [],
  balanceSheetpage: 0,
  balanceSheetstatus: "idle",
  balanceSheetcount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_Alllist":
      return { ...state, Alllist: [...action.payload] };
    case "set_AllgeneralLedgers":
      return { ...state, AllGeneralLedger: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_formdata":
      return { ...state, formData: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_filters":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    //journals
    case "set_journals":
      return { ...state, journals: [...action.payload] };
    case "set_journalpage":
      return { ...state, jpage: action.payload };
    case "set_journalstatus":
      return { ...state, jstatus: action.payload };
    case "set_journalcount":
      return { ...state, jcount: action.payload };

    //general ledger
    case "set_general_ledger":
      return { ...state, generalLedger: [...action.payload] };
    case "set_general_ledger_page":
      return { ...state, generalLedgerpage: action.payload };
    case "set_general_ledger_status":
      return { ...state, generalLedgerstatus: action.payload };
    case "set_general_ledger_count":
      return { ...state, generalLedgercount: action.payload };
    case "set_generalledgerfilters":
      return {
        ...state,
        generalLedgerfilters: { ...state.filters, ...action.payload },
      };
    //trail balance
    case "set_trail_balance":
      return { ...state, trailBalance: [...action.payload] };
    case "set__trail_balance_page":
      return { ...state, trailBalancepage: action.payload };
    case "set_trail_balance_status":
      return { ...state, trailBalancestatus: action.payload };
    case "set_trail_balance_count":
      return { ...state, trailBalancecount: action.payload };

    //income statment
    case "set_income_statment":
      return { ...state, incomeStatment: action.payload };
    case "set_income_statment_page":
      return { ...state, incomeStatmentpage: action.payload };
    case "set_income_statment_status":
      return { ...state, incomeStatmentstatus: action.payload };
    case "set_income_statment_count":
      return { ...state, incomeStatmentcount: action.payload };

    //Balance Sheet
    case "set_balance_sheet":
      return { ...state, balanceSheet: action.payload };
    case "set_balance_sheet_page":
      return { ...state, balanceSheetpage: action.payload };
    case "set_balance_sheet_status":
      return { ...state, balanceSheetstatus: action.payload };
    case "set_balance_sheet_count":
      return { ...state, balanceSheetcount: action.payload };

    case "set_simple_journal_type":
      return { ...state, simplejournalType: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function TransactionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `Accounting/LisYearJournals`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.journalEntries });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchAll = useCallback(
    async (filter, url, accessPoint, page = 0, pageSize) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        pageSize: pageSize,
        ...filter,
      };

      const resData = await req(url, null, {}, true, headerData);

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_Alllist", payload: resData.data[accessPoint] });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchAllGeneralLedger = useCallback(
    async (filter, url, accessPoint, page = 0, pageSize) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        pageSize: pageSize,
        ...filter,
      };

      const resData = await req(url, null, {}, true, headerData);

      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_AllgeneralLedgers",
        payload: resData.data[accessPoint],
      });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const getJournal = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "set_journalstatus", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `AccountingReports/GetJournal`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "set_journalstatus", payload: `idle` });
      dispatch({
        type: "set_journals",
        payload: resData.data.journal,
      });
      dispatch({ type: "set_journalcount", payload: resData.data.totalSize });
    },
    [req]
  );

  const getGeneralLedger = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "set_general_ledger_status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `AccountingReports/GetGeneralLedger`,
        null,
        {},
        true,
        headerData
      );

      dispatch({ type: "set_general_ledger_status", payload: `idle` });
      dispatch({
        type: "set_general_ledger",
        payload: resData.data.generalLedgerRecords,
      });
      dispatch({
        type: "set_general_ledger_count",
        payload: resData.data.totalSize,
      });
    },
    [req]
  );

  const getTrailBalance = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "set_trail_balance_status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `AccountingReports/GetTrialBalance`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "set_trail_balance_status", payload: `idle` });
      dispatch({
        type: "set_trail_balance",
        payload: resData.data,
      });
      dispatch({
        type: "set_trail_balance_count",
        payload: resData.data.totalSize,
      });
    },
    [req]
  );

  const GetIncomeStatment = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "set_income_statment_status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `AccountingReports/GetIncomeStatement`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "set_income_statment_status", payload: `idle` });
      dispatch({
        type: "set_income_statment",
        payload: resData.data,
      });
      dispatch({
        type: "set_income_statment_count",
        payload: resData.data.totalSize,
      });
    },
    [req]
  );

  const GetBalanceSheet = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "set_balance_sheet_status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `AccountingReports/GetBalanceSheet`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "set_balance_sheet_status", payload: `idle` });
      dispatch({
        type: "set_balance_sheet",
        payload: resData.data,
      });
      dispatch({
        type: "set_balance_sheet_count",
        payload: resData.data.totalSize,
      });
    },
    [req]
  );

  const fetchOne = useCallback(
    (filter) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        let headerData = {
          ...filter,
        };
        const resData = await req(
          `Accounting/JournalEntryInfo`,
          null,
          {},
          true,
          headerData
        );
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          delete data.companyId;
          const resData = await req(
            "Accounting/CreateJournalEntry",
            data,
            { method: "POST" },
            true
          );

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const createSimpleEntry = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          delete data.companyId;
          const resData = await req(
            "Accounting/CreateSimpleJournalEntry",
            data,
            { method: "POST" },
            true
          );

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Accounting/UpdateJournalEntry/${id}`,
            data,
            { method: "PUT" },
            true
          );

          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(
        `Accounting/DeleteJournalEntry/${id}`,
        {},
        { method: "DELETE" },
        true
      );
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const createDefaultChart = useCallback(
    async (id) => {
      await req(
        `AccountingUtils/CreateDefaultChartOfAccounts`,
        {},
        { method: "POST" },
        true,
        { companyId: id }
      );
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  const setFormData = useCallback((filters) => {
    dispatch({ type: "set_formdata", payload: filters });
  }, []);

  const setGeneralledgerfilters = useCallback((filters) => {
    dispatch({ type: "set_generalledgerfilters", payload: filters });
  }, []);

  const setSimpleJournalType = useCallback((type) => {
    dispatch({ type: "set_simple_journal_type", payload: type });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchOne,
        create,
        edit,
        remove,
        setFilters,
        setFormData,
        getJournal,
        getGeneralLedger,
        setGeneralledgerfilters,
        fetchAll,
        getTrailBalance,
        GetIncomeStatment,
        GetBalanceSheet,
        fetchAllGeneralLedger,
        createSimpleEntry,
        setSimpleJournalType,
        createDefaultChart,
      }}
    >
      {children}
    </Context.Provider>
  );
}
