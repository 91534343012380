import { lazy, Suspense } from "react";
import styled, { css } from "styled-components";
import { firstUp } from "../utilities/functions";
import { MdNotificationAdd } from "react-icons/md";
import { LuSettings } from "react-icons/lu";
const icons = {
  Hide: lazy(() => import("./icons/Hide")),
  Eye: lazy(() => import("./icons/Eye")),
  Home: lazy(() => import("./icons/Home")),
  Suitcase: lazy(() => import("./icons/Suitcase")),
  Search: lazy(() => import("./icons/Search")),
  Bell: lazy(() => import("./icons/Bell")),
  AddSquare: lazy(() => import("./icons/AddSquare")),
  Calendar: lazy(() => import("./icons/Calendar")),
  Logout: lazy(() => import("./icons/Logout")),
  User: lazy(() => import("./icons/User")),
  Attendance: lazy(() => import("./icons/Attendance")),
  Payroll: lazy(() => import("./icons/Payroll")),
  Settings: lazy(() => import("./icons/Settings")),
  UserCase: lazy(() => import("./icons/UserCase")),
  UserAdd: lazy(() => import("./icons/UserAdd")),
  Phone: lazy(() => import("./icons/Phone")),
  Envelope: lazy(() => import("./icons/Envelope")),
  EllipsisVertical: lazy(() => import("./icons/EllipsisVertical")),
  Invoice: lazy(() => import("./icons/Invoice")),
  Disk: lazy(() => import("./icons/Disk")),
  ArrowLeft: lazy(() => import("./icons/ArrowLeft")),
  Pen: lazy(() => import("./icons/Pen")),
  Trash: lazy(() => import("./icons/Trash")),
  Repeat: lazy(() => import("./icons/Repeat")),
  Building: lazy(() => import("./icons/Building")),
  BuildingUser: lazy(() => import("./icons/BuildingUser")),
  SuitcaseAdd: lazy(() => import("./icons/SuitcaseAdd")),
  CalendarDetailed: lazy(() => import("./icons/CalendarDetailed")),
  UserAddOutlined: lazy(() => import("./icons/UserAddOutlined")),
  Close: lazy(() => import("./icons/Close")),
  Clock: lazy(() => import("./icons/Clock")),
  CalendarLeaves: lazy(() => import("./icons/CalendarLeaves")),
  CalendarHolidays: lazy(() => import("./icons/CalendarHolidays")),
  Document: lazy(() => import("./icons/Document")),
  CircleXMark: lazy(() => import("./icons/CircleXMark")),
  Picture: lazy(() => import("./icons/Picture")),
  Print: lazy(() => import("./icons/Print")),
  CloudArrowUp: lazy(() => import("./icons/CloudArrowUp")),
  Currency: lazy(() => import("./icons/Currency")),
  Purches: lazy(() => import("./icons/Purches")),
  Report: lazy(() => import("./icons/Report")),
  HRaccount: lazy(() => import("./icons/HRaccount")),
  CaretDown: lazy(() => import("./icons/CaretDown")),
};

const Wrap = styled.span`
  line-height: 0;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      width: calc(10px + ${(props) => props.w}px);
      height: calc(10px + ${(props) => props.w}px);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: ${props.theme.color.gray200} 0px solid;
      &:hover {
        fill: ${props.theme.color[props.hover]};
        background-color: ${props.theme.color.gray200};
        outline: ${props.theme.color.gray200} 10px solid;
      }
      &:active {
        background-color: ${props.theme.color.gray400};
        outline: ${props.theme.color.gray400} 10px solid;
        transform: scale(0.95);
      }
    `}
  svg {
    fill: ${(props) => props.c && props.theme.color[props.c]};
    width: ${(props) => props.w}px;
  }
  ${(props) =>
    props.circle &&
    css`
      width: ${props.w + 22}px;
      height: ${props.w + 22}px;
      display: inline-block;
      border-radius: 200px;
      background-color: ${(props) => props.c && props.theme.color[props.c]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

function Icon({ name, w = "30", c = "dark", hover, ...rest }) {
  if (!icons[firstUp(name || "")]) return;
  const Component = icons[firstUp(name)];
  return (
    <Suspense fallback={"loading"}>
      <Wrap {...{ w, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;
