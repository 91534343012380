import styled from "styled-components";
import Layout from "../../layout/Layout";
import ConsoleHomepage from "./ConsoleHomepage";
import StatCard from "./StatCard";
import Welcome from "./Welcome";
import Salaries from "./charts/Salaries";
import Item from "./ConsoleHomepage/Item";
import Departments from "./charts/Departments";
import EmployeeBySalary from "./charts/EmployeeBySalary.js";
import AttendancePerMonth from "./charts/AttendancePerMonth";
import ListCompanies from "../companies/ListCompanies";
import { useDashboard } from "./provider";
import { useEffect } from "react";
import { Divider } from "../../ui";
import ActiveEmployee from "./charts/ActiveEmployee";
import EmployeeHirarchy from "./charts/EmployeeHirarchy";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  margin: 20px 0 50px 0;
  @media only screen and (max-width: 1300px) {
    display: block;
    > div {
      margin-bottom: 30px;
    }
  }
  svg{
    path{
      fill: white
    }
  }
`;

const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 40px;
  margin-top: 20px;
  @media only screen and (max-width: 1300px) {
    display: block;
    > div {
      margin-bottom: 30px;
    }
  }
`;

function Dashboard() {
  const {
    state: { list: statistics },
    fetchStatistic,
  } = useDashboard();

  useEffect(() => {
    fetchStatistic();
  }, [fetchStatistic]);

  // if (!statistics ) return
  return (
    <Layout>
      {/* <Welcome /> */}
      {Boolean(statistics) && (
        <Grid>
          <StatCard
            iconName="suitcase"
            title={statistics.totalCompanies}
            subtitle="Companies"
            color1="#898989"
            color2="#898989"
          />
          <StatCard
            iconName="user"
            title={statistics.totalEmployees}
            subtitle="Employees"
            color1="#898989"
            color2="#898989"
          />
          <StatCard
            iconName="userCase"
            title={statistics.totalUsers}
            subtitle="Users"
            color1="#898989"
            color2="#898989"
          />
        </Grid>
      )}

      <ConsoleHomepage />
      <Divider h={30} />
      <Grid3>
        <Salaries />
        <Departments />
      </Grid3>
      <Grid3>
        <AttendancePerMonth />
        {/* <ActiveEmployee /> */}
      </Grid3>

      <EmployeeBySalary />

      {/* <EmployeeHirarchy /> */}

      <div style={{ marginTop: "50px" }}></div>
      <ListCompanies />
    </Layout>
  );
}

export default Dashboard