import styled from "styled-components";
import { Card, Icon } from "../../ui";
import Link from "../../ui/Link";

const StyledCard = styled(Card)`
  padding: 0;
  box-shadow: 0px 1px 28px #0000001f;
  overflow: hidden;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Li = styled.li`
  a {
    display: flex;
    align-items: center;
    line-height: 0;
    text-decoration: none;
    padding: 30px 40px;
    border-bottom: ${(props) => props.theme.color.grayBg} 1px solid;
    transition: background-color 0.15s ease-out;
    &:hover {
      background-color: ${(props) => props.theme.color.grayBg};
    }
    &:last-child {
      border-bottom: none;
    }
    > span:last-child {
      font-weight: bold;
      font-size: 18px;
      color: ${(props) => props.theme.color.dark};
      margin-left: 20px;
      display: block;
    }
    > span:first-child {
      width: 50px;
      height: 50px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.color[props.icoBg]};
      svg {
        fill: white;
      }
    }
  }
`;

function UserMenu() {
  return (
    <StyledCard>
      <Ul>
        <Li icoBg="primary">
          <Link to="/employees/avatar">
            <span>
              <Icon c="primary" name="picture" w={20} />
            </span>
            <span>Upload Avatar</span>
          </Link>
        </Li>

        <Li icoBg="primary">
          <Link to="/changePassword">
            <span>
              <Icon c="primary" name="User" w={20} />
            </span>
            <span>Change Password</span>
          </Link>
        </Li>
      </Ul>
    </StyledCard>
  );
}

export default UserMenu;
