import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Divider, Pagination } from "../../ui";
import ConsoleHomepage from "../dashboard/ConsoleHomepage";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import FilterForm from "./FilterForm";
import { useAttendance } from "./provider";
import CompanyNameCell from "./CompanyNameCell";
import DateCell from "./DateCell";
import DateCell2 from "./DateCell2";
import { filter } from "lodash";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function AttendanceList() {
  //define stuff
  const navigate = useNavigate();
  const { notify } = useUtils();
  //fetching company
  const [empty, setEmpty] = useState(true);


  // handle add new leave button
  const handleClick = () => {
    navigate("/attendance/new");
  };

  //fetch leaves
  const {
    state: {
      list: attendance,
      selected,
      status,
      count,
      page,
      perPage,
      filters
    },
    fetchList: fetchAttendance,
    remove,
    dispatch,
    setFilters
  } = useAttendance();

  //cols
  const cols = [
    { name: "id", label: "ID Number" },

    {
      name: "employeeInfo.fullName",
      label: "Employee Name",
      additionalInfo: "employeeInfo.jobDescription",
    },
    {
      name: "companyInfo",
      label: "Company Name",
      render: CompanyNameCell,
    },
    { name: "punchIn", label: "PunchIn Time", render: DateCell },
    { name: "punchOut", label: "PunchOut Time", render: DateCell2 },
    { name: "hourlyLeave", label: "Hourly Leave" },

    { name: "delay", label: "Delay" },

    { name: "overTime", label: "Over Time" },
    { name: "overBreak", label: "Over Break" },
    { name: "workTime", label: "Work Time" },
    { name: "shiftTime", label: "Shift Time" },
    { name: "longPres", label: "Long Press" },
    { name: "missPres", label: "Miss Press" },

  ];

  const handleView = (id) => {
    navigate(`/attendance/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/attendance/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("leave has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };


  var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

d.setDate(d.getDate() - 30);
let fom =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();
useEffect(()=>{
  dispatch({ type: "set_selected", payload: null });
  if(!filters.FromDate)setFilters({
    FromDate:fom,
    ToDate:now,
  })
},[])

  useEffect(() => {
    if(filters.companyId) fetchAttendance({...filters, pagenumber: parseInt(page + 1)} );
  }, [page, fetchAttendance, filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  // console.log(attendance , 'attendance');

  if (!attendance ||!filters.FromDate) return;
  return (
    <>
      {/* <Grid>
        <Button
          className="small"
          iconName="userAdd"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            navigate("/leaves/new");
          }}
        >
          New Leave
        </Button>
      </Grid> */}

      <ConsoleHomepage
        noTitle
        noSearch
        noButton
        prepend={
          <Button
            className="small"
            iconName="userAdd"
            onClick={() => {
              navigate("/leaves/new");
            }}
          >
            New Leave
          </Button>
        }
      />

      <Divider h={50} />

      <Grid>
        <h2>Attendance List</h2>
        <Button
          className="small"
          onClick={() => {
            navigate("/attendance/uploadexcel");
          }}
          iconName="invoice"
        >
          Upload Excel
        </Button>
        <Button
          className="small"
          onClick={() => {
            navigate("/attendance/machine");
          }}
          iconName="invoice"
        >
          Machine List
        </Button>

        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm  setFilters={setFilters}></FilterForm>
      </Card>
      <TableContainer
        empty={attendance?.length == 0}
        status={status}
        aria-label="Attendance table"
        cols={cols}
        data={attendance}
        checkbox
        showButton={handleView}
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default AttendanceList;
