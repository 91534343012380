import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Divider, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import ConsoleHomepage from "../dashboard/ConsoleHomepage";
import FilterForm from "./FilterForm";
import { usePayrolls } from "./provider";
import months from "../../data/months.json";
import CompanyNameCell from "./CompanyNameCell";
import SalaryCell from "./TableCell/SalaryCell";

const cols = [
  {
    name: "companyInfo",
    label: "Company Name",
    render: CompanyNameCell,
  },
  { name: "month", fn: (v) => months[v - 1]?.long, label: "month" },
  { name: "year", label: "year" },
  { name: "totalNetSalary", label: "Net Salaries" ,render: SalaryCell },
  { name: "salariesNumber", label: "Salaries Count" },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

let d = new Date();

function PayrollsList() {
  const navigate = useNavigate();
  // const { notify } = useUtils();
  const [companyId, setCompanyId] = useState(null);
  // const [month, setMonth] = useState(null);
  // const [year, setYear] = useState(null);
  const {
    state: { list: payrolls, status },
    fetchList,
    dispatch,
    count,
    page,
    perPage,
  } = usePayrolls();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    if (companies.length  ) setCompanyId(companies[0].id);
  }, [companies]);

  const formatPayrolls = useCallback((payrolls) => {
    const newPayrolls = [];
    payrolls.forEach((item) => {
      if (!item.employeePayrolls?.length) return;
      item.employeePayrolls.forEach((item2) => {
        newPayrolls.push({
          ...item,
          ...item2,
          id: `${item.id}-${item2.employeeId}`,
        });
      });
    });
    return newPayrolls;
  }, []);

  const handleCompanyChange = useCallback((id) => {
    console.log(id , 'iddddddddd');
    setCompanyId(id);
  }, [])

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleClick = (year, month) => {
    navigate(
      `/payrolls/new/${companyId}/${d.getFullYear()}/${d.getMonth() + 1}`
    );

  };

  const handleView = ({companyId, year, month}) => {
    navigate(`/payrolls/view/${companyId}/${year}/${month}`);
  };

  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  return (
    <>
      <ConsoleHomepage forPayroll noTitle noButton/>

      <Divider h={50} />

      <Grid>
        <h2>Payrolls List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm
          handleCompanyChange2={handleCompanyChange}
          fetchAttendance={fetchList}
        ></FilterForm>
      </Card>
      <TableContainer
        empty={payrolls?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={payrolls}
        checkbox
        showButton={handleView}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default PayrollsList;
