import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Pagination, Select, TextField } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import { useSupliers } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import { useState } from "react";
import { useCompanies } from "../companies/provider";
import WebSiteCell from "./table/WebSiteCell";
import { usePurches } from "../purches/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function SupliersList() {
  const [search, setSearch] = useState("");
  let location = useLocation();
  const {
    state: { list: companies, selected: company },
    dispatch: cdispatch,
    fetchList: fetchCompany,
  } = useCompanies();
  const {
    state: { list, status, count, page, perPage, companyID },
    fetchList,
    remove,
    dispatch,
  } = useSupliers();
  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])
    
  const [isPO, setIsPO] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes('HR') && setIsPO(false); 

  }, []);

  const {
    state: { companyID:cid },
    dispatch: dispatchPurches,
  } = usePurches();

  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    if(isPO)
    fetchList(user?.employee?.company.id)
    else
    if (company) fetchList(companyID);
  }, [fetchList, company]);

  const cols = [
    { name: "name", label: "Name" },
    {
      name: "phone",
      label: "Phone",
    },
    { name: "email", label: "Email" },
    { name: "webSite", label: "Web Site", renders: WebSiteCell },
    { name: "address", label: "Address" },
    { name: "ordersCount", label: "ordersCount" },
  ];

  const handleClick = (id) => {
    navigate(`/supliers/new/${cid}`);
  };

  const handleView = ({ id }) => {
    navigate(`/supliers/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/supliers/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Item has been removed");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  useEffect(() => {
    !isPO&&fetchCompany();
  }, [fetchCompany]);

  useEffect(() => {
    cdispatch({ type: "set_selected", payload: companies[0] });
  }, []);

  useEffect(() => {
    if (companies && !companyID &&!isPO)
      dispatch({
        type: "set_selected",
        payload: companies.filter((c) => c.id === location?.state?.id),
      });
    return () => cdispatch({ type: "set_selected", payload: null });
  }, [companies, dispatch]);

  useEffect(()=>{
    dispatch({ type: "companyChange", payload: cid });

  },[])
  const setCompany = useCallback(
    (company) => {
      cdispatch({ type: "set_selected", payload: company.data });
      dispatch({ type: "companyChange", payload: company?.data?.id });
      dispatchPurches({ type: "companychange", payload: company?.data?.id })
    },
    [dispatch]
  );

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  if (!company) return;
  return (
    <>
      <Grid>
        <h2>Supplier List</h2>
        <SearchInput onChange={setSearch} value={search} />
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <div style={{ marginBottom: "30px" }}>
      {isPO ? (
            <TextField
            style={{ width: "230px" }}
              label="company name"
              onChange={(e)=> console.log(e.target.value)}
              inputProps={{
                value: user?.employee?.company?.companyName,
                readOnly: true,
              }}
            />
          ) : (
            <Select
              style={{
                height: "60px",
                width: "230px",
                marginBottom: "0px 75px",
              }}
              data={companies}
              mapOptions={mapCompanyOptions.current}
              onChange={setCompany}
              defaultValue={companyID}
              label={"companies"}
              inputProps={{
                value: companyID,
                readOnly: true,
              }}
            />
          )}
      </div>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Currency table"
        cols={cols}
        data={list.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase(), 0)
        )}
        checkbox
        moreOptions={{
          edit: handleEdit,
          delete: handleDelete,
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default SupliersList;
