import React, { useEffect, useState } from "react";
import { useTransaction } from "../provider";
import FilterForm from "../FilterForm";
import { useCompanies } from "../../companies/provider";
import styled from "styled-components";
import { useStatus } from "../../../services/StatusProvider";
import { useYear } from "../../year/provider";
import GeneralLedgerForm from "./GeneralLedgerForm";
import { Button, Pagination } from "../../../ui";
import TableContainer from "../../../ui/TableContainer";
import CreatedDateCell from "../tableCells/CreatedDateCell";
import {
  dateToLocalConverter,
  exporToExcle,
} from "../../../utilities/functions";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

const cols = [
  { name: "balance", label: "Balance" },
  { name: "credit", label: "Credit" },
  { name: "debit", label: "Debit" },
  { name: "notes", label: "Notes" },
  { name: "createdDate", label: "createdDate", render: CreatedDateCell },
];
function GetGeneralLedger() {
  const [error, setError] = useState();
  const [companyId, setCompanyId] = useState();
  const {
    state: {
      generalLedger,
      generalLedgerstatus,
      generalLedgercount,
      generalLedgerpage,
      perPage,
      generalLedgerfilters,
      AllGeneralLedger,
    },

    fetchList,
    dispatch,
    setFilters,
    getGeneralLedger,
    fetchAllGeneralLedger: fetchAll,
  } = useTransaction();
  let user = JSON.parse(localStorage.getItem("user"));
  console.log(AllGeneralLedger);
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);

  let show = ["years", "account"];

  useEffect(() => {
    if (generalLedgerfilters?.accountId)
      getGeneralLedger(generalLedgerfilters, generalLedgerpage);
  }, [fetchList, generalLedgerfilters, generalLedgerpage, perPage]);
  //
  const getAllPages = async (
    url,
    filters,
    accessPoint,
    fullSize,
    pageSize = 150
  ) => {
    let pages = parseInt(fullSize / pageSize);
    for (let i = 0; i < pages + 1; i++) {
      await fetchAll(filters, url, accessPoint, i, pageSize);
    }
  };

  useEffect(() => {
    if (generalLedgerfilters?.yearId)
      getAllPages(
        `AccountingReports/GetGeneralLedger`,
        generalLedgerfilters,
        "generalLedgerRecords",
        generalLedgercount
      );
  }, [generalLedgerfilters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_general_ledger_page", payload: newPage });
  };
  const getExcelObj = (x) => {
    let newObj = new Array(AllGeneralLedger.length);
    AllGeneralLedger?.map((item, idx) => {
      newObj.push({
        Debit: item.debit,
        Credit: item.credit,
        notes: item.notes,
        createdDate: dateToLocalConverter(item.createdDate),
      });
    });
    return newObj.filter((item) => {
      return item;
    });
  };
  return (
    <>
      <Grid>
        <h2>Get General Ledger</h2>

        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            generalLedgerfilters.accountName &&
            exporToExcle(
              getExcelObj(AllGeneralLedger),
              "General Ledger in: " +
                years
                  .filter((y) => y.id == generalLedgerfilters.yearId)
                  .map((y) => y.title) +
                " for account : " +
                generalLedgerfilters.accountName
            )
          }
        >
          Export Excel
        </Button>
      </Grid>

      <GeneralLedgerForm
        setFilters={setFilters}
        years={years}
        isAcc={isAcc}
        error={error}
        setError={setError}
        companyId={companyId}
        show={show}
      />
      <TableContainer
        empty={generalLedger?.length === 0}
        status={generalLedgerstatus}
        aria-label="generalLedger table"
        cols={cols}
        data={generalLedger}
      />
      <Pagination
        data-testid="pagination"
        count={generalLedgercount}
        page={generalLedgerpage}
        rowsPerPage={10}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default GetGeneralLedger;
