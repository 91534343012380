import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import FilterForm2 from "./FilterForm2";
import { useAttendance } from "./provider";
import MachineDateCell from "./MachineDateCell";
import FilterForm from "./FilterForm";

const Grid = styled.div`
  display: flex;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
  .lastSync{
    font-size:22px;
    justify-content: end;
    flex:1;
  }
`;

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
`;


const TypeCell =(data)=>{
  let x = ''
  if(data.typeId   == 1) x= "PunchIn" 
else if(data.typeId == 2) x= "PunchOut"
else if(data.typeId == 3) x= "Break Out"
else x="Break IN"
  return <>
  {
x
}
  </>
}


function MachineList({}) {
  //define stuff
  const navigate = useNavigate();
  const { notify } = useUtils();
  //fetching company
  const [companyId, setCompanyId] = useState(null);
  const [Eid, setEid] = useState(null);

  const [checked, setChecked] = useState(null);

  const handleSuccess = () => {
    navigate(-1);
    notify("a new attendance has been created successfully.");
  };
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    if (companies.length) setCompanyId(companies[0].id);
  }, [companies]);


  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch leaves
  const {
    state: {
      mlist: attendance,
      status,
      count,
      page,
      perPage,
      filters2,
	  Search,
    isSync,
    companyID,
    departmentID
    },
    fetchMachineList: fetchAttendance,
    remove,
    dispatch,
    setFilters2
  } = useAttendance();

  //cols
  const cols = [
    {
      name: "transactionDate",
      label: "Transaction Date",
      render: MachineDateCell,
    },
    { name: "typeId", label: "Type", render : TypeCell },
    { name: "notes", label: "Notes" },
  ];


  const handleEdit = (id) => {
    navigate(`/attendance/editmachien/${id}` , );
  };



  //create attendance

  const { createAttendanceFromMachine: create } = useAttendance();
  let ids = [];
  const handleSubmit = () => {
    checked.map((item) => {
      ids = [item.id, ...ids];
    });
    try {
      create(checked[0].employeeId, { ids: ids });
      handleSuccess();
    } catch (e) {
      console.log("e", e);
    }
  };
  console.log(filters2 , '2');
  useEffect(() => {
     fetchAttendance({...filters2, pagenumber: parseInt(page + 1)});
  }, [fetchAttendance,filters2 , page]);

if(!attendance) return 
  return (
    <>
      <Grid>
        <h2>Machine List</h2>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <Grid>
        <FilterForm2
              setFilters={setFilters2}
            ></FilterForm2>
        <div className="lastSync">
            {
              isSync ? <p style={{color:"green" ,textAlign:"right"}}>Sync-App on</p>:<p style={{color:"red" ,textAlign:"right"}}>Sync-App off</p>
            }

        </div>
        </Grid>
      </Card>
        <TableContainer
          empty={attendance?.length === 0}
          //status={status}
          aria-label="Leaves table"
          cols={cols}
          data={attendance || []}
          checkbox
          onChecked={setChecked}
          moreOptions={{edit: handleEdit}}
        />
      <Button
        className="small"
        disabled={checked?.length == 0  ? true : false}
        onClick={() => handleSubmit()}
        style={{ float: "right", marginTop: "20px" }}
      >
        save
      </Button>
    </>
  );
}

export default MachineList;
