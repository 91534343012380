import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Select, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useEmployees, useAttendance } from "./provider";
import fields2 from "./fields2";
import TableContainer from "../../ui/TableContainer";
import { dateToLocalConverter, toDateTime } from "../../utilities/functions";
import DateCell from "./Breaks/DateCell";
import DateCell2 from "./Breaks/DateCell2";
import { useUtils } from "../../services/utilsProvider";
import InputDate from "../../ui/InputDate";
import Loading from "../../ui/Loading";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid1 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function transformDate(x) {
  let d = new Date(x + "z").toLocaleString();
  let dd = new Date(d);
  return (
    dd.getDate().padLeft() +
    "-" +
    (dd.getMonth() + 1).padLeft() +
    "-" +
    dd.getFullYear() +
    " " +
    dd.getHours().padLeft() +
    ":" +
    dd.getMinutes().padLeft()
  );
}

const types = [
  { typeId: "1", name: "Regular" },
  { typeId: "2", name: "Overtime" },
  { typeId: "3", name: "Absence" },
  { typeId: "7", name: "Ignored" },
];

const attendanceTransaction = [
  { typeId: "0", name: "Unknown" },
  { typeId: "1", name: "PunchIn" },
  { typeId: "2", name: "PunchOut" },
  { typeId: "3", name: "BreakOut" },
  { typeId: "4", name: "BreakIn" },
];

const AttendanceType = [
  { typeId: "1", name: "Regular" },
  { typeId: "2", name: "Overtime" },
  { typeId: "3", name: "Absence" },
  { typeId: "4", name: "Leave" },
  { typeId: "5", name: "HourlyLeave" },
  { typeId: "6", name: "Officialholiday" },
  { typeId: "7", name: "Ignored" },
];

const shiftcols = [
  { name: "day", label: "day" },
  { name: "start", label: "start" },
  { name: "end", label: "end" },
];

const cols = [
  { name: "id", label: "Id" },
  { name: "breakOut", label: "Break Out", render: DateCell2 },

  {
    name: "breakIn",
    label: "Break In",
    render: DateCell,
  },
  { name: "duration", label: "duration" },
];
function ViewLeave({ editid }) {
  const params = useParams();
  const navigate = useNavigate();

  const [employeeId, setEmployeeId] = useState(0);
  const { notify } = useUtils();
  const [loading, setLoading] = useState();

  const {
    state: { selected, punches: punchList1, delete: d1 },
    fetchOne,
    fetchPunch,
    removeBreak: remove,
  } = useAttendance();

  useEffect(() => {
    setEmployeeId(params.id);
  }, [params.id]);

  useEffect(() => {
    //fetchPunch(emp)
  }, [punchList1]);
  // const {
  //   state: { selected: company },
  //   fetchOne: fetchCompany,
  // } = useCompanies();

  useEffect(() => {
    if (employeeId) fetchOne(employeeId);
  }, [employeeId, fetchOne, d1]);

  // if (!company) return;
  const handleEdit = (id) => {
    navigate(`/attendance/EditBreak/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("break has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selected, "selected");
  useEffect(() => {
    if (!selected || selected.attendanceShift?.id != params.id)
      setLoading(true);
    else setLoading(false);
  }, [selected]);
  if (loading) return <Loading></Loading>;

  if (!selected || selected.attendanceShift?.id != params.id)
    return <Loading></Loading>;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Work Shift Information</h2>
        <div style={{ cursor: "pointer" }}>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/attendance/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid1>
          <TextField
            label="Emplyee Name"
            name="employeeName"
            inputProps={{
              value: selected["attendanceShift"].employeeInfo.fullName,
              type: "text",
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />
          {fields
            .filter((f) => !f.viewDisabled)
            .map((f) => {
              const name = f.viewName || f.name;
              const value =
                f.type === "date"
                  ? new Date(selected[name]).toLocaleDateString("fr-FR")
                  : selected?.attendanceShift[name];
              return (
                <TextField
                  key={name}
                  label={f.label}
                  inputProps={{
                    value,
                    readOnly: true,
                    disabled: true,
                    style: {
                      background: "#fff",
                      borderBottom: "2px solid #ddd",
                      outline: "none",
                    },
                  }}
                />
              );
            })}
        </Grid1>
        <Grid2>
          <>
            <InputDate
              label="In Date"
              value={
                new Date(
                  dateToLocalConverter(selected.attendanceShift["inDate"])
                )
              }
              type="time"
              pickerProps={{
                readOnly: true,
                disabled: true,
              }}
            />

            <InputDate
              label="Out Date"
              value={
                new Date(
                  dateToLocalConverter(selected?.attendanceShift["outDate"])
                )
              }
              type="time"
              pickerProps={{
                readOnly: true,
                disabled: true,
              }}
            />
          </>

          <InputDate
            label="Punch In"
            value={
              new Date(
                dateToLocalConverter(selected?.attendanceShift["punchIn"])
              )
            }
            type="time"
            pickerProps={{
              readOnly: true,
              disabled: true,
            }}
          />

          <InputDate
            label="Punch Out"
            value={
              new Date(
                dateToLocalConverter(selected?.attendanceShift["punchOut"])
              )
            }
            type="time"
            pickerProps={{
              readOnly: true,
              disabled: true,
            }}
          />

          <TextField
            label="Rule In"
            inputProps={{
              value: types
                .filter(
                  (type) =>
                    type.typeId ==
                    selected.attendanceShift["ruleInId"].toString()
                )
                .map((type) => {
                  return type.name;
                }),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label="Rule Out"
            inputProps={{
              value: types
                .filter(
                  (type) =>
                    type.typeId ==
                    selected.attendanceShift["ruleOutId"].toString()
                )
                .map((type) => {
                  return type.name;
                }),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label="Attendance Pattern"
            inputProps={{
              value: selected.attendanceShift?.attendancePatternInfo
                ? selected.attendanceShift.attendancePatternInfo
                    .attendancePatternName
                : "No Pattern",
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Tolerance"
            inputProps={{
              value: selected.attendanceShift["tolerance"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Break Duration"
            inputProps={{
              value: selected.attendanceShift["breakDuration"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Total Breaks"
            inputProps={{
              value: selected.attendanceShift["totalBreaks"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Hourly Leave"
            inputProps={{
              value: selected.attendanceShift["hourlyLeave"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Over Breaks"
            inputProps={{
              value: selected.attendanceShift["overBreak"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Shift Time"
            inputProps={{
              value: selected.attendanceShift["shiftTime"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Long Press"
            inputProps={{
              value: selected.attendanceShift["longPres"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />

          <TextField
            label="Miss Press"
            inputProps={{
              value: selected.attendanceShift["missPres"],
              readOnly: true,
              disabled: true,
              style: {
                background: "#fff",
                borderBottom: "2px solid #ddd",
                outline: "none",
              },
            }}
          />
        </Grid2>
        <Grid1>
          {selected.actualAttendanceShifts?.length === 0 ? (
            <>
              <TextField
                label="In Date"
                inputProps={{
                  value: selected.attendanceShift["inDate"],
                  readOnly: true,
                  disabled: true,
                  style: {
                    background: "#fff",
                    borderBottom: "2px solid #ddd",
                    outline: "none",
                  },
                }}
              />

              <TextField
                label="Out Date"
                inputProps={{
                  value: selected.attendanceShift["outDate"],
                  readOnly: true,
                  disabled: true,
                  style: {
                    background: "#fff",
                    borderBottom: "2px solid #ddd",
                    outline: "none",
                  },
                }}
              />
            </>
          ) : (
            <>
              {selected.actualAttendanceShifts &&
                selected.actualAttendanceShifts.map((s) => {
                  return (
                    <>
                      <TextField
                        label="from"
                        inputProps={{
                          value: transformDate(s.start),
                          readOnly: true,
                          disabled: true,
                          style: {
                            background: "#fff",
                            borderBottom: "2px solid #ddd",
                            outline: "none",
                          },
                        }}
                      />

                      <TextField
                        label="to"
                        inputProps={{
                          value: transformDate(s.end),
                          readOnly: true,
                          disabled: true,
                          style: {
                            background: "#fff",
                            borderBottom: "2px solid #ddd",
                            outline: "none",
                          },
                        }}
                      />
                    </>
                  );
                })}
            </>
          )}
        </Grid1>
        <Grid>
          <h3>Breaks</h3>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/attendance/AddBreak/${params.id}`)}
          >
            Add break
          </Button>
        </Grid>
        <TableContainer
          empty={selected?.breaks.length === 0}
          //status={status}
          aria-label="Leaves table"
          cols={cols}
          data={selected?.breaks || []}
          checkbox
          //onChecked={setChecked}
          moreOptions={{ edit: handleEdit, delete: handleDelete }}
        />
      </Card>
    </div>
  );
}

export default ViewLeave;
