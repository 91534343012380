import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Card } from "../../../ui";
import Select from "../../../ui/select/Select";
import { useStats } from "../../stats/provider";
import months from "../../../data/months.json";
import { genYears } from "../../../utilities/functions";

const chartData = [
  {
    name: "Jan",
    Total: 4000,
  },
  {
    name: "Feb",
    Total: 3000,
  },
  {
    name: "Mar",
    Total: 2000,
  },
  {
    name: "Apr",
    Total: 2780,
  },
  {
    name: "May",
    Total: 1890,
  },
];

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    flex-grow: 1;
  }
`;

// const renderLegendText = (value) => {
//   return (
//     <span
//       style={{
//         color: "#abafb3",
//         fontSize: "15px",
//         paddingRight: "9px",
//         verticalAlign: "middle",
//       }}
//     >
//       {value}
//     </span>
//   );
// };

function Salaries() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const {
    state: { totalSalaries },
    fetchTotalSalaries,
  } = useStats();

  useEffect(() => {
    fetchTotalSalaries(year, month);
  }, [fetchTotalSalaries, year, month]);

  return (
    <div>
      <Title>
        <h2>Salaries per Month</h2>
        <div style={{ marginRight: "10px" }}>
          <Select
            inputStyle={{ height: "48px", width: "110px" }}
            data={genYears(2000)}
            defaultValue={year.toString()}
            onChange={(v) => {setYear(v.value)}}
            inputProps={{
              value: year.toString(),
              onChange: () => {}
            }}
          />
        </div>
        <Select
          inputStyle={{ height: "48px", width: "170px" }}
          data={months.map(m => ({name: m.long, value: m.index}))}
          mapOptions={{label: "name", value: 'value'}}
          defaultValue={month}
          onChange={(v) => setMonth(v.value)}
          inputProps={{
            value: month,
            onChange: () => {}
          }}
        />
      </Title>
      <Card>
        <ResponsiveContainer width="100%" height={420}>
          <BarChart
            width={500}
            height={300}
            data={totalSalaries.map(d => ({name: d.company.companyName, Total: d.totalSalary}))}
            margin={{
              top: 0,
              right: 0,
              left: 5,
              bottom: 15,
            }}
            barCategoryGap={11}
          >
            <CartesianGrid
              strokeDasharray="0 0"
              vertical={false}
              style={{ stroke: "#e5e5e5" }}
            />
            <XAxis
              axisLine={{ stroke: "#e5e5e5" }}
              dataKey="name"
              tickLine={false}
              style={{
                fontSize: "14px",
                fill: "#abafb3",
                fontWeight: "bold",
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "14px",
                fill: "#abafb3",
              }}
            />
            <Tooltip />
            {/* <Legend
              align="right"
              verticalAlign="top"
              height={70}
              iconType="circle"
              formatter={renderLegendText}
              wrapperStyle={{ right: "-9px" }}
            /> */}
            <Bar dataKey="Total" fill="#7f32de" />
            {/* <Bar dataKey="Theme: 02" fill="#ca90bb" />
            <Bar dataKey="Theme: 03" fill="#dcdcdc" /> */}
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}

export default Salaries;
