import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useReports() {
  return useContext(Context);
}

const initState = {
  selectedReport: null,
  reports: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_report":
      return { ...state, selectedReport: action.payload };
    case "set_reports":
      return { ...state, reports: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ReportsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);

  const setReports = useCallback((filters) => {
    dispatch({ type: "set_reports", payload: filters });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        setReports,
      }}
    >
      {children}
    </Context.Provider>
  );
}
