import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useLeaves, useOrders } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "../../data/countries.json";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import { usePurches } from "../purches/provider";

let day_for_hourly_absence = 0;
let final_abcence_from = 0;
let first_of_year = "2022-01-01";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(value, originalValue) {
  var d = new Date(value);
  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft()
    );
  }

  if (d == "Invalid Date") {
    final_abcence_from = day_for_hourly_absence + " " + value;
    return final_abcence_from;
  }
}

const schema = Yup.object().shape({
  employeeID: Yup.string(),
});

const Grid = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 55px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
    margin-top: 20px;
  }
`;

function FilterForm({
  fetchOrders,
  selectedstatus,
  setSelectedStatus,
  setemployeeID,
  handleSuccess,
  page,
}) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const {
    state: { leave, orderS, departmentID, employeeID ,Fstate },
    create,
    fetchOne,
    edit,
    dispatch,
  } = useOrders();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  
  const [isPO, setIsPO] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes('HR') && setIsPO(false); 

  }, []);

  const {
    state: { companyID, departmentID: did, employeeID: eid },
    dispatch: dispatchPurches,
  } = usePurches();


  //status enum
  let statusEnum = [
    { id: 1, status: "New" },
    { id: 2, status: "InProgress" },
    { id: 3, status: "Done" },
    { id: 4, status: "Cancelled " },
  ];
  //fetching for filters

  //handle change status
  const handleStatusChange = useCallback((id) => {
    dispatch({ type: "Fstate", payload: id.data.id });
  }, []);
  //fetch companies

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    if (companies && !companyID)
      dispatchPurches({ type: "companychange", payload: companies[0]?.id });
  }, [companies, fetchCompanies]);

  const handleCompanyChange = useCallback((id) => {
    dispatchPurches({ type: "companychange", payload: id.data.id });
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch departments

  const {
    state: { list: departmentList, status },
    fetchList: fetchDepartments,
  } = useDepartments();

  // useEffect(() => {
  //   if (departmentList) setdepartmentID(departmentList[0]?.id);
  //   else setdepartmentID(null);
  // }, [fetchDepartments, departmentList, companyID]);

  const handleDepartmentChange = useCallback(
    (id) => {
      dispatch({ type: "departmentchange", payload: id.data.id });
      if (id.data.id !== "a")
        dispatchPurches({ type: "departmentchange", payload: id.data.id });
    },
    [departmentList]
  );

  const mapDepartmentOptions = useRef({ value: "id", label: "departmentName" });

  console.log(employeeID , eid , 'employeeID');
  useEffect(() => {
    if(isPO)
    fetchDepartments(user?.employee?.company.id);
  else
  if (companyID) fetchDepartments(companyID);
  }, [companyID,isPO]);

  //fetch employees
  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  // useEffect(() => {
  //   if (employees.length) setemployeeID(employees[0]?.id);
  // }, [employees , departmentList , departmentID]);

  useEffect(() => {
    
    if(!departmentID)dispatch({ type: "departmentchange", payload: did });
  }, []);
  const handleEmployeeChange = useCallback((id) => {
    //setemployeeID(id.data.id);
    dispatch({ type: "employeechange", payload: id.data.id });
    if (id.data.id !== "a")
      dispatchPurches({ type: "employeechange", payload: id.data.id });
    setSelectedEmployee(id.data.id);
  }, []);

  // useEffect(() => {
  //   if (employees?.length === 0)
  //     dispatch({ type: "employeechange", payload: "a" });
  //   else if (employees.filter((e) => e.id == selectedEmployee))
  //     dispatch({ type: "employeechange", payload: "a" });
  // }, [employees]);
  useEffect(() => {
    let did = null;
    if (departmentID !== "a") did = departmentID;
    else did =null 
    
    if(isPO)  fetchEmployees(user?.employee?.company.id, 0, 10, did);
    else
    if (companyID) fetchEmployees(companyID, 0, 10, departmentID);  
  }, [companyID, departmentID]);

  const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });

  const onSubmit = async (values) => {
    let cid= null;
    let did = null;
    let eid = null;
    if (departmentID !== "a") did = departmentID;
    if (employeeID !== "a") eid = employeeID;
    if(isPO)
    cid = user?.employee?.company.id
    else 
    cid = companyID
    try {
      if (leave) await edit(final_abcence_from, values);
      else {
        if(cid)  await  fetchOrders(cid, did, eid, Fstate);
        reset();
      }
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };
  const hanlePage = (values) => {
    let did = null;
    let eid = null;
    if (departmentID !== "a") did = departmentID;
    if (employeeID !== "a") eid = employeeID;
    try {
      if(companyID)fetchOrders(companyID, did, eid, Fstate, page);
      reset();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    hanlePage();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Grid>
        <div>
        {isPO ? (
            <TextField
              label="company name"
              onChange={(e)=> console.log(e.target.value)}
              inputProps={{
                value: user?.employee?.company?.companyName,
                ...register("date"),
                readOnly: true,
              }}
            />
          ) : (
            <Select
              style={{
                height: "60px",
                width: "100%",
                marginBottom: "0px 75px",
              }}
              data={companies}
              mapOptions={mapCompanyOptions.current}
              onChange={handleCompanyChange}
              defaultValue={companyID}
              label={"companies"}
              inputProps={{
                value: companyID,
                readOnly: true,
              }}
            />
          )}
        </div>

        <div>
          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
            }}
            data={[{ id: "a", departmentName: "All" }, ...departmentList]}
            mapOptions={mapDepartmentOptions.current}
            onChange={handleDepartmentChange}
            defaultValue={departmentID  }
            label={"department"}
            inputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div>
          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
            }}
            data={[{ id: "a", fullName: "All" }, ...employees]}
            mapOptions={mapEmployeeOptions.current}
            onChange={handleEmployeeChange}
            defaultValue={employeeID}
            label={"Employee"}
            inputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div>
          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            data={statusEnum}
            mapOptions={{ value: "id", label: "status" }}
            onChange={handleStatusChange}
            defaultValue={Fstate}
            label={"Status"}
            inputProps={{
              value: Fstate,
              readOnly: true,
            }}
          />
        </div>
        <ButtonWrapper>
          <Button
            data-testid="save-button"
            type="submit"
            iconName="Eye"
            className="small"
            loading={isSubmitting}
          >
            view Data
          </Button>
        </ButtonWrapper>
      </Grid>
    </form>
  );
}

export default FilterForm;
