import React from 'react'
import { getValueFromEnum } from '../../../utilities/functions'
import {accountsStatus} from '../../../data/Enums'

function AccountStatus(data) {
  return (
    <p>{getValueFromEnum(data?.accountStatus , 'id' , accountsStatus ,'status' )}</p>
  )
}

export default AccountStatus