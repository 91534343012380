import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Card,
  TextField,
  Select,
  Divider,
  Icon,
  Checkbox,
} from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import FileField from "../../ui/FileField";
import { useOrders } from "./provider";
import { useUtils } from "../../services/utilsProvider";

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  padding-bottom:40px;

  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ShiftsWrap = styled.div`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 24px;
    gap: 20px;
    width: 1210px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    input[type="checkbox"] {
      width: 30px;
      height: 30px;
      background-color: ${(props) => props.theme.color.primary};
      border: none;
      outline: none;
      border-radius: 20px;
      margin-top: 15px;
    }
    span.remove {
      margin-top: 30px;
      display: block;
      justify-self: center;
      cursor: pointer;
      svg {
        fill: ${(props) => props.theme.color.grayText};
      }
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
    }
    span.add {
      cursor: pointer;
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
    h4 {
      margin: 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.grayText};
    }
  }
`;

function SendEmail({  }) {
  const [error, setError] = useState(null);
  const [contractFile, setContractFile] = useState();
  const { notify } = useUtils();
  let navigate = useNavigate()
  const handleSuccess = () => {
    notify('Email has been sent correctly')
    navigate(-1)
  }
  const [signers, setSigners] = useState(
    Array(1)
      .fill(0)
      .map(() => ({
        email: "",
        fullName: "",
        routingOrder: "",
        anchorString: "",
        asCarbonCopy: false,
      }))
  );

  const {
    sendEmailApi
  } = useOrders();
  const additem = () => {
    setSigners((signers) => {
      signers.push({
        email: "",
        fullName: "",
        routingOrder: "",
        anchorString: "",
        asCarbonCopy: false,
      });
      return [...signers];
    });
  };

  const removeitem = (i) => {
    let newi = signers.filter((s, i2) => {
      return i !== i2;
    });
    setSigners(newi);
  };

  // const navigate = useNavigate();
  const { pId } = useParams();

  const yupSchema = {
    emailSubject: yup.string().required("This Feild is Required"),
 
  };
  const schema = yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "file")
        setContractFile(value["file"][0]);

    });
    return () => subscription.unsubscribe();
  }, [watch]);
  function displayShiftsFields() {
    return signers.map((shift, i) => (
      <div key={i} data-count={i + 1}>
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.signers?.[i]?.email}
          msg={errors?.signers?.[i]?.email?.message}
          inputProps={{
            value: signers.email,
            onChange: (e) => {
              setSigners((signers) => {
                signers[i].email = e.target.value;
                setValue("signers", [...signers]);
                return [...signers];
              });
            },
            required: true,
            type: "string",
          }}
        />
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.signers?.[i]?.fullName}
          msg={errors?.signers?.[i]?.fullName?.message}
          inputProps={{
            value: signers.fullName,
            onChange: (e) => {
              setSigners((signers) => {
                signers[i].fullName = e.target.value;
                setValue("signers", [...signers]);
                return [...signers];
              });
            },
            required: true,
            type: "string",
          }}
        />
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.signers?.[i]?.routingOrder}
          msg={errors?.signers?.[i]?.routingOrder?.message}
          inputProps={{
            value: signers.routingOrder,
            onChange: (e) => {
              setSigners((signers) => {
                signers[i].routingOrder = e.target.value;
                setValue("signers", [...signers]);
                return [...signers];
              });
            },
            required: true,
            type: "string",
          }}
        />
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.signers?.[i]?.anchorString}
          msg={errors?.signers?.[i]?.anchorString?.message}
          inputProps={{
            value: signers.anchorString,
            onChange: (e) => {
              setSigners((signers) => {
                signers[i].anchorString = e.target.value;
                setValue("signers", [...signers]);
                return [...signers];
              });
            },
            required: true,
            type: "string",
          }}
        />

        <TextField
          style={{ width: "100%" }}
          className="check"
          label=""
          error={!!errors?.signers?.[i]?.asCarbonCopy}
          msg={errors?.signers?.[i]?.asCarbonCopy?.message}
          inputProps={{
            checked: signers.asCarbonCopy,
            onChange: (e) => {
              setSigners((signers) => {
                signers[i].asCarbonCopy =!signers[i].asCarbonCopy;
                setValue("signers", [...signers]);
                return [...signers];
              });
            },
            required: true,
            type: "checkbox",
          }}
        />


        <Icon
          className="remove"
          onClick={() => removeitem(i)}
          name="trash"
          w={16}
        />
      </div>
    ));
  }
  let params = useParams()
  const onSubmit = async (values) => {
    try {
      await sendEmailApi( {...values , id : params.id});
      reset();

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `The Name "${values.fullName}" is already in use for this company.`,
        });
      console.log("e", e);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>

      <Card elevation={4} style={{ minWidth: "400px" }}>
      {error && <ErrorsList error={error} />}
     
        <Grid>
          <div>
            <TextField
              label="Email Subject"
              name="emailSubject"
              error={!!errors.emailSubject}
              msg={errors.emailSubject?.message}
              style={{ width: "92%" }}
              inputProps={{
                ...register("emailSubject"),
                autoFocus: true,
                required: true,
              }}
            />
          </div>
          <span></span>
          {/* <div>
            <FileField
              style={{
                width: "100%",
              }}
              label="file"
              buttonLabel={"Upload File"}
              document
              documentFile={contractFile}
              onClose={() => {
                setValue("contractUpload", "");
                setValue("contract", "");
                setContractFile(null);
              }}
              inputProps={{
                name: "file",
                ...register("file"),
                accept:
                  ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              }}
            />
          </div> */}
        </Grid>
        {/* <Grid>
          <div>
            <h3>signers <span style={{color:'red' , fontWeight:'normal'}}>{errors && errors['signers']?.message}</span></h3>
            
            <ShiftsWrap>
              <div>
                <h4>Email</h4>
                <h4>Full Name</h4>
                <h4>Routing Order</h4>
                <h4>Anchor</h4>
                <h4>As Carbon Copy</h4>
              </div>
              {displayShiftsFields()}

              <div
                onClick={additem}
                style={{ textAlign: "right", border: "none" }}
              >
                <span />
                <span />
                <span />
                <span />
                <Icon name="addSquare" w={24} className="add" />
              </div>
            </ShiftsWrap>
          </div>
          <span />
        </Grid> */}
        <ButtonWrapper>
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
            loading={isSubmitting}
          >
            Save
          </Button>
        </ButtonWrapper>
      </Card>
    </form>
  );
}

export default memo(SendEmail);
