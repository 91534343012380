import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import MachienForm from "./MachienForm";
import { useAttendance } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditMachien() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  
  const {
    state: { onePunch:selected, punches: punchList1 ,delete:d1},
    fetchOnePunch,
    fetchPunch,
  } = useAttendance();

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

  useEffect(()=>{
    fetchOnePunch(params.id)
  },[])

  console.log(selected);

  if(!selected) return
  return (
    <div id="edit-leave">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Punch</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <MachienForm record={selected}  handleSuccess={handleSuccess} />
    </div>
  );
}

export default EditMachien;
