import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Checkbox } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { useCompanies } from "../companies/provider";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useAccounts } from "./provider";

import InputDate from "../../ui/InputDate";
import { accountType, accountsStatus, specialType } from "../../data/Enums";
import TextArea from "../../ui/TextArea";
import { delay, rmEmpty } from "../../utilities/functions";
import { useStatus } from "../../services/StatusProvider";
import { useTransaction } from "../transaction/provider";

const Grid = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function AccountForm({ account = null, handleSuccess }) {
  const [error, setError] = useState(null);
  let user = JSON.parse(localStorage.getItem("user"));
  const [selectMapOption, setSelectMapOption] = useState({
    value: "id",
    label: "label1",
  });

  let params = useParams();
  const navigate = useNavigate();
  const {
    state: { filters, list: accounts, NamesList, accountName },
    fetchList: fetchAccounts,
    fetchByName,
    create,
    edit,
  } = useAccounts();

  useEffect(() => {
    fetchAccounts({ companyId: user?.employee?.company?.id, isActive: true });
  }, []);
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();
  const yupSchema = fields.reduce(createYupSchema, {});
  const schema = Yup.object().shape(yupSchema);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: account,
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // useEffect(() => {
  //   fetchAccounts({pageSize:150, conpanyId:filters.companyId , isActive: true});
  // }, []);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => {
  //     if (name === "companyId") {
  //        fetchAccounts({pageSize:150, conpanyId:values['companyId']?.value , isActive: true});
  //     }
  //   });

  //   return () => subscription.unsubscribe();
  // }, [fetchAccounts, watch]);

  useEffect(() => {
    if (account) {
      fields.forEach((f) => {
        setValue(f.name, account[f.name] || "");
      });
    }
  }, [account, companies, setValue]);

  const getResouce = (name) => {
    if (name === "companies") return companies;
    if (name === "accounts") {
      if (account)
        return [
          { id: null, accountName: "none" },
          ...accounts.filter((account) => account.id !== params.id),
        ];
      else return [{ id: null, accountName: "none" }, ...accounts];
    }

    if (name === "accountsStatus") return [...accountsStatus];
    if (name === "accountType") return [...accountType];
    if (name === "specialType") return [...specialType];
  };

  const handleChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );
  const {
    state: { formData },
    setFormData,
  } = useTransaction();

  const [index, setIndex] = useState();
  useEffect(() => {
    if (formData?.entries) {
      formData?.entries.map((e, i) => {
        if (e.accountId == -1 && !index) {
          setIndex(i);

          formData.entries[i].accountId = null;
        }
      });
    }
  }, []);

  let getOptions = (e) => {
    if (isNaN(e)) {
      //label 1
      setSelectMapOption({ value: "id", label: "label" });
    } else {
      //label 2

      setSelectMapOption({ value: "id", label: "label2" });
    }
    if (
      e.length > 2 &&
      NamesList.map(
        (s) => !s?.accountName.toLowerCase().startsWith(e.toLowerCase())[0]
      )
    )
      fetchByName({ companyId: getValues("companyId"), searchText: e }, false);
  };
  const onSubmit = async (values) => {
    if (values.baseAccountId == null) delete values.baseAccountId;
    if (values.accountType == null) values.accountType = 1;
    if (values.startBalance == "") values.startBalance = 0;
    // console.log(values, "onSubmit");
    // return;
    try {
      if (account) await edit(account.id, rmEmpty(values));
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `"${e.errors.message}" `,
        });
      else
        setError({
          title: `something went wrong , please check the data and try again .`,
        });
      console.log("e", e);
    }
  };

  useEffect(() => {
    setValue("companyId", filters?.companyId);
    if (account == null) setValue("baseAccountId", Number(params.id));
  }, []);

  if (!isAcc) {
    if (!companies.length) return;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card style={{ paddingBottom: 40 }}>
        <Grid>
          <TextField
            label={"conpany"}
            name={"conpany"}
            error={!!errors["companyId"]}
            msg={errors["companyId"]?.message}
            inputProps={{
              value: user?.employee?.company?.companyName,
              disabled: true,
            }}
          />
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            if (f.viewOnluy) return;
            inputProps.type = f.type;
            if ((f.editOnly && !account) || (f.newOnly && account)) return null;

            if (f.data) {
              return (
                <div key={i}>
                  <Select
                    style={{ width: "100%" }}
                    inputStyle={{ height: "60px" }}
                    label={f.label}
                    data={f.resourceName ? getResouce(f.resourceName) : f.data}
                    mapOptions={f.mapData}
                    multi={f.multi || false}
                    required={f.required}
                    onChange={handleChange}
                    defaultValue={
                      params.id ? getValues(f.name) : account && account[f.name]
                    }
                    setPlaceholderText={
                      getValues(f.name) == 0 &&
                      (getResouce(f.resourceName)[0].type ||
                        getResouce(f.resourceName)[0].status)
                    }
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message || f.msg}
                    inputProps={{
                      ...register(f.name),
                    }}
                  />
                </div>
              );
            } else if (f.type === "date")
              return (
                <InputDate
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  type={f.type}
                  size="medium"
                  value={new Date(getValues(f.name))}
                  onDateChange={(v) => setValue(f.name, v)}
                  pickerProps={f.pickerProps}
                />
              );
            else
              return (
                <TextField
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  onChange={(v) => setValue(f.name, v.target.value)}
                  inputProps={{
                    ...register(f.name),
                    ...inputProps,
                  }}
                />
              );
          })}
          <Select
            label="Base account"
            data={[
              { id: null, label: "none" },
              ...NamesList.map((item) => ({
                ...item,
                label: item.accountName + " - " + item.accountNumber,
                label2: item.accountNumber + " - " + item.accountName,
              })),
            ]}
            style={{ marginTop: "10px" }}
            mapOptions={selectMapOption}
            defaultValue={account?.accountId}
            error={!!errors.accountId}
            msg={errors.accountId?.message}
            setPlaceholderText={accountName || account?.baseAccountName}
            getTextValue={delay(getOptions)}
            onChange={(e) => setValue("baseAccountId", e.value)}
          />
          <Select
            data={[
              {
                id: 1,
                name: "Active",
              },
              {
                id: 2,
                name: "notActive",
              },
            ]}
            name="companyId"
            mapOptions={{ value: "id", label: "name" }}
            onChange={(e) => {
              e.value == 1
                ? setValue("isActive", true)
                : setValue("isActive", false);
            }}
            defaultValue={account?.isActive == true ? 1 : 2}
            label={"status"}
          />
        </Grid>
        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="5"
          error={!!errors.notes}
          msg={errors.notes?.message}
          inputProps={{
            ...register("notes"),
          }}
        />
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(AccountForm);
