import React, { Children, useCallback, useEffect, useState } from "react";
import { Button, Card, Checkbox, Flex, Select, TextField } from "../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useDepartments } from "../departments/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import { useTransaction } from "./provider";
import ErrorsList from "../../ui/ErrorsList";
import InputDate from "../../ui/InputDate";
import { delay, setDateFormat } from "../../utilities/functions";
import { useAccounts } from "../accounts/provider";
import { MethodEnum } from "../../data/Enums";

const schema = Yup.object().shape({
  // companyId: Yup.number().nullable().transform(emptyStringToNull),
  // date: Yup.date().nullable(),
});

let yearsquarter = [
  {
    id: 1,
    label: "full year",
    startDate: null,
    endDate: null,
  },

  {
    id: 2,
    label: "first quarter",
    startDate: new Date().getFullYear() + "-01-01",
    endDate: new Date().getFullYear() + "-03-31",
  },
  {
    id: 3,
    label: "secound quarter",
    startDate: new Date().getFullYear() + "-04-01",
    endDate: new Date().getFullYear() + "-06-30",
  },
  {
    id: 4,
    label: "third quarter",
    startDate: new Date().getFullYear() + "-07-01",
    endDate: new Date().getFullYear() + "-09-30",
  },
  {
    id: 5,
    label: "fourth quarter",
    startDate: new Date().getFullYear() + "-10-01",
    endDate: new Date().getFullYear() + "-12-31",
  },
  {
    id: 6,
    label: "first half year",
    startDate: new Date().getFullYear() + "-01-01",
    endDate: new Date().getFullYear() + "-06-30",
  },
  {
    id: 7,
    label: "secound half year",
    startDate: new Date().getFullYear() + "-07-01",
    endDate: new Date().getFullYear() + "-12-31",
  },
];

function FilterForm({
  show,
  setFilters,
  companyId,
  setCompanyId,
  isAcc,
  years = [],
  error,
  setError,
  setJournalEntries = () => {},
}) {
  let user = JSON.parse(localStorage.getItem("user"));
  const {
    state: { filters },
  } = useTransaction();
  const {
    state: { list: companies },
  } = useCompanies();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectMapOption, setSelectMapOption] = useState({
    value: "id",
    label: "label",
  });

  const {
    state: { NamesList },
    fetchByName,
  } = useAccounts();

  let getOptions = (e) => {
    if (isNaN(e)) {
      //label 1
      setSelectMapOption({ value: "id", label: "label" });
    } else {
      //label 2
      setSelectMapOption({ value: "id", label: "label2" });
    }
    if (
      e.length > 2 &&
      NamesList.map(
        (s) => !s?.accountName.toLowerCase().startsWith(e.toLowerCase())[0]
      )
    )
      fetchByName({ companyId: companyId, searchText: e }, true);
  };

  const onSubmit = async (values) => {
    if (isAcc) {
      values.companyId = user?.employee?.company?.id;

      values.companyName = user?.employee?.company?.companyName;
    } else {
      values.companyName = companies
        .filter((c) => c.id == companyId)
        .map((c) => c.businessName);
      values.companyId = companyId;
    }

    values.yearTitle = years
      .filter((y) => y.id == values.yearId)
      .map((y) => y.title)[0];

    if (values.journalDate)
      values.journalDate = setDateFormat(values.journalDate, false);
    if (values.yearId) values.financialYearId = values.yearId;
    if (values.yearId) values.yearId = values.yearId;
    setJournalEntries([]);
    if (values.startDate == null) delete values.startDate;
    if (values.endDate == null) delete values.endDate;

    setFilters({ ...filters, ...values });
  };
  const FilterHiddenInputs = (props) => {
    if (!props?.show?.includes(props?.children?.props?.name)) return <></>;
    else return <>{props?.children}</>;
  };

  const handleCompanyChange = useCallback((data) => {
    setCompanyId(data.value);
    setValue("companyId", data.value);
  }, []);
  useEffect(() => {
    if (!getValues()) {
      setValue("companyId", companyId);
      setValue("companyName", user?.employee?.company?.companyName);
      setValue("yearId", filters.yearId);
    }
  }, []);

  const handleYearChange = useCallback((data) => {
    setError(null);
    setValue("yearId", data.value);
  }, []);

  if (!years) return;
  return (
    <Card style={{ marginBottom: "10px" }}>
      {error && <ErrorsList error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex jc="flex-start" ai="flex-end" gap={20}>
          {isAcc ? (
            <TextField
              label="Company"
              name="company"
              style={{ marginBottom: "0px" }}
              inputProps={{
                value: user?.employee?.company?.companyName,
                disabled: true,
              }}
            />
          ) : (
            <Select
              style={{
                maxWidth: 220,
              }}
              data={companies}
              name="companies"
              mapOptions={{ value: "id", label: "businessName" }}
              onChange={handleCompanyChange}
              defaultValue={filters?.companyId || companies[0]?.id}
              label={"company"}
            />
          )}

          <FilterHiddenInputs show={show}>
            <Select
              style={{
                maxWidth: 220,
              }}
              data={years}
              name="years"
              mapOptions={{ value: "id", label: "title" }}
              onChange={handleYearChange}
              defaultValue={filters?.yearId || getValues()?.yearId}
              //setPlaceholderText={filters?.yearTitle}
              label={"year"}
            />
          </FilterHiddenInputs>
          <FilterHiddenInputs show={show}>
            <InputDate
              style={{ marginBottom: "0px" }}
              label="Date"
              name="date"
              error={!!errors.journalDate}
              msg={errors.journalDate?.message}
              type="date"
              value={new Date(filters.journalDate)}
              onDateChange={(v) => {
                setValue("journalDate", v);
              }}
            />
          </FilterHiddenInputs>

          <FilterHiddenInputs show={show}>
            <Select
              label="Base account"
              data={[
                ...NamesList.map((item) => ({
                  ...item,
                  label: item.accountName + " - " + item.accountNumber,
                  label2: item.accountNumber + " - " + item.accountName,
                })),
              ]}
              style={{ marginTop: "10px" }}
              mapOptions={selectMapOption}
              defaultValue={filters?.accountId}
              name={"account"}
              error={!!errors.accountId}
              msg={errors.accountId?.message}
              setPlaceholderText={filters?.accountName}
              getTextValue={delay(getOptions)}
              onChange={(e) => setValue("baseAccountId", e.value)}
            />
          </FilterHiddenInputs>

          <FilterHiddenInputs show={show}>
            <Select
              style={{
                maxWidth: 220,
              }}
              data={MethodEnum}
              name="method"
              mapOptions={{ value: "id", label: "method" }}
              onChange={(e) => setValue("method", e.value)}
              defaultValue={filters?.method || getValues()?.method}
              setPlaceholderText={MethodEnum[filters?.method]?.method}
              label={"method"}
            />
          </FilterHiddenInputs>
          <FilterHiddenInputs show={show}>
            <Select
              label="duration"
              data={yearsquarter}
              style={{ marginTop: "10px" }}
              mapOptions={{
                value: "id",
                label: "label",
              }}
              defaultValue={filters?.quarter}
              name={"quarter"}
              error={!!errors.quarter}
              msg={errors.quarter?.message}
              setPlaceholderText={filters?.quarter}
              getTextValue={yearsquarter[filters?.quarter]?.label}
              onChange={(e) => {
                setValue("quarter", e.value);
                setValue("startDate", e.data.startDate);
                setValue("endDate", e.data.endDate);
              }}
            />
          </FilterHiddenInputs>
          <Button
            data-testid="save-button"
            type="submit"
            // iconName="Eye"
            className="small"
            loading={isSubmitting}
            style={{ height: 60 }}
          >
            Apply
          </Button>
        </Flex>
      </form>
    </Card>
  );
}

export default FilterForm;
