import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Checkbox, Icon, Select, TextField } from "../../ui";
import { useProject } from "./provider";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import FileField from "../../ui/FileField";
import Label from "../../ui/InputLabel";
import TextArea from "../../ui/TextArea";
import { useNews } from "../news/provider";
import { WithContext as ReactTags } from "react-tag-input";

import "./style/style.css";

const schema = Yup.object().shape({
  companyId: Yup.number(),
  projectName: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  tags: Yup.string(),
  imageName: Yup.string(),
  isActive: Yup.boolean(),
  logo: Yup.string(),
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 500px;
  gap: 32px;
  row-gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ProjectForm = ({ project = null, handleSuccess }) => {
  const [error, setError] = useState(null);

  const { cid, id } = useParams();
  let navigate = useNavigate();

  const [companyId, setCompanyId] = useState(cid);
  const [editorHtml, setEditorHtml] = useState(project?.content || null);

  const [preview, setPreview] = useState(project?.imageUrl || null);
  const [preview2, setPreview2] = useState(project?.logoUrl || null);
  const [textTags, setTextTags] = useState([]);
  const {
    state: { list: companies, selected },
    fetchOne: fetchCompany,
  } = useCompanies();
  const {
    state: { fileName, logo },
    // dispatch,
    _uploadImage,
    _uploadLogo,
    create,
    edit,
  } = useProject();
  useEffect(() => {
    console.log(logo, "logo");
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: project,
  });
  useEffect(() => {
    fetchCompany(cid);
  }, [fetchCompany, cid]);
  useEffect(() => setValue("content", editorHtml), [editorHtml, setEditorHtml]);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  useEffect(() => {
    if (project) {
      project.tags.split(";").map((t) => tags.push({ id: t, text: t }));
    }
  }, []);
  useEffect(() => {
    setTextTags(tags.map((t) => t.text));
  }, [tags]);
  const onSubmit = async (values) => {
    values.companyId = cid;
    values.image = fileName;
    values.logo = logo;
    values.tags = textTags.join(";").toString();
    try {
      if (project) {
        edit(id, values);
      } else {
        create(values);
      }
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleImageChange = (event) => {
    setPreview(URL.createObjectURL(event.target.files[0]));
    _uploadImage(event.target.files[0]);
  };

  const handleLogoChange = (event) => {
    setPreview2(URL.createObjectURL(event.target.files[0]));
    _uploadLogo(event.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <Grid>
            <TextField
              label="Company"
              value={selected?.businessName}
              style={{ marginBottom: 0 }}
              inputProps={{
                value: selected?.businessName,
                readOnly: true,
                disabled: true,
                style: { height: 60 },
              }}
            />

            {fields.map((f, i) => {
              if (f.viewOnly) return null;
              const inputProps = { ...f.inputProps };

              if (i === 0) inputProps.autoFocus = true;

              inputProps.type = f.type;
              if (f.switch) {
                return (
                  <div style={{ display: "flex", marginTop: "50px" }}>
                    <Checkbox
                      id="statusId"
                      name="status"
                      inputProps={{
                        ...register(f.name),
                        defaultChecked: project ? project?.isActive : true,
                      }}
                    />
                    <Label>Active</Label>
                  </div>
                );
              } else if (f.longDescription) {
                return (
                  <>
                    <FileField
                      id="image"
                      label="Project Image"
                      buttonLabel={project ? "Edit Image" : "Upload Image"}
                      preview={preview}
                      edit={Boolean(project)}
                      onChange={(e) => {
                        handleImageChange(e);
                      }}
                      inputProps={{
                        name: "image",
                        // ...register("image"),
                        accept: "image/jpng,image/png,image/jpg,image/jpeg",
                      }}
                    />
                    <TextArea
                      key={i}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      msg={errors[f.name]?.message}
                      inputProps={{
                        ...register(f.name),
                        ...inputProps,
                      }}
                      rows="6"
                    />
                  </>
                );
              } else if (f.tagInput) {
                return (
                  <div>
                    <Label>{f.label}</Label>
                    <ReactTags
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                    />
                  </div>
                );
              } else {
                return (
                  <TextField
                    key={i}
                    label={f.label}
                    name={f.name}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message}
                    inputProps={{
                      ...register(f.name),
                      ...inputProps,
                    }}
                  />
                );
              }
            })}
          </Grid>
          <FileField
            id="logo"
            label="Project Logo"
            buttonLabel={project ? "Edit Logo" : "Upload Logo"}
            preview={preview2}
            edit={Boolean(project)}
            onChange={(e) => {
              handleLogoChange(e);
            }}
            inputProps={{
              name: "image2",
              // ...register000("image"),
              accept: "image/jpng,image/png,image/jpg,image/jpeg",
            }}
          />
        </div>
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default ProjectForm;
