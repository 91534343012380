import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import EmployeeDeductions from "./EmployeeDeductions";
import ListDeductions from "./ListDeductions";
import ViewDeduction from "./ViewDeduction";

export default (
  <Route
    path="EmployeeDeductions"
    element={
      <RequireAuth>
        <EmployeeDeductions />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListDeductions />
        </RequireAuth>
      }
    />
    
    
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewDeduction />
        </RequireAuth>
      }
    />
  </Route>
);
