import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./InputLabel";

const Wrapper = styled.div`
  margin-bottom: 25px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrapper {
    position: relative;
    > span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }

  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
`;

function TextField({
  label,
  inputProps,
  msg = null,
  error = false,
  icon = null,
  small,
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      <Label htmlFor={label?.replace(/\s/g, "-").toLowerCase()}>{label}</Label>
      <div className="inputWrapper">
        {icon && <span>{icon}</span>}
        <Input
          small={small}
          id={label?.replace(/\s/g, "-").toLowerCase()}
          {...inputProps}
        />
      </div>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default TextField;
