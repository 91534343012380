import React from 'react'
import styled from 'styled-components';
import noaccess from '../assets/icons/no-access.svg'
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';

const Div = styled.div`
  width: 60%;
  height:80vh;
  text-align: center;
  margin:4% auto;
  border-radius: 20px;
  background-color:#fff;
  color:#999;
  padding:5% 0;
  .image{
    width:50%;
    margin: auto;
  }
  .text{
    color:#999;
    font-size:36px
  }
  .back{
    width:210px;
    color:#fff;
    border-radius:50px;
    background-color:#DC3472;
    margin: 0 auto;
    cursor:pointer;
  }
`;

function NoAaccess() {
  let navigate = useNavigate()
  return (
    <Layout>
   <Div>
      <div className='image'>
          <img src={noaccess} alt='no-access' width='100%' height='100%' />
      </div>
      <div className='text'>
      <p>You have no access to this page.</p>
      </div>
    </Div>
    </Layout>
  )
} 

export default NoAaccess