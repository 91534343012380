import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { createThumb } from "../../utilities/functions";

const Context = createContext();

export function useCompanies() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
  fileName: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_fileName":
      return { ...state, fileName: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(`Company/List?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data });
      // dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Company/Info/${id}/`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const _uploadLogo = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("file", data.logo[0]);
      formData.append("id", data.id);

      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/Logo",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const uploadPolicy = useCallback(
    (data, id) => {
      let formData = new FormData();
      formData.append("file", data.file[0]);
      formData.append("id", data.id);
      formData.append("title", data.title);

      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/CompanyContract",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_fileName", payload: resData.data.fileName });
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          let uploadRes;

          // if (data.contractUpload.length) {
          //   uploadRes = await _uploadContract({
          //     contract: data.contractUpload,
          //   });
          //   data.contract = uploadRes.fileName;
          // } else {
          //   data.contract = "";
          // }
          console.log(data, "data");
          if (data.parentCompanyId == 0) data.parentCompanyId = null;

          const newData = { ...data };
          delete newData.logo;
          delete newData.contractUpload;

          const resData = await req(
            "Company/Create",
            newData,
            { method: "POST" },
            true
          );

          dispatch({ type: "add", payload: resData.data });

          if (data.logo.length) {
            const thumb = await createThumb(data.logo[0], 400, 400);

            let ext = data.logo[0].type.split("/")[1];

            var file = new File(
              [thumb],
              `${data.businessName.replaceAll(" ", "_")}_logo.${ext}`,
              { lastModified: new Date().getTime(), type: data.logo[0].type }
            );

            await _uploadLogo({ id: resData.data.id, logo: [file] });
          }

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, _uploadLogo]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          let uploadRes;
          // if (data.contractUpload.length) {
          //   uploadRes = await _uploadContract({
          //     contract: data.contractUpload,
          //   });
          //   data.contract = uploadRes.fileName;
          // }

          if (data.parentCompanyId == 0) data.parentCompanyId = null;

          const resData = await req(
            `Company/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          if (data.logo.length) {
            const thumb = await createThumb(data.logo[0], 400, 400);
            await _uploadLogo({ id, logo: [thumb] });
          }
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, _uploadLogo]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Company/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchOne,
        create,
        edit,
        remove,
        uploadPolicy,
      }}
    >
      {children}
    </Context.Provider>
  );
}
