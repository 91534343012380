import React from 'react'

function statusCell(data) {

    let values = [
        {status:1 , value:'Pending'},
        {status:2 , value:'Approved'},
        {status:3 , value:'Rejected'},
        {status:4 , value:'Accepted By Head'},
        {status:5 , value:'Rejected By Head'},
        {status:6 , value:'Processed'},
        {status:7 , value:'Canceled'},

    ]

  return (
    <>
    {values
      .filter((e) => e.status == data.status)
      .map((e) => {
        return e.value;
      })}
  </>
  )
}

export default statusCell