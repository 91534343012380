import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";
import { useEmployees } from "./provider";

function Employee() {
  const { dispatch } = useEmployees();

  useEffect(() => {
    return () => {
      dispatch({ type: "set_page", payload: 0 });
    };
  }, [dispatch]);

  return <Layout><Outlet /></Layout>;
}

export default Employee;
