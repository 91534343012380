import { memo, useEffect } from "react";
import styled from "styled-components";
import { useDepartments } from "./provider";
import { Button, Card, TextField } from "../../ui";
import { useCompanies } from "../companies/provider";
import { useNavigate, useParams } from "react-router-dom";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

function ViewDepartment() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { selected },
    fetchOne,
  } = useDepartments();
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne]);

  useEffect(() => {
    if (selected) fetchCompany(selected.companyId);
  }, [fetchCompany, selected]);

  if (!company) return;
  if (!selected) return;

  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Department Information</h2>
        <div style={{ cursor: "pointer" }}>
          {/* <Button
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
            iconName="userAdd"
            className="small"
            bg="rose"
            onClick={() => navigate(`/employees/company/${company.id}`)}
          >
            Users
          </Button> */}
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/departments/company/${params.cid}/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <TextField
            label="Department Name"
            value={selected.departmentName}
            inputProps={{
              value: selected.departmentName,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label="Company"
            inputProps={{
              value: company.businessName,
              readOnly: true,
              disabled: true,
            }}
          />
          {/* <div>
            <Select
              style={{ height: "60px", width: "100%" }}
              label="Company"
              data={[{ id: "", businessName: "" }, ...companies]}
              mapOptions={{ value: "id", label: "businessName" }}
              watch={watch}
              onChange={(v) => {
                setValue("companyId", v);
              }}
              error={!!errors.companyId}
              msg={errors.companyId?.message}
              inputProps={{
                ...register("companyId"),
              }}
            />
          </div> */}
        </Grid2>
      </Card>

      {/* <ButtonWrapper>
        <Button
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button className="outlined small" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ButtonWrapper> */}
    </div>
  );
}

export default memo(ViewDepartment);
