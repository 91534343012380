import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function EmployeeCompensations() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default EmployeeCompensations;
