import { Route } from "react-router-dom";
import News from "./News";
import NewsList from "./NewsList";
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import { RequireAuth } from "../../services/auth";
import ViewNews from "./ViewNews";

export default (
  <>
    <Route
      path="news/company/:cid"
      element={
        <RequireAuth>
          <News />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <NewsList />
          </RequireAuth>
        }
      />
      <Route
        path="new"
        element={
          <RequireAuth>
            <AddNews />
          </RequireAuth>
        }
      />

      <Route
        path="edit/:id"
        element={
          <RequireAuth>
            <EditNews />
          </RequireAuth>
        }
      />
      <Route
        path="view/:id"
        element={
          <RequireAuth>
            <ViewNews />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
