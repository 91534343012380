import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Year from "./Year";
import YearList from "./YearList";
import AddYear from "./AddYear";
import EditYear from "./EditYear";
import ViewYear from "./ViewYear";

export default (
  <Route
    path="year"
    element={
      <RequireAuth>
        <Year />
      </RequireAuth>
    }
  >
  
    <Route
    index
      element={
        <RequireAuth>
          <YearList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddYear />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditYear />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewYear />
        </RequireAuth>
      }
    />
  </Route>
);
