import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUtils } from '../../services/utilsProvider';
import { Icon } from '../../ui';
import OrdersForm from './OrdersForm';
import LeaveForm from './OrdersForm';


const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddOrder() {
const navigate = useNavigate();
const { notify } = useUtils();
const [supplierId, setSupplierId] = useState(null);


const handleSuccess = () => {
    notify("a new order has been created successfully.");
  };

  return (
    <div id="add-user">
    <Grid>
      <h2 style={{ marginTop: "0" }}>Add New Order</h2>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
        <span>Back</span>
        <Icon name="arrowLeft" />
      </div>
    </Grid>

    <OrdersForm handleSuccess={handleSuccess} setSupplierId={setSupplierId} supplierId={supplierId}/>
  </div>
  )
}

export default AddOrder