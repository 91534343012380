import { useCallback, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
} from "recharts";
import styled from "styled-components";
import { Card, Divider } from "../../../ui";
import Select from "../../../ui/select/Select";
import { genYears } from "../../../utilities/functions";
import { useStats } from "../../stats/provider";
import Holidays from "./Holidays";
import months from "../../../data/months.json";
import { useCompanies } from "../../companies/provider";

const data = [
  { name: "Active", value: 400 },
  { name: "deleted", value: 300 },
];

const COLORS = [
  "#ca90bb",
  "#38a8af",
  "#4a5be6",
  "#dc3472",
  "#00bf78",
  "#7f32de",
];

const StyledCard = styled(Card)`
  margin-top: 40px;

  .recharts-legend-wrapper ul {
    text-align: left !important;

  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0 0 10px 0;
    flex-grow: 1;
  }
`;

const renderLegendText = (value) => {
  return (
    <span
      style={{
        color: "#abafb3",
        fontSize: "15px",
        paddingRight: "9px",
        paddingLeft: "9px",
        verticalAlign: "middle",
      }}
    >
      {value}
    </span>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (innerRadius + 5) * cos;
  const sy = cy + (innerRadius + 5) * sin;

  return (
    <g>
      <Sector
        cx={sx}
        cy={sy}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function ActiveEmployee() {
  const [active, setActive] = useState(0);

  const {
    state: { attendance },
    fetchAttendance,
  } = useStats();

  const {
    state: { list: companies, selected: company },
    dispatch,
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);


  useEffect(() => {
    if (!companies.length) return;
    dispatch({ type: "set_selected", payload: companies[0], keep: true });
  }, [companies, dispatch]);


  const handleCompanyChange = useCallback(
    (v) => dispatch({ type: "set_selected", payload: v.data }),
    [dispatch]
  );

  const onPieEnter = (_, index) => {
    setActive(index);
  };

  return (
    <div style={{ width: "100%" , marginTop:"40px"}}>
      <Card>
      <Title>
        <h2>Active Employee</h2>
        <Select
          inputStyle={{ height: "48px", width: "250px" }}
          data={companies}
          mapOptions={{ label: "businessName", value: "id" }}
          defaultValue={company?.id || ''}
          onChange={handleCompanyChange}
          inputProps={{
            value: company?.id || '',
            onChange: () => {}
          }}
        />
      </Title>
        <ResponsiveContainer width="100%" height={420}>
          <PieChart height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data?.map((d) => ({
                name: d.name,
                value: d.value,
              }))}
              outerRadius={150}
              fill="#8884d8"
              legendType="circle"
              activeShape={renderActiveShape}
              activeIndex={active}
              onMouseEnter={onPieEnter}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {data.length && <Legend align="left" formatter={renderLegendText} />}
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}

export default ActiveEmployee;
