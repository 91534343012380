import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import EditPurches from "./EditPurches";

import Purches from "./Purches";
import PurchesList from "./PurchesList";





export default (
  <>
    <Route
      path="purchase"
      element={
        <RequireAuth>
          <Purches />
        </RequireAuth>
      }
    >
    <Route
      index
      element={
        <RequireAuth>
          <PurchesList />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditPurches />
        </RequireAuth>
      }
    />
    </Route>
  </>  
);
