import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Button, Pagination, Select } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import {useSigners } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import { useState } from "react";
import { useCompanies } from "../companies/provider";
import { usePurches } from "../purches/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function SignersList() {
  const [search, setSearch] = useState("");
  let location = useLocation()
  const {
    state: { list: companies, selected: company },
    dispatch : cdispatch,
    fetchList:fetchCompany,
  } = useCompanies();
  const theme = useTheme();
  const {
    state: { list, status , count, page, perPage,companyID },
    fetchList,
    remove,
    dispatch
  } = useSigners();
  const {
    state: { companyID:cid },
    dispatch: dispatchPurches,
  } = usePurches();
  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    if(company) fetchList( company?.id || location?.state?.id);
  }, [fetchList , company]);

  const cols = [
    { name: "id", label: "id" },
    {
      name: "fullName",
      label: "Full Name",
    },
    { name: "routingOrder", label: "Routing Order" },

    // { name: "anchorString", label: "Anchor String" },
  ];

  useEffect(()=>{
    if(companies && !companyID) dispatch({type:"companyChange" , payload:location?.state.id || companies[0].id})
  },[location.state])
  const handleClick = (id) => {
    navigate(`/signers/new/${companyID}`);
  };
  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])

  const handleView = ({id}) => {
    navigate(`/signers/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/signers/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Item has been removed");
    } catch (error) {
      console.log(error);
    }
  };



  const handleChangePage = (newPage) =>{
    dispatch({ type: "set_page", payload: newPage });
}




useEffect(() => {
  fetchCompany();
}, [fetchCompany]);

useEffect(() => {
  if (companies && !companyID)
    dispatch({ type: "set_selected", payload: companies.filter((c) => c.id === location?.state?.id) });
  return () => cdispatch({ type: "set_selected", payload: null });
}, [companies, dispatch]);

const setCompany = useCallback(
  (company) => {
    cdispatch({ type: "set_selected", payload: company.data });
    dispatch({type:"companyChange" , payload:company?.data?.id})
    dispatchPurches({ type: "companychange", payload: company?.data?.id })
  },
  [dispatch]
);


const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  if (!company ) return
  return (
    <>
      <Grid>
        <h2>Signees List</h2>
        <SearchInput onChange={setSearch} value={search} />
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <div style={{ marginBottom: "30px" }}>
        <strong>Company: </strong>
        <Select
          data={companies}
          mapOptions={mapCompanyOptions.current}
          onChange={setCompany}
          defaultValue={companyID}
          style={{ height: "40px", maxWidth: "280px" }}
          inputProps={{
            value: companyID,
            readOnly: true,
          }}
        />
      </div>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Currency table"
        cols={cols}
        data={list.filter(item => item.fullName.toLowerCase().includes(search.toLowerCase(), 0))}
        checkbox
        moreOptions={{
          edit: handleEdit,
          delete: handleDelete,
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default SignersList;
