import { memo, useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import Button from "./Button";
import styled from "styled-components";
import Card from "./Card";
import Checkbox from "./Checkbox";
import MoreOptions from "./tableContainer/MoreOptions";
import Loading from "./Loading";
import Slider from "./Slider";
import {
  currency,
  getCountry,
  isActive,
  isFixed,
  objByStr,
  toDateTime,
} from "../utilities/functions";
import TextField from "./TextField";
import { format } from "date-fns";

const Table = styled.table`
  min-width: 100%;
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: 16px;
  border-collapse: collapse;
  tr td {
    padding: 20px;
  }
  thead {
    tr {
      td {
        border-bottom: #f4f4f4 2px solid;
        font-size: 19px;
        color: black;
        font-weight: bold;
      }
    }
  }
  tbody {
    tr {
      transition: all 0.12s ease;
      td {
        border: none;
        border-bottom: #f4f4f4 1px solid;
      }
      &:hover {
        box-shadow: 0px 1px 28px #0000001f;
      }
      &.active {
        background: #f5f5f5;
        td {
          border-bottom: #e9e9e9 1px solid;
        }
      }
    }
  }
`;

const ArrowDown = styled.span`
  color: red;
`;
const enums = ["PunchIn", "PunchOut"];
function TableContainer({
  empty = false,
  status = "",
  cols,
  textCols,
  data = [],
  tableProps = {},
  checkbox = false,
  showButton = false,
  moreOptions = false,
  textField = false,
  onChecked = () => {},
  children,
  ...rest
}) {
  const [checked, setChecked] = useState();
  const [all, setAll] = useState(false);

  useEffect(() => {
    setChecked(new Array(data.length).fill(false));
  }, [data]);

  useEffect(() => {
    onChecked(checked?.map((v, i) => (v ? data[i] : null)).filter((v) => v));
  }, [checked, onChecked, data]);

  function formatData(value, Type, data) {
    if (!Type || !value) return value;
    switch (Type) {
      case "count":
        return value.length;
      case "date":
        // return new Date(value).toLocaleDateString("fr-FR");
        // return toDateTime(value, { time: false, short: true });
        return format(new Date(value), "dd/MM/yyyy");
      case "datetime":
        // return toDateTime(value);
        return format(new Date(value), "dd/MM/yyyy hh:mm a");
      case "currency":
        return currency(value);
      case "country":
        return getCountry(value);
      case "fixed":
        return isFixed(value);
      case "active":
        return isActive(value);
      case "link":
        return (
          <a href={value} target="_blank" rel="noreferrer">
            Download
          </a>
        );
      default:
        return Type(value, data);
    }
  }

  const renderCell = (col, i) => {
    if (col.render) {
      const Col = col.render;
      return <Col {...data[i]} {...col.renderParams} index={i} />;
    }
    const Append = col.append || (() => {});
    return (
      <span style={col.styles || {}}>
        {formatData(objByStr(data[i], col.name), col.fn)}
        <Append {...data[i]} />
      </span>
    );
  };

  const handleCheckAll = () => {
    if (all) {
      setAll(false);
      setChecked((checked) => [...checked.fill(false)]);
    } else {
      setAll(true);
      setChecked((checked) => [...checked.fill(true)]);
    }
    // onChecked(checked)
  };

  const handleCheckbox = (index) => {
    setAll(false);
    var count = 0;
    setChecked((checked) => {
      const a = checked.map((c, i) => {
        var v = i === index ? !c : c;
        if (v) count++;
        return v;
      });
      if (count === checked.length) setAll(true);
      return a;
    });
  };

  if (!data || !checked) return;

  return (
    <Card style={{ padding: 0, position: "relative" }}>
      {status === "fetching" && (
        <div
          style={{
            position: "absolute",
            top: "80px",
            left: "calc(50% - 35px)",
            width: "70px",
            zIndex: 9999,
          }}
        >
          <Loading />
        </div>
      )}
      <Slider {...rest}>
        <Table {...{ sx: { minWidth: 500 }, size: "small", ...tableProps }}>
          <thead>
            <tr>
              {checkbox && (
                <td>
                  <Checkbox
                    name="all"
                    value="all"
                    checked={all}
                    inputProps={{ checked: all, onChange: handleCheckAll }}
                  />
                </td>
              )}
              {cols.map((col) => (
                <td key={col.name} {...col.props}>
                  {col.filter ? (
                    <Dropdown content={col.filter} space={5}>
                      <Button
                        variant={col.filtered ? "contained" : "text"}
                        disableElevation
                        endIcon={<ArrowDown />}
                      >
                        {col.label}
                      </Button>
                    </Dropdown>
                  ) : (
                    col.label
                  )}
                </td>
              ))}
              {textField &&
                textCols.map((col) => {
                  return <td style={{ textAlign: "left" }}>{col.label}</td>;
                })}
              {showButton && <td style={{ textAlign: "center" }}>Details</td>}
              {moreOptions && <td></td>}
            </tr>
          </thead>
          <tbody>
            {!empty ? (
              data.map((row, i) => (
                <tr
                  key={`${row.id}${i}`}
                  className={checked[i] ? "active" : ""}
                >
                  {checkbox && (
                    <td>
                      <Checkbox
                        name={"row" + i}
                        value={row.id || ""}
                        inputProps={{
                          checked: checked[i] || false,
                          onChange: () => handleCheckbox(i),
                        }}
                      />
                    </td>
                  )}
                  {cols.map((col) => (
                    <td key={col.name}>{renderCell(col, i)}</td>
                  ))}
                  {textField &&
                    textCols.map(
                      (col, index) =>
                        textField &&
                        col.inputName && (
                          <td key={col.name}>
                            <TextField
                              label={col.inputLabel && col.inputLabel}
                              name={col.inputName && col.inputName}
                              onChange={(v) => {
                                data[index - 1][col.inputName] = v.target.value;
                              }}
                              inputProps={{
                                type: col.inputType && col.inputType,
                                label:
                                  col.inputName &&
                                  (data[i][col.inputName] !== 0
                                    ? data[i][col.inputName]
                                    : 0),
                              }}
                            />
                          </td>
                        )
                    )}

                  {showButton && (
                    <td>
                      <Button
                        style={{ width: "100%", minWidth: "125px" }}
                        className="transparent small"
                        onClick={() => {
                          showButton(row);
                        }}
                      >
                        View More
                      </Button>
                    </td>
                  )}
                  {moreOptions && (
                    <td>
                      <MoreOptions
                        id={row.id}
                        data={row}
                        moreOptions={moreOptions}
                      />
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td align="center" colSpan={cols.length + 2}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Slider>
    </Card>
  );
}

export default memo(TableContainer);
