import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Checkbox } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { useCompanies } from "../companies/provider";

import fields from "./fields";
import {
  createYupSchema,
  setDateFormat,
} from "../../utilities/yupSchemaCreator";
import { useYear } from "./provider";

import InputDate from "../../ui/InputDate";
import TextArea from "../../ui/TextArea";

const Grid = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function YearForm({ year = null, handleSuccess }) {
  const [error, setErrors] = useState(null);

  const navigate = useNavigate();

  const { create, edit } = useYear();

  const yupSchema = fields.reduce(createYupSchema, {
    title: Yup.string(),
  });
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: year,
  });

  useEffect(() => {
    if (year) {
      fields.forEach((f) => {
        setValue(f.name, year[f.name] || "");
      });
    }
  }, [year, setValue]);

  const getResouce = (name) => {
    return;
  };

  const handleChange = useCallback(
    (v, name) => {
      setValue(name, Array.isArray(v) ? v.map((i) => i.value) : v);
    },
    [setValue]
  );

  let params = useParams();

  const onSubmit = async (values) => {
    values.start = setDateFormat(values.start);
    values.end = setDateFormat(values.end);
    setErrors({});

    if (values.start == values.end) {
      setError("start", {
        type: "custom",
        message: "start and end dates are the same",
      });
      setError("end", {
        type: "custom",
        message: "start and end dates are the same",
      });
      return;
    }
    try {
      if (year) await edit(params.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setErrors({ title: e.errors.message });
      else if (e.status === 409)
        setErrors({
          title: `Year overlap , please check the dates`,
        });
      else if (e.status === 500)
        setErrors({
          title: `somthing went wrong please try again`,
        });
      console.log("e", e);
    }
  };

  //if (!companies.length) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card style={{ paddingBottom: 40 }}>
        {/* <Grid>
          <TextField
            label="Title"
            name="title"
            style={{ marginBottom: "10px" }}
            error={!!errors['title']}
            msg={errors['title']?.message}
            inputProps={{
              ...register('title')
            }}
          />
        </Grid> */}
        <Grid>
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            if ((f.editOnly && !year) || (f.newOnly && year)) return null;

            if (f.data) {
              let selected;
              if (f.editOnly) {
                const singleResourceName = f.resourceName.substring(
                  0,
                  f.resourceName.length - 1
                );

                selected = getValues(f.resourceName).map(
                  (r) => r.id || r[`${singleResourceName}Id`] || r
                );
              }

              return (
                <div key={i}>
                  <Select
                    style={{ width: "100%" }}
                    inputStyle={{ height: "60px" }}
                    label={f.label}
                    data={f.resourceName ? getResouce(f.resourceName) : f.data}
                    mapOptions={f.mapData}
                    multi={f.multi || false}
                    required={f.required}
                    onChange={handleChange}
                    defaultValue={selected}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message || f.msg}
                    inputProps={{
                      ...register(f.name),
                    }}
                  />
                </div>
              );
            } else if (f.type === "date")
              return (
                <InputDate
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  type={f.type}
                  size="medium"
                  value={new Date(getValues(f.name))}
                  onDateChange={(v) => setValue(f.name, v)}
                  pickerProps={f.pickerProps}
                />
              );
            else
              return (
                <TextField
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  inputProps={{
                    ...register(f.name),
                    ...inputProps,
                  }}
                />
              );
          })}
          {/* <Select
            data={[
              {
                id: 1,
                name: "Locked",
              },
              {
                id: 2,
                name: "not Locked",
              },
            ]}
            name="companyId"
            mapOptions={{ value: "id", label: "name" }}
            onChange={(e) => {
              e.value == 1
                ? setValue("isLocked", true)
                : setValue("isLocked", false);
            }}
            defaultValue={year?.isLocked == true ? 1 : 2}
            label={"status"}
          /> */}
        </Grid>
        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="5"
          error={!!errors.notes}
          msg={errors.notes?.message}
          inputProps={{
            ...register("notes"),
          }}
        />
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(YearForm);
