import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Checkbox, Icon } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useTransaction } from "./provider";

import InputDate from "../../ui/InputDate";
import TextArea from "../../ui/TextArea";
import { useCompanies } from "../companies/provider";
import { useStatus } from "../../services/StatusProvider";
import { useYear } from "../year/provider";
import { useAccounts } from "../accounts/provider";
import { delay } from "../../utilities/functions";

const Grid = styled.div`
  max-width: 991px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  row-gap: 0;
`;

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ShiftsWrap = styled.div`
  > div {
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0px;
    position: relative;
    height: 65px;
    .grids {
      width: 1200px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 24px;
      gap: 5px;
      position: relative;
      .acc {
        input {
          border: 1px solid
            ${(props) => (props.sumError ? "red" : props.theme.color.grayText)};
        }
      }
      span.remove {
        margin-top: 30px;
        display: block;
        justify-self: center;
        cursor: pointer;
        svg {
          fill: ${(props) => props.theme.color.grayText};
        }
        &:hover {
          svg {
            fill: ${(props) => props.theme.color.red};
          }
        }
      }
      span.add {
        cursor: pointer;
        &:hover {
          svg {
            fill: ${(props) => props.theme.color.primary};
          }
        }
      }
      h4 {
        margin: 0;
      }
      > div {
        margin-bottom: 0;
      }
      &:first-child::before {
        content: none;
      }
      &:last-child::before {
        content: none;
      }
    }
  }
`;

function TransactionForm({ journal = null, handleSuccess }) {
  const [error, setError] = useState(null);
  const [selectMapOption, setSelectMapOption] = useState({
    value: "id",
    label: "label1",
  });

  const navigate = useNavigate();
  let creditSum = 0;
  let debitSum = 0;
  const {
    state: { filters, formData },
    create,
    edit,
    setFormData,
  } = useTransaction();

  const yupSchema = fields.reduce(createYupSchema, {});
  const schema = Yup.object().shape({
    // notes: Yup.string().required("Required"),
    entries: Yup.array().of(
      Yup.object().shape({
        //   debit: Yup
        //     .number()
        //    // .required("Required")
        //     .nullable()
        //     .typeError("should be a number"),
        //   credit: Yup
        //   .number()
        //  // .required("Required")
        //   .nullable()
        //   .typeError("should be a number"),
        accountId: Yup.string()
          .nullable()
          .min(1, "you should select a valid account"),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData || journal,
  });

  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();

  useEffect(() => {
    if (getValues("companyId"))
      fetchYears({ companyId: getValues("companyId") });
  }, [getValues("companyId")]);

  useEffect(() => {
    if (isAcc) {
      setValue("companyId", user?.employee?.company?.id);
    } else {
      setValue("companyId", companies[0]?.id);
    }
  }, [user]);
  useEffect(() => {
    if (formData) {
      fields.forEach((f) => {
        setValue(f.name, formData[f.name] || "");
      });
    } else if (journal) {
      fields.forEach((f) => {
        setValue(f.name, journal[f.name] || "");
      });
    }
  }, [formData, journal, setValue]);

  const getResouce = (name) => {
    return;
  };

  const handleCompanyChange = useCallback(
    (data) => setValue("companyId", data.value),
    [setValue]
  );
  let params = useParams();

  let handleClick = () => {
    dispatch({ type: "set_values", payload: getValues() });
    navigate("/accounting/new");
  };
  const onSubmit = async (values) => {
    setFormData(null);
    values.financialYearId = filters?.yearId;
    if (
      values.entries[values.entries.length - 1].credit == 0 &&
      values.entries[values.entries.length - 1].debit == 0
    ) {
      values.entries = values.entries.slice(0, -1);
    }

    try {
      if (journal) await edit(params.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      if (e.status === 404)
        setError({
          title: "something went wrong , please check the data and try again",
        });
      else if (e.status === 409)
        setError({
          title: ` already in exist.`,
        });
      console.log("e", e);
    }
  };
  const handleYearChange = useCallback(
    (data) => setValue("financialYearId", data.value),
    [setValue]
  );

  // entries section
  const [entries, setEntries] = useState(
    Array(1)
      .fill(0)
      .map(() => ({
        debit: 0,
        credit: 0,
        accountId: 0,
        accountName: "",
        notes: "",
      }))
  );

  const [sums, setSums] = useState(
    Array(1)
      .fill(0)
      .map(() => ({ credit: 0, debit: 0 }))
  );
  useEffect(() => {
    if (formData) {
      let sh = formData.entries.map((entries, i) => {
        entries.debit = entries.debit;
        entries.credit = entries.credit;
        entries.accountId = entries.accountId;
        entries.notes = entries.notes;
        entries.accountName = entries.accountName;
        return entries;
      });

      let s = formData.entries.map((entries, i) => {
        entries.debit = entries.debit;
        entries.credit = entries.credit;
        return entries;
      });
      setEntries(sh);
      setSums(s);
      setValue("financialYearId", formData.financialYearId);
      setValue("notes", formData.notes);
      setValue("entries", [...sh]);
    } else if (journal) {
      let sh = journal.entries.map((entries, i) => {
        entries.debit = entries.debit;
        entries.credit = entries.credit;
        entries.accountId = entries.accountId;
        entries.notes = entries.notes;
        return entries;
      });
      console.log("set");
      let s = journal.entries.map((entries, i) => {
        entries.debit = entries.debit;
        entries.credit = entries.credit;
        return entries;
      });
      setEntries(sh);
      setSums(s);
      setValue("financialYearId", journal.financialYearId);
      setValue("notes", journal.notes);
      setValue("entries", [...sh]);
    }
  }, [formData, journal]);
  // useEffect(() => {
  //   setValue("entries", entries);

  //   if (journal) {
  //     setValue("notes", journal.notes || "");
  //   }
  // }, [journal]);

  const addEntry = () => {
    setEntries((entries) => {
      entries.push({
        debit: 0,
        credit: 0,
        accountId: 0,
        accountName: "",
        notes: "",
      });
      sums.push({ debit: 0, credit: 0 });
      return [...entries];
    });
  };

  const removeEntry = (i) => {
    setEntries((entries) => entries.filter((s, i2) => i !== i2));
    setSums((sums) => sums.filter((s, i2) => i !== i2));
  };

  let checkEquality = () => {
    sums.map((s, i) => (debitSum += sums[i].debit));
    sums.map((s, i) => (creditSum += sums[i].credit));
    let checker = creditSum != debitSum;
    if (checker) return true;
    else return false;
  };

  const {
    state: { NamesList },
    fetchByName: fetchAccount,
    dispatch,
  } = useAccounts();

  let getOptions = (e) => {
    if (isNaN(e)) {
      //label 1
      setSelectMapOption({ value: "id", label: "label" });
    } else {
      //label 2

      setSelectMapOption({ value: "id", label: "label2" });
    }
    if (
      e.length > 2 &&
      NamesList.map(
        (s) => !s?.accountName.toLowerCase().startsWith(e.toLowerCase())[0]
      )
    )
      fetchAccount({ companyId: getValues("companyId"), searchText: e }, true);
  };

  const handleAccountChange = useCallback(
    (e) => {
      if (e.data.id == -1) {
        setEntries((entries, i) => {
          entries[e.data.index].accountId = e.value;
          entries[e.data.index].accountName = e.data.label;
          entries[e.data.index].index = e.data.index;
          setValue("entries", [...entries]);
          setFormData(getValues());
          return [...entries];
        });
        navigate("/accounting/new");
      } else
        setEntries((entries, i) => {
          entries[e.data.index].accountId = e.value;
          entries[e.data.index].accountName = e.data.label;
          entries[e.data.index].index = e.data.index;
          setValue("entries", [...entries]);
          return [...entries];
        });
    },
    [setValue]
  );

  const addEntries = (value) => {
    if (
      entries[entries.length - 1].debit != 0 ||
      entries[entries.length - 1].credit != 0
    ) {
      addEntry();
    }
  };
  if (formData && !entries) return;
  function displayEntriesFields() {
    return entries.map((entry, i) => (
      <div key={i} data-count={i + 1}>
        <div className="grids">
          <div className="acc">
            <TextField
              label=""
              error={!!errors?.entries?.[i]?.debit}
              msg={errors?.entries?.[i]?.debit?.message}
              inputProps={{
                value: entry?.debit,
                onChange: (e) => {
                  addEntries(e.target.value);
                  setEntries((entries) => {
                    sums[i].debit = Number(e.target.value);
                    entries[i].debit = Number(e.target.value);
                    setValue("entries", [...entries]);
                    return [...entries];
                  });
                },
                required: true,
                disabled: sums[i]?.credit,
              }}
            />
          </div>
          <div className="acc">
            <TextField
              label=""
              error={!!errors?.entries?.[i]?.credit}
              msg={errors?.entries?.[i]?.credit?.message}
              inputProps={{
                value: entry?.credit,
                onChange: (e) => {
                  addEntries(e.target.value);

                  setEntries((entries) => {
                    sums[i].credit = Number(e.target.value);
                    entries[i].credit = Number(e.target.value);
                    setValue("entries", [...entries]);
                    return [...entries];
                  });
                },
                required: true,
                disabled: sums[i]?.debit,
              }}
            />
          </div>
          <Select
            label=""
            data={[
              ...NamesList.map((item) => ({
                ...item,
                index: i,
                label: item.accountName + " - " + item.accountNumber,
                label2: item.accountNumber + " - " + item.accountName,
              })),
              {
                id: -1,
                index: i,
                label: "add account",
                label2: "add accouunt",
                pinned: true,
              },
            ]}
            style={{ marginTop: "12px" }}
            mapOptions={selectMapOption}
            defaultValue={entries?.[i]?.accountId}
            error={!!errors?.entries?.[i]?.accountId}
            setPlaceholderText={entries[i]?.accountName}
            msg={errors?.entries?.[i]?.accountId?.message}
            getTextValue={delay(getOptions)}
            onChange={handleAccountChange}
          />
          <TextField
            label=""
            name="notes"
            error={!!errors.notes}
            value={entries[i].notes}
            msg={errors.notes?.message}
            onChange={(e) => {
              setEntries((entries) => {
                //sums[i].notes = e.target.value;
                entries[i].notes = e.target.value;
                setValue("entries", [...entries]);
                return [...entries];
              });
            }}
            inputProps={{
              value: entries[i].notes,
            }}
          />
          <Icon
            className="remove"
            onClick={() => removeEntry(i)}
            name="trash"
            w={16}
          />
        </div>
      </div>
    ));
  }

  //if (!companies.length) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card style={{ paddingBottom: 40 }}>
        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="2"
          error={!!errors.notes}
          msg={errors.notes?.message}
          inputProps={{
            ...register("notes"),
          }}
        />

        <Grid>
          <div>
            <ShiftsWrap sumError={checkEquality()}>
              <div>
                <div className="grids">
                  <h4>debit</h4>
                  <h4>credit</h4>
                  <h4>Account</h4>
                  <h4>Note</h4>
                </div>
              </div>
              {displayEntriesFields()}

              <div style={{ textAlign: "right", border: "none" }}>
                <span />
                <span />
                <span />

                <Icon
                  onClick={addEntry}
                  name="addSquare"
                  w={24}
                  className="add"
                />
              </div>
            </ShiftsWrap>
          </div>
        </Grid>

        <Grid2>
          <TextField
            label="Debit Sum"
            name="debitSum"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: debitSum,
              disabled: true,
            }}
          />

          <TextField
            label="Credit Sum"
            name="creditSum"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: creditSum,
              disabled: true,
            }}
          />

          <TextField
            label="Total"
            name="total"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: creditSum - debitSum,
              disabled: true,
            }}
          />
        </Grid2>
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
          disabled={checkEquality()}
        >
          Save
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(TransactionForm);
