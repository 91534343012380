import { memo, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import { useAttendancePatterns } from "./provider";
import Divider from "../../ui/Divider";
import { dateToLocalConverter } from "../../utilities/functions";
import { format } from "date-fns";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 780px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 0;
`;

const ShiftsWrap = styled.div`
  max-width: 1300px;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    h4 {
      margin: 0 0 6px 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.gray};
    }
  }
`;

function ViewAttendancePattern() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { selected },
    fetchOne,
  } = useAttendancePatterns();
  // const {
  //   state: { selected: company },
  //   fetchOne: fetchCompany,
  // } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne]);

  // useEffect(() => {
  //   if (selected) fetchCompany(selected.companyId);
  // }, [fetchCompany, selected]);

  // if (!company) return;
  if (!selected?.attendanceShifts) return;

  function displayShiftsFields() {
    return selected.attendanceShifts.map((item, i) => (
      <div key={i} data-count={i + 1}>
        <TextField
          label=""
          inputProps={{
            value: format(new Date(dateToLocalConverter(item?.startDate)), "dd/MM/yyyy hh:mm a"),
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
        <TextField
          label=""
          inputProps={{
            value: format(new Date(dateToLocalConverter(item?.endDate)), "dd/MM/yyyy hh:mm a"),
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
        <TextField
          label=""
          inputProps={{
            value: item?.tolerance,
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
        <TextField
          label=""
          inputProps={{
            value: item?.breakDuration,
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
      </div>
    ));
  }

  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Attendance Pattern Information</h2>

        <div style={{ cursor: "pointer" }}>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/attendance-pattern/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <TextField
            label="Company"
            inputProps={{
              value: selected.companyName,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label="Pattern Name"
            inputProps={{
              value: selected.attendancePatternName,
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid2>

        <div>
          <h3>Shifts</h3>
          <ShiftsWrap>
            <div>
              <h4>Start Date</h4>
              <h4>End Date</h4>
              <h4>Tolerance (Minutes)</h4>

              <h4>Break Duration (Minutes)</h4>
            </div>
            {displayShiftsFields()}
          </ShiftsWrap>
        </div>

        <Grid2>
          <div>
            <Divider var="spacer" h={24} />
            <TextField
              label="Next Start Date"
              inputProps={{
                type: "string",
                value: format(new Date(dateToLocalConverter(selected?.nextStartDate)), "dd/MM/yyyy hh:mm a"),
                readOnly: true,
                disabled: true,
              }}
            />
          </div>
        </Grid2>
      </Card>

      {/* <ButtonWrapper>
        <Button
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button className="outlined small" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ButtonWrapper> */}
    </div>
  );
}

export default memo(ViewAttendancePattern);
