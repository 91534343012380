import * as yup from "yup";
import { setDateFormat } from "../../utilities/functions";
import { parseDate } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "start",
    type: "date",
    required: "required",
    label: "Start Date",
    validationType: "date",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [parseDate],
      },

      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
  {
    name: "end",
    type: "date",
    label: "End Date",
    validationType: "date",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [parseDate],
      },
      {
        type: "min",
        params: [yup.ref("start"), "End date has to be more than start date"]
      },
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
 
];

export default fields;
