import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../ui";
import SearchInput from "../../ui/SearchInput";
import Slider from "../../ui/Slider";
import { useEmployees } from "../employees/provider";
import Item from "./ConsoleHomepage/Item";
import { useState } from "react";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;

  h2 {
    flex-grow: 3;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .search {
    /* flex-grow: 1; */
    min-width: 270px;
  }
  button {
    margin-left: auto;
  }
  .selected {
    input {
      &::placeholder {
        color: ${(props) => props.theme.color.dark};
        font-size: 18px;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    display: block;
    width: 100%;
    h2 {
      text-align: left;
      display: inline-block;
      width: 45%;
      margin-bottom: 20px;
    }
    .search {
      display: inline-block;
      width: 45%;
      margin-bottom: 20px;
    }
    .custom-select {
      width: 30% !important;
      display: inline-block;
      margin: 30px 0;
      margin-right: 20px;
      .selected {
        width: 100% !important;
      }
    }
    button {
      display: inline-block;
      margin-bottom: 30px;
    }
  }

`;

const Grid2 = styled.div`
  display: flex;
  gap: 24px;
`;

function ConsoleHomepage({
  forPayroll,
  noTitle = false,
  noSearch = false,
  noButton = false,
  prepend = null,
}) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const {
    state: { list: employees },
    fetchList,
  } = useEmployees();

  useEffect(() => {
    fetchList({statusId : 1}); 
  }, [fetchList]);

  if (!employees.length) return;

  return (
    <div>
      <Grid>
        {prepend && <div class="prepended">{prepend}</div>}
        {Boolean(!noTitle) && <h2>Console Homepage</h2>}
        {Boolean(!noSearch) && <SearchInput onChange={setSearch} value={search} />}
        {/* <Select
          style={{ height: "48px", width: "117px" }}
          data={["1", "2", "3"]}
          defaultValue={new Date().getDate().toString()}
          inputProps={{
            name: 'day'
          }}
        /> */}
        {/* <Select
          style={{ height: "48px", width: "150px" }}
          data={months.map(m => ({name: m.long, value: m.index}))}
          mapOptions={{label: "name", value: 'value'}}
          defaultValue={new Date().getMonth()}
          inputProps={{
            name: 'month'
          }}
        /> */}
        {/* <Select
          style={{ height: "48px", width: "117px" }}
          data={genYears(2000)}
          defaultValue={new Date().getFullYear().toString()}
          inputProps={{
            name: 'year'
          }}
        /> */}
        {Boolean(!noButton) && <Button
          className="small"
          onClick={() => (forPayroll ? null : navigate("/employees/new"))}
          iconName="userAdd"
        >
          {forPayroll ? "Add Wage" : "New Contact"}
        </Button>}
      </Grid>
      <Slider>
        <Grid2>
          {employees
            .filter((item) =>
              item.fullName.toLowerCase().includes(search.toLowerCase(), 0)
            )
            .map((item) => (
              <Item forPayroll={forPayroll} key={item.id} data={item} />
            ))}
        </Grid2>
      </Slider>
    </div>
  );
}

export default ConsoleHomepage;
