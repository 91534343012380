import { useEffect, useState } from "react";
import { useCompanies } from "../../companies/provider";

function DepartmentCompanyCell({ companyId }) {
  const [name, setName] = useState();
  const { fetchOne } = useCompanies();

  useEffect(() => {
    (async () => {
      const res = await fetchOne(companyId);
      setName(res.businessName);
    })();
  }, [companyId, fetchOne]);

  return name ? name : "loading";
}

export default DepartmentCompanyCell;
