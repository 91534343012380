import React from 'react'
import { getValueFromEnum } from '../../../utilities/functions'
import {accountType} from '../../../data/Enums'

function IsActive(data) {
  return (
    <p>{data?.isActive ? 'Active' : ' not Active'}</p>
  )
}

export default IsActive