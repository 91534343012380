import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddAttendancePattern from "./AddAttendancePattern";
import AttendancePatternClone from "./AttendancePatternClone";
import AttendancePatterns from "./AttendencePattern";
import EditAttendancePattern from "./EditAttendancePattern";
import ListAttendancePatterns from "./ListAttendancePatterns";
import ViewAttendancePattern from "./ViewAttendancePattern";

export default (
  <Route
    path="attendance-pattern"
    element={
      <RequireAuth>
        <AttendancePatterns />
      </RequireAuth>
    }
  >
    <Route
      path="list/company/:id"
      element={
        <RequireAuth>
          <ListAttendancePatterns />
        </RequireAuth>
      }
    />
    <Route
      path="new/company/:id"
      element={
        <RequireAuth>
          <AddAttendancePattern />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditAttendancePattern />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewAttendancePattern />
        </RequireAuth>
      }
    />
    <Route
      path="clone/:id"
      element={
        <RequireAuth>
          <AttendancePatternClone />
        </RequireAuth>
      }
    />
  </Route>
);
