import React from 'react'

function TotalDeductions(row) {
  if (!row) return;
console.log(row
    ,'deductions');
  return (
    <div>{
        row?.totalDeductions + ' ' + row?.currencyInfo.symbol
    }</div>
  )
}

export default TotalDeductions