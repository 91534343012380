import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import { useCurrency } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import { useState } from "react";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function CurrenctList() {
  const [search, setSearch] = useState("");
  const {
    state: { list, status , count, page, perPage },
    fetchList,
    remove,
    dispatch
  } = useCurrency();
  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const cols = [
    { name: "name", label: "Currency Name" },
    {
      name: "shortName",
      label: "Short Name",
    },
    { name: "symbol", label: "Symbol" },

  ];

  const handleClick = () => {
    navigate("/Currency/new");
  };

  const handleEdit = (id) => {
    navigate(`/Currency/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Item has been removed");
    } catch (error) {
      console.log(error);
    }
  };



  const handleChangePage = (newPage) =>{
    dispatch({ type: "set_page", payload: newPage });
}
  if (!list ) return
  return (
    <>
      <Grid>
        <h2>Currency List</h2>
        <SearchInput onChange={setSearch} value={search} />
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Currency table"
        cols={cols}
        data={list.filter(item => item.name.toLowerCase().includes(search.toLowerCase(), 0))}
        checkbox
        moreOptions={{
          edit: handleEdit,
          delete: handleDelete,
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default CurrenctList;
