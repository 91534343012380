import { useCallback, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
} from "recharts";
import styled from "styled-components";
import { Card, Select } from "../../../ui";
import { useCompanies } from "../../companies/provider";
import { useStats } from "../../stats/provider";

const data = [
  { name: "Software Engineering", value: 400 },
  { name: "UX/UI Design", value: 300 },
  { name: "QA Engineering", value: 300 },
  { name: "Theme: 04", value: 200 },
  { name: "Theme: 05", value: 278 },
  { name: "Theme: 06", value: 189 },
];

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    flex-grow: 1;
  }
`;

const COLORS = ["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"];

const renderLegendText = (value) => {
  return (
    <span
      style={{
        color: "#abafb3",
        fontSize: "15px",
        paddingRight: "9px",
        paddingLeft: "9px",
        verticalAlign: "middle",
      }}
    >
      {value}
    </span>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (innerRadius + 9) * cos;
  const sy = cy + (innerRadius + 9) * sin;

  return (
    <g>
      <Sector
        cx={sx}
        cy={sy}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function Departments() {
  const [active, setActive] = useState(0);

  const {
    state: { list: companies, selected: company },
    dispatch,
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { departments },
    fetchDepartments,
  } = useStats();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companies.length) return;
    dispatch({ type: "set_selected", payload: companies[0], keep: true });
  }, [companies, dispatch]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  const onPieEnter = (_, index) => {
    setActive(index);
  };

  const handleCompanyChange = useCallback(
    (v) => dispatch({ type: "set_selected", payload: v.data }),
    [dispatch]
  );

  const companyDepartments =
    departments?.filter((h) => h.company.id === company?.id)
  ;

  return (
    <div style={{ width: "100%" }}>
      <Title>
        <h2>Departments</h2>
        <Select
          inputStyle={{ height: "48px", width: "250px" }}
          data={companies}
          mapOptions={{ label: "businessName", value: "id" }}
          defaultValue={company?.id || ''}
          onChange={handleCompanyChange}
          inputProps={{
            value: company?.id || '',
            onChange: () => {}
          }}
        />
      </Title>
      <Card>
        {!companyDepartments.length && <p>This company has no departments</p>}
        <ResponsiveContainer width="100%" height={420}>
          <PieChart height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={companyDepartments?.map((d) => ({
                name: d.department.departmentName,
                value: d.employeesCount,
              }))}
              outerRadius={150}
              fill="#8884d8"
              legendType="circle"
              activeShape={renderActiveShape}
              activeIndex={active}
              onMouseEnter={onPieEnter}
            >
              {companyDepartments.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {/* {companyDepartments.length && <Legend align="left" formatter={renderLegendText} />} */}
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}

export default Departments;
