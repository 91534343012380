import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../ui/TableContainer";
import { useUserAccounts } from "../provider";
import { useUtils } from "../../../services/utilsProvider";
import { Pagination } from "../../../ui";
import ActiveCell from "./ActiveCell";

const cols = [
  { name: "displayName", label: "Employee Name" },
  { name: "email", label: "Email" },
  { name: "isActive", label: "Status" , render:ActiveCell },

  
];

function ListAdmins({ company }) {
  const {
    state: { allEmployees,allEmployeeStatus },
    fetchAllEmployees:fetchEmployees,

  } = useUserAccounts();

  useEffect(() => {
     fetchEmployees({
      pageNumber: 1,
      pageSize: 150,
      role: "Admin"
    } );
  }, [fetchEmployees]);


  return (
    <div>
      <TableContainer
        empty={allEmployees?.length === 0}
        status={allEmployeeStatus}
        aria-label="Holidays table"
        cols={cols}
        data={allEmployees}
      />

    </div>
  );
}

export default memo(ListAdmins);
