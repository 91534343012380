import React from "react";

function EmployeeRoll(row) {
  if (!row) return;
  let roles = row?.roles;
  if (roles.includes('HR')) return <>HR</>;
  else return <div>{roles.join(', ')}</div>;
}

export default EmployeeRoll;
