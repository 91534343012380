import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useUtils } from "../../services/utilsProvider";

const Context = createContext();

export function useLeaves() {
  return useContext(Context);
}

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

d.setDate(d.getDate() - 30);
let fom =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

const initState = {
  list: [],
  rlist: [],
  status: "idle",
  error: null,
  emps: [],
  deps: [],
  selected: [],
  absFrom: fom,
  absTo: now,
  companyID: null,
  departmentID: null,
  employeeID: null,
  page: 0,
  filters: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_rlist":
      return { ...state, list: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      return { ...state, selected: { ...action.payload } };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_emps_list":
      return { ...state, emps: { ...action.payload } };
    case "set_deps_list":
      return { ...state, deps: { ...action.payload } };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "approve":
      const edited = state.list.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
      return { ...state, list: edited };
    case "compnayIDchange":
      return { ...state, companyID: action.payload };
    case "departmentIDchange":
      return { ...state, departmentID: action.payload };
    case "employeeIDchange":
      return { ...state, employeeID: action.payload };
    case "from":
      return { ...state, absFrom: action.payload };
    case "to":
      return { ...state, absTo: action.payload };
    case "set_filters":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function LeavesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { notify } = useUtils();
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "Leave/Create",
            data,
            { method: "POST" },
            true
          );

          dispatch({ type: "add", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchList = useCallback(
    async (headersData) => {
      dispatch({ type: "status", payload: "fetching" });

      if (headersData.departmentId < 0 || headersData.departmentId == null)
        delete headersData.departmentId;

      const resData = await req(`Leave/List`, null, {}, true, headersData);
      dispatch({ type: "status", payload: `idle` });

      dispatch({ type: "set_list", payload: resData.data.leaves });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchReportList = useCallback(
    async (headersData, Eid) => {
      dispatch({ type: "status", payload: "fetching" });

      if (headersData.departmentId < 0 || headersData.departmentId == null)
        delete headersData.departmentId;
      delete headersData.employeeId;
      if (!headersData.fromDate) delete headersData.fromDate;
      if (!headersData.toDate) delete headersData.toDate;

      const resData = await req(
        `Leave/Report/${Eid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });

      dispatch({ type: "set_rlist", payload: resData.data });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Leave/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Leave/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setStatus = useCallback(
    async (id, status) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Leave/SetStatus/${id}`,
            {
              statusId: status,
            },
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData.data });
          dispatch({ type: "approve", payload: resData.data });
          notify("leave has been Approved successfull");
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const accept = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Leave/Accept/${id}`, {}, { method: "PUT" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const reject = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Leave/Reject/${id}`, {}, { method: "PUT" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Leave/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        setStatus,
        remove,
        reject,
        accept,
        fetchReportList,
        setFilters,
      }}
    >
      {children}
    </Context.Provider>
  );
}
