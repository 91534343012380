import { useRef, useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import Select from "../../ui/select/Select";
import AttendanceReportList from "../attendance/AttendanceReportList";
import LeaveReportList from "../leaves/LeaveReportList";
import { useReports } from "./provider";
import EmployeeReport from "../employees/EmployeeReport";
import CurrentAttendance from "../attendance/CurrentAttendance";
import { useStatus } from "../../services/StatusProvider";
import GetJounrals from "../transaction/reports/GetJounrals";
import GetJournals from "../transaction/reports/GetJounrals";
import GetGeneralLedger from "../transaction/reports/GetGeneralLedger";
import GetTrialBalance from "../transaction/reports/GetTrialBalance";
import GetIncomeStatment from "../transaction/reports/GetIncomeStatment";
import GetBalanceSheet from "../transaction/reports/GetBalanceSheet";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function ListReport() {
  let user = JSON.parse(localStorage.getItem("user"));

  const mapReportOptions = useRef({ value: "id", label: "ReportName" });
  const [isPO, setIsPO] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHr, setIsHr] = useState(false);

  const {
    state: { isAcc },
  } = useStatus();

  useEffect(() => {
    user?.roles?.filter((r) => r === "Admin").map((e) => setIsAdmin(true));
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes("HR") && setIsHr(true);
  }, [user]);

  const {
    state: { selectedReport, reports },
    dispatch,
    setReports,
  } = useReports();

  const handleReportChange = useCallback((item) => {
    dispatch({ type: "set_report", payload: item.value });
  }, []);

  const AccList = [
    { id: 5, ReportName: "Get Journal" },
    { id: 6, ReportName: "Get General Ledger" },
    { id: 7, ReportName: "Get Trial Balance" },
    { id: 8, ReportName: "Get Income Statement" },
    { id: 9, ReportName: "Get Balance Sheet" },
  ];

  const HRList = [
    { id: 1, ReportName: "Attendance" },
    { id: 2, ReportName: "Leaves" },
    { id: 3, ReportName: "Employees" },
    { id: 4, ReportName: "Current Attendance" },
  ];

  useEffect(() => {
    if (isAcc) {
      setReports(AccList);
    } else if (isAdmin) {
      setReports([...HRList, ...AccList]);
    } else {
      setReports([...HRList]);
    }
  }, [isAcc, isAdmin]);

  useEffect(() => {
    if (reports && !selectedReport)
      dispatch({ type: "set_report", payload: reports[0]?.id });
  }, [reports, isAcc, isAdmin]);

  console.log(reports, isHr, user?.roles, "reports");
  if (!reports && !selectedReport) return;
  return (
    <>
      <Grid>
        <h2>Report List</h2>

        <Select
          data={reports}
          mapOptions={mapReportOptions.current}
          onChange={handleReportChange}
          defaultValue={selectedReport}
          inputStyle={{ height: "40px", maxWidth: "280px" }}
          inputProps={{
            value: selectedReport,
            readOnly: true,
          }}
        />
      </Grid>
      {selectedReport == 1 && (isAdmin || isHr) && <AttendanceReportList />}
      {selectedReport == 2 && <LeaveReportList />}
      {selectedReport == 3 && <EmployeeReport />}
      {selectedReport == 4 && <CurrentAttendance />}
      {selectedReport == 5 && (isAcc || isAdmin) && <GetJournals />}
      {selectedReport == 6 && (isAcc || isAdmin) && <GetGeneralLedger />}
      {selectedReport == 7 && (isAcc || isAdmin) && <GetTrialBalance />}
      {selectedReport == 8 && (isAcc || isAdmin) && <GetIncomeStatment />}
      {selectedReport == 9 && (isAcc || isAdmin) && <GetBalanceSheet />}
    </>
  );
}

export default ListReport;
