import React from 'react'
import { toDateTime } from '../../../utilities/functions'

function EnddateCell(data) {
    return (
    <div>
        {
            toDateTime(data?.end, {time : false , short: true})
        }
    </div>
  )
}

export default EnddateCell