import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddUserAccount from "./AddUserAccount";
import EditHrCompanies from "./ViewHR";
import ListAccounts from "./ListAccounts";
import SetHRUserAccount from "./SetHRUserAccount";
import UserAccounts from "./UserAccounts";
import ViewHR from "./ViewHR";
import AllEmployee from "./AllEmployee/AllEmployee";
import CreateAdmin from "./CreateAdmin";

export default (
  <Route
    path="accounts"
    element={
      <RequireAuth>
        <UserAccounts />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListAccounts />
        </RequireAuth>
      }
    />
    <Route
      path="new/employee/:id"
      element={
        <RequireAuth>
          <AddUserAccount />
        </RequireAuth>
      }
    />
    <Route
      path="set-hr/employee/:id"
      element={
        <RequireAuth>
          <SetHRUserAccount />
        </RequireAuth>
      }
    />
     <Route
      path="view/hr/:username"
      element={
        <RequireAuth>
          <ViewHR />
        </RequireAuth>
      }
    /> 
    <Route
      path="AllEmployees"
      element={
        <RequireAuth>
          <AllEmployee />
        </RequireAuth>
      }
    />

<Route
      path="createAdmin"
      element={
        <RequireAuth>
          <CreateAdmin />
        </RequireAuth>
      }
    />
  </Route>
);
