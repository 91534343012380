import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useLeaves, usePurches } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "../../data/countries.json";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import fields from "./fields";
let day_for_hourly_absence = 0;
let final_abcence_from = 0;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(value, originalValue) {
  var d = new Date(value);

  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      d.getHours().padLeft() +
      ":" +
      d.getMinutes().padLeft()
    );
  }

  if (d == "Invalid Date") {
    final_abcence_from = day_for_hourly_absence + " " + value;
    return final_abcence_from;
  }
}

const schema = Yup.object().shape({
  description: Yup.string().required("this field is required"),
});

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function PurchesForm({ employeeIdforif, handleSuccess }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();
  
  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);
  let location = useLocation();

  let types = [
    {status:1 , value:'Pending'},
    {status:2 , value:'Approved'},
    {status:3 , value:'Rejected'},
    {status:4 , value:'Accepted By Head'},
    {status:5 , value:'Rejected By Head'},
    {status:6 , value:'Processed'},
    {status:7 , value:'Canceled'},

  ];
  const {
    state: { list: leave, emps: employee, deps: departments, selected, page },
    create,
    fetchOne,
    edit,
    dispatch,
    fetchEmpList,
    fetchDEPSList,
  } = usePurches();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submitting
  const onSubmit = async (values) => {
    delete values.employeeName
    delete values.company
    console.log(values, "onSubmit");

    
    try {

      await edit(location?.state.purches.id, values)
      reset();
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      setError({ title: e.errors.message });
      console.log("e", e.errors.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Card style={{ marginTop: "20px" }}>
        <Grid>
          <TextField
            required
            label="employee name"
            name="employeeName"
            error={!!errors.employeeName}
            msg={errors.employeeName?.message}
            inputProps={{
              ...register("employeeName"),
              required: true,
              disabled: true,
              value: location?.state.purches.employeeName,
              style: {
                marginTop: "20px",
              },
            }}
          />

          <TextField
            required
            label="Company"
            name="company"
            error={!!errors.company}
            msg={errors.company?.message}
            inputProps={{
              ...register("company"),
              required: true,
              disabled: true,
              value: companies
                .filter((item) => {
                  return item.id === location?.state.purches.companyId;
                })
                .map((i) =>  i.businessName),
              style: {
                marginTop: "20px",
              },
            }}
          />

          <TextField
            required
            label="status"
            name="status"
            inputProps={{
            
              required: true,
              disabled: true,
              value: types
                .filter((item) => {

                  return item.status == location?.state.purches.status;
                })
                .map((i) => i.value),
              style: {
                marginTop: "20px",
              },
            }}
          />

        </Grid>
        <Grid>
        <TextField
            style={{ marginTop: "20px" }}
            required
            label="Description"
            name="description"
            error={!!errors.description}
            msg={errors.description?.message}
            
            inputProps={{
              defaultValue: location?.state.purches.description , 
              autoFocus:true,
              ...register("description"),
              required: true,
              
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default PurchesForm;
