import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEmployees } from "./provider";
import { Button, Card, TextField, Select } from "../../ui";
import { useCompanies } from "../companies/provider";
import ErrorsList from "../../ui/ErrorsList";
import { useDepartments } from "../departments/provider";
import { useAttendancePatterns } from "../attendancePatterns/provider";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import FileField from "../../ui/FileField";
import { useUtils } from "../../services/utilsProvider";
import InputDate from "../../ui/InputDate";
import { setDateFormat, transformDate2 } from "../../utilities/functions";

const yupSchema = fields.reduce(createYupSchema, {
  maxAnnualLeave: Yup.string().required("this field is required"),
  maxSickLeave: Yup.string().required("this field is required"),
  dailyHours: Yup.string().required("this field is required"),
  userName: Yup.string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(5, "user name must be at least 5 characters long"),
});
const schema = Yup.object().shape(yupSchema);

// const compensationsCols = [
//   { name: "amount", fn: "currency", label: "Amount" },
//   { name: "notes", label: "Notes" },
// ];

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const getToday = () => {
  let d = new Date();
  let today =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft();
  return today.split("-")[0];
};
function EmployeeForm({ employee = null, handleSuccess }) {

  const [contractFile, setContractFile] = useState(
    employee?.contract
      ? { name: employee?.contract, url: employee?.contractUrl }
      : null
  );

  const [cvFile, setCvFile] = useState(
    employee?.documentList.length
      ? {
          name: employee?.documentList[0].documentUrl,
          url: employee?.documentList[0].documentUrl,
        }
      : null
  );

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const {
    state: { currency },
    fetchCurrency,
    create,
    edit,
    dispatch: clear,
  } = useEmployees();

  useEffect(() => {
    fetchCurrency();
  }, []);

  const {
    state: { list: companies},
    fetchList: fetchCompanies,
    fetchOne: fetchCompany,
  } = useCompanies();

  const {
    state: { list: departments },
    fetchList: fetchDepartments,
  } = useDepartments();

  const {
    state: { list: attendancePatterns },
    fetchList: fetchAttendancePatterns,
  } = useAttendancePatterns();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: employee,
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name === "companyId") {
        fetchDepartments(values[name]);
        fetchCompany(values[name]);
      }
    });

    return () => subscription.unsubscribe();
  }, [fetchDepartments, watch]);

  useEffect(() => {
    if (employee) fetchAttendancePatterns(employee.companyId);
  }, [employee, fetchAttendancePatterns]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "contractUpload")
        setContractFile(value["contractUpload"][0]);
      if (name === "cvUpload") setCvFile(value["cvUpload"][0]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const [preview, setPreview] = useState(employee?.photoUrl || null);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "avatar") {
        setPreview(URL.createObjectURL(value["avatar"][0]));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { alertUser } = useUtils();
  const onSubmit = async (values) => {
    delete values.cvUpload;
  values.birthDate= setDateFormat(values.birthDate)
  values.hiringDate= setDateFormat(values.hiringDate)


    try {
      if (employee) await edit(employee.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) {
        if(e.errors.response === 54) setError({title :'MaxAnnualLeave must be greater than already taken Annual-Leaves, including Hourly-Leaves'});
        else if(e.errors.response === 55) setError({title :'maxSickLeave must be greater than already taken Sickness-Leaves'});
        else setError({title :e.errors.message});

      }
      else if (e.status === 409)
        setError({
          title: `There is another employee with the same name or same shift number`,
        });
    }
  };

  const getResouce = (name) => {
    if (name === "departments") return departments;
    if (name === "companies") return companies;
    if (name === "currencyList") return currency;
    // else return [{id:null , attendancePatternName:'no Pattern'} ,...attendancePatterns];
    else return attendancePatterns;
  };

  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
      if (name === "companyId") fetchAttendancePatterns(v.value);
    },
    [fetchAttendancePatterns, setValue]
  );
  if (!companies?.length) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <div>
            <Grid>
              {fields.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;

                inputProps.type = f.type;

                // const resource = f.resourceName
                //   ? getResouce(f.resourceName)
                //   : f.data;

                if (f.data) {
                  return (
                    <div key={i}>
                      <Select
                        style={{ width: "100%", marginBottom: "20px" }}
                        inputStyle={{ height: "60px", width: "100%" }}
                        label={f.label}
                        data={
                          f.resourceName ? getResouce(f.resourceName) : f.data
                        }
                        mapOptions={f.mapData}
                        required={f.required}
                        defaultValue={getValues(f.name)}
                        onChange={onChange}
                        error={!!errors[f.name]}
                        msg={errors[f.name]?.message || f.msg}
                        inputProps={{
                          ...register(f.name),
                        }}
                      />
                    </div>
                  );
                } else if (f.type === "date")
                return (
                  <InputDate
                    key={i}
                    label={f.label}
                    name={f.name}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message}
                    type={f.type}
                    size="medium"
                    value={new Date(getValues(f.name))}
                    onDateChange={(v) => setValue(f.name, v)}
                    pickerProps={f.pickerProps}
                  />
                );
                else
                  return (
                    <TextField
                      key={i}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      msg={errors[f.name]?.message}
                      inputProps={{
                        ...register(f.name),
                        ...inputProps,
                      }}
                    />
                  );
              })}

              <TextField
                label={"Max Annual Leave"}
                name={"maxAnnualLeave"}
                error={!!errors["maxAnnualLeave"]}
                msg={errors["maxAnnualLeave"]?.message}
                required={true}
                inputProps={{
                  defaultValue: employee?.maxAnnualLeave,
                  ...register("maxAnnualLeave"),
                  required: true,
                }}
              />
              <TextField
                label={"Max Sick Leave"}
                name={"maxSickLeave"}
                error={!!errors["maxSickLeave"]}
                msg={errors["maxSickLeave"]?.message}
                required={true}
                inputProps={{
                  defaultValue: employee?.MaxSickLeave,
                  ...register("maxSickLeave"),
                  required: true,
                }}
              />
              <TextField
                label={"Daily Hours"}
                name={"dailyHours"}
                error={!!errors["dailyHours"]}
                msg={errors["dailyHours"]?.message}
                required={true}
                inputProps={{
                  defaultValue: employee?.dailyHours,
                  ...register("dailyHours"),
                  required: true,
                }}
              />
              {employee && (
                <>
                  <TextField
                    label={"User Name"}
                    name={"userName"}
                    error={!!errors["userName"]}
                    msg={errors["userName"]?.message}
                    required={true}
                    inputProps={{
                      defaultValue:
                        (employee?.userInfo && employee?.userInfo?.username) ||
                        "",
                      ...register("userName"),
                      required: true,
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid>
              <FileField
                style={{
                  gridColumn: "1/3",
                  marginBottom: "20px",
                }}
                label="Contract"
                buttonLabel={
                  employee ? "Upload new contract" : "Upload contract"
                }
                document
                documentFile={contractFile}
                onClose={() => {
                  setValue("contractUpload", "");
                  setValue("contract", "");
                  setContractFile(null);
                }}
                inputProps={{
                  name: "contractUpload",
                  ...register("contractUpload"),
                  accept:
                    ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
              />
              {/* <FileField
            style={{
              gridColumn: "1/3",
            }}
            label="CV"
            buttonLabel={employee ? "Upload new cv" : "Upload cv"}
            document
            documentFile={cvFile}
            onClose={() => {
              setValue("cvUpload", "");
              setValue("cv", "");
              setCvFile(null);
            }}
            inputProps={{
              name: "cvUpload",
              ...register("cvUpload"),
              accept:
                ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }}
          /> */}
              {/* <TextField
            required
            label="Contract"
            name="contract"
            error={!!errors.contract}
            msg={errors.contract?.message}
            style={{ display: "none" }}
            inputProps={{
              value: selectedFileName,
              ...register("contract"),
            }}
          />
          <UploadFile
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setSelectedFileName={setSelectedFileName}
          /> */}{" "}
            </Grid>
          </div>
          <div>
            <FileField
              label="Employee Avatar"
              buttonLabel={employee ? "Edit Avatar" : "Upload Avatar"}
              preview={preview}
              edit={Boolean(employee)}
              inputProps={{
                name: "avatar",
                ...register("avatar"),
                accept: "image/jpng,image/png,image/jpg,image/jpeg",
              }}
            />
          </div>
        </Grid>
      </Card>
      <ButtonWrapper>
        {
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
          >
            Save
          </Button>
        }
        <Button
          type="button"
          className="outlined small"
          onClick={() => {
            navigate(-1);
            return () => clear({ type: "set_selected", payload: null });
          }}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(EmployeeForm);
