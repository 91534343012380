import { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button, Card, Icon, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useOrders } from "./provider";
import { useForm } from "react-hook-form";
import DocumentForm from "../offerdocuments/DocumentForm";
import { useUtils } from "../../services/utilsProvider";
import { useOfferDocument } from "../offerdocuments/provider";
import TextArea from "../../ui/TextArea";
const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid4 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ShiftsWrap = styled.div`
  > div {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 24px;
    gap: 20px;
    width: 1200px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    span.remove {
      margin-top: 30px;
      display: block;
      justify-self: center;
      cursor: pointer;

      svg {
        fill: ${(props) => props.theme.color.grayText};
      }
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
    }
    span.add {
      cursor: pointer;
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
    h4 {
      margin: 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.grayText};
    }
  }
  .text {
    font-size: 18px;
    font-weight: bold;
    color: #202020;
    line-height: 33px;
    letter-spacing: 0;
    text-align: right;
    margin-top: 13%;
  }
`;

const Grid3 = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 32px;
  row-gap: 0;
`;

const FileLabel = styled.label`
  ${(props) =>
    !props.document &&
    css`
      max-width: 50%;
    `}
  padding: 14px;
  border-radius: 15px;
  background-color: ${(props) =>
    props.theme.color[props.edit ? "primary" : "gray50"]};
  display: flex;
  align-items: center;
  line-height: 0;
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &:active {
    border-width: 2px;
    background-color: ${(props) => props.theme.color.gray100};
  }
  ${(props) =>
    props.edit
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          line-height: 0;
          p {
            margin-bottom: 0 !important;
          }
          &:hover,
          &:active {
            background-color: ${(props) => props.theme.color.primary};
          }
          svg {
            fill: white;
            width: 24px;
          }
        `
      : css`
          border: 1px dashed ${(props) => props.theme.color.gray};
        `}
  img {
    height: 54px;
  }
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  .info {
    margin-left: 20px;
    cursor: pointer;
    p {
      margin: 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 600;
      font-family: ${(props) => props.theme.fonts.openSans};
    }
    span {
      color: ${(props) => props.theme.color.grayLight};
      font-size: 14px;
    }
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 15px;
      svg {
        width: 18px;
      }
    }
  }
  ${(props) =>
    props.document &&
    css`
      .info {
        text-decoration: none;
        color: ${(props) => props.theme.color.dark};
        p {
          margin-bottom: 10px;
          line-height: 18px;
          inline-size: 230px;
          overflow-wrap: break-word;
        }
      }
    `}
`;

function ViewOrders({ editid }) {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const [selectedFile, setSelectedFile] = useState(null);

  const {
    state: { selected, pid },
    fetchOne,
    dispatch,
  } = useOrders();

  const { remove } = useOfferDocument();

  const [error, setError] = useState(null);

  const [items, setItems] = useState(
    Array(1)
      .fill(0)
      .map(() => ({ description: "", quantity: "" }))
  );
  // const {
  //   state: { selected: company },
  //   fetchOne: fetchCompany,
  // } = useCompanies();

  const handleDeleteFile = async (id) => {
    try {
      await remove(id);
      notify("An Offer has been Deleted successfull.");
      await fetchOne(params.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOne(params.id);
  }, [selectedFile, params.id, fetchOne]);
  useEffect(() => {
    dispatch({ type: "set_pid", payload: null });
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({});

  useEffect(() => {
    let id;
    if (selected?.items) {
      id = selected?.companyId;
      let sh = selected?.items.map((shifts, i) => {
        items.description = selected.description;
        items.quantity = selected.quantity;
        items.unitPrice = shifts.unitPrice;

        return shifts;
      });
      setItems(sh);
    } else {
      //id = companyId;
    }
  }, [selected]);

  // const additem = () => {
  //   setItems((shifts) => {
  //     shifts.push({ description: "", quantity: "" });
  //     return [...shifts];
  //   });
  // };

  // const removeitem = (i) => {
  //   let newi = items.filter((s, i2) => {
  //     console.log(s, i2 , i );
  //     return i !== i2
  //   })
  //   console.log(newi);
  //   setItems(newi)
  // };

  function displayShiftsFields() {
    return items.map((shift, i) => (
      <div key={i} data-count={i + 1}>
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.items?.[i]?.description}
          msg={errors?.items?.[i]?.description?.message}
          inputProps={{
            value: shift.description,
            onChange: (e) => {
              setItems((shifts) => {
                shifts[i].description = e.target.value;
                setValue("items", [...shifts]);
                return [...shifts];
              });
            },
            //required: true,
            type: "string",
            readOnly: true,
          }}
        />
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.items?.[i]?.quantity}
          msg={errors?.items?.[i]?.quantity?.message}
          inputProps={{
            value: shift.quantity,
            onChange: (e) => {
              setItems((shifts) => {
                shifts[i].quantity = e.target.value;
                setValue("items", [...shifts]);
                return [...shifts];
              });
            },
            //required: true,
            type: "string",
            readOnly: true,
          }}
        />
        <TextField
          style={{ width: "100%" }}
          label=""
          error={!!errors?.items?.[i]?.unitPrice}
          msg={errors?.items?.[i]?.unitPrice?.message}
          inputProps={{
            value: shift.unitPrice,
            onChange: (e) => {
              setItems((shifts) => {
                shifts[i].unitPrice = e.target.value;
                setValue("items", [...shifts]);
                return [...shifts];
              });
            },
            required: true,
            type: "string",
          }}
        />
      </div>
    ));
  }
console.log(selected , 'selected');
  // if (!company) return;
  if (!selected) return;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Order Information</h2>
        <div style={{ cursor: "pointer" }}>
          {/* <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => exporToExcle(selected?.items)}
          >
            Export Excel
          </Button> */}
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/orders/edit/${params.id}`)}
          >
            Edit
          </Button>
          {selected.envelopeInfo === null && (
            <>
              <div style={{ cursor: "pointer" }}>
                <Button
                  style={{
                    paddingRight: "40px",
                    paddingLeft: "40px",
                    marginLeft: "30px",
                  }}
                  iconName="pen"
                  className="small"
                  onClick={() => navigate("/orders/sendemail/" + params.id)}
                >
                  Send Email
                </Button>
              </div>
            </>
          )}
        </div>
      </Grid>
      <Card>
        <Grid4>
          <TextField
            label={"Supplier Name"}
            inputProps={{
              value: selected.supplierName,
              readOnly: true,
              disabled: true,
            }}
          />

          <div>
            <TextField
              required
              label="Tax Rate"
              name="taxRate"
              error={!!errors.taxRate}
              msg={errors.taxRate?.message}
              inputProps={{
                value: selected.taxRate,
                readOnly: true,
                disabled: true,
              }}
            />
          </div>

          <span />
        </Grid4>
        <TextArea
          style={{ maxWidth: "1200px" }}
          label={"Description"}
          value={selected?.description}
          rows="4"
          inputProps={{
            readOnly: true,
            disabled: true,
          }}
        ></TextArea>

        <Grid2>
          <div>
            <h3>Items</h3>
            <ShiftsWrap>
              <div>
                <h4>Description</h4>
                <h4>Quantity</h4>
                <h4>unitPrice</h4>
              </div>
              {displayShiftsFields()}
            </ShiftsWrap>
            <ShiftsWrap>
              <div>
                <span></span>
                <span className="text">SubToltal : </span>
                <div>
                  <TextField
                    required
                    label=""
                    name="subTotal"
                    error={!!errors.subTotal}
                    msg={errors.subTotal?.message}
                    inputProps={{
                      value: selected.subTotal,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                </div>
              </div>
            </ShiftsWrap>
            <ShiftsWrap>
              <div>
                <span></span>
                <span className="text">Tax : </span>
                <div>
                  <TextField
                    required
                    label=""
                    name="tax"
                    error={!!errors.tax}
                    msg={errors.tax?.message}
                    inputProps={{
                      value: selected.tax,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                </div>
              </div>
            </ShiftsWrap>
            <ShiftsWrap>
              <div>
                <span></span>
                <span className="text">Others : </span>
                <div>
                  <TextField
                    required
                    label=""
                    name="other"
                    error={!!errors.other}
                    msg={errors.other?.message}
                    inputProps={{
                      value: selected.other,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                </div>
              </div>
            </ShiftsWrap>

            <ShiftsWrap>
              <div>
                <span></span>
                <span className="text">Total : </span>
                <TextField
                  required
                  label=""
                  name="total"
                  error={!!errors.total}
                  msg={errors.other?.total}
                  inputProps={{
                    value: selected.total,
                    readOnly: true,
                    disabled: true,
                  }}
                />
              </div>
            </ShiftsWrap>
          </div>
        </Grid2>
        <Grid2>
          {selected?.envelopeInfo && (
            <>
              {/* <TextField
                    label="Name"
                    inputProps={{
                      value: selected?.envelopeInfo.name,
                      readOnly: true,
                      disabled: true,
                    }}
                  /> */}
              <TextField
                label="Email"
                inputProps={{
                  value: selected?.envelopeInfo.emailSubject,
                  readOnly: true,
                  disabled: true,
                }}
              />
              <TextField
                label="Status"
                inputProps={{
                  value: selected?.envelopeInfo.status,
                  readOnly: true,
                  disabled: true,
                }}
              />
              {/* <TextField
                    label="Routing Order"
                    inputProps={{
                      value: selected?.envelopeInfo.routingOrder,
                      readOnly: true,
                      disabled: true,
                    }}
                  />   */}
              <TextField
                label="Signed Time"
                inputProps={{
                  value: selected?.envelopeInfo.completedDateTime,
                  readOnly: true,
                  disabled: true,
                }}
              />
            </>
          )}
        </Grid2>
      </Card>

      <Grid3>
        <h2>Signees</h2>
      </Grid3>
      {
        selected?.docSigners?.length ?<>      <Grid>
        {selected&&selected?.docSigners.map((s) => (
          <p>
            {s.fullName } <span>{s.action === 1 ? 'Signed' : 'Declined ' }</span>
          </p>
        ))}
      </Grid>
</>:""
      }
      <Grid3>
        <h2>Offers</h2>
        <DocumentForm setSelectedFile={setSelectedFile} />
      </Grid3>
      {selected?.offersList?.length > 0 && (
        <Card style={{ display: "flex", flexWrap: "wrap" }}>
          {selected.offersList &&
            selected.offersList.map((d) => {
              return (
                <div style={{ marginTop: "10px" }} className="">
                  <FileLabel
                    document={d}
                    className="doc-preview"
                    style={{ margin: "0 20px" }}
                  >
                    {
                      <span
                        className="close"
                        onClick={() => handleDeleteFile(d.id)}
                      >
                        <Icon name="circleXMark" />
                      </span>
                    }
                    <a
                      className="icon"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon name="document" />
                    </a>
                    <a
                      className="info"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>{d.documentTitle}</p>
                      <span>Click to Download</span>
                    </a>
                  </FileLabel>
                </div>
              );
            })}
        </Card>
      )}
    </div>
  );
}

export default ViewOrders;
