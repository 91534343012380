import React, { useCallback, useRef, useState } from 'react'
import UploadExcel from './UploadExcel';
import UploadCSV from './UploadCSV';
import { Select } from '../../ui';
import styled from 'styled-components';



const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
function UploadAttendance() {
    const [reports, setReports] = useState([{ id: 1, ReportName: "upload Excel" },{ id: 2, ReportName: "Upload HP2000 Attendance" }]);
    const [selectedReport,setSelectedReport] = useState(1)
    const mapReportOptions = useRef({ value: "id", label: "ReportName" });
  
  
    const handleReportChange = useCallback(
      (item) => {
        setSelectedReport(item.value);      
      },
      []
    );
  return (
    <>
    <Grid>
      <h2>Upload Attendance File</h2>

      <Select
          data={reports}

        mapOptions={mapReportOptions.current}
        onChange={handleReportChange}
        defaultValue={1}
        inputStyle={{ height: "40px", maxWidth: "280px" }}
        inputProps={{
          value: selectedReport,
          readOnly: true,
        }}
      />
    </Grid>
    {selectedReport == 1 && <UploadExcel />}
    {selectedReport == 2 && <UploadCSV />}

  </>
  )
}

export default UploadAttendance