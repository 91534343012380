import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Pagination } from "../../ui";

import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";

import FilterForm from "./FilterForm";
import { usePurches } from "./provider";
import statusCell from "./statusCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function PurchesList() {
  //define stuff
  const navigate = useNavigate();
  const { notify ,alertUser} = useUtils();
  //fetching company
  const [companyId, setCompanyId] = useState(null);

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

 
  const handleCompanyChange = useCallback((id) => {
    setCompanyId(id);
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // handle add new leave button
  

  //fetch leaves
  const {
    state: { list, status, count, page, perPage, companyID , departmentID,employeeID ,Fdate },
    fetchList: fetchLeaves,
    setStatus,
    remove,
    dispatch,
  } = usePurches();

  //cols
  const cols = [
    { name: "id", label: "ID Number" },
    { name: "employeeName", label: "Employee Name" },
    { name: "status", label: "Status" ,     render: statusCell },
    { name: "ordersCount", label: "Orders Count"},

    { name: "description", label: "Description"},
  ];


  const handleEdit = (id , x,  purches) => {
    console.log(purches);
    navigate(`/purchase/edit/${id}`, 
    {
      state: {
        purches
      }
    }
    );
  };

  const handleApprove = (id) => {
    setStatus(id , 2);
  };
  const handleReject = (id) => {
    setStatus(id , 3);
  };

  const handleDelete = async (id) => {
    setStatus(id , 7);
  };

  const handleClick = async (id) => {
    navigate('/orders')
  };

  function handleOrdersClick  (id){
    let approved = false
    list.filter((item)=> item.id === id).map(i=> {
      if(i.status ===  2)
         navigate('/orders/new/' + id)
      else
        alertUser('need to be approved first')
    } )
    
  }
  useEffect(() => {
    fetchLeaves( companyID , departmentID,employeeID ,Fdate);
  }, [fetchLeaves,  companyID , departmentID,employeeID ,Fdate]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])
  
  console.log(list , 'list');
  return (
    <>
      <Grid>
        <h2>Purchase List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          Orders
        </Button>
      </Grid>

      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm fetchLeaves={fetchLeaves} page={page}></FilterForm>
      </Card>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={list}
        checkbox
        moreOptions={{
          editWithData: handleEdit,
          Approve: {
            label: "Approve",
            icon: "repeat",
            fn: handleApprove,
          },
          reject: {
            label: "reject",
            icon: "Trash",
            fn: handleReject,
          },
          orders:{
            label: "create order",
            icon: "invoice",
            fn: handleOrdersClick,
          },
          cancel: {
            label: "cancel",
            icon: "Trash",
            fn: handleDelete,
          },
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default PurchesList;
