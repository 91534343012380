import styled, { css } from "styled-components";

const SelectedBox = styled.div`
  position: relative;
  border: #c2c2c2 1px solid;
  border-radius: 15px;
  padding: 22px 0 22px 18px;
  font-weight: bold;
  font-size: 16px;
  line-height: 0;
  transition: all 0.15s linear;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: flex;
  align-items: center;
  .error & {
    border-color: ${(props) => props.theme.color.red};
  }
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 22px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    transition: all 0.1s linear;
    ${(props) =>
      props.open
        ? css`
            border-bottom-color: ${(props) => props.theme.color.primary};
            transform: translate(0, -50%);
          `
        : css`
            border-top-color: ${(props) => props.theme.color.primary};
          `}
  }
  input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.color.dark};
    margin-right: 30px;
    width: 100%;
  }
`;

function Selected({
  open,
  label,
  refEl,
  styles,
  selected,
  query,
  handleInput,
  defaultValue,
  disabled,
  setPlaceholderText,
  getTextValue = () => {},
  ...rest
}) {
  function getPlaceholder() {
    if (!(selected.length && selected[0].hasOwnProperty("data"))) return "";

    return selected.map((item) => item.name).join(", ");
  }

  return (
    <SelectedBox {...rest} open={open} className="selected">
      <input
        id={label.replace(/\s/g, "-").toLowerCase()}
        ref={refEl}
        {...styles}
        placeholder={
          getPlaceholder().length == 0 ? setPlaceholderText : getPlaceholder()
        }
        value={query}
        onInput={handleInput}
        required
        onChange={(e) => getTextValue(e.target.value)}
        autoComplete="off"
        disabled={disabled}
      />
    </SelectedBox>
  );
}

export default Selected;
