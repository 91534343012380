import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import {  useSigners, useSupliers } from "./provider";
import SuplierForm from "./SuplierForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditSuplier() {
  const {id} = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const {
    state: { selected: signer },
    fetchOne,
    dispatch,
  } = useSupliers();

  useEffect(() => {
    fetchOne(id);
  }, [id, fetchOne]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

  if(!signer || signer?.id !== Number(id) ) return

  return (
    <div id="edit-signer">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Signee</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <SuplierForm signer={signer} cid={id} handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(EditSuplier);
