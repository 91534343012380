import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Divider, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { usePayrolls } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import TableContainer from "../../ui/TableContainer";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import months from "../../data/months.json";
import { genYears } from "../../utilities/functions";
import Loading from "../../ui/Loading";

const schema = Yup.object().shape({
  month: Yup.number().transform(emptyStringToNull),
  year: Yup.number().transform(emptyStringToNull),
  companyId: Yup.number().transform(emptyStringToNull),
  employeePayrolls: Yup.array().of(
    Yup.object().shape({
      bonus: Yup.number().transform(emptyStringToNull),
      basicSalary: Yup.number().transform(emptyStringToNull),
      employeeId: Yup.number().transform(emptyStringToNull),
      id: Yup.number().transform(emptyStringToNull),
    })
  ),
});

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const EmployeeCell = ({ employeeId, employees }) => {
  const employee = employees?.find((e) => e.id === employeeId);
  return <div>{employee?.fullName}</div>;
};

const BasicSalaryCell = ({ basicSalary, index, onSalaryChange }) => {
  return (
    <TextField
      label=""
      inputProps={{
        value: basicSalary,
        onChange: (e) => onSalaryChange(e, index),
        required: true,
        type: "number",
      }}
    />
  );
};

const BonusCell = ({ bonus, index, onBonusChange }) => {
  return (
    <TextField
      label=""
      inputProps={{
        value: bonus,
        onChange: (e) => onBonusChange(e, index),
        required: true,
        type: "number",
      }}
    />
  );
};

function EditPayrollsForm({
  ifVar,
  employeeIdforif,
  payrolls,
  id,
  month,
  year,
  setRoute,
  handleSuccess,
}) {
  const [error, setError] = useState(null);
  const [employeePayrolls, setEmployeePayrolls] = useState([]);

  const navigate = useNavigate();
  // let params = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: payrolls,
  });

  setValue("employeePayrolls", employeePayrolls);

  const {
    state: { status ,selected : payroll },
    fetchOne,
    create,
  } = usePayrolls();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  // useEffect(() => {
  //   if(!payrolls) return
  //   setValue('companyId', payrolls.companyId);
  //   setValue('month', payrolls.month);
  //   setValue('year', payrolls.year);
  //   setValue('employeePayrolls', payrolls.employeePayroll);
  // }, [payrolls, setValue])

  useEffect(() => {
    if (payrolls) {
      setEmployeePayrolls([...payrolls.employeePayrolls]);
    } else {
      setEmployeePayrolls(
        employees.map((e) => ({
          employeeId: e.id,
          bonus: "",
          basicSalary: e.basicSalary,
        }))
      );
    }
  }, [employees, payrolls]);

  useEffect(() => {
    fetchCompanies();
    
  }, [fetchCompanies]);

  useEffect(() => {
    fetchEmployees(Number(id));
  fetchOne({companyId:Number(id) ,year : year , month : month})

  }, [fetchEmployees,fetchOne, id]);

  const onSubmit = async (values) => {
    try {
      delete values.companyName;
      values.employeePayrolls = values.employeePayrolls.map((e) => {
        e.id = 0;
        delete e.totalCompensations;
        delete e.totalDeductions;
        delete e.netSalary;
        delete e.employeeName;
        return e;
      });

      console.log(values);
      return;
      await create(values);
      reset();

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e.message);
    }
  };

  const onSalaryChange = useCallback(
    (e, index) => {
      setEmployeePayrolls((payrolls) => {
        payrolls[index].basicSalary = e.target.value;
        setValue("employeePayrolls", [...payrolls]);
        return [...payrolls];
      });
    },
    [setValue]
  );
  console.log(payroll.employeePayrolls ,'payroll');

  const onBonusChange = useCallback(
    (e, index) => {
      setEmployeePayrolls((payrolls) => {
        payrolls[index].bonus = e.target.value;
        setValue("employeePayrolls", [...payrolls]);
        return [...payrolls];
      });
    },
    [setValue]
  );

  let cols = [
    {
      name: "employeeName",
      label: "Name",
      //render: EmployeeCell,
      renderParams: { employees },
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      render: BasicSalaryCell,
      renderParams: { onSalaryChange },
    },
    {
      name: "bonus",
      label: "Bonus",
      render: BonusCell,
      renderParams: { onBonusChange },
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Flex>
          {
            <>
              <TextField
                label="Company Name"
                name="companyId"
                inputProps={{
                  value: Number(id),
                  ...register("companyId"),
                  autoFocus: true,
                  required: true,
                  disabled: true,
                  style: {
                    background: "#fff",
                    borderBottom: "2px solid #ddd",
                    outline: "none",
                  },
                }}
              />
            </>
          }
          <Select
            inputStyle={{ height: "48px", width: "170px" }}
            data={months.map((m) => ({ name: m.long, value: m.index }))}
            mapOptions={{ label: "name", value: "value" }}
            label="month"
            name="month"
            defaultValue={Number(month)}
            onChange={setRoute}
            disabled={employeeIdforif ? true : false}
            inputProps={{
              ...register("month"),
            }}
          />
          <Select
            label="year"
            name="year"
            inputStyle={{ height: "48px", width: "110px" }}
            data={genYears(2000)}
            defaultValue={year}
            onChange={setRoute}
            disabled={employeeIdforif ? true : false}
            inputProps={{
              ...register("year"),
            }}
          />
          {status === "fetching" && <Loading />}
        </Flex>
        <Divider h={30} />
        <TableContainer
          empty={!employeePayrolls.length}
          aria-label="Leaves table"
          cols={cols}
          data={employeeIdforif ? payroll.employeePayrolls :employeePayrolls}
        />
      </Card>

      <ButtonWrapper>
        <Button
          iconName="Disk"
          className="small"
          onClick={() => onSubmit()}
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button className="outlined small" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default EditPayrollsForm;
