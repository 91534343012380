import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useSigners } from "./provider";
import { Button, Card, Checkbox, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
// import UploadFile from "../../ui/UploadFile";
import FileField from "../../ui/FileField";
import { useCompanies } from "../companies/provider";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(originalValue) {
  originalValue = new Date(originalValue);
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return (
    originalValue.getFullYear() +
    "-" +
    (originalValue.getMonth() + 1).padLeft() +
    "-" +
    originalValue.getDate().padLeft()
  );
}

const schema = Yup.object().shape({
  companyId: Yup.string().required(),
  fullName: Yup.string().required(),
  email: Yup.string().email().required(),
  routingOrder: Yup.string().required(),
  // anchorString: Yup.string(),
});

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function AddSigners({ signer = null, cid, handleSuccess }) {
  let params = useParams();
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const navigate = useNavigate();
  const { create, edit } = useSigners();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: signer,
  });

  //fetch companies

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const handleCompanyChange = useCallback((id) => {
    setValue('companyId',id.value);
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);


  useEffect(()=>{
    if(signer) setCompanyId(signer?.companyId)
  },[signer])
  const onSubmit = async (values) => {
    try {
      if (signer) await edit(signer.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status == 409) setError({ title: "Already exists" });
      console.log("e", e, error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <div>
            <Select
              style={{
                height: "60px",
                width: "100%",
                marginBottom: "0px 75px",
              }}
              data={companies}
              error={!!errors.companyId}
              msg={errors.companyId?.fullName}
              mapOptions={mapCompanyOptions.current}
              onChange={handleCompanyChange}
              label={"companies"}
              defaultValue={signer?.companyInfo.id || Number(cid)  }
              inputProps={{
    
                ...register("companyId"),
                readOnly: true,
              }}
            />
          </div>

          <TextField
            required
            label="Full Name"
            name="fullName"
            error={!!errors.fullName}
            msg={errors.fullName?.message}
            inputProps={{
              ...register("fullName"),
              required: true,
            }}
          />
          <TextField
            required
            label={"Email"}
            name="email"
            error={!!errors.email}
            msg={errors.email?.message}
            inputProps={{
              ...register("email"),
              required: true,
            }}
          />
          <TextField
            required
            label={"routing Order"}
            name="routingOrder"
            error={!!errors.routingOrder}
            msg={errors.routingOrder?.message}
            inputProps={{
              ...register("routingOrder"),
              required: true,
            }}
          />
          {/* <TextField
            required
            label={"Anchor String"}
            name="anchorString"
            error={!!errors.anchorString}
            msg={errors.anchorString?.message}
            inputProps={{
              ...register("anchorString"),
              required: true,
            }}
          /> */}
          <Checkbox
            id="asCarbonCopy"
            name="asCarbonCopy"
            label="As Carbon Copy"
            style={{ paddingTop: "60px" }}
            inputProps={{
              ...register("asCarbonCopy"),
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(AddSigners);
