import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Company from "./Company";
import EditLeave from "./EditAttendance";

import Attendance from "./Attendance";
import AttendanceList from "./AttendanceList";
import ViewAttendance from "./ViewAttendance";
import EditAttendance from "./EditAttendance";
import AddAttendance from "./AddAttendance";
import MachineList from "./MachineList";
import UploadExcel from "./UploadExcel";
import AttendanceReportList from "./AttendanceReportList";
import UploadAttendance from "./UploadAttendance";
import EditBreak from "./Breaks/EditBreak";
import AddBreak from "./Breaks/AddBreak";
import EditMachien from "./EditMachien";
import EmployeeAttenddance from "./EmployeeAttenddance";


export default (
  <>
    <Route
      path="attendance"
      element={
        <RequireAuth>
          <Attendance />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <AttendanceList />
          </RequireAuth>
        }
      />
      <Route
        path="machine"
        element={
          <RequireAuth>
            <MachineList />
          </RequireAuth>
        }
      />
      <Route
        path="new"
        element={
          <RequireAuth>
            <AddAttendance />
          </RequireAuth>
        }
      />
      <Route
        path="view/:id"
        element={
          <RequireAuth>
            <ViewAttendance />
          </RequireAuth>
        }
      />
      <Route
        path="viewEmployee/:id"
        element={
          <RequireAuth>
            <EmployeeAttenddance />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:id"
        element={
          <RequireAuth>
            <EditAttendance />
          </RequireAuth>
        }
      />
      <Route
        path="uploadexcel"
        element={
          <RequireAuth>
            <UploadAttendance />
          </RequireAuth>
        }
      />
      <Route
        path="report"
        element={
          <RequireAuth>
            <AttendanceReportList />
          </RequireAuth>
        }
      />
      <Route
        path="AddBreak/:id"
        element={
          <RequireAuth>
            <AddBreak />
          </RequireAuth>
        }
      />
      <Route
        path="EditBreak/:id"
        element={
          <RequireAuth>
            <EditBreak />
          </RequireAuth>
        }
      />
           <Route
        path="editmachien/:id"
        element={
          <RequireAuth>
            <EditMachien />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
