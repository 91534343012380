import { memo, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { Button, Card, Divider, Icon, TextField } from "../../ui";
import { useCompanies } from "./provider";
import { useNavigate, useParams } from "react-router-dom";
import ListAttendancePatterns from "../attendancePatterns/ListAttendancePatterns";
import Label from "../../ui/InputLabel";

import countries from "../../data/countries.json";

import CompanyNameCell from "./listCompanies/CompanyNameCell";
import { useUtils } from "../../services/utilsProvider";
import DocumentForm from "./DocumentForm";
import { format } from "date-fns";
import InputDate from "../../ui/InputDate";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  /* max-width: 710px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Grid3 = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 32px;
  row-gap: 0;
`;
const Wrap = styled.div`
  margin-left: 20px;
  .preview {
    width: 255px;
    min-height: 180px;
    background-color: ${(props) => props.theme.color.gray200};
    background-size: cover;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100%;
      margin: 0;
      display: block;
      border: ${(props) => props.theme.color.grayBg} 1px solid;
    }
  }
`;
const FileLabel = styled.label`
  ${(props) =>
    !props.document &&
    css`
      max-width: 50%;
    `}
  padding: 14px;
  border-radius: 15px;
  background-color: ${(props) =>
    props.theme.color[props.edit ? "primary" : "gray50"]};
  display: flex;
  align-items: center;
  line-height: 0;
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &:active {
    border-width: 2px;
    background-color: ${(props) => props.theme.color.gray100};
  }
  ${(props) =>
    props.edit
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          line-height: 0;
          p {
            margin-bottom: 0 !important;
          }
          &:hover,
          &:active {
            background-color: ${(props) => props.theme.color.primary};
          }
          svg {
            fill: white;
            width: 24px;
          }
        `
      : css`
          border: 1px dashed ${(props) => props.theme.color.gray};
        `}
  img {
    height: 54px;
  }
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  .info {
    margin-left: 20px;
    cursor: pointer;
    p {
      margin: 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 600;
      font-family: ${(props) => props.theme.fonts.openSans};
    }
    span {
      color: ${(props) => props.theme.color.grayLight};
      font-size: 14px;
    }
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 15px;
      svg {
        width: 18px;
      }
    }
  }
  ${(props) =>
    props.document &&
    css`
      .info {
        text-decoration: none;
        color: ${(props) => props.theme.color.dark};
        p {
          margin-bottom: 10px;
          line-height: 18px;
          inline-size: 230px;
          overflow-wrap: break-word;
        }
      }
    `}
`;

// const ButtonWrapper = styled.div`
//   margin-top: 40px;
//   button {
//     float: right;
//     margin-left: 20px;
//     min-width: 180px;
//   }
// `;

function ViewCompany() {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  // const { notify } = useUtils();
  const [selectedFile, setSelectedFile] = useState();

  const {
    state: { selected, fileName },
    fetchOne,
  } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne, fileName]);
  if (!selected || selected == {}) return;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Company Information</h2>
        <div style={{ cursor: "pointer" }}>
          <Button
            onClick={() => navigate(`/departments/company/${params.id}`)}
            className="small"
            iconName="building"
            bg="rose"
          >
            Departments
          </Button>
          <Button
            style={{
              marginLeft: "30px",
            }}
            onClick={() => navigate("/employees/new/")}
            iconName="userAdd"
            className="small"
            bg="rose"
          >
            Employee
          </Button>

          <Button
            className="small"
            bg="rose"
            onClick={() => {
              navigate(`/projects/company/${params.id}`);
            }}
            style={{
              marginLeft: "30px",
            }}
          >
            Projects
          </Button>
          <Button
            className="small"
            bg="rose"
            style={{ marginLeft: "30px" }}
            onClick={() => navigate(`/news/company/${params.id}`)}
          >
            News
          </Button>

          <Button
            style={{
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/companies/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <Grid2>
            <TextField
              label="Business Name"
              value={selected.businessName}
              inputProps={{
                value: selected.businessName,
                readOnly: true,
                disabled: true,
              }}
            />
            {/* <TextField
              label="Email Address"
              inputProps={{
                value: selected.email,
                readOnly: true,
                disabled: true,
              }}
            /> */}
            <TextField
              label="Tax Number"
              inputProps={{
                value: selected.taxNumber,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Website"
              inputProps={{
                value: selected.webSite,
                readOnly: true,
                disabled: true,
              }}
            />

            <InputDate
              label="Registration Date"
              value={new Date(selected.registrationDate)}
              pickerProps={{
                readOnly: true,
                disabled: true,
              }}
            />

            <TextField
              label="Location"
              inputProps={{
                value: countries
                  .filter((c) => c.code == selected?.countryCode)
                  .map((c) => c.name),
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Industry"
              inputProps={{
                value: selected.businessType,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Wage Tax"
              inputProps={{
                value: selected.wageTax,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Phone Number"
              inputProps={{
                value: selected.phone,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Number of Annual Leaves"
              inputProps={{
                value: selected.totalPaidAnnual,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Number of Sick Leaves"
              inputProps={{
                value: selected.totalPaidSick,
                readOnly: true,
                disabled: true,
              }}
            />

            {/* <DownLoadFile selectedFile={selectedFile}></DownLoadFile> */}
          </Grid2>

          <Wrap>
            <Label>Company Logo</Label>
            <div className="preview">
              {Boolean(selected.logoUrl) && (
                <img src={selected.logoUrl} alt="preview uploaded file" />
              )}
            </div>
          </Wrap>
        </Grid2>
      </Card>

      <Divider h={20} />

      <Grid3>
        <h2>Policy</h2>

        <DocumentForm compId={params.id} setSelectedFile={setSelectedFile} />
      </Grid3>
      {selected?.contractsList && selected?.contractsList?.length > 0 && (
        <Card style={{ display: "flex", flexWrap: "wrap" }}>
          {selected.contractsList &&
            selected.contractsList.map((d, i) => {
              return (
                <div key={i} style={{ marginTop: "10px" }}>
                  <FileLabel
                    document={d.documentTitle}
                    className="doc-preview"
                    style={{ margin: "0 20px" }}
                  >
                    {/* {
                <span
                  className="close"
                  // onClick={() => handleDeleteFile(d.id)}
                >
                  <Icon name="circleXMark" />
                </span>
              } */}
                    <a
                      className="icon"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon name="document" />
                    </a>
                    <a
                      className="info"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>{d.documentTitle}</p>
                      <span>Click to Download</span>
                    </a>
                  </FileLabel>
                </div>
              );
            })}
        </Card>
      )}

      <Divider h={20} />

      {selected?.parentCompanyInfo && (
        <>
          <h3>Parent Company </h3>

          <Grid>
            <TextField
              label=""
              inputProps={{
                value: selected.parentCompanyInfo?.companyName,
                readOnly: true,
                disabled: true,
              }}
            />
          </Grid>
        </>
      )}
      {selected?.subCompaniesInfo && (
        <>
          <h3>Sub Companies </h3>

          <Grid>
            {selected.subCompaniesInfo.map((c, i) => {
              return (
                <TextField
                  key={i}
                  label=""
                  inputProps={{
                    value: c.companyName,
                    readOnly: true,
                    disabled: true,
                  }}
                />
              );
            })}
          </Grid>
        </>
      )}
      <Divider h={50} />

      <ListAttendancePatterns />
      <Divider h={50} />
      <Grid>
        <div></div>
      </Grid>
    </div>
  );
}

export default memo(ViewCompany);
