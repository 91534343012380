import styled from "styled-components";

const Input = styled.input`
    outline: #c2c2c2 1px solid;
    background-color: white;
    border-radius: 12px;
    height: ${props => props.small ? '48px' : '60px'};
    width: 100%;
    border: none;
    padding: 0 5px 0 26px;
    font-family: 'Cairo', sans-serif;
    font-size: 20px;
    font-weight: 600;
    &:focus, &:active {
        outline: #7F32DE 2px solid;
        background-color: white;
    }
    &:read-only, &:disabled {
        outline: ${props => props.theme.color.grayBg} 1px solid;
    }
`;

export default Input;