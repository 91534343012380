import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Checkbox } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { useCompanies } from "../companies/provider";
import { useDepartments } from "../departments/provider";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useHolidays } from "./provider";
import Label from "../../ui/InputLabel";
import { transformDate } from "../../utilities/functions";
import InputDate from "../../ui/InputDate";
import { subYears } from "date-fns";

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function HolidayForm({ holiday = null, handleSuccess }) {
  const [error, setError] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  // const [reload , setReload] = useState(false)

  const navigate = useNavigate();
  // var depsIds =[]
  const { create, edit } = useHolidays();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { list: departments },
    fetchList: fetchDepartments,
  } = useDepartments();

  const yupSchema = fields.reduce(createYupSchema, {});
  if (holiday) delete yupSchema.companyIds;
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: holiday,
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchDepartments(holiday?.companyId || "");
  }, [fetchDepartments, holiday]);

  useEffect(() => {
    if (holiday && companies && departments) {
      fields.forEach((f) => {
        setValue(f.name, holiday[f.name] || "");
      });
    }
  }, [holiday, companies, departments, setValue]);

  const getResouce = (name) => {
    return name === "departments" ? departments : companies;
  };

  const handleChange = useCallback(
    (v, name) => {
      setValue(name, Array.isArray(v) ? v.map((i) => i.value) : v);
      if (name === "companyIds")
        setSelectedCompanies(Array.isArray(v) ? v.map((i) => i.data) : v);
    },
    [setValue]
  );
  // const handleDepsChange = useCallback(
  //   (values, name) => {
  //     if(values[0]?.value == 0 ){
  //       console.log(departments?.length > 0 , 'departments');
  //       departments?.length > 0 && departments.filter((d) => d.companyId == companyId).map((e)=>depsIds.push(e.id))
  //       setValue("companyDepartmentIds", depsIds);
  //       console.log(depsIds , 'depsIds');
  //     }
  //     else{
  //     const companyDepartmentIds = getValues("companyDepartmentIds") || {};
  //     companyDepartmentIds[name] = values?.map((item) => item.value);
  //     setValue("companyDepartmentIds", companyDepartmentIds);
  //   }
  //   },
  //   [getValues, setValue]
  // );

  //
  let params = useParams();
  const handleDepsChange = useCallback(
    (values, name) => {
      let companyDepartmentIds = getValues("companyDepartmentIds") || {};

      if (values[0]?.name === "All")
        companyDepartmentIds[name] = departments
          .filter((d) => d.companyId === companyId)
          .map((d) => d.id);
      else companyDepartmentIds[name] = values?.map((item) => item.value);
      setValue("companyDepartmentIds", companyDepartmentIds);
    },
    [getValues, setValue, companyId, departments]
  );
  function emptyDate(originalValue) {
    originalValue = new Date(originalValue);
    if (
      typeof originalValue === "string" &&
      (originalValue === "" || originalValue === "Invalid Date")
    ) {
      return "";
    }
    return (
      originalValue.getFullYear() +
      "-" +
      (originalValue.getMonth() + 1).padLeft() +
      "-" +
      originalValue.getDate().padLeft()
    );
  }
  
  const onSubmit = async (values) => {
    if (!holiday) {
      values.departmentIds = [];
      Object.values(values.companyIds).forEach((c) => {
        values.departmentIds?.push(
          ...values.companyDepartmentIds.departmentIds
        );
      });
      delete values.companyDepartmentIds;
    }

    values.startDate = emptyDate(values.startDate);
    values.endDate = emptyDate(values.endDate);

    try {
      if (holiday) await edit(params.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `The Name "${values.fullName}" is already in use for this company.`,
        });
      console.log("e", e);
    }
  };

  if (!companies.length) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card style={{ paddingBottom: 40 }}>
        <Grid>
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            // if (holiday && f.name === "companyIds") return null;
            if ((f.editOnly && !holiday) || (f.newOnly && holiday)) return null;

            if (f.data) {
              let selected;
              if (f.editOnly) {
                const singleResourceName = f.resourceName.substring(
                  0,
                  f.resourceName.length - 1
                );

                selected = getValues(f.resourceName).map(
                  (r) => r.id || r[`${singleResourceName}Id`] || r
                );
              }

              return (
                <div key={i}>
                  <Select
                    style={{ width: "100%" }}
                    inputStyle={{ height: "60px" }}
                    label={f.label}
                    data={f.resourceName ? getResouce(f.resourceName) : f.data}
                    mapOptions={f.mapData}
                    multi={f.multi || false}
                    required={f.required}
                    onChange={handleChange}
                    defaultValue={selected}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message || f.msg}
                    inputProps={{
                      ...register(f.name),
                    }}
                  />
                </div>
              );
            } else if (f.type === "date")
              return (
                <InputDate
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  type={f.type}
                  size="medium"
                  value={new Date(getValues(f.name))}
                  onDateChange={(v) => setValue(f.name, v)}
                  pickerProps={f.pickerProps}
                />
              );
            else
              return (
                <TextField
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  inputProps={{
                    ...register(f.name),
                    ...inputProps,
                  }}
                />
              );
          })}
          <Checkbox
            id="notify"
            name="notify"
            label="Notify"
            inputProps={{
              ...register("notify"),
            }}
          />
          {Boolean(selectedCompanies?.length) && (
            <div style={{ marginTop: "20px" }}>
              <Label>Company Departments</Label>
              <ButtonWrapper style={{ marginTop: "0px" }}>
                {departments.length > 0 &&
                  selectedCompanies?.map((c) => (
                    <div key={c.id}>
                      <strong
                        style={{ width: "200px", display: "inline-block" }}
                      >
                        {c.businessName}:{" "}
                      </strong>
                      <Select
                        inputStyle={{ height: "50px" }}
                        label=""
                        data={[
                          { id: 0, departmentName: "All" },
                          ...departments.filter((d) => d.companyId === c.id),
                        ]}
                        mapOptions={{ label: "departmentName", value: "id" }}
                        multi
                        onChange={handleDepsChange}
                        onClick={() => {
                          setCompanyId(c.id);
                        }}
                        inputProps={{
                          name: "departmentIds",
                        }}
                      />
                      <br />
                      <br />
                    </div>
                  ))}
              </ButtonWrapper>
            </div>
          )}
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(HolidayForm);
