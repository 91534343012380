import * as yup from "yup";
import { parseDate } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "startDate",
    type: "date",
    required: "required",
    label: "Start Date",
    validationType: "date",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [parseDate],
      },
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
 
 
];

export default fields;
