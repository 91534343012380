import React from "react";

function SalaryCell(row) {
  if (!row) return;
  console.log(row  ,'ddddddddd');
  return (
    <div>
      {row?.totalNetSalary.map((r) => {
        return (
          <p>
            {r?.totalNetSalaries +
          " " +
          r?.currencyInfo?.symbol}
          </p>
        );
      })}
    </div>
  );
}

export default SalaryCell;
