import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Divider } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useCompensations, useEmployeeDeduction } from "./provider";

const Grid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  row-gap: 0;
  margin-buttom:20px;
`;

// const ButtonWrapper = styled.div`
//   margin-top: 40px;
//   button {
//     float: right;
//     margin-left: 20px;
//     min-width: 180px;
//   }
// `;

function DeductionsForm({
  compensation = null,
  handleSuccess = () => {},
  empId,
  setCompensation ,
  setUpdated,
  updated
}) {
  const [error, setError] = useState(null);
  const [comp, setComp] = useState(null);

  // const navigate = useNavigate();

useEffect(()=>{
  if(compensation[0])setComp(compensation[0])
},[compensation])
useEffect(()=>{
  setComp(null)
},[updated])

  const {
    state: { list: compensations , namesList:deductionsNameList},
    remove: removeCompensation,
    fetchNameList,
    dispatch,

    create, edit
  } = useEmployeeDeduction();

  const yupSchema = fields.reduce(createYupSchema, {});
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: compensation,
  });


  useEffect(()=>{
    fetchNameList()
  },[])

  useEffect(() => {
    if (comp) {
      fields.forEach((f) => {
        setValue(f.name, comp[f?.name] );
      });
    }
    else{
      fields.forEach((f) => {
        setValue(f.name, "" );
      });
    }
  }, [comp]);

  const onSubmit = async (values) => {
    try {
        if (compensation?.length !=0) {
        await edit(compensation[0].id, {employeeId: Number(empId) , compensationId : compensation[0].id ,...values});
        setComp(null)
        setCompensation(null)
        setUpdated(!updated)
      }
      else {
        await create({ ...values, employeeId: Number(empId) });
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `deduction is already in use for this employee.`,
        });
      console.log("e", e);
    }
  };
  const getResouce = (name) => {
    if (deductionsNameList && name === "deductionsNameList") return deductionsNameList;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      
        <Grid>
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            const resource = f.resourceName
            ? getResouce(f.resourceName)
            : f.data;
            if (f.data) {
              if (!resource?.length) return null;
              
              return (
                <div key={i}>
                  <Select
                    inputStyle={{ height: "60px" }}
                    style={{ width: "100%" }}
                    label={f.label}
                    data={f.resourceName ? getResouce(f.resourceName) : f.data}
                    mapOptions={f.mapData}
                    required={f.required}
                    onChange={(e) => setValue(f.name,e.value)}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message || f.msg}
                    defaultValue={comp?.compensationId ? comp?.compensationId : 0}
                    inputProps={{
                      ...register(f.name),
                    }}
                  />
                </div>
              );
            } else
              return (
                <TextField
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  onChange={(e)=> setValue(f.name,e.target.value)}
                  defaultValue={comp&&comp[f.name]}
                  inputProps={{
                    ...register(f.name),
                  }}
                />
              );
          })}
        {
          compensation?.length !=0 ? <>
          <Button
        style={{
          width:'150px',
          margin:'50px'
        }}
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
          onClick={()=> setUpdated(!updated)}
        >
         
          update
        </Button>
          </>
          :<>
          <Button
        style={{
          width:'150px',
          margin:'50px'
        }}
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
         
          save
        </Button>
          </>
        }
        </Grid>
        <Divider h={10} />
        {/* <ButtonWrapper> */}
        
        {/* <Button className="outlined small" onClick={() => navigate(-1)}>
            Cancel
          </Button> */}
        {/* </ButtonWrapper> */}
    </form>
  );
}

export default memo(DeductionsForm);
