import { useContext, useReducer, createContext, useCallback, useState } from "react";

const Context = createContext();

export function useNotify() {
  return useContext(Context);
}
const initState = {
  notify: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "notify":
      return { ...state, notify: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};
export default function NotifyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <Context.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </Context.Provider>
  );
}
