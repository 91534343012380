import { memo, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Icon, TextField } from "../../ui";
import { useAttendancePatterns } from "./provider";
import { useCompanies } from "../companies/provider";
import ErrorsList from "../../ui/ErrorsList";
import Divider from "../../ui/Divider";
import { toUTC } from "../../utilities/yupSchemaCreator";
import {
  dateToLocalConverter,
  transformDate2,
} from "../../utilities/functions";
import InputDate from "../../ui/InputDate";

const schema = yup.object().shape({
  attendancePatternName: yup.string().required("Required"),
  nextStartDate: yup
    .date()
    .required("Required")
    .nullable()
    .transform(toUTC)
    .typeError("Required"),
  attendanceShifts: yup.array().of(
    yup.object().shape({
      startDate: yup
        .date()
        .required("Required")
        .nullable()
        .transform(toUTC)
        .typeError("Required"),
      endDate: yup
        .date()
        .min(yup.ref("startDate"), "End date has to be more than start date")
        .required("Required")
        .nullable()
        .transform(toUTC)
        .typeError("Required"),
        tolerance: yup.number().nullable().typeError("must be a number").min(0,'tolerance should be greater than zero').max(240 , 'tolerance should be smaller than 240'),
      breakDuration: yup.number().nullable().typeError("must be a number").min(0,'tolerance should be greater than zero').max(240 , 'tolerance should be smaller than 240'),
    })
  ),
});

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ShiftsWrap = styled.div`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 24px;
    gap: 20px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    span.remove {
      margin-top: 30px;
      display: block;
      justify-self: center;
      cursor: pointer;
      svg {
        fill: ${(props) => props.theme.color.grayText};
      }
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
    }
    span.add {
      cursor: pointer;
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
    h4 {
      margin: 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.grayText};
    }
  }
`;

function AttendancePatternForm({
  attendancePattern = null,
  companyId,
  handleSuccess,
}) {
  const [error, setError] = useState(null);
  const [shifts, setShifts] = useState(
    Array(1)
      .fill(0)
      .map(() => ({ startDate: "", endDate: "",tolerance:"" , breakDuration: "" }))
  );

  const navigate = useNavigate();


      //get utc
      const getUtc = (x) => {
        let d = new Date(x);
        console.log(x , 'utc');
        return (
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1).padLeft() +
          "-" +
          d.getDate().padLeft() +
          " " +
          d.getHours().padLeft() +
          ":" +
          d.getMinutes().padLeft()
        );
      };
    
  
  const { create, edit } = useAttendancePatterns();

  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValue: attendancePattern,
  });

  const { fetchList: fetchCompanies } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    let id;
    if (attendancePattern?.attendanceShifts) {
      id = attendancePattern.companyId;
      let sh = attendancePattern.attendanceShifts.map((shifts, i) => {
        
        shifts.startDate = dateToLocalConverter(shifts.startDate);
        shifts.endDate = dateToLocalConverter(shifts.endDate);
        return shifts;
      });
      setShifts(sh);
      console.log('called ' , sh);

      setValue("attendanceShifts", [...sh]);
    } else {
      id = companyId;
    }
    fetchCompany(id);

  }, [attendancePattern, companyId, fetchCompany ]);

  useEffect(()=>{
    setValue("attendanceShifts", shifts);

    if (attendancePattern) {
      setValue(
        "attendancePatternName",
        attendancePattern.attendancePatternName || ""
      );
      setValue("companyId", attendancePattern.companyId || "");
      setValue(
        "nextStartDate",
        dateToLocalConverter(attendancePattern.nextStartDate) || ""
      );
    }
  },[attendancePattern])

  const addShiftDay = () => {
    setShifts((shifts) => {
      shifts.push({ startDate: "", endDate: "", tolerance:"",breakDuration: "" });
      return [...shifts];
    });
  };

  const removeShift = (i) => {
    setShifts((shifts) => shifts.filter((s, i2) => i !== i2));
  };


  useEffect(()=>{
    setValue("attendanceShifts", [...shifts]);
  },[shifts])
  
  const onSubmit = async (values) => {
    values.nextStartDate =getUtc( values.nextStartDate);
    values.attendanceShifts = values.attendanceShifts.map((shift) => ({
      startDate: getUtc(shift.startDate),
      endDate: getUtc(shift.endDate),
      breakDuration: shift.breakDuration,
      tolerance: shift.tolerance,
    }));

    try {
      let res;
      if (attendancePattern)
        res = await edit(attendancePattern.id, { companyId, ...values });
      else {
        res = await create({ companyId, ...values });
        reset();
      }
      handleSuccess(res.id);
      navigate(-1);
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };

  function displayShiftsFields() {
    return shifts.map((shift, i) => (
      <div key={i} data-count={i + 1}>
        <InputDate
          style={{ width: "300px" }}
          type="datetime"
          label=""
          error={!!errors?.attendanceShifts?.[i]?.startDate}
          msg={errors?.attendanceShifts?.[i]?.startDate?.message}
          value={new Date(shift.startDate)}
          onDateChange={(date) => {
            setShifts((shifts) => {
              shifts[i].startDate = date.toString();
              setValue("attendanceShifts", [...shifts]);
              return [...shifts];
            });
          }}
        />
        {/* <TextField
          label=""
          error={!!errors?.attendanceShifts?.[i]?.startDate}
          msg={errors?.attendanceShifts?.[i]?.startDate?.message}
          inputProps={{
            value: shift.startDate.replace(" ", "T"),
            onChange: (e) => {
              setShifts((shifts) => {
                shifts[i].startDate = e.target.value;
                setValue("attendanceShifts", [...shifts]);
                return [...shifts];
              });
            },
            required: true,
            type: "datetime-local",
          }}
        /> */}
        <InputDate
          label=""
          style={{ width: "300px" }}
          type="datetime"
          error={!!errors?.attendanceShifts?.[i]?.endDate}
          msg={errors?.attendanceShifts?.[i]?.endDate?.message}
          value={new Date(shift.endDate)}
          onDateChange={(date) => {
            setShifts((shifts) => {
              shifts[i].endDate = date.toString();
              setValue("attendanceShifts", [...shifts]);
              return [...shifts];
            });
          }}
        />
        {/* <TextField
          label=""
          error={!!errors?.attendanceShifts?.[i]?.endDate}
          msg={errors?.attendanceShifts?.[i]?.endDate?.message}
          inputProps={{
            value: shift.endDate.replace(" ", "T"),
            onChange: (e) => {
              setShifts((shifts) => {
                shifts[i].endDate = e.target.value;
                setValue("attendanceShifts", [...shifts]);
                return [...shifts];
              });
            },
            required: true,
            type: "datetime-local",
          }}
        /> */}
<TextField
          label=""
          error={!!errors?.attendanceShifts?.[i]?.tolerance}
          msg={errors?.attendanceShifts?.[i]?.tolerance?.message}
          style={{ width: "300px" }}
          inputProps={{
            value: shift?.tolerance,
            onChange: (e) => {
              setShifts((shifts) => {
                shifts[i].tolerance = e.target.value;
                setValue("attendanceShifts", [...shifts]);
                return [...shifts];
              });
            },
            required: true,
          }}
        />

        <TextField
          label=""
          error={!!errors?.attendanceShifts?.[i]?.breakDuration}
          msg={errors?.attendanceShifts?.[i]?.breakDuration?.message}
          style={{ width: "300px" }}
          inputProps={{
            value: shift?.breakDuration,
            onChange: (e) => {
              setShifts((shifts) => {
                shifts[i].breakDuration = e.target.value;
                setValue("attendanceShifts", [...shifts]);
                return [...shifts];
              });
            },
            required: true,
          }}
        />

        <Icon
          className="remove"
          onClick={() => removeShift(i)}
          name="trash"
          w={16}
        />
      </div>
    ));
  }

  if (!company) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          {attendancePattern && (
            <TextField
              label="Company"
              inputProps={{
                value: attendancePattern?.companyName,
                readOnly: true,
                disabled: true,
              }}
            />
          )}
          {/* {!attendancePattern && (
            <>
              <Select
                label="Companies"
                data={companies}
                mapOptions={{ value: "id", label: "businessName" }}
                onChange={handleChange}
                multi
                inputProps={{
                  name: "companyId",
                  ...register("companyIds"),
                }}
              />
            </>
          )} */}

          <TextField
            label="Pattern Name"
            name="attendancePatternName"
            error={!!errors.attendancePatternName}
            msg={errors.attendancePatternName?.message}
            inputProps={{
              ...register("attendancePatternName"),
              autoFocus: true,
              required: true,
            }}
          />
          {/* <span /> */}
        </Grid>
        <Grid>
          <div>
            <h3>Shifts</h3>
            <ShiftsWrap>
              <div>
                <h4>Start Date</h4>
                <h4>End Date</h4>
                <h4>tolerance (Minutes)</h4>
                <h4>Break Duration (Minutes)</h4>
              </div>
              {displayShiftsFields()}

              <div style={{ textAlign: "right", border: "none" }}>
                <span />
                <span />
                <span />
                <span />


                <Icon
                  onClick={addShiftDay}
                  name="addSquare"
                  w={24}
                  className="add"
                />
              </div>
            </ShiftsWrap>
          </div>
          <span />
          <div>
            <Divider var="spacer" h={24} />
            <InputDate
              style={{width: 300}}
              label="Next Start Date"
              type="datetime"
              error={!!errors.nextStartDate}
              msg={errors.nextStartDate?.message}
              value={new Date(getValues("nextStartDate"))}
              onDateChange={(v) => setValue("nextStartDate", v)}
            />
            {/* <TextField
              label="Next Start Date"
              name="nextStartDate"
              error={!!errors.nextStartDate}
              msg={errors.nextStartDate?.message}
              inputProps={{
                ...register("nextStartDate"),
                type: "datetime-local",
              }}
            /> */}
          </div>
        </Grid>
      </Card>

      <ButtonWrapper>
        {
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
            loading={isSubmitting}
          >
            Save
          </Button>
        }
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(AttendancePatternForm);
