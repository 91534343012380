import styled from "styled-components";
import useGenPagerList from "./useGenPagerList";

const Pager = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  section {
    display: flex;
    strong {
      padding: 0 5px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      border: #ccc 1px solid;
      border-radius: 15px;
      margin: 0 10px;
      &.pages {
        margin: 0;
      }
      li {
        display: inline;
        color: #666;
        height: 51px;
        width: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 15px;
        transition: all .1s ease-in;
        &:hover:not(.ellipse):not(.active) {
          background-color: #e5e5e5;
        }
        &.active {
          background-color: ${(props) => props.theme.color.primary};
          color: white;
        }
        .angle-left {
          border-top: #666 2px solid;
          border-left: #666 2px solid;
          width: 10px;
          height: 10px;
          transform: rotate(-45deg);
        }
        .angle-right {
          border-top: #666 2px solid;
          border-right: #666 2px solid;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
        }
      }
    }
  }
`;

function Pagination({ count = 0, page = 0, rowsPerPage = 10, onPageChange }) {


  const totalPages = Math.ceil(count / rowsPerPage);
  const list = useGenPagerList(totalPages, page);

  const availableRows = count - page * rowsPerPage;

  const handlePageClick = (p) => {
    const totalPages = Math.ceil(count / rowsPerPage);
    if (p < 0) onPageChange(0);
    else if (p > totalPages) onPageChange(totalPages - 1);
    else onPageChange(p - 1)
  };

  if (count === 0 || totalPages < 2) return;
  return (
    <Pager>
      <section className="left">
        Showing{" "}
        <strong>
          {page * rowsPerPage + 1}-
          {page * rowsPerPage +
            (availableRows < rowsPerPage ? availableRows : rowsPerPage)}
        </strong>{" "}
        from <strong>{count}</strong> total row{count > 1 && "s"}
      </section>
      <section className="right">
        <ul>
          <li onClick={() => handlePageClick(page)}>
            <span className="angle-left" />
          </li>
        </ul>
        <ul className="pages">
          {list.map((n, i) => n !== '...' ? (
            <li
              key={i}
              className={n === page + 1 ? "active" : ""}
              onClick={() => handlePageClick(n)}
            >
              {n}
            </li>
          ) : (
            <li className="ellipse">&hellip;</li>
          ))}
        </ul>
        <ul>
          <li onClick={() => handlePageClick(page + 2)}>
            <span className="angle-right" />
          </li>
        </ul>
      </section>
    </Pager>
  );
}

export default Pagination;
