import React from "react";
import ProjectForm from "./ProjectForm";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import styled from "styled-components";
import { Icon } from "../../ui";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const AddProject = () => {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("a new project has been created successfully.");
  };

  return (
    <div id="add-project">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Project</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <ProjectForm handleSuccess={handleSuccess} />
    </div>
  );
};

export default AddProject;
