import { memo } from "react";
import styled, { css } from "styled-components";
import upload from "../assets/upload.svg";
import { downloadFile } from "../utilities/functions";
import Icon from "./Icon";
import Label from "./InputLabel";

const Wrap = styled.div`
  ${(props) =>
    !props.document &&
    css`
      margin-left: 20px;
    `}
  .preview {
    width: 255px;
    min-height: 180px;
    background-color: ${(props) => props.theme.color.gray200};
    background-size: cover;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100%;
      margin: 0;
      display: block;
    }
  }
  ${(props) =>
    props.document &&
    css`
      .wrap2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    `}
`;

const FileLabel = styled.label`
  ${(props) =>
    !props.document &&
    css`
      max-width: 255px;
    `}
  padding: 14px;
  border-radius: 15px;
  background-color: ${(props) =>
    props.theme.color[props.edit ? "primary" : "gray50"]};
  display: flex;
  align-items: center;
  line-height: 0;
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &:active {
    border-width: 2px;
    background-color: ${(props) => props.theme.color.gray100};
  }
  ${(props) =>
    props.edit
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          line-height: 0;
          p {
            margin-bottom: 0 !important;
          }
          &:hover,
          &:active {
            background-color: ${(props) => props.theme.color.primary};
          }
          svg {
            fill: white;
            width: 24px;
          }
        `
      : css`
          border: 1px dashed ${(props) => props.theme.color.gray};
        `}
  .preview {
    width: 225px;
    height: 307px;
    background-color: ${(props) => props.theme.color.gray200};
  }
  img {
    height: 54px;
  }
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  .info {
    margin-left: 20px;
    cursor: pointer;
    p {
      margin: 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 600;
      font-family: ${(props) => props.theme.fonts.openSans};
    }
    span {
      color: ${(props) => props.theme.color.grayLight};
      font-size: 14px;
    }
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 15px;
      svg {
        width: 18px;
      }
    }
  }
  ${(props) =>
    props.document &&
    css`
      .info {
        text-decoration: none;
        color: ${props => props.theme.color.dark};
        p {
          margin-bottom: 10px;
          line-height: 18px;
          inline-size: 230px;
          overflow-wrap: break-word;
        }
      }
    `}
`;

function FileField({
  label,
  preview,
  inputProps,
  msg = null,
  error = false,
  buttonLabel,
  document,
  documentFile,
  disabled = false,
  readOnly,
  edit,
  onClose,
  ...props
}) {
  // const handleDownload = () => {
  //   console.log(documentFile.url);
  //   if (documentFile && documentFile.url)
  //     downloadFile(documentFile.url, documentFile.name);
  // };
  return (
    <Wrap document={document} preview={preview} {...props}>
      <Label>{label}</Label>
      {!document && (
        <div className="preview">
          {Boolean(preview) && (
            <img src={preview} alt="preview uploaded file" />
          )}
        </div>
      )}
      <div className="wrap2">
        {!disabled && (
          <FileLabel
            document={document}
            htmlFor={inputProps?.name + "file"}
            edit={edit}
          >
            {edit ? (
              <Icon name="pen" />
            ) : (
              <img src={upload} alt="upload file" />
            )}
            <div className="info">
              <p>{buttonLabel}</p>
              {!edit && (
                <small>{document ? "PDF, DOC, DOCX, PPT" : "JPG, PNG"}</small>
              )}
            </div>
            {
              <input
                className="custom-file-input"
                id={inputProps?.name + "file"}
                type="file"
                {...inputProps}
              />
            }
          </FileLabel>
        )}
        {documentFile && (
          <FileLabel document={document} className="doc-preview">
            {!readOnly && <span className="close" onClick={onClose}>
              <Icon name="circleXMark" />
            </span>}
            <a className="icon" href={documentFile.url} download={documentFile.url} target="_blank" rel="noreferrer">
              <Icon name="document" />
            </a>
            <a className="info" href={documentFile.url} download={documentFile.url} target="_blank" rel="noreferrer">
              <p>{documentFile.name}</p>
              {documentFile.size ? (
                <small>{documentFile.size} KB</small>
              ) : (
                <small>Click to download</small>
              )}
            </a>
          </FileLabel>
        )}
      </div>
    </Wrap>
  );
}

export default memo(FileField);
