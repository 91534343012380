import React from 'react'

function Typecell(row) {
    const types = [
        { typeId: "1", name: "annual" },
        { typeId: "2", name: "Sickness" },
        { typeId: "3", name: "Marriage" },
        { typeId: "4", name: "Maternity" },
        { typeId: "5", name: "WorkAccident" },
        { typeId: "6", name: "Death" },
        { typeId: "7", name: "Unpaid" },
        { typeId: "8", name: "Others" },
        { typeId: "9", name: "Hourly" },
      ];
   if(!row?.typeId) return
  return (
    <div>{types.filter(t => t.typeId == row.typeId).map(t => {return t.name})}</div>
  )
}

export default Typecell