import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddHoliday from "./AddHoliday";
import EditHoliday from "./EditHoliday";
import Holiday from "./Holiday";
import ListHolidays from "./ListHolidays";
import ViewHoliday from "./ViewHoliday";

export default (
  <Route
    path="holidays"
    element={
      <RequireAuth>
        <Holiday />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListHolidays />
        </RequireAuth>
      }
    />
    <Route
      path="company/:id"
      element={
        <RequireAuth>
          <ListHolidays />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddHoliday />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditHoliday />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewHoliday />
        </RequireAuth>
      }
    />
  </Route>
);
