import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useEmployees, useLeaves } from "./provider";
import TextArea from "../../ui/TextArea";
import { toDateTime } from "../../utilities/functions";
import Loading from "../../ui/Loading";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid3 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 0;
`;
function ViewLeave({ editid }) {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const {
    state: { selected },
    fetchOne,
  } = useLeaves();

  // const {
  //   state: { selected: company },
  //   fetchOne: fetchCompany,
  // } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne]);

  const getRegular = (f, name) => {
    let value =
      f.name == "absenceFrom" || f.name == "absenceTo"
        ? new Date(selected[name]).toLocaleDateString("fr-FR")
        : selected[name];

    return value;
  };
  // if (!company) return;
  useEffect(() => {
    if (selected === {}) setLoading(true);
    else setLoading(false);
  }, [selected]);
  if (loading) return <Loading></Loading>;

  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Leave Information</h2>
        <div style={{ cursor: "pointer" }}>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/leaves/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <TextField
            label="name"
            inputProps={{
              value: selected?.employeeName,
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid2>
        <Grid3>
          <TextArea
            style={{ maxWidth: "1200px" }}
            label="NOTES"
            name="NOTES"
            rows="4"
            value={selected?.notes}
            inputProps={{
              autoFocus: true,
              disabled: true,
              required: true,
            }}
          />
        </Grid3>
        <Grid2>
          <TextField
            label="absence from"
            inputProps={{
              value: toDateTime(
                new Date(
                  selected?.absenceFrom?.replace(/-/g, "/") + "z"
                ).toLocaleString(),
                { time: selected?.typeId == 9 ? true : false }
              ),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label="absence to"
            inputProps={{
              value: toDateTime(
                new Date(
                  selected?.absenceTo?.replace(/-/g, "/") + "z"
                ).toLocaleString(),
                { time: selected?.typeId == 9 ? true : false }
              ),
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid2>
      </Card>
    </div>
  );
}

export default ViewLeave;
