import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useUserAccounts() {
  return useContext(Context);
}

const initState = {
  list: [],
  companylist: [],
  hrs: [],
  pos: [],
  employees: [],
  allEmployees: [],
  accountants: [],
  filters: {
    pagesize: 150,
  },
  selected: null,
  count: 0,
  page: 0,
  ecount: 0,
  allEmployeeCount: 0,
  accountantsCount: 0,
  epage: 0,
  Pcount: 0,
  Ppage: 0,
  accountantsPage: 0,
  allEmployeePage: 0,

  perPage: 10,
  status: "idle",
  eStatus: "idle",
  hrStatus: "idle",
  poStatus: "idle",
  allEmployeeStatus: "idle",

  pochangeStatus: "idle",
  accountantsStatus: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_hrs":
      return { ...state, hrs: [...action.payload] };
    case "set_pos":
      return { ...state, pos: [...action.payload] };
    case "set_employees":
      return { ...state, employees: [...action.payload] };
    case "set_allEmployees":
      return { ...state, allEmployees: [...action.payload] };
    case "set_accountants":
      return { ...state, accountants: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_ecount":
      return { ...state, ecount: action.payload };
    case "set_Pcount":
      return { ...state, Pcount: action.payload };
    case "set_accountantsCount":
      return { ...state, accountantsCount: action.payload };
    case "set_allEmployeeCount":
      return { ...state, allEmployeeCount: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "eStatus":
      return { ...state, eStatus: action.payload };
    case "hrStatus":
      return { ...state, hrStatus: action.payload };
    case "poStatus":
      return { ...state, poStatus: action.payload };
    case "accountantsStatus":
      return { ...state, accountantsStatus: action.payload };
    case "pochangeStatus":
      console.log("action", action.payload);
      return { ...state, pochangeStatus: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_accountantsPage":
      return { ...state, accountantsPage: action.payload };
    case "set_allEmployeePage":
      return { ...state, allEmployeePage: action.payload };

    case "set_epage":
      return { ...state, epage: action.payload };
    case "set_Ppage":
      return { ...state, Ppage: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_company_list":
      return { ...state, companylist: action.payload };

    case "set_AllEmployees_status":
      return { ...state, allEmployeeStatus: action.payload };
    case "set_filters":
      console.log("set_filters action.payload = ", action.payload);
      return { ...state, filters: { ...state.filters, ...action.payload } };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UserAccountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchHRs = useCallback(
    async (cid, pageNumber = 1, pageSize = 10) => {
      dispatch({ type: "hrStatus", payload: "fetching" });
      const headersData = { pageNumber, pageSize };
      const resData = await req(
        `UserAccount/HRList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "hrStatus", payload: `idle` });
      dispatch({ type: "set_hrs", payload: resData.data.users });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req, state.pochangeStatus]
  );

  const fetchEmployees = useCallback(
    async (cid, pageNumber = 1, pageSize = 10) => {
      dispatch({ type: "eStatus", payload: "fetching" });
      const headersData = { pageNumber, pageSize };
      const resData = await req(
        `UserAccount/EmployeeList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "eStatus", payload: `idle` });
      dispatch({ type: "set_employees", payload: resData.data.users });
      dispatch({ type: "set_ecount", payload: resData.data.totalSize });
    },
    [req, state.pochangeStatus]
  );

  const fetchAllEmployees = useCallback(
    async (filters) => {
      const headersData = { ...filters };

      const resData = await req(
        `UserAccount/ListAll`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "set_AllEmployees_status", payload: `idle` });
      dispatch({ type: "set_allEmployees", payload: resData.data.users });
      dispatch({
        type: "set_allEmployeeCount",
        payload: resData.data.totalSize,
      });
    },
    [req]
  );

  const fetchPo = useCallback(
    async (cid, pageNumber = 1, pageSize = 10) => {
      dispatch({ type: "poStatus", payload: "fetching" });
      const headersData = { pageNumber, pageSize };
      const resData = await req(
        `UserAccount/POList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "poStatus", payload: `idle` });
      dispatch({ type: "pochangeStatus", payload: "changed 2" });
      dispatch({ type: "set_pos", payload: resData.data.users });
      dispatch({ type: "set_Pcount", payload: resData.data.totalSize });
    },
    [req]
  );


  const fetchAccountant = useCallback(
    async (cid, pageNumber = 1, pageSize = 10) => {
      dispatch({ type: "accountantsStatus", payload: "fetching" });
      const headersData = { pageNumber, pageSize };
      const resData = await req(
        `UserAccount/AccList/${cid}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "accountantsStatus", payload: `idle` });
      dispatch({ type: "set_accountants", payload: resData.data.users });
      dispatch({ type: "set_accountantsCount", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (username) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(
          `UserAccount/Info/${username}`,
          null,
          {},
          true
        );
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "UserAccount/Create",
            data,
            { method: "POST" },
            true
          );
          // dispatch({ type: "add", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const createAdmin = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "UserAccount/CreateAdmin",
            data,
            { method: "POST" },
            true
          );

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchHrCompanies = useCallback(
    async (un) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(
            `UserAccount/Info/${un}`,
            null,
            { method: "GET" },
            true
          );
          dispatch({ type: "hrStatus", payload: `idle` });
          dispatch({
            type: "set_company_list",
            payload: resData.data.companys,
          });
          resolve(resData.data.companys);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setAsHR = useCallback(
    async (uid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "hrStatus", payload: "fetching" });
        try {
          const resData = await req(
            `UserAccount/SetAsHR/${uid}`,
            data,
            { method: "PUT" },
            true
          );
          // dispatch({ type: "edit", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "hrStatus", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setAsPO = useCallback(
    async (uid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `UserAccount/SetAsPO/${uid}`,
            {
              grant: true,
            },
            { method: "PUT" },
            true
          );
          // dispatch({ type: "edit", payload: resData.data });

          dispatch({ type: "pochangeStatus", payload: "changed" });

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const revokePO = useCallback(
    async (uid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `UserAccount/SetAsPO/${uid}`,
            {
              grant: false,
              
            },
            { method: "PUT" },
            true
          );
          // dispatch({ type: "edit", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const editHR = useCallback(
    async (uid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "pochangeStatus", payload: "fetching" });

        try {
          const resData = await req(
            `UserAccount/UpdateHR/${uid}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "set_selected", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "pochangeStatus", payload: `idle` });
        }
      });
    },
    [req]
  );


  const editAcc = useCallback(
    async (uid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "pochangeStatus", payload: "fetching" });

        try {
          const resData = await req(
            `UserAccount/SetAsAcc/${uid}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "set_selected", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "pochangeStatus", payload: `idle` });
        }
      });
    },
    [req]
  );


  

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchHRs,
        fetchEmployees,
        fetchOne,
        create,
        setAsHR,
        fetchHrCompanies,
        editHR,
        setAsPO,
        fetchPo,
        revokePO,
        fetchAllEmployees,
        setFilters,
        createAdmin,
        fetchAccountant,
        editAcc
      }}
    >
      {children}
    </Context.Provider>
  );
}
