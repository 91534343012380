import styled from "styled-components";

const GridCell = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 17px;
  font-family: ${(props) => props.theme.fonts.openSans};
  img {
    width: 52px;
    border-radius: 12px;
  }
  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    font-size: 14px;
  }
`;

function ParentCompanyNameCell(company) {
 if (!company.parentCompanyInfo) {
  return
 }

  return (
    <GridCell>
      <section>
        <img src={company.parentCompanyInfo?.logoUrl || "https://via.placeholder.com/150"} alt="company Logo" />
      </section>
      <section>
        <strong>{company.parentCompanyInfo?.companyName}</strong>
        <span>{company.parentCompanyInfo?.businessType}</span>
      </section>
    </GridCell>
  );
}

export default ParentCompanyNameCell;