import React from 'react'
import { turncate } from '../../../utilities/functions'

function NotesCell(data) {
  return (
    <div>
        {
            turncate(data?.notes , 10)
        }
    </div>
  )
}

export default NotesCell