import React from 'react'

function NetSalary(row) {
    if(!row) return 
  return (
    <div>

{
        row?.netSalary + ' ' + row?.currencyInfo.symbol
    }
    </div>
  )
}

export default NetSalary