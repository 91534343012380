import styled from "styled-components";

const GridCell = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 17px;
  font-family: ${(props) => props.theme.fonts.openSans};
  img {
    width: 49px;
    border-radius: 15px;
  }
  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    font-size: 14px;
  }
`;

function CompanyNameCell(data) {
  
  if (!data)  return
  return (
    <GridCell>
      <section>
        <img src={data?.companyInfo?.logoUrl || "https://via.placeholder.com/150"} alt="company Logo" />
      </section>
      <section>
        <strong>{data?.companyInfo?.companyName}</strong>
        <span>{data?.companyInfo?.businessType}</span>
      </section>
    </GridCell>
  );
}

export default CompanyNameCell;