import React from "react";

const TagCell = ({ tags }) => {
  var Tags = tags.split(";");
  return (
    <div>
      {Tags.map((t, index) => (
        <div key={index}>{t}</div>
      ))}
    </div>
  );
};

export default TagCell;
