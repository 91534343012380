import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../ui/TableContainer";
import { useUserAccounts } from "../provider";
import { useUtils } from "../../../services/utilsProvider";
import { Pagination } from "../../../ui";

const cols = [
  { name: "displayName", label: "Display Name" },
  { name: "username", label: "Username" },
];

function ListAccountant({ company }) {
  const navigate = useNavigate();
  const {alertUser ,notify} = useUtils()

  const {
    state: { accountants, accountantsStatus, accountantsCount, pochangeStatus,accountantsPage, perPage},
    fetchAccountant,
    dispatch  ,
    editAcc
  } = useUserAccounts();

  useEffect(() => {
    if (company.id) fetchAccountant(company.id ,(accountantsPage +1));
  }, [fetchAccountant, company.id , accountantsPage ,pochangeStatus]); 

  const handleChangePage = useCallback(
    (event, newPage) => newPage&& dispatch({ type: "set_Ppage", payload: newPage }),
    [dispatch]
  );

  const handleRevokePO = useCallback(
    async (id) => {
      try{
        await editAcc(id , {grant : false})
        dispatch({ type: "accountantsStatus", payload: `changed` });
        dispatch({ type: "accountantsStatus", payload: `idle` });
        notify("an Accountant Role has been revoked successfully.");
      }catch(e){
        alertUser("not an Accountant")
      }
       
     },
     [navigate]
   );
  return (
    <div>
      <TableContainer
        empty={accountants?.length === 0}
        status={accountantsStatus}
        aria-label="Holidays table"
        cols={cols}
        data={accountants
        }
        moreOptions={{
          setAsPo: {
            label: "ٌRevoke ACcountant Role",
            icon: "",
            fn: handleRevokePO,
          },
        }}

      />
      <Pagination
        data-testid="pagination"
        count={accountantsCount}
        page={accountantsPage}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default memo(ListAccountant);

