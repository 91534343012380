import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Attendance() {
  return (
    <Layout>
      <div id="attendance">
        <Outlet />
      </div>
    </Layout>
  );
}

export default Attendance;
