import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Flex, Select, TextField } from "../../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../../companies/provider";
import { emptyStringToNull } from "../../../utilities/yupSchemaCreator";
import { useTransaction } from "../provider";
import ErrorsList from "../../../ui/ErrorsList";
import { delay } from "../../../utilities/functions";
import { useAccounts } from "../../accounts/provider";

const schema = Yup.object().shape({
  companyId: Yup.number().nullable().transform(emptyStringToNull),
  date: Yup.date().nullable(),
});

function GeneralLedgerForm({ show, isAcc, years = [], error }) {
  let user = JSON.parse(localStorage.getItem("user"));
  const [companyId, setCompanyId] = useState();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);
  const {
    state: { generalLedgerfilters, filters },
    setGeneralledgerfilters,
  } = useTransaction();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    //values.date = setDateFormat(values.date, true);
    setGeneralledgerfilters(values);
  };

  const FilterHiddenInputs = (props) => {
    if (!props?.show?.includes(props?.children?.props?.name)) return <></>;
    else return <>{props?.children}</>;
  };

  const handleCompanyChange = useCallback(
    (data) => setCompanyId(data.value),
    []
  );

  const handleYearChange = useCallback((data) => {
    setValue("yearId", data.value);
  }, []);
  const [selectMapOption, setSelectMapOption] = useState({
    value: "id",
    label: "label",
  });
  const {
    state: { NamesList },
    fetchByName,
  } = useAccounts();

  let getOptions = (e) => {
    if (isNaN(e)) {
      //label 1
      setSelectMapOption({ value: "id", label: "label" });
    } else {
      //label 2
      setSelectMapOption({ value: "id", label: "label2" });
    }
    if (
      e.length > 2 &&
      NamesList.map(
        (s) => !s?.accountName.toLowerCase().startsWith(e.toLowerCase())[0]
      )
    )
      fetchByName({ companyId: companyId, searchText: e }, true);
  };
  if (!years) return;
  return (
    <Card style={{ marginBottom: "10px" }}>
      {error && <ErrorsList error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex jc="flex-start" ai="flex-end" gap={20}>
          {isAcc ? (
            <TextField
              label="Company"
              name="company"
              style={{ marginBottom: "0px" }}
              inputProps={{
                value: user?.employee?.company?.companyName,
                disabled: true,
              }}
            />
          ) : (
            <Select
              style={{
                maxWidth: 220,
              }}
              data={companies}
              name="companies"
              mapOptions={{ value: "id", label: "businessName" }}
              onChange={handleCompanyChange}
              defaultValue={generalLedgerfilters?.companyId || companies[0]?.id}
              label={"company"}
            />
          )}

          <FilterHiddenInputs show={show}>
            <Select
              style={{
                maxWidth: 220,
              }}
              data={years}
              name="years"
              mapOptions={{ value: "id", label: "title" }}
              onChange={handleYearChange}
              defaultValue={
                getValues()?.yearId ||
                generalLedgerfilters?.yearId ||
                filters?.yearId
              }
              label={"year"}
            />
          </FilterHiddenInputs>
          <Select
            label="Account"
            data={[
              ...NamesList.map((item) => ({
                ...item,
                label: item.accountName + " - " + item.accountNumber,
                label2: item.accountNumber + " - " + item.accountName,
              })),
            ]}
            style={{ marginTop: "10px" }}
            mapOptions={selectMapOption}
            defaultValue={generalLedgerfilters?.accountId}
            error={!!errors.accountId}
            msg={errors.accountId?.message}
            setPlaceholderText={generalLedgerfilters?.accountName}
            getTextValue={delay(getOptions)}
            onChange={(e) => {
              setValue("accountId", e.value);
              setValue("accountName", e.data.accountName);
            }}
          />
          <Button
            data-testid="save-button"
            type="submit"
            // iconName="Eye"
            className="small"
            loading={isSubmitting}
            style={{ height: 60 }}
          >
            Apply
          </Button>
        </Flex>
      </form>
    </Card>
  );
}

export default GeneralLedgerForm;
