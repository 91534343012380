import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddCompensation from "./AddCompensation";
import Compensations from "./Compensations";
import EditCompensation from "./EditCompensation";
import ListCompensations from "./ListCompensations";
import ViewCompensation from "./ViewCompensation";

export default (
  <Route
    path="compensations"
    element={
      <RequireAuth>
        <Compensations />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListCompensations />
        </RequireAuth>
      }
    />
    <Route
      path="new/payroll/:pId"
      element={
        <RequireAuth>
          <AddCompensation />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id/payroll/:pid"
      element={
        <RequireAuth>
          <EditCompensation />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewCompensation />
        </RequireAuth>
      }
    />
  </Route>
);
