import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import {  useCurrency } from "./provider";
import CurrencyForm from "./CurrencyForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditCurrency() {
  const {id} = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const [cur , setCur] = useState(null) 
  const {
    state: { selected: currency },
    fetchOne,
    dispatch,
  } = useCurrency();

  useEffect(() => {
    fetchOne(id);
  }, [id, fetchOne, dispatch ,currency]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };


  if(!currency||currency?.id !== Number(id) ) return
  return (
    <div id="edit-holiday">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit currency</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <CurrencyForm holiday={currency} handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(EditCurrency);
