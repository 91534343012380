import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Icon, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useEmployees } from "./provider";

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const schema = Yup.object().shape({
  bankName: Yup.string().required(),
  bankAddress: Yup.string().required(),
  ibanNumber: Yup.string().required(),
  swiftCode: Yup.string().required(),
  });

function CreateBank({ bank }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: window.location.state.bank,
  });

  const {
    state: { selected },
    fetchBank,
    removeBank,
    setBank,
  } = useEmployees();
  const [error, setError] = useState(null);
  const { notify } = useUtils();
  let navigate = useNavigate()
  let location = useLocation()

  let {id} = useParams()
  const onSubmit = async (values) => {
    try {
      await setBank( id , values);
      notify('A Bank Has Been Set')
      navigate(-1)
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
    }
  };

  if (location.state.bank ){
    bank = location.state.bank
  }



  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <ErrorsList error={error} />}

        <Grid1>
        <h2 style={{ marginTop: "0" }}>Add New Employee Bank</h2>
        </Grid1>

        
        
        <Card>  
          <Grid>

          <TextField
            label='Emplyee Name'
            inputProps={{
              value:sessionStorage.getItem('empname'),
              readOnly: true,
              disabled: true,
              style: {
								background: '#fff',
								borderBottom: '2px solid #ddd',
								outline: 'none',
							},
            }}
          />
          </Grid>
        
          <Grid>
          
            <TextField
              label={"Bank Name"}
              name={"bankName"}
              error={!!errors["bankName"]}
              msg={errors["bankName"]?.message}
              required={true}
              inputProps={{
                defaultValue:bank.bankName,
                ...register("bankName"),
                required:true
              }}
            />
            <TextField
              label={"Bank Address"}
              name={"bankAddress"}
              error={!!errors["bankAddress"]}
              msg={errors["bankAddress"]?.message}
              inputProps={{
                defaultValue:bank.bankAddress,
                ...register("bankAddress"),
              }}
            />
            <TextField
              label={"Iban Number"}
              name={"ibanNumber"}
              error={!!errors["ibanNumber"]}
              msg={errors["ibanNumber"]?.message}
              inputProps={{
                defaultValue:bank.ibanNumber,
                ...register("ibanNumber"),
              }}
            />
            <TextField
              label={"Swift Code"}
              name={"swiftCode"}
              error={!!errors["swiftCode"]}
              msg={errors["swiftCode"]?.message}
              inputProps={{
                defaultValue:bank.swiftCode,

                ...register("swiftCode"),
              }}
            />
          </Grid>
        </Card>

        <ButtonWrapper>
        {
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
            loading={isSubmitting}
          >
            Save
          </Button>
        }
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
      </form>
    </>
  );
}

export default CreateBank;
