import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Icon } from "../../ui";
import TransactionForm from "./TransactionForm";
import FilterForm from "./FilterForm";
import { useTransaction } from "./provider";
import { useYear } from "../year/provider";
import { useStatus } from "../../services/StatusProvider";
import { useCompanies } from "../companies/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddTransaction({ noBack }) {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("a new Journal has been created successfully.");
  };

  const {
    state: { list: companies },
  } = useCompanies();

  const [companyId, setCompanyId] = useState();
  const [error, setError] = useState();
  let show = ["years"];

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  const {
    state: { selected, filters },
    fetchOne,
    setFilters,
  } = useTransaction();
  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);
  if (!isAcc) return <h3>you have no access to these section </h3>;

  return (
    <div id="add-Journal">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Journal</h2>
        <div>
          {!noBack && (
            <>
              <div
                style={{ cursor: "pointer", marginLeft: "20px" }}
                onClick={() => navigate(-1)}
              >
                <span>Back</span>
                <Icon name="arrowLeft" />
              </div>
            </>
          )}
        </div>
      </Grid>

      <TransactionForm handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(AddTransaction);
