import React from 'react'
import { useNavigate } from 'react-router-dom';

function EmployeeNameCell(row) {
    let navigate = useNavigate()
  return (
    <div onClick={()=>navigate('/employees/view/' + row?.employeeId)} style={{cursor:'pointer' , textDecoration:'underline'}}>{row.employeeName}</div>
  )
}

export default EmployeeNameCell