import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Card, Pagination ,Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import FilterForm from "./FilterForm";
import { useAttendance } from "./provider";
import CompanyNameCell from "./CompanyNameCell";
import DateCell from "./DateCell";
import DateCell2 from "./DateCell2";
import { dateToLocalConverter, exporToExcle } from "../../utilities/functions";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function AttendanceReportList() {
  //define stuff
  const navigate = useNavigate();
  const { notify } = useUtils();






  //fetch attendance
  const {
    state: {
      reportList: attendance,
      status,
      rcount,
      rpage,
      filters,
      page
    },
    fetchReportAttendance: fetchReportAttendance,
    remove,
    dispatch,
    setFilters
  } = useAttendance();

  //cols
  const cols = [
    {
      name: "employeeInfo.fullName",
      label: "Employee Name",
      additionalInfo: "employeeInfo.jobDescription",
    },
    {
      name: "companyInfo",
      label: "Company Name",
      render: CompanyNameCell,
    },
    { name: "punchIn", label: "First PunchIn Time", render: DateCell },
    { name: "punchOut", label: "Last PunchOut Time", render: DateCell2 },
    { name: "overTime", label: "Total Over Time" },
    { name: "delay", label: "Total Delays" },
    { name: "breakDuration", label: "Break Duration" },
    { name: "totalBreaks", label: "Total Breaks" },
    { name: "hourlyLeave", label: "Hourly Leave" },

    { name: "overBreak", label: "Over Break" },
    { name: "workTime", label: "Work Time" },
    { name: "shiftTime", label: "Shift Time" },
    { name: "longPres", label: "Long Press" },
    { name: "missPres", label: "Miss Press" },
  ];

  const handleView = (id) => {
    navigate(`/attendance/viewEmployee/${id.employeeInfo.id}`);
  };




  const getExcelObj = (x) => {
    let newObj = new Array(x.length);

    x?.attendances.map((item, idx) => {
      newObj[idx] = {
        "Employee Name": item?.employeeInfo?.fullName,
        "Company Name": item?.companyInfo?.companyName,
        "First PunchIn Time": dateToLocalConverter(item?.punchIn),
        "Last PunchOut Time": dateToLocalConverter(item?.punchOut),
        "Total Over Time": item?.overTime,
        "Total Delays": item?.delay,
        "Break Duration": item?.breakDuration,
        "Total Breaks": item?.totalBreaks,
        "Over Break": item?.overBreak,
        "Work Time": item?.workTime,
        "Shift Time": item?.shiftTime,
        "Long Press": item?.longPres,
        "Miss Press": item?.missPres,
      };
    });
    return newObj;
  };



  useEffect(() => {
    filters&&fetchReportAttendance(filters , page);
  }, [rpage, fetchReportAttendance, filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_rpage", payload: newPage });
  };

  var d = new Date();
  let now =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft();
  
  d.setDate(d.getDate() - 30);
  let fom =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft();
  
  useEffect(()=>{
    if(!filters.FromDate)setFilters({
      FromDate:fom,
      ToDate:now,
    })
  },[])
  if (!attendance) return;
  return (
    <>
      <Grid>
        <h2>Attendance Report List</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            exporToExcle(
              getExcelObj(attendance),
              attendance.attendances[0]?.companyInfo?.companyName +
                " attendance report from " +
                attendance?.fromDate +
                " to " +
                attendance?.toDate
            )
          }
        >
          Export Excel
        </Button>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
      <FilterForm  setFilters={setFilters} ></FilterForm>
      </Card>
      <TableContainer
        empty={attendance?.length == 0}
        //status={status}
        aria-label="Attendance table"
        cols={cols}
        data={attendance.attendances}
        checkbox
        showButton={handleView}
        //moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={rcount}
        page={rpage}
        rowsPerPage={150}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default AttendanceReportList;
