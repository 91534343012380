import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUtils } from '../../services/utilsProvider';
import { Icon, Select } from '../../ui';
import { useCompanies } from '../companies/provider';
import UploadExcel from './uploadExcel';


const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
function AddFromExcel() {

    const navigate = useNavigate();
    const { notify } = useUtils();
    const [companyId,setCompanyId] = useState()


    const handleSuccess = () => {
      navigate('/company/' + companyId);
      notify("a file has been uploaded successfully.");
    };
    const {
        state: { list: companies },
        fetchList: fetchCompanies,
      } = useCompanies();
    
      useEffect(() => {
        fetchCompanies();
      }, [fetchCompanies]);
    
      const mapCompanyOptions = useRef({ value: "id", label: "businessName" });
  
  return (
    
        <div id="add-employee">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Employee</h2>
        
          <Select
            data={companies}
            mapOptions={mapCompanyOptions.current}
            onChange={(e)=>setCompanyId(e.value)}
            defaultValue={Number(companyId)}
            inputStyle={{ height: "40px", maxWidth: "280px" }}
            inputProps={{
              readOnly: true,
            }}
          />
        
        <div style={{ cursor: "pointer" }} >
          <span onClick={() => navigate(-1)}>Back</span>
          <Icon name="arrowLeft" onClick={() => navigate(-1)}/>
        </div>
      </Grid>

      <UploadExcel companyId={companyId} handleSuccess={handleSuccess} />
    </div>
  )
}

export default AddFromExcel