import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import ListReport from "./ListReport";
import Report from "./Reports";


export default (
  <Route
    path="report"
    element={
      <RequireAuth>
        <Report />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListReport />
        </RequireAuth>
      }
    />
  </Route>
);
