import { useCallback, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Card, Select } from "../../../ui";
import { genYears } from "../../../utilities/functions";
import { useStats } from "../../stats/provider";
import months from "../../../data/months.json";
import { useCompanies } from "../../companies/provider";


const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    flex-grow: 1;
  }
`;

const Container = styled.div`
  #chart-container {
    font-family: Arial;
    height: 420px;
    border: 2px dashed #aaa;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
  }
`;



function EmployeeHirarchy() {
  //   const [year, setYear] = useState(new Date().getFullYear());
  //   const [month, setMonth] = useState(new Date().getMonth() + 1);

  //   const {
  //     state: { numberSalaries },
  //     fetchNumberSalaries,
  //   } = useStats();

  //   useEffect(() => {
  //     fetchNumberSalaries(year, month);
  //   }, [fetchNumberSalaries, year, month]);
  const {
    state: { list: companies, selected: company },
    dispatch,
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companies.length) return;
    dispatch({ type: "set_selected", payload: companies[0], keep: true });
  }, [companies, dispatch]);

  const handleCompanyChange = useCallback(
    (v) => dispatch({ type: "set_selected", payload: v.data }),
    [dispatch]
  );
  return (
    <div>
      <Title>
        <h2>Employees per Company</h2>
        <Select
          inputStyle={{ height: "48px", width: "250px" }}
          data={companies}
          mapOptions={{ label: "businessName", value: "id" }}
          defaultValue={company?.id || ""}
          onChange={handleCompanyChange}
          inputProps={{
            value: company?.id || "",
            onChange: () => {},
          }}
        />
      </Title>
      <Card>
        <ResponsiveContainer width="100%" height={660}>
          <Container>

          </Container>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}

export default EmployeeHirarchy;
