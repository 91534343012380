import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAttendance, useDepartments } from "./provider";
import { Button, Card, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import { useCompanies } from "../companies/provider";
import ErrorsList from "../../ui/ErrorsList";
import FilterForm from "./FilterForm";
import {
  dateToLocalConverter,
  setDateFormat,
  toDateTime,
  transformDate,
} from "../../utilities/functions";
import InputDate from "../../ui/InputDate";
import Loading from "../../ui/Loading";
const schema = Yup.object().shape({
  tolerance: Yup.string().required('required').min(0,'must be greater than 0').max(120 , 'must be lower than 120'),
  ruleInId: Yup.number().typeError('required').required('required'),
  ruleOutId: Yup.number().typeError('required').required('required'),
  totalBreaks: Yup.number().typeError('required').required('required'),
});

const Grid = styled.div`
  max-width: 845px;
  padding-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const Grid1 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;
const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Sbutton = styled.div`
  margin-top: 45px;
  width: undefinedpx;
  background-color: #6418c3;
  color: white;
  border: none;
  height: 60px;
  padding: 0 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
  font-size: 17px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
`;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const getToday = () => {
  let d = new Date();
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft()
  );
};

const getLocalDate = (x) => {
  let d = new Date(x + "z").toLocaleString();
  let dd = new Date(d);
  console.log(
    dd.getDate().padLeft() +
      "-" +
      (dd.getMonth() + 1).padLeft() +
      "-" +
      dd.getFullYear() +
      " " +
      dd.getHours().padLeft() +
      ":" +
      dd.getMinutes().padLeft()
  );
  return (
    dd.getDate().padLeft() +
    "-" +
    (dd.getMonth() + 1).padLeft() +
    "-" +
    dd.getFullYear() +
    " " +
    dd.getHours().padLeft() +
    ":" +
    dd.getMinutes().padLeft()
  );
};

const getShortLocalDate = (x) => {
  let d = new Date(x + "z").toLocaleString();
  let dd = new Date(d);
  return (
    dd.getFullYear() +
    "-" +
    (dd.getMonth() + 1).padLeft() +
    "-" +
    dd.getDate().padLeft() +
    "T" +
    dd.getHours().padLeft() +
    ":" +
    dd.getMinutes().padLeft()
  );
};

function AttendanceForm({ emp, handleSuccess }) {
  const [error, setError] = useState(null);
  const [loading , setLoading] = useState()

  //define data
  const [employeeId, setEmployeeId] = useState();
  const [inDate, setInDate] = useState();
  const [outDate, setOutDate] = useState();
  const [punchIn, setPunchIn] = useState();
  const [punchOut, setPunchOut] = useState();
  const [ruleIn, setRuleIn] = useState();
  const [ruleOut, setRuleOut] = useState();
  const [typeId, setTypeId] = useState();
  const [tolerance, setTolerance] = useState(null);

  const [breakDuration, setBreakDuration] = useState(null);
  const [totalBreaks, setTotalBreaks] = useState(null);
  const [selectedType, setselctedType] = useState(0);
  const [selectedDay, setSelectedDay] = useState();

  const navigate = useNavigate();
  let params = useParams();

  //eums
  const types = [
    { typeId: "1", name: "Regular" },
    { typeId: "2", name: "Overtime" },
    { typeId: "3", name: "Absence" },
    { typeId: "7", name: "Ignored" },
  ];

  const types2 = [
    { typeId: "1", name: "Regular" },
    { typeId: "2", name: "Overtime" },
    { typeId: "3", name: "Absence" },
    { typeId: "4", name: "Leave" },
    { typeId: "5", name: "HourlyLeave" },
    { typeId: "6", name: "Officialholiday" },
    { typeId: "7", name: "Ignored" },
  ];

  const types3 = [
    { typeId: "0", name: "All" },
    { typeId: "1", name: "Punch In" },
    { typeId: "2", name: "Punch Out" },
    { typeId: "3", name: "Break Out " },
    { typeId: "4", name: "Break In" },
  ];
  // fetch attendance
  const {
    state: {
      list: leave,
      emps: employee,
      deps: departments,
      selected,
      punchList,
      filters
    },
    create,
    fetchOne,
    setFilters,
    edit: editAttendance,
    dispatch,
    fetchPunchList,
  } = useAttendance();

  useEffect(() => {
    if (params.id) fetchOne(params.id);
  }, [fetchOne]);

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch punch list
  useEffect(() => {
    if (punchIn)
      fetchPunchList(employeeId, setDateFormat( punchIn), selectedType);
  }, [selectedType, selectedDay, fetchPunchList]);

  //get utc
  const getUtc = (x) => {
    let d = new Date(x);
    return (
      d.getUTCFullYear() +
      "-" +
      (d.getUTCMonth() + 1).padLeft() +
      "-" +
      d.getUTCDate().padLeft() +
      " " +
      d.getUTCHours().padLeft() +
      ":" +
      d.getUTCMinutes().padLeft()
    );
  };

  const onSubmit = async (values) => {
    let houres = 4
    if(tolerance >(houres*60) || tolerance < 0  ){
      setError({title: "tolerance must be between 0 and 4 hours"})
      return
    }

    if(breakDuration >(houres*60) || breakDuration < 0  ){
      setError({title: "Break Duration must be between 0 and 4 hours"})
      return
    }
    console.log(filters);
    try {
      if (!emp)
        await create({
          employeeId:filters.employeeId,
          inDate: getUtc(inDate),
          outDate: getUtc(outDate),
          punchIn: getUtc(punchIn),
          ruleInId: parseInt(ruleIn),
          breakDuration: parseInt(breakDuration),
          tolerance: tolerance || 0,
          totalBreaks: parseInt(totalBreaks) || 0,
          outDate: getUtc(outDate),
          punchOut: getUtc(punchOut),
          ruleOutId: parseInt(ruleOut),
          typeId: parseInt(
            typeId != 0 ? typeId : selected.attendanceShift.typeId.toString()
          ),
        });
      else {
        await editAttendance(selected.attendanceShift.id, {
          employeeId: employeeId,
          inDate: getUtc(inDate),
          outDate: getUtc(outDate),
          punchIn: getUtc(punchIn),
          ruleInId: parseInt(ruleIn),
          outDate: getUtc(outDate),
          punchOut: getUtc(punchOut),
          ruleOutId: parseInt(ruleOut),
          tolerance: tolerance || 0,
          breakDuration: parseInt(breakDuration),
          totalBreaks: parseInt(totalBreaks) || 0,
          typeId: parseInt(
            typeId != 0 ? typeId : selected.attendanceShift.typeId.toString()
          ),
        });
        reset();
        setError(false)
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status < 500) setError({title:'please fill All fields with correct values'}); 

      console.log("e", e);
    }
  };
  console.log(selected == {} , 'selected', selected);


  if (emp) {
    if (!selected ) return;

    if (!selected?.attendanceShift?.inDate) return;

    !employeeId && setEmployeeId(selected.attendanceShift?.employeeId);
    !inDate && setInDate(dateToLocalConverter(selected.attendanceShift.inDate));
    !outDate &&
      setOutDate(dateToLocalConverter(selected.attendanceShift.outDate ?? ""));
    !punchIn &&
      setPunchIn(dateToLocalConverter(selected.attendanceShift.punchIn));
    !punchOut &&
      setPunchOut(
        dateToLocalConverter(selected.attendanceShift.punchOut ?? "")
      );
    !ruleIn && setRuleIn(selected?.attendanceShift?.ruleInId);

    breakDuration === null &&
      setBreakDuration(selected.attendanceShift.breakDuration || "0");
    totalBreaks === null &&
      setTotalBreaks(selected.attendanceShift.totalBreaks || 0);
    tolerance === null &&
      setTolerance(selected.attendanceShift.tolerance || 0);

    if (!ruleOut)
      ruleOut != 0 && setRuleOut(selected.attendanceShift?.ruleOutId ?? 1);
    !selectedDay && setSelectedDay(getToday());
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        {emp ? (
          <Grid>
            <TextField
              label="Employee Name"
              name="employeeName"
              error={!!errors.departmentName}
              msg={errors.departmentName?.message}
              inputProps={{
                value: selected["attendanceShift"].employeeInfo.fullName,
                ...register("employeeName"),
                autoFocus: true,
                required: true,
                disabled: true,
                style: {
                  background: "#fff",
                  borderBottom: "2px solid #ddd",
                  outline: "none",
                },
              }}
            />
          </Grid>
        ) : (
          <>
            <FilterForm
              hide={{
                dates:true,
                buttons:true,
                search:true,
                AllInSelect:true
              }}
              editable
              setFilters={setFilters}
            ></FilterForm>
          </>
        )}

        <Grid style={{ marginTop: "20px" }}>
          <InputDate
            label="In Date"
            name="inDate"
            error={!!errors.inDate}
            msg={errors.inDate?.message}
            value={new Date(inDate)}
            type="time"
            onDateChange={(v) => {
              setInDate(v);
            }}
          />

          <InputDate
            label="Out Date"
            name="outDate"
            error={!!errors.outDate}
            msg={errors.outDate?.message}
            value={new Date(outDate)}
            type="time"
            onDateChange={(v) => {
              setOutDate(v);
            }}
          />


<InputDate
            label="Punch In"
            name="punchIn"
            error={!!errors.punchIn}
            msg={errors.punchIn?.message}
            value={new Date(punchIn)}
            type="time"
            onDateChange={(v) => {
              setPunchIn(v);
            }}
          />

<InputDate
            label="Punch Out"
            name="punchOut"
            error={!!errors.punchOut}
            msg={errors.punchOut?.message}
            value={new Date(punchOut)}
            type="time"
            onDateChange={(v) => {
              setPunchOut(v);
            }}
          />



          <Select
            style={{ height: "60px", width: "100%" }}
            label="Rule In"
            data={types}
            mapOptions={{ value: "typeId", label: "name" }}
            watch={watch}
            defaultValue={
              selected && selected?.attendanceShift?.ruleInId.toString()
            }
            onChange={(v) => {
              setRuleIn(v.value);
            }}
            error={!!errors.ruleIn}
            msg={errors.ruleIn?.message}
            inputProps={{
              ...register("ruleIn"),
            }}
          />

          <Select
            style={{ height: "60px", width: "100%" }}
            label="Rule Out"
            data={types}
            mapOptions={{ value: "typeId", label: "name" }}
            watch={watch}
            defaultValue={
              selected && selected.attendanceShift?.ruleOutId.toString()
            }
            onChange={(v) => {
              setRuleOut(v.value);
            }}
            error={!!errors.ruleOut}
            msg={errors.ruleOut?.message}
            inputProps={{
              ...register("ruleOut"),
            }}
          />

          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "60px",
            }}
            data={types2}
            mapOptions={{ value: "typeId", label: "name" }}
            onChange={(v) => {
              setTypeId(v.value);
            }}
            defaultValue={
              selected ? selected?.attendanceShift?.typeId.toString() : 0
            }
            label={"Type"}
            inputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "60px",
            }}
            required
            label="Tolerance"
            name="tolerance"
            error={!!errors.tolerance}
            msg={!errors.tolerance?.message}
            onChange={(v) => {
              setTolerance(v.target.value);
            }}
            inputProps={{
              value: tolerance,
              ...register("tolerance"),
            }}
          />
          <TextField
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "60px",
            }}
            required
            label="Break Duration"
            name="breakDuration"
            error={!!errors.breakDuration}
            msg={!errors.breakDuration?.message}
            onChange={(v) => {
              setBreakDuration(v.target.value);
            }}
            inputProps={{
              value: breakDuration,
              ...register("breakDuration"),
            }}
          />

          <TextField
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "60px",
            }}
            required
            label="Total Breaks"
            name="totalBreaks"
            error={!!errors.totalBreaks}
            msg={!errors.totalBreaks?.message}
            onChange={(v) => {
              setTotalBreaks(v.target.value);
            }}
            inputProps={{
              value: totalBreaks,
              ...register("totalBreaks"),
            }}
          />
        </Grid>

        {emp && (
          <>
            <h3>Shift Time</h3>
            <Grid1>
              {selected.actualAttendanceShifts &&
              selected.actualAttendanceShifts?.length == 0 ? (
                <>
                  <TextField
                    label="In Date"
                    inputProps={{
                      value: selected.attendanceShift["inDate"],
                      readOnly: true,
                      disabled: true,
                      style: {
                        background: "#fff",
                        borderBottom: "2px solid #ddd",
                        outline: "none",
                      },
                    }}
                  />

                  <TextField
                    label="Out Date"
                    inputProps={{
                      value: selected.attendanceShift["outDate"],
                      readOnly: true,
                      disabled: true,
                      style: {
                        background: "#fff",
                        borderBottom: "2px solid #ddd",
                        outline: "none",
                      },
                    }}
                  />
                </>
              ) : (
                <>
                  {selected.actualAttendanceShifts &&
                    selected.actualAttendanceShifts.map((s) => {
                      return (
                        <>
                          <TextField
                            label="from"
                            inputProps={{
                              value: s.start,
                              readOnly: true,
                              disabled: true,
                              style: {
                                background: "#fff",
                                borderBottom: "2px solid #ddd",
                                outline: "none",
                              },
                            }}
                          />

                          <TextField
                            label="to"
                            inputProps={{
                              value: s.end,
                              readOnly: true,
                              disabled: true,
                              style: {
                                background: "#fff",
                                borderBottom: "2px solid #ddd",
                                outline: "none",
                              },
                            }}
                          />
                        </>
                      );
                    })}
                </>
              )}
            </Grid1>
          </>
        )}
      </Card>
      {emp && (
        <Card style={{ marginTop: "20px" }}>
          <>
            <Grid2 style={{ marginTop: "20px" }}>
              <h3 style={{ paddingTop: "30px", paddingLeft: "40px" }}>
                Punch List For:{" "}
              </h3>

              
<InputDate
            label="Selcted Day"
            name="selctedDay"
            error={!!errors.selctedDay}
            msg={errors.selctedDay?.message}
            value={new Date(punchIn)}
            type="date"
            pickerProps={{
              disabled:true,
            }}
          />

              

              <Select
                style={{
                  height: "60px",
                  width: "100%",
                  marginBottom: "20px",
                }}
                data={types3}
                mapOptions={{ value: "typeId", label: "name" }}
                onChange={(v) => {
                  setselctedType(v.value);
                }}
                defaultValue="0"
                label={"Types"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid2>
            {punchList.data &&
              punchList.data.map((item) => {
                return (
                  <>
                    <Grid2>
                      <TextField
                        required
                        label="Transaction"
                        name="transaction"
                        inputProps={{
                          value: dateToLocalConverter(item.transactionDate),
                          type: "datetime-local",
                          readOnly: true,
                          disabled: true,
                        }}
                      />

                      <TextField
                        label="Type"
                        inputProps={{
                          value: types3
                            .filter(
                              (type) => type.typeId == item["typeId"].toString()
                            )
                            .map((type) => {
                              return type.name;
                            }),
                          readOnly: true,
                          disabled: true,
                        }}
                      />
                      <Sbutton
                        className=".kuMHGW"
                        onClick={() => {
                          item["typeId"] == 1
                            ? setPunchIn(
                                dateToLocalConverter(item.transactionDate)
                              )
                            : setPunchOut(
                                dateToLocalConverter(item.transactionDate)
                              );
                        }}
                      >
                        use
                      </Sbutton>
                    </Grid2>
                  </>
                );
              })}
          </>
        </Card>
      )}

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </Button>

        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(AttendanceForm);
