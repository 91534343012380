import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Transaction() {


  return <Layout><Outlet /></Layout>;
}

export default Transaction;
