import React from "react";
import styled from "styled-components";
import logo from "../../assets/punchapp-logo.svg";

const Grid = styled.div`
  img {
    display: block;
    margin: auto;
    width: 300px;
    margin-top: 20px;
  }
  h1 {
    margin-top: 0;
    text-align: center;
    margin-top: 20px;
  }
  div {
    max-width: 1330px;
    margin: auto;
    margin-top: 20px;
  }
  p {
    width: 98%;
    margin-left: auto;
    font-size: 22px;
  }
  span {
    display: block;
    width: 98%;
    margin-left: auto;
  }
`;

function PrivacyPolicy() {
  return (
    <Grid>
      <img src={logo} />
      <h1>Privacy and Terms</h1>
      <div>
        <h3>1. ACCEPTANCE OF TERMS OF SERVICE :</h3>
        <p>
          We offer services optimized for mobile devices. These services are
          available as a browser-based application as well as a native
          application for Ios and Android (“Services”). By visiting the website
          or the corresponding WAP site (“sites”) and/or registering the
          Services, you accept that you are entering into a binding contract
          with us governed by the Terms of Service and Privacy Policy. Please
          take the time to read and understand the Terms of Service. If you do
          not agree with the Terms of service as well as the Privacy Policy,
          please don’t use the sites or any of the services.
        </p>
      </div>

      <div>
        <h3>2. ACCESS AND AVAILABILITY :</h3>
        <p>
          In order to make the most out of our Service you must have the
          necessary mobile communication means with easy internet access. Some
          wireless applications are only available for a select number of mobile
          devices. This is an issue with its manufacturers, hence out of the
          control of the Provider. To prevent disappointment, we ask you to
          check your device's compatibility before subscribing to the Service.
        </p>
      </div>

      <div>
        <h3>3. SECURITY AND REGISTRATION OBLIGATION :</h3>
        <p>
          You are responsible for maintaining the secrecy of any password and/or
          account issued to you by the Provider. You are fully liable for all
          actions involving the use of your password or account.
        </p>
      </div>

      <div>
        <h3>4. CHANGES OF THE SERVICES :</h3>
        <p>
          We reserve the right to upgrade, amend, suspend, or discontinue the
          Services without prior notification at any time. You agree that we
          shall not be liable to you or to any third party for any amendment,
          suspension, or discontinuance of the Services.
        </p>
      </div>

      <div>
        <h3>5. CODE OF CONDUCT :</h3>
        <p>
          You agree to use the Service in accordance with the following Code of
          Conduct:
          <span>a. You will not use the Services for any illegal purposes</span>
          <span>
            b. You will not use the Service to engage in any form of harassment,
            including but not limited to the posting of communications,
            pictures, or recordings which contain abusive, racist, pornographic,
            or offensive language or images
          </span>
          <span>
            c. You will not reproduce, copy, sell, or resell the Service in
            whole or in part
          </span>
        </p>
      </div>

      <div>
        <h3>6. PRIVACY POLICY :</h3>
        <p>
          Our use of your personal information is governed by our Privacy
          Policy. Our Privacy Policy forms an integral part of the Terms of
          Service, and by agreeing to the Terms of Service, you also consent to
          how we may handle your personal information.{" "}
        </p>
      </div>

      <div>
        <h3>7. INDEMNIFICATION :</h3>
        <p>
          You agree to indemnify, defend, and hold harmless Provider, its
          subsidiaries, related parties, affiliates, and their representative
          and employees from and against any and all claims, lawsuits, demands,
          actions, or other proceedings brought against it by any third party
          due to, arising out of or related to (i) the use of the service, the
          content, the downloads, without limitation, (ii) violation of the
          Terms of Service or (iii) violation of any law, regulation or third
          party rights.{" "}
        </p>
      </div>

      <div>
        <h3>8. ADVERTISING :</h3>
        <p>
          As far as your country of residence allows us, we may use
          advertisements and promotions. As a condition of using the Services,
          you agree that we may display advertisements and other promotions on
          our sites and deliver advertisements and promotions on your mobile
          device or otherwise in connection with our Service. You also agree
          that you will not attempt to block or otherwise interfere with such
          advertisements or promotions. We will not share your personal details
          with third parties without your consent. You have the right to ask us
          at any time not to contact you by way of direct marketing. The
          inclusion of any advertising or promotion on our sites or in our
          Service does not constitute any endorsement by the Provider of such
          content, product, service, or company. Provider shall not be a party
          to, or in any way responsible for, any transaction concerning products
          or services made available from such third parties or for any content
          or information presented in connection with any products or services
          of third parties. You agree that Provider is not liable for any damage
          of any nature, whatever may result from such transactions.
        </p>
      </div>

      <div>
        <h3>9. INTELLECTUAL PROPERTY RIGHTS :</h3>
        <p>
          You are aware and agree that the software used in connection with the
          Service, the Sites, and the Services contains information and marks
          protected by valid and applicable copyright law, trade secret law,
          trademark law, and any other intellectual property rights and laws.
          Except as otherwise set forth herein, all now known and hereafter
          rights of every nature worldwide pertaining to the Sites, Services,
          and Software in or as part of any version, belong to us at all times
          (“Intellectual Property Right”). You agree to make no claim of
          interest in or ownership of any Intellectual Property Rights and you
          acknowledge that no title to the Intellectual Property Rights is
          transferred to you and that you do not obtain any rights, express or
          implied, other than the rights expressly granted in the Terms of
          Service. You guarantee that you will not amend, rent, rent out, lend,
          lease, borrow, loan, sell, distribute, create, or generate content or
          products partially or entirely derived from the Service or the
          Software except in the event that Provider has given you explicit
          written permission to do so. Provider hereby grants you, and you
          hereby accept, a personal, limited, non-transferable, non-exclusive,
          revocable and non-assignable license and permission to install the
          Software and download the content to use the Service on a designated
          compatible mobile device solely for your own personal non-commercial
          use and to use the Software and transfer the Sites solely in
          accordance with the Terms of Service, as long as you do not copy,
          alter or amend any Software, source coders or content, or reproduce,
          modify, perform, transfer, distribute, sell, resell, create a derived
          product or content from it, or carry out reverse engineer or reverse
          assembly those or otherwise attempt to find a source code, and you
          agree not to sell any rights related to the content, the software and
          the Provider hereby grants you, and you hereby accept, a personal,
          limited, non-transferable, non-exclusive, revocable and non-assignable
          license and permission to install the Software and download the
          content to use the Service on a designated compatible mobile device
          solely for your own personal non-commercial use and to use the
          Software and the Sites solely in accordance with the Terms of Service,
          as long as you do not copy, alter or amend any Software, source codes
          or content, or reproduce, modify, perform, transfer, distribute, sell,
          resell, create a derived product or content from it, or carry out
          reverse engineer or reverse assembly those, or otherwise attempt to
          find a source code, and you agree not to sell any rights related to
          the content, the Software and the Intellectual Property Rights, code
          those, issue sub-licenses for those, encumber those with security
          rights or otherwise transfer those. You guarantee that you will not
          amend the content, the Software, and Intellectual Property Rights in
          any way or use amended versions of the Software and Intellectual
          Property Rights, including (but not limited to) in order to gain
          unauthorized access to the Service. You guarantee that you will only
          use the Provider’s interface to access the Service. Provider herewith
          grants permission to make only one copy of the information on the
          equipment you use for gaining access to the Service and to use and
          display the copy of the Registered Information made on that equipment
          for private purposes. We aim to respect the intellectual property
          rights of others. If you are a copyright owner or authorized to act on
          behalf of one, or authorized to act under any exclusive right under
          copyright, and you believe that any content on the Sites or Services
          infringes your copyright, you may submit Provider’s designated
          copyright agent a copyright infringement notification by providing the
          following information:
          <span>
            a. Identify the copyrighted work that you claim has been infringed,
            or if multiple copyrighted works are covered on a single Site and/or
            Service, you may provide a list of the copyrighted works that you
            claim have been infringed.
          </span>
          <span>
            b. Identify the material that you claim is infringing or will be the
            subject of infringing activity and that has to be removed, and
            provide access and information reasonably sufficient to permit
            Provider to locate the material and disable it;
          </span>
          <span>
            c. Provide your contact information so that we can contact you such
            as your address, telephone number, and email address.
          </span>
          <span>
            d. The statement that you have identified material on the Sites
            and/or Services that infringes your copyright;
          </span>
          <span>
            e. The statement that you have a good faith belief that the disputed
            use of the copyrighted material or reference or link to such
            material is not authorized by the copyright owner, its agent, or the
            law;
          </span>
          <span>
            f. The statement that the information in the notification is
            accurate and, under penalty of perjury, that you are the owner, or
            authorized to act on behalf of the owner, of the copyright or of an
            exclusive right under the copyright that is allegedly infringed; and
          </span>
          <span>
            g. Provide your full legal name and your electronic or physical
            signature.
          </span>
          Deliver the completed notification to us by e-mail. Upon receipt of
          the notification, Provider will take whatever action, in its sole
          discretion, it deems appropriate, including removal of the challenged
          material from the Site and/or Services. If you are unsure about your
          rights in respect of the material, or whether there has been a
          copyright infringement, we suggest you take legal advice before
          sending a notification to the Provider.
        </p>
      </div>

      <div>
        <h3>10. NO WARRANTY :</h3>
        <p>
          WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, SUBSIDIARIES,
          AFFILIATES, AGENTS, AND LICENSORS (THE "PROVIDER PARTIES") DISCLAIM
          ANY RESPONSIBILITY FOR ANY HARM RESULTING FROM YOUR USE OF THE
          SERVICE, THE CONTENT, THIS SOFTWARE, AND SITES. YOU EXPRESSLY
          UNDERSTAND AND AGREE THAT:
          <span>
            a. THE SERVICE, THE CONTENT, THE SOFTWARE, AND SITES ARE PROVIDED ON
            AN "AS IS" AND "AS AVAILABLE" BASIS WITH NO WARRANTIES WHATSOEVER.
            THE PROVIDER PARTIES EXPRESSLY DISCLAIM TO THE FULLEST EXTENT
            PERMITTED BY LAW ALL WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY,
            OR OTHERWISE, WITH RESPECT TO THE SERVICE, THE CONTENT, SOFTWARE,
            AND SITES, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT OF PROPRIETARY RIGHTS.
          </span>
          <span>
            b. THE PROVIDER PARTIES DISCLAIM ANY WARRANTIES REGARDING THE
            SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THE SERVICE,
            THE CONTENT, SOFTWARE, AND SITES. THE PROVIDER PARTIES DO NOT
            WARRANT THAT THE SERVICE, THE CONTENT, SOFTWARE, AND SITES WILL MEET
            YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SERVICE, THE CONTENT,
            THE SOFTWARE, AND THE SITES WILL BE UNINTERRUPTED AND ERROR-FREE.
          </span>
          <span>
            c. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
            YOU FROM US OR THROUGH OUR SERVICES SHALL CREATE ANY WARRANTY NOT
            EXPRESSLY MADE HEREIN. YOU MAY NOT RELY ON ANY SUCH INFORMATION OR
            ADVICE;
          </span>
          <span>
            d. YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD AND/OR USE THE
            SERVICE, THE CONTENT, SOFTWARE, AND SITES AT YOUR OWN DISCRETION AND
            RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR
            COMPUTER SYSTEM, MOBILE PHONE, DEVICE OR LOSS OF DATA THAT RESULT
            FROM THE DOWNLOAD OR USE OF THE CONTENT, THIS SOFTWARE, AND SITES
          </span>
          <span>
            e. WE ARE NOT RESPONSIBLE FOR ANY MISCHARACTERIZATIONS OR
            MISREPRESENTATIONS OF THE IDEAS OR FACTS AS PRESENTED IN THE CONTENT
            AND/OR SITES.
          </span>
        </p>
      </div>

      <div>
        <h3>11. LIMITATION OF LIABILITY :</h3>
        <p>
          YOU EXPRESSLY AGREE THAT UNDER NO CIRCUMSTANCES SHALL THE PROVIDER
          PARTIES BE LIABLE TO YOU OR A THIRD PARTY FOR ANY DAMAGES, INCLUDING,
          WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
          PUNITIVE, THIRD PARTY OR CONSEQUENTIAL (INCLUDING DAMAGES FOR LOSS OF
          BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION
          AND THE LIKE) ARISING OUT OF YOUR USE, MISUSE OR INABILITY TO USE THE
          SERVICE, THE CONTENT, SOFTWARE, AND SITES, EVEN IF PROVIDER'S
          AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES (INCLUDING DAMAGES INCURRED BY THIRD PARTIES). SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE FOREGOING LIMITATION
          OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE PROVIDER
          PARTIES' TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
          ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE)
          EXCEED THE AMOUNT PAID BY YOU TO REGISTER FOR THE SERVICE, THE
          CONTENT, THE SOFTWARE, AND SITES.
        </p>
      </div>

      <div>
        <h3>12. GENERAL INFORMATION :</h3>
        <p>
          These Terms of Service form the entire legal agreement and
          understanding between you and Provider and supersede all previous
          agreements, understandings, and representations between you and
          Provider relating to the Sites or the Services. You agree that no
          joint venture, partnership, employment, or agency relationship exists
          between you and us because of the Terms of Service or the use of the
          Sites or the Services. You may not transfer any of your rights or
          delegate any of your obligations under this agreement without our
          prior written consent. Nothing in this agreement affects your
          statutory right as a consumer.
        </p>
      </div>

      <div style={{marginBottom:'30px'}}>
        <h3>13. GOVERNING LAW, JURISDICTION, AND SEVERABILITY :</h3>
        <p>
          National laws from your country of residence are applicable to the
          relationship between you and Provider, regardless of provisions of
          applicable international law. You and Provider hereby waive any right
          to a jury trial with respect to any action brought in connection with
          the Terms of Service. The application of the United Nations Convention
          of Contracts for the International Sale of Goods is expressly
          excluded. Disputes will be submitted to final and binding arbitration
          under the rules of the arbitration association in your country. Any
          failure by Provider to exercise or invoke certain rights or
          stipulations laid down in these Terms of Service in no way constitutes
          a waiver or renunciation of those rights or stipulations. In case any
          provision of the Terms of Service is determined to be invalid or
          unenforceable by a court of competent jurisdiction or appointed
          arbitrator, such determination shall in no way affect the validity or
          enforceability of any other provision herein.
        </p>
      </div>
    </Grid>
  );
}

export default PrivacyPolicy;
