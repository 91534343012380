import React, { useCallback, useEffect, useRef } from "react";
import { Button, Flex, Select, TextField } from "../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import { useProject } from "./provider";
import { useNavigate, useParams } from "react-router-dom";

const schema = Yup.object().shape({
  companyId: Yup.number().nullable().transform(emptyStringToNull),
  search: Yup.string(),
  pagesize: Yup.number().nullable().transform(emptyStringToNull),
});

function FilterForm({ setFilters }) {
  let navigate = useNavigate();
  const { cid } = useParams();

  const {
    state: { filters },
  } = useProject();

  const {
    state: { list: companies, selected },
    fetchOne: fetchCompany,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyId: cid,
      search: "",
      pagesize: 150,
    },
  });

  const watchCompanyId = watch("companyId");

  useEffect(() => {
    fetchCompany(cid);
  }, [fetchCompany, cid]);

  const onSubmit = async (values) => {
    setFilters(values);
  };

  let statusEnum = [
    { id: -1, status: "All" },
    { id: true, status: "Active" },
    { id: false, status: "InActive" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Flex jc="flex-start" ai="flex-end" gap={20}>
        <TextField
          label="Company"
          value={selected?.businessName}
          style={{ marginBottom: 0 }}
          inputProps={{
            value: selected?.businessName,
            readOnly: true,
            disabled: true,
            style: { height: 60 },
          }}
        />
        <TextField
          style={{ marginBottom: 0 }}
          label="Search"
          name="search"
          error={!!errors.search}
          msg={errors.search?.message}
          inputProps={{
            ...register("search"),
            style: { height: 60 },
          }}
        />

        <Select
          style={{
            width: "220px",
          }}
          data={statusEnum}
          mapOptions={{ value: "id", label: "status" }}
          onChange={useCallback(
            (data) => setValue("isActive", data.value),
            [setValue]
          )}
          label={"status"}
          defaultValue={-1}
        />

        <div>
          <Select
            style={{
              maxWidth: 120,
            }}
            data={[10, 50, 100, 150]}
            label="Display"
            onChange={useCallback(
              (data) => setValue("pagesize", data.value),
              [setValue]
            )}
            defaultValue={getValues("pagesize") || ""}
          />
        </div>

        <Button
          data-testid="save-button"
          type="submit"
          // iconName="Eye"
          className="small"
          loading={isSubmitting}
          style={{ height: 60 }}
        >
          Apply
        </Button>
      </Flex>
    </form>
  );
}

export default FilterForm;
