import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Report() {

  return <Layout><Outlet /></Layout>;
}

export default Report;
