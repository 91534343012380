import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Checkbox, Divider, Icon, Select } from "../../../ui";
import { useCompanies } from "../../companies/provider";
import { useAccounts } from "../provider";
import { useForm } from "react-hook-form";
import { createYupSchema } from "../../../utilities/yupSchemaCreator";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import ErrorsList from "../../../ui/ErrorsList";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  & > div {
    margin-top: 10px;
  }
  .check {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;
function CloneAccounts() {
  const { id } = useParams();
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const {
    state: { list: companies, status },
    fetchList: fetchCompanies,
  } = useCompanies();

  const { cloneChartOfAccount } = useAccounts();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const schema = Yup.object().shape({
    sourceCompanyId: Yup.string().required(),
    targetCompanyId: Yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (v) => {
    try {
      await cloneChartOfAccount(v);
      navigate("/accounting");
    } catch (e) {
      setError({ title: e.errors.message });
    }
  };

  if (!companies) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <div id="set-as-hr">
        <Grid>
          <h2 style={{ marginTop: "0" }}>Clone Chart Of Accounts</h2>
          <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <span>Back</span>
            <Icon name="arrowLeft" />
          </div>
        </Grid>
        <Grid2>
          <div>
            <Select
              data={companies}
              mapOptions={{ value: "id", label: "businessName" }}
              onChange={(e) => setValue("sourceCompanyId", e.value)}
              label="source company"
              error={!!errors["sourceCompanyId"]}
              msg={errors["sourceCompanyId"]?.message}
              inputProps={{
                name: "sourceCompanyId",
              }}
            />
          </div>
          <div>
            <Select
              data={companies}
              mapOptions={{ value: "id", label: "businessName" }}
              label="target company"
              onChange={(e) => setValue("targetCompanyId", e.value)}
              error={!!errors["targetCompanyId"]}
              msg={errors["targetCompanyId"]?.message}
              inputProps={{
                name: "targetCompanyId",
              }}
            />
          </div>
          <div className="check">
            <Checkbox
              id="onlyActiveAccounts"
              name="onlyActiveAccounts"
              label="only Active Accounts"
              inputProps={{
                ...register("onlyActiveAccounts"),
              }}
            />
          </div>
          <Divider />
          <Button loading={Boolean(status === "cloning")}>Submit</Button>
        </Grid2>
      </div>
    </form>
  );
}

export default CloneAccounts;
