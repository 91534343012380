import styled from "styled-components";
import { firstUp } from "../../utilities/functions";

const StyledOptions = styled.div`
  background-color: white;
  border: #c2c2c2 1px solid;
  border-radius: 15px;
  padding: 8px 0;
  width: ${(props) => props.w + "px" || "auto"};
  ul {
    padding: 10px 0 10px 0;
    margin: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    li {
      cursor: pointer;
      padding: 0 50px 0 20px;
      &:hover,
      &.selected {
        background-color: ${(props) => props.theme.color.primary};
        color: white;
      }
    }
  }
`;

function Options({ data, selected, term, onSelect, w, multi }) {
  const optionsList = data
    .filter((item) => {
      if (item.data.pinned) return term;
      else return item?.name?.toLowerCase().startsWith(term.toLowerCase());
    })
    .sort((a, b) => {
      if (!multi) return 0;
      if (selected.map((item) => item.value).includes(a.value)) return -1;
      else return 1;
    })
    .map((item, i) => {
      if (!data.length) return "";

      return (
        <li
          onClick={() => onSelect(item)}
          className={
            selected.map((s) => s.value).includes(item.value) ? "selected" : ""
          }
          key={i}
        >
          {firstUp(item.name || "")}
        </li>
      );
    });

  return (
    <StyledOptions w={w + 50}>
      <ul>{optionsList}</ul>
    </StyledOptions>
  );
}

export default Options;
