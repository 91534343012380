import React from "react";

function AccountNameCell(data) {
  const getStyles = () => {
    if (data.isGroup)
      return {
        textDecoration: "underline",
        fontSize: "19px",
        fontWeight: "bold",
      };
    else if (
      data.account == "Total Revenue" ||
      data.account == "Total Expenses" ||
      data.account == "net value" ||
      data.account == "SUM "
    )
      return {
        background: "#ccc",
        fontWeight: "bold",
        fontSize: "17px",
        width: "fit-content",
      };
  };

  return <div style={getStyles()}>{data.account}</div>;
}

export default AccountNameCell;
