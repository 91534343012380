import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useAttendance() {
  return useContext(Context);
}


const initState = {
  list: [],
  mlist: [],
  mmlist: [],
  reportList: [],
  ereportList: [],
  creportList: [],

  brek: null,
  status: "idle",
  error: null,
  punchList: [],
  page: 0,
  erpage: 0,
  rpage: 0,
  cpage: 0,
  selected: null,
  perPage: 10,
  filters:{},
  filters2:{},
  isSync: null,
  onePunch: null,
  count: null,
  ecount: null,
  ercount: null,
  ccount: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_mlist":
      return { ...state, mlist: action.payload };
    case "set_mmlist":
      return { ...state, mmlist: action.payload };
    case "set_lastSync":
      return { ...state, isSync: action.payload };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_rcount":
      return { ...state, rcount: action.payload };
    case "set_ercount":
      return { ...state, ercount: action.payload };
    case "status":
      return { ...state, status: action.payload };

    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      return { ...state, selected: { ...action.payload } };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_rpage":
      return { ...state, rpage: action.payload };
    case "set_erpage":
      return { ...state, erpage: action.payload };
    case "set_crpage":
      return { ...state, crpage: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_onePunch":
      return { ...state, onePunch: { ...action.payload } };
    case "set_emps_list":
      return { ...state, emps: { ...action.payload } };
    case "set_deps_list":
      return { ...state, deps: { ...action.payload } };
    case "set_punch":
      return { ...state, punchList: { ...action.payload } };
    case "set_break":
      return { ...state, brek: action.payload };
    case "set_report":
      return { ...state, reportList: action.payload };

    case "set_ereport":
      return { ...state, ereportList: action.payload };
      case "set_creport":
        return { ...state, creportList: action.payload };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };

    case "compnayIDchange":
      return { ...state, companyID: action.payload };
    case "departmentIDchange":
      return { ...state, departmentID: action.payload };

    case "employeeIDchange":
      return { ...state, employeeID: action.payload };

    case "from":
      return { ...state, absFrom: action.payload };
    case "to":
      console.log(action.payload, " action.payload");
      return { ...state, absTo: action.payload };
      case "typeIDchange":
        return { ...state, typeID: action.payload };
    case "search":
      return { ...state, search: action.payload };

      case "set_filters":
        return { ...state, filters: { ...state.filters, ...action.payload } };
        case "set_filters2":
          return { ...state, filters2: { ...state.filters, ...action.payload } };
        default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function AttendanceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/UpdateWorkShift/${id}`,
            data,
            { method: "PUT" },
            true
          );
          //dispatch({ type: 'add', payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const editMachine = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/UpdateMachinePunch/${id}`,
            data,
            { method: "PUT" },
            true
          );
          console.log(resData, "ss");
          //dispatch({ type: 'add', payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const editBreak = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/UpdateBreak/${id}`,
            data,
            { method: "PUT" },
            true
          );
          //dispatch({ type: 'add', payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const UpdatePunch = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/UpdateBreak/${id}`,
            data,
            { method: "PUT" },
            true
          );
          //dispatch({ type: 'add', payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const getParams = (Cid, Did, Eid, AFdate, ATdate, query) => {
    let urlParams = `?${query}`;
    ATdate && (urlParams = `/${ATdate}` + urlParams);
    AFdate && (urlParams = `/${AFdate}` + urlParams);

    Eid && (urlParams = `/${Eid}` + urlParams);

    Did && (urlParams = `/${Did}` + urlParams);

    Cid && (urlParams = `/${Cid}` + urlParams);

    return urlParams;
  };

  const fetchList = useCallback(
    async (
      headersData
    ) => {
      dispatch({ type: "status", payload: "fetching" });
      if(headersData.departmentId < 0 || headersData.departmentId== null ) headersData.departmentId =0
      if(headersData.employeeId < 0 || headersData.employeeId== null) headersData.employeeId =0

      const defaultHeadersData = {
        ...{ pagesize: 10 , pagenumber: 1 },
        ...headersData,
      };
      const resData = await req(`Attendance/List`, null, {}, true, defaultHeadersData);


      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.attendances });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  //reports
  const fetchReportAttendance = useCallback(
    async (
      headersData,page
    ) => {
      dispatch({ type: "status", payload: "fetching" });

      
      if(headersData.departmentId < 0 || headersData.departmentId== null ) headersData.departmentId =0
      if(headersData.employeeId < 0 || headersData.employeeId== null) headersData.employeeId =0

      console.log(headersData , 'dara');
      const defaultHeadersData = {
        ...{ pagesize: 150 , pagenumber:page+ 1 },
        ...headersData,
      };
 
      const resData = await req(
        `Report/Attendance`,
        null,
        {},
        true,
        defaultHeadersData
      );
      if (!resData || resData.status == 400) {
        window.location.reload();
      }

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_report", payload: resData.data });
      dispatch({ type: "set_rcount", payload: resData.data?.totalSize });
    },
    [req]
  );

  const fetchMachineList = useCallback(
    async (headersData) => {
      dispatch({ type: "status", payload: "fetching" });
      if(headersData.departmentId < 0 || headersData.departmentId== null ) headersData.departmentId =0
      if(headersData.employeeId < 0 || headersData.employeeId== null) headersData.employeeId =0

      const resData = await req(
        `Attendance/MachinePunchList`,
        null,
        {},
        true,
        headersData
      );
      console.log(resData, "now");
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_lastSync",
        payload: resData?.data.isSyncAppRunning,
      });

      dispatch({ type: "set_mlist", payload: resData?.data.items || [] });

      dispatch({ type: "set_count", payload: resData.data?.totalSize });
    },
    [req]
  );

  const fetchEmployeeAttendanceList = useCallback(
    async (Cid, Did, Eid, AFdate, ATdate) => {
      dispatch({ type: "status", payload: "fetching" });
      var d = new Date();
      let now =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1).padLeft() +
        "-" +
        d.getDate().padLeft();

      let headersData = {
        pageSize: 150,
      };
      if (Cid) headersData.companyId = Cid;
      if (Did) headersData.departmentId = Did;
      if (AFdate != 0 && AFdate) headersData.FromDate = AFdate;
      headersData.ToDate = ATdate || now;


      const resData = await req(
        `Report/EmployeeAttendance/${Eid}`,
        null,
        {},
        true,
        headersData
      );
      console.log(resData, "now");
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_ereport", payload: resData?.data || [] });
      dispatch({ type: "set_count", payload: resData.data?.totalSize });
    },
    [req]
  );

  const fetchCurrentAttendance = useCallback(
    async (Cid, Did , typeID) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageSize: 150,
      };
      if (Cid) headersData.companyId = Cid;
      if (Did) headersData.departmentId = Did;
      if (typeID) headersData.transactionTypeId = typeID;

      const resData = await req(
        `Attendance/Current`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_creport", payload: resData?.data || [] });
      dispatch({ type: "set_count", payload: resData.data?.totalSize });
    },
    [req]
  );

  const createAttendanceFromMachine = useCallback(
    async (Eid, data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {};
      const resData = await req(
        `Attendance/CreateFromMachine/${Eid}`,
        data,
        { method: "PUT" },
        true
      );

      dispatch({ type: "status", payload: `idle` });
      //dispatch({ type: 'set_list', payload: resData.data });
      //dispatch({ type: 'set_count', payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        const resData = await req(
          `Attendance/WorkShiftInfo/${id}`,
          null,
          {},
          true
        );
        dispatch({ type: "set_selected", payload: resData.data });
        resolve(resData.data);
      });
    },
    [req, state.status]
  );

  const fetchOnePunch = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        const resData = await req(
          `Attendance/MachinePunchInfo/${id}`,
          null,
          {},
          true
        );
        dispatch({ type: "set_onePunch", payload: resData.data });
        resolve(resData.data);
      });
    },
    [req, state.status]
  );

  const fetchBreak = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Attendance/BreakInfo/${id}`, null, {}, true);
        dispatch({ type: "set_break", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const fetchPunchList = useCallback(
    (id, date, d) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        let headersData = {
          dayDate: date,
          punchTypeId: d,
        };
        const resData = await req(
          `Attendance/PunchList/${id}`,
          null,
          {},
          true,
          headersData
        );
        dispatch({ type: "set_punch", payload: resData });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/CreateWorkShift`,
            data,
            { method: "POST" },
            true
          );
          //dispatch({ type: 'edit', payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const createBreak = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Attendance/AddBreak`,
            data,
            { method: "POST" },
            true
          );
          //dispatch({ type: 'edit', payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  //fetch employee list
  const fetchEmpList = useCallback(
    async (cid, page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1},page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(`Employee/List`, null, { query }, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_emps_list", payload: resData.data.employees });
    },
    [req]
  );

  const fetchDEPSList = useCallback(
    async (cid, page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(
        `Department/List/${cid}?${query}`,
        null,
        {},
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_deps_list", payload: resData.data });
      // dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(
        `Attendance/DeleteWorkShift/${id}`,
        {},
        { method: "DELETE" },
        true
      );
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const removeBreak = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Attendance/DeleteBreak/${id}`, {}, { method: "PUT" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);


  const setFilters2 = useCallback((filters) => {
    dispatch({ type: "set_filters2", payload: filters });
  }, []);



  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        fetchEmpList,
        fetchDEPSList,
        remove,
        fetchPunchList,
        fetchMachineList,
        createAttendanceFromMachine,
        fetchReportAttendance,
        createBreak,
        fetchBreak,
        editBreak,
        removeBreak,
        editMachine,
        fetchEmployeeAttendanceList,
        fetchOnePunch,
        fetchCurrentAttendance,

        setFilters,
        setFilters2


      }}
    >
      {children}
    </Context.Provider>
  );
}
