export const accountsStatus = [
  { id: 0, status: "NONE" },
  { id: 1, status: "CREDIT" },
  { id: 2, status: "DEBIT" },
  { id: 3, status: "BOTH" },
];

export const accountType = [
  { id: 1, type: "ASSET" },
  { id: 2, type: "LIABILITY" },
  { id: 3, type: "REVENUE" },
  { id: 4, type: "EXPENSE" },
  { id: 5, type: "STAKEHOLDERS_EQUITY" },
];

export const specialType = [
  { id: 0, type: "NONE" },
  { id: 1, type: "CACHE" },
];

export const ActiveEnum = [
  { id: 1, type: "Active" },
  { id: 0, type: "not Active" },
];

export const MethodEnum = [
  { id: 0, method: "BY_BALANCE" },
  { id: 1, method: "BY_TOTALS" },
];
