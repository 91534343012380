import styled from "styled-components";

const List = styled.dl`
    margin: 0;
    padding: 10px 0;
    list-style: none;
    dt {
        border-bottom: ${props => props.theme.color.grayBg} 1px solid;
    }
    dd{
        margin-left: 0;
    }
`

export default List;