var apiUrl;
var notificationUrl;
if (process.env.REACT_APP_ENV === "production") {
  apiUrl = "https://api.hr-portals.com/api/v1";
  notificationUrl = "https://api.hr-portals.com/";
} else if (process.env.REACT_APP_ENV === "sandbox") {
  apiUrl = "https://sandbox.api.hr-portals.com/api/v1";
  notificationUrl = "https://sandbox.api.hr-portals.com/";
} else {
  apiUrl = "https://staging.api.hr-portals.com/api/v1";
  notificationUrl = "https://staging.api.hr-portals.com/";
}

export { apiUrl, notificationUrl };
