import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import FilterForm from "./FilterForm";
import { useOrders } from "./provider";
import StatusCell from "./table/StatusCell";
import OffersCountCell from "./table/OffersCountCell";
import { usePurches } from "../purches/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function OrdersList() {
  //define stuff
  const navigate = useNavigate();
  const { notify } = useUtils();

  //fetch leaves
  const {
    state: { companyID, departmentID, employeeID },
    dispatch: dispatchPurches,
  } = usePurches();

  //fetching company
  const [selectedstatus, setSelectedStatus] = useState(null);
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const [isPO, setIsPO] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes("HR") && setIsPO(false);
  }, []);
  useEffect(() => {
    if (companies && !companyID)
      dispatchPurches({ type: "companychange", payload: companies[0]?.id });
  }, [companies]);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    !isPO && fetchCompanies();
  }, [fetchCompanies]);

  // handle add new leave button
  const handleClick = () => {
    navigate("/signers", { state: { id: companyID } });
  };

  //fetch leaves
  const {
    state: { list, selected, status, orderS, count, page, perPage },
    fetchList: fetchOrders,
    fetchOne,
    setStatus,
    remove,
    dispatch,
  } = useOrders();
  useEffect(() => {
    dispatch({ type: "set_selected", payload: null });
  }, []);

  //cols
  const cols = [
    { name: "id", label: "ID Number" },
    { name: "description", label: "Description" },
    { name: "status", label: "status", render: StatusCell },
    { name: "offersCount", label: "Offers Count", render: OffersCountCell },
  ];

  const handleView = (id) => {
    navigate(`/orders/view/${id.id}`);
  };

  const handleEdit = async (id) => {
    navigate(`/orders/edit/${id}`);
  };

  const handleProcessing = (id) => {
    setStatus(id, 2);
  };
  const handleDone = (id) => {
    setStatus(id, 3);
  };
  const handleCancelled = (id) => {
    setStatus(id, 4);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("order has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };
  if (!companyID && !isPO) return;
  return (
    <>
      <Grid>
        <h2>Orders List</h2>
        <Button
          className="small"
          onClick={() => navigate("/supliers")}
          iconName="invoice"
        >
          Supliers
        </Button>

        {!isPO && (
          <Button className="small" onClick={handleClick} iconName="invoice">
            Signees
          </Button>
        )}
        <Button
          className="small"
          onClick={() => {
            navigate("/orders/newOrder/" + companyID);
          }}
          iconName="invoice"
        >
          New Entry
        </Button>
      </Grid>

      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm
          fetchOrders={fetchOrders}
          companyID={companyID}
          page={page}
          setSelectedStatus={setSelectedStatus}
          selectedstatus={selectedstatus}
          employeeID={employeeID}
          departmentID={departmentID}
        ></FilterForm>
      </Card>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={list}
        checkbox
        showButton={handleView}
        moreOptions={{
          edit: handleEdit,
          Processing: {
            label: "Processing",
            icon: "",
            fn: handleProcessing,
          },
          Done: {
            label: "Done",
            icon: "",
            fn: handleDone,
          },
          Cancelled: {
            label: "Cancelled ",
            icon: "",
            fn: handleCancelled,
          },
          delete: handleDelete,
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default OrdersList;
