import React from 'react'
import { toDateTime } from '../../../utilities/functions'

function StartdateCell(data) {
    return (
    <div>
        {
            toDateTime(data?.start , {time : false , short: true})
        }
    </div>
  )
}

export default StartdateCell