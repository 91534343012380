import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../ui/TableContainer";
import { useUserAccounts } from "../provider";
import { useUtils } from "../../../services/utilsProvider";
import { Pagination } from "../../../ui";

const cols = [
  { name: "employeeName", label: "Employee Name" },
  { name: "companyName", label: "Company Name" },
  { name: "roles", fn: (v) => v.join(", "), label: "Roles" },
  { name: "username", label: "Username" },
];

function ListEmployees({ company }) {
  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();
  const {
    state: { employees, eStatus, ecount, epage, perPage },
    fetchEmployees,
    setAsPO,
    editAcc,
    dispatch,
  } = useUserAccounts();

  useEffect(() => {
    if (company.id) fetchEmployees(company.id, epage + 1);
  }, [fetchEmployees, company.id, epage]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      dispatch({ type: "set_epage", payload: event });
    },

    [dispatch]
  );

  const handleSetHR = useCallback(
    (id) => {
      navigate(`/accounts/set-hr/employee/${id}`);
    },
    [navigate]
  );

  const handleSetPO = useCallback(
    async (id) => {
      try {
        await setAsPO(id);
        notify("a Po Privilege has been granted successfully.");
        dispatch({ type: "pochangeStatus", payload: `changed` });
        dispatch({ type: "pochangeStatus", payload: `idle` });
      } catch (e) {
        alertUser("Already granted");
      }
    },
    [navigate]
  );

  const handleSetAccountant = useCallback(
    async (id) => {
      try {
        await editAcc(id, { grant: true });
        notify("an Accountant Role has been granted successfully.");
        dispatch({ type: "pochangeStatus", payload: `changed` });
        dispatch({ type: "pochangeStatus", payload: `idle` });
        dispatch({ type: "accountantsStatus", payload: `changed` });
        dispatch({ type: "accountantsStatus", payload: `idle` });
      } catch (e) {
        alertUser("Already granted");
      }
    },
    [navigate]
  );

  return (
    <div>
      <TableContainer
        empty={employees?.length === 0}
        status={eStatus}
        aria-label="Holidays table"
        cols={cols}
        data={employees}
        moreOptions={{
          setAsHR: {
            label: "Set as HR",
            icon: " ",
            fn: handleSetHR,
          },
          setAsPo: {
            label: "Set as PO",
            icon: "",
            fn: handleSetPO,
          },
          setAsAcc: {
            label: "Set as Accountant",
            icon: "",
            fn: handleSetAccountant,
          },
        }}
      />
      <Pagination
        data-testid="pagination"
        count={ecount}
        page={epage}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default memo(ListEmployees);
