import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Divider, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { usePayrolls } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import TableContainer from "../../ui/TableContainer";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import months from "../../data/months.json";
import { genYears } from "../../utilities/functions";
import Loading from "../../ui/Loading";
import { getValue } from "@testing-library/user-event/dist/utils";
import CurrencyCell from "./TableCell/CurrencyCell";

const schema = Yup.object().shape({
  month: Yup.number().transform(emptyStringToNull),
  year: Yup.number().transform(emptyStringToNull),
  companyId: Yup.number().transform(emptyStringToNull),
  employeePayrolls: Yup.array().of(
    Yup.object().shape({
      // bonus: Yup.number().transform(emptyStringToNull),
      // basicSalary: Yup.number().transform(emptyStringToNull),
      // employeeId: Yup.number().transform(emptyStringToNull),
      // id: Yup.number().transform(emptyStringToNull),
    })
  ),
});

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const EmployeeCell = (data) => {
  return <div>{data.ename}</div>;
};

const BasicSalaryCell = ({ basicSalary, index, onSalaryChange }) => {
  return (
    <TextField
      label=""
      inputProps={{
        value: basicSalary,
        onChange: (e) => onSalaryChange(e, index),
        required: true,
        type: "number",
      }}
    />
  );
};

const BonusCell = ({ bonus, index, onBonusChange }) => {
  return (
    <TextField
      label=""
      inputProps={{
        value: bonus,
        onChange: (e) => onBonusChange(e, index),
        required: true,
        type: "number",
      }}
    />
  );
};

function PayrollsForm({ payrolls, id, month, year, handleSuccess }) {
  const [error, setError] = useState(null);
  const [employeePayrolls, setEmployeePayrolls] = useState([]);
  const [companyId, setCompantId] = useState();

  const navigate = useNavigate();
  // let params = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: payrolls,
  });

  const {
    state: { status, selected: payroll },
    create,
  } = usePayrolls();

  useEffect(() => {
    setValue("employeePayrolls", employeePayrolls);
  }, [employeePayrolls, setValue]);

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  // useEffect(() => {
  //   if(!payrolls) return
  //   setValue('companyId', payrolls.companyId);
  //   setValue('month', payrolls.month);
  //   setValue('year', payrolls.year);
  //   setValue('employeePayrolls', payrolls.employeePayroll);
  // }, [payrolls, setValue])
  useEffect(() => {
    setEmployeePayrolls(
      payroll?.employeePayrolls?.map((e) => ({
        employeeId: e.employeeId,
        ename: e.employeeName,
        bonus: "",
        basicSalary: e.basicSalary,
        currencyInfo: e.currencyInfo,
      }))
    );
  }, [payrolls]);

  useEffect(() => {
    setEmployeePayrolls(
      payroll?.employeePayrolls?.map((e) => ({
        employeeId: e.employeeId,
        ename: e.employeeName,
        bonus: e.bonus || 0,
        basicSalary: e.basicSalary,
        currencyInfo: e.currencyInfo,
      }))
    );
  }, [employees, companyId, setEmployeePayrolls]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchEmployees(Number(id));
  }, [id, fetchEmployees]);

  useEffect(() => {
    setValue("companyId", companyId);
    fetchEmployees(companyId);
  }, [companyId, fetchEmployees, setValue]);

  const onSubmit = async (values) => {
    try {
      // console.log(payrolls);
      // return;
      delete values.companyName;
      values.employeePayrolls = values.employeePayrolls?.map((e) => {
        e.id = 0;
        delete e.totalCompensations;
        delete e.totalDeductions;
        delete e.netSalary;
        delete e.employeeName;
        delete e.ename;
        return e;
      });
      await create(values);
      reset();

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };

  const onSalaryChange = useCallback(
    (e, index) => {
      setEmployeePayrolls((payrolls) => {
        payrolls[index].basicSalary = e.target.value;
        setValue("employeePayrolls", [...payrolls]);
        return [...payrolls];
      });
    },
    [setValue]
  );

  const onBonusChange = useCallback(
    (e, index) => {
      setEmployeePayrolls((payrolls) => {
        payrolls[index].bonus = Number(e.target.value);
        setValue("employeePayrolls", [...payrolls]);
        return [...payrolls];
      });
    },
    [setValue]
  );

  let cols = [
    {
      name: "employeeId",
      label: "Name",
      render: EmployeeCell,
      renderParams: { employees },
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      render: BasicSalaryCell,
      renderParams: { onSalaryChange },
    },
    {
      name: "bonus",
      label: "Bonus",
      render: BonusCell,
      renderParams: { onBonusChange },
    },
    {
      name: "currency",
      label: "currency",
      render: CurrencyCell,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Flex>
          <Select
            data={companies}
            mapOptions={{ label: "businessName", value: "id" }}
            inputStyle={{ height: "48px", width: "260px" }}
            label="Company Name"
            name="companyId"
            defaultValue={Number(id)}
            //onChange={setRoute}
            onChange={(v) => setCompantId(v.value)}
            inputProps={{
              ...register("companyId"),
            }}
          />
          <Select
            inputStyle={{ height: "48px", width: "170px" }}
            data={months?.map((m) => ({ name: m.long, value: m.index }))}
            mapOptions={{ label: "name", value: "value" }}
            label="month"
            name="month"
            defaultValue={Number(month)}
            onChange={(v) => setValue("month", v.value)}
            inputProps={{
              ...register("month"),
            }}
          />
          <Select
            label="year"
            name="year"
            inputStyle={{ height: "48px", width: "110px" }}
            data={genYears(2000)}
            defaultValue={year}
            onChange={(v) => setValue("year", v.value)}
            //onChange={setRoute}
            inputProps={{
              ...register("year"),
            }}
          />
          {status === "fetching" && <Loading />}
        </Flex>
        <Divider h={30} />
        <TableContainer
          empty={!employeePayrolls?.length}
          aria-label="Leaves table"
          cols={cols}
          data={employeePayrolls}
        />
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          onClick={() => onSubmit}
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default PayrollsForm;
