import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import ListCompanies from "./ListCompanies";
import Companies from "./Comapnies";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import ViewCompany from "./ViewCompany";

export default (
  <Route
    path="companies"
    element={
      <RequireAuth>
        <Companies />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListCompanies />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddCompany />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditCompany />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewCompany />
        </RequireAuth>
      }
    />
  </Route>
);
