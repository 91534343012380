import styled from "styled-components";
import { Card, Icon } from "../../ui";

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: transparent
    linear-gradient(
      128deg,
      ${(props) => props.color1} 0%,
      ${(props) => props.color2} 100%
    )
    0% 0% no-repeat padding-box;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 20%;
  //   width: 72px;
  //   height: 72px;
  //   border-radius: 50%;
  //   transform: translate(0, -50%);
  //   background-color: white;
  //   opacity: 0.15;
  // }
  svg {
    height: 90px;
    fill: white;
    width: auto;
  }
  .text {
    color: white;
    line-height: 0;
    margin-left: 30px;
    span {
      display: block;
      &:first-child {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 40px;
      }
      &:last-child {
        font-size: 25px;
      }
    }
  }
`;

function StatCard({ iconName, title, subtitle, color1, color2 }) {
  return (
    <StyledCard {...{ color1, color2 }}>
      <Icon name={iconName} />
      <div className="text">
        <span>{title}</span>
        <span>{subtitle}</span>
      </div>
    </StyledCard>
  );
}

export default StatCard;
