import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Divider } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useCompanies } from "./provider";
import FileField from "../../ui/FileField";
import { useUtils } from "../../services/utilsProvider";

const Grid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr ;
  gap: 20px;
  row-gap: 0;
  margin-buttom: 20px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-top: 48px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const schema = Yup.object().shape({
  title: Yup.string().required(),
});


function DeductionsForm({
  compId = null,
  updated,
  setSelectedFile
}) {
  const [error, setError] = useState(null);
  const [comp, setComp] = useState(null);
  const [contractFile, setContractFile] = useState(null)
  let params = useParams();
  const { notify } = useUtils();

  // const navigate = useNavigate();
  const handleSuccess = () => {
    notify( "A new Document has been added successfully")
    setValue("file", "");
    setContractFile(null);
    setSelectedFile(null) 
  }




  
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: company,
  });

  const {uploadPolicy } = useCompanies()


  useEffect(() => {
    
    const subscription = watch((value, { name, type }) => {
      if (name == "file")
      setContractFile(value["file"][0]);
      setSelectedFile(true)
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const onSubmit = async (values) => {

    try {
       await uploadPolicy({ ...values, id: Number(params.id ) });
        reset();
      
      handleSuccess();
    } catch (e) {
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `document is already exist for this employee.`,
        });
      console.log("e", e);
    }
  };


  

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Grid>
        <div>
        <FileField
            style={{
              gridColumn: "1/3",
              marginBottom: "20px",
              marginTop: "22px",
            }}
            buttonLabel="Upload Document"
            document
            documentFile={contractFile}
            onClose={() => {
              setValue("file", "");
              setContractFile(null);
              setSelectedFile(null)

            }}
            inputProps={{
              name: "file",
              ...register("file"),
              accept:
                ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }}
          />
          
        </div>
        <div>
        <TextField
						required
						label={'Title'}
						name="title"
						error={!!errors.title}
						msg={errors.title?.message}
						inputProps={{
							...register('title'),
							required: true,
						}}
					/>

        </div>
        <ButtonWrapper> 
          <Button type='submit' className=" small" >
            upload
          </Button>
       </ButtonWrapper>
      </Grid>

    </form>
  );
}

export default memo(DeductionsForm);
