import { useEffect, useRef, useState } from "react";
import { Button, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { usePayrolls } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import months from "../../data/months.json";
import { rmEmpty } from "../../utilities/functions";

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return Number(value);
}

const schema = Yup.object().shape({
  companyId: Yup.number().nullable(true).transform(emptyStringToNull),
  month: Yup.number().nullable(true).transform(emptyStringToNull),
  year: Yup.number().nullable(true).transform(emptyStringToNull),
});

const Grid = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  align-items: end;
`;

function FilterForm({ handleCompanyChange2 }) {
  const [error, setError] = useState(null);
  const [year, setYear] = useState(null);
  const { fetchList } = usePayrolls();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyId: companies[0]?.id,
      year: "",
      month: "",
    },
  });



  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });
  const mapMonthOptions = useRef({ value: "index", label: "long" });

  const onSubmit = async (values) => {
    try {
      await fetchList({
        id: 0,
        ...rmEmpty(values),
      });
      reset();
    } catch (e) {
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Grid>
        <div>
          {companies.length && (
            <Select
              style={{ width: "250px" }}
              inputStyle={{
                height: "50px",
              }}
              data={companies}
              mapOptions={mapCompanyOptions.current}
              label="companies"
              defaultValue={companies[0]?.id}
              onChange={(v) => {
                handleCompanyChange2(v.value);
                setValue("companyId", v.value);
              }}
              inputProps={{
                ...register("companyId"),
              }}
            />
          )}
        </div>

        <Select
          inputStyle={{
            height: "50px",
          }}
          label="month"
          data={months}
          mapOptions={mapMonthOptions.current}
          name="month"
          onChange={(v) => {
            setValue("month", v.value);
          }}
          inputProps={{
            ...register("month"),
          }}
        />

        <TextField
          style={{ width: "140px" }}
          label="Year"
          name="year"
          noSpace
          error={!!errors.year}
          msg={errors.year?.message}
          onChange={(v) => {
            setYear(v.target.value);
          }}
          inputProps={{
            // value: year,
            type: "number",
            ...register("year"),
            min: "1900",
            max: "2099",
            step: "1",
            style: { height: "48px", marginBottom: "2px" },
          }}
        />
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Eye"
          className="small"
          loading={isSubmitting}
        >
          view Data
        </Button>
      </Grid>
    </form>
  );
}

export default FilterForm;
