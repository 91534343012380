import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Type = styled.p`
  font-family: "Cairo", sans-serif;
  ${(props) =>
    props.var === "display" &&
    css`
      font-size: 75px;
      line-height: 85px;
      font-weight: bold;
      color: #333;
    `}
  ${(props) =>
    props.var === "subtitle" &&
    css`
      font-size: 20px;
      color: #999;
      h2 + &,
      h1 + & {
        margin-top: -40px;
      }
    `}
  em {
    color: #7f32de;
    font-style: normal;
  };
  ${stylesBlocks}
`;

export default Type;
