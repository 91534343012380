// import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";
// import { useUsers } from "./usersProvider";

function Notification() {
  return <Layout><Outlet /></Layout>;
}

export default Notification;
