import styled, { css } from "styled-components";

const StyledDiv = styled.div.attrs((props) => ({
  // we can define static props
  horizontal: props.horizontal || "right",

  // or we can define dynamic ones
  vertical: props.vertical || "bottom",
}))`
  position: fixed;
  z-index: 999999;
  padding: 15px 25px;
  border-radius: 15px;
  ${(props) =>
    props.horizontal === "left"
      ? css`
          left: 20px;
        `
      : css`
          right: 20px;
        `};
  ${(props) =>
    props.vertical === "top"
      ? css`
          top: 20px;
        `
      : css`
          bottom: 20px;
        `};
  ${(props) => {
    switch (props.severity) {
      case "alert":
        return css`
          background-color: ${(props) => props.theme.color.red };
        `;
      case "success":
        return css`
          background-color: ${(props) => props.theme.color.green };
        `;
      default:
        return css`
          background: ${(props) => props.theme.color.dark};
        `;
    }
  }}
  color: white;
  transition: all 0.3s ease;
  ${(props) =>
    props.open
      ? css`
          opacity: 1;
          pointer-events: default;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
  ${(props) =>
    props.open
      ? css`
          opacity: 1;
          pointer-events: default;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

function Snackbar({ open = false, onClose, children, ...rest }) {
  return (
    <StyledDiv open={open} {...rest}>
      {children}
    </StyledDiv>
  );
}

export default Snackbar;
