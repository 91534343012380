import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useAttendance, useLeaves } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import InputDate from "../../ui/InputDate";
import { useDepartments } from "../departments/provider";
import { useEmployees } from "../employees/provider";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

d.setDate(d.getDate() - 30);
let fom =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

function emptyDate(value, originalValue) {
  var d = new Date(value);
  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft()
    );
  }
}

const schema = Yup.object().shape({
  FromDate: Yup.string().transform(emptyDate),
  ToDate: Yup.string().transform(emptyDate),

  companyId: Yup.number().nullable().transform(emptyStringToNull),
  departmentId: Yup.number().nullable().transform(emptyStringToNull),
  search: Yup.string(),
  pagesize: Yup.number().nullable().transform(emptyStringToNull),
});

const Grid = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 55px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function FilterForm2({ setFilters , hide , editable}) {
  const {
    state: { filters },
    dispatch,

  } = useAttendance();
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      FromDate: new Date(fom),
      ToDate: new Date(now),
      companyId: filters.companyId,
      departmentId: filters.departmentId,
      employeeId: filters.employeeId,
    },
  });

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const watchCompanyId = watch("companyId");


  const {
    state: { list: departmentList },
    fetchList: fetchDepartments,
  } = useDepartments();


  useEffect(() => {
    if (watchCompanyId) {
      fetchDepartments(getValues("companyId"));
      setValue('departmentId', '');
    }
  }, [watchCompanyId, fetchDepartments, getValues, setValue]);



  const watchDepartmentId = watch("departmentId");

  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();


  useEffect(() => {
    if (watchDepartmentId) {
      fetchEmployees({companyId:getValues("companyId"),departmentId:getValues("departmentId")});
      setValue('employeeId', '');
    }
  }, [watchDepartmentId, fetchEmployees, getValues, setValue]);



  const onCompanyChange = useCallback(
    (data) => setValue("companyId", data.value),
    [setValue]
  )

  const onDepartmentChange = useCallback(
    (data) => setValue("departmentId", data.value ),
    [setValue]
  )

  const onEmployeeChange = useCallback(
    (data) => setValue("employeeId", data.value || 0),
    [setValue]
  )

  const watchEmployeeId = watch("employeeId");

  useEffect(()=>{
    if(editable ){
      setFilters(getValues());
    }
  },[watchCompanyId , watchDepartmentId , watchEmployeeId])

  const onSubmit = async (values) => {
    console.log('called width1' , values);
    setFilters(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Grid>
       {
        !hide?.dates && <> <InputDate
        label="From"
        name="FromDate"
        error={!!errors.FromDate}
        msg={errors.FromDate?.message}
        value={new Date(fom)}
        type="date"
        onDateChange={(v) => {
          setValue("FromDate", v);
        }}
      />

      <InputDate
        label="To"
        name="ToDate"
        error={!!errors.ToDate}
        msg={errors.ToDate?.message}
        type="date"
        value={new Date(now)}
        onDateChange={(v) => {
          setValue("ToDate", v);
        }}
      /></>
       }

        {
          !hide?.companyId && <>
          <Select
          label={"companies"}
          data={companies}
          mapOptions={{ value: "id", label: "businessName" }}
          defaultValue={getValues("companyId") || ""}
          onChange={onCompanyChange}
        /></>
        }

        {
          !hide?.departmentId &&<>
          <Select
            label={"department"}
            style={{
              width:'100%'
            }}
            data={departmentList}
            mapOptions={{ value: "id", label: "departmentName" }}
            defaultValue={getValues("departmentId") || ""}
            onChange={onDepartmentChange}
          />
        </> 
        }

        {
          !hide?.employeeId && 
          <>
            <Select
              label={"Employee"}
              style={{
                width:'100%'
              }}
              data={employees}
              mapOptions={{ value: "id", label: "fullName" }}
              defaultValue={getValues("employeeId") || ""}
              onChange={onEmployeeChange}
            />
          </>
        }

        {
          !hide?.buttons && <ButtonWrapper>
          <Button
            data-testid="save-button"
            type="submit"
            iconName="Eye"
            className="small"
            loading={isSubmitting}
          >
            view Data
          </Button>

        </ButtonWrapper>
        }
      </Grid>
    </form>
  );
}

export default FilterForm2;

