import React, { useState } from "react";

// Importing core components
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
const Editor = ({ editorHtml, setEditorHtml }) => {
  const [theme, setTheme] = useState("");

  const handleChange = (html) => {
    setEditorHtml(html);
    console.log(editorHtml, "editor html");
  };
  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  var formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  return (
    <QuillEditor
      style={{
        border: "1px solid rgb(194, 194, 194)",
        borderRadius: "12px",
        width: "800px",
      }}
      theme="snow"
      value={editorHtml}
      onChange={handleChange}
    />
  );
};

export default Editor;
