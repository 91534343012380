import React, { useCallback, useEffect, useState } from "react";
import TableContainer from "../../../ui/TableContainer";
import { Divider, Pagination } from "../../../ui";
import { useUserAccounts } from "../provider";
import EmployeeRoll from "./EmployeeRoll";
import FilterForm from "./FilterForm";
import { useUtils } from "../../../services/utilsProvider";
import { useNavigate } from "react-router-dom";

function AllEmployeeList({ company }) {

let navigate = useNavigate()

  
const cols = [
    { name: "displayName", label: "Employee Name"},
    { name: "email", label: "Email"},
    { name: "roles", label: "Role", render: EmployeeRoll },


  ];



  const { notify, alertUser } = useUtils();
  const {
    state: { allEmployees, allEmployeeStatus, allEmployeeCount, allEmployeePage, filters,perPage },
    fetchAllEmployees: fetchEmployees,
    setAsPO,
    dispatch,
    setFilters,
    revokePO
  } = useUserAccounts();
  useEffect(() => {
    if(filters)fetchEmployees({...filters,pageNumber: allEmployeePage + 1});
  }, [fetchEmployees,allEmployeeStatus, filters, allEmployeePage]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      dispatch({ type: "set_allEmployeePage", payload: event });
    },

    [dispatch]
  );

  const handleSetHR = useCallback(
    (id) => {
      navigate(`/accounts/set-hr/employee/${id}`);
    },
    [navigate]
  );

  const handleSetPO = useCallback(
    async (id) => {
      let isPO = false
      allEmployees?.filter((emp)=> emp.id == id).map((emp)=> {
        console.log(emp , emp.roles.includes('PO'));    
        if(emp.roles.includes('PO')) isPO = true
      })

      console.log(isPO , 'Employ');
    if(isPO){
        await revokePO(id);
        notify("a Po Privilege has been revoked successfully.");
        dispatch({ type: "set_AllEmployees_status", payload: `changed` });
        isPO=false
      return
      }  
        await setAsPO(id);
        notify("a Po Privilege has been granted successfully.");
        dispatch({ type: "set_AllEmployees_status", payload: `changed` });
        isPO=false
      
    },
    [navigate,allEmployees]
  );

  return (
    <div>
    
    <FilterForm setFilters={setFilters} />
    <Divider border />

      <TableContainer
        empty={allEmployees?.length === 0}
        status={allEmployeeStatus}
        aria-label="Holidays table"
        cols={cols}
        data={allEmployees}
        moreOptions={{
          setAsHR: {
            label: "Set as HR",
            icon: " ",
            fn: handleSetHR,
          },
          setAsPo: {
            label: "Change PO Status",
            icon: "",
            fn: handleSetPO,
          },
        }}
      />
      <Pagination
        data-testid="pagination"
        count={allEmployeeCount}
        page={allEmployeePage}
        rowsPerPage={150}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default AllEmployeeList;
