import { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Icon } from "../../ui";
import EmployeeForm from "./EmployeeForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddEmployee() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("a new employee has been created successfully.");
  };

  return (
    <div id="add-employee">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Employee</h2>
        <div style={{ cursor: "pointer" }} >
          <span onClick={() => navigate(-1)}>Back</span>
          <Icon name="arrowLeft" onClick={() => navigate(-1)}/>
          <Button onClick={()=>navigate('/employees/addfile')}>Add From Excel</Button>
        </div>
      </Grid>

      <EmployeeForm handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(AddEmployee);
