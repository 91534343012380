import React, { useEffect, useState } from "react";
import TableContainer from "../../../ui/TableContainer";
import FilterForm from "../FilterForm";
import { Button, Card, Flex, Pagination, TextField } from "../../../ui";
import styled from "styled-components";
import { useYear } from "../../year/provider";
import { useTransaction } from "../provider";
import { ceil, filter, floor } from "lodash";
import { useStatus } from "../../../services/StatusProvider";
import { useCompanies } from "../../companies/provider";
import DebitCell from "./GetJournalsCells/DebitCell";
import CreditCell from "./GetJournalsCells/CreditCell";
import {
  dateToLocalConverter,
  exporToExcle,
} from "../../../utilities/functions";
import { MethodEnum } from "../../../data/Enums";
const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

const cols = [
  { name: "debit", label: "debit", render: DebitCell },
  { name: "credit", label: "credit", render: CreditCell },
  { name: "accountName", label: "Account" },
];
function GetTrialBalance() {
  const [error, setError] = useState();
  const [companyId, setCompanyId] = useState();
  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: {
      trailBalance,
      trailBalancestatus,
      trailBalancecount,
      trailBalancepage,
      perPage,
      filters,
      Alllist,
    },
    getTrailBalance,
    remove,
    dispatch,
    setFilters,
    getJournal,
    fetchAll,
  } = useTransaction();
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!years && companyId && !filters)
      setFilters({
        companyId: companyId,
        //financialYearId: years[0]?.id,
      });
  }, [companyId, years]);

  const {
    state: { isAcc },
  } = useStatus();

  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);
  let show = ["method", "years", "quarter"];

  const getExcelObj = (x) => {
    let newObj = new Array(trailBalance?.length);
    trailBalance?.map((i, idx) => {
      newObj.push({
        Debit: i.debit,
        Credit: i.credit,
        Account: i.accountName,
      });
    });
    return newObj.filter((item) => {
      return item;
    });
  };

  const handleChangePage = (newPage) => {
    dispatch({ type: "set__trail_balance_page", payload: newPage });
  };

  useEffect(() => {
    if (filters?.yearId) getTrailBalance(filters, trailBalancepage);
  }, [getTrailBalance, filters, trailBalancepage, perPage]);

  return (
    <>
      <Grid>
        <h2>Get Trial Balance</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            filters.yearId &&
            exporToExcle(
              getExcelObj(trailBalance),
              "trail Balance report  for: " +
                years
                  .filter((y) => y.id == filters.yearId)
                  .map((y) => y.title) +
                " " +
                MethodEnum.filter((y) => y.id == filters.method).map(
                  (y) => y.method
                )
            )
          }
        >
          Export Excel
        </Button>
      </Grid>

      <FilterForm
        setFilters={setFilters}
        years={years}
        isAcc={isAcc}
        error={error}
        setError={setError}
        companyId={companyId}
        show={show}
      />

      <>
        <TableContainer
          empty={trailBalance.length == 0}
          status={trailBalancestatus}
          aria-label="journal table"
          cols={cols}
          data={trailBalance}
          checkbox
        />
      </>
      {/* <Pagination
        data-testid="pagination"
        count={trailBalancecount}
        page={trailBalancepage}
        rowsPerPage={10}
        onPageChange={handleChangePage}
      /> */}
    </>
  );
}

export default GetTrialBalance;
