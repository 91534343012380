import { useCallback, useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import styled from "styled-components";
import { Select } from "../../../ui";
import { genYears } from "../../../utilities/functions";
import { useCompanies } from "../../companies/provider";
import { useStats } from "../../stats/provider";

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    margin: 0 0 10px 0;
    flex-grow: 1;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  section.left {
    div.date {
      background-color: #ca90bb;
      border-radius: 20px;
      padding: 15px 20px;
      text-align: center;
      color: white;
      font-size: 20px;
      font-weight: 600;
      margin-top: -15px;
    }
    ul.legend {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 25px;
      li {
        font-size: 15px;
        color: #abafb3;
        display: flex;
        align-items: center;
        line-height: 0;
        margin-bottom: 14px;
        .dot {
          display: inline-block;
          width: 14px;
          height: 14px;
          background-color: #8a8af6;
          border-radius: 50%;
          margin-right: 10px;
        }
        &:last-child {
          .dot {
            background-color: #84e0be;
          }
        }
      }
    }
  }
`;

const data = [
  { name: "Number of Holidays Left", value: 20 },
  { name: "Number of Holidays Taken", value: 12 },
];

const COLORS = ["#8A8AF6", "#84E0BE"];

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        style={{ fontWeight: "bold", fontSize: "20px" }}
        fill={fill}
      >
        {payload.value}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius + 10}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function Holidays() {
  const [active, setActive] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());

  const {
    state: { list: companies, selected: company },
    dispatch,
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { holidays },
    fetchHolidays,
  } = useStats();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companies.length) return;
    dispatch({ type: "set_selected", payload: companies[0], keep: true });
  }, [companies, dispatch]);

  useEffect(() => {
    fetchHolidays(year);
  }, [fetchHolidays, year]);

  const onPieEnter = (_, index) => {
    setActive(index);
  };

  const handleCompanyChange = useCallback(
    (v) => dispatch({ type: "set_selected", payload: v.data }),
    [dispatch]
  );

  const companyHoliday = holidays?.filter(
    (h) => h.company.id === company?.id
  )[0];

  return (
    <>
      <Title>
        <h2 style={{ margin: 0 }}>Holidays</h2>
        <div style={{ marginRight: "10px" }}>
          <Select
            inputStyle={{ height: "48px", width: "220px" }}
            data={companies}
            mapOptions={{ label: "businessName", value: "id" }}
            defaultValue={company?.id || ''}
            onChange={handleCompanyChange}
            inputProps={{
              value: company?.id || '',
              onChange: () => {}
            }}
          />
        </div>
        <Select
          inputStyle={{ height: "48px", width: "110px" }}
          data={genYears(2000)}
          defaultValue={year.toString()}
          onChange={(v) => {
            setYear(v.value);
          }}
          inputProps={{
            value: year.toString(),
            onChange: () => {}
          }}
        />
      </Title>
      <Flex>
        <section className="left">
          <h3>Date of Next Holiday</h3>
          <div className="date">
            {companyHoliday?.nextHoliday?.holidayDate || "Not specified"}
          </div>
          <ul className="legend">
            <li>
              <span className="dot"></span>{" "}
              <span>Number of Holidays Taken</span>
            </li>
            <li>
              <span className="dot"></span> <span>Number of Holidays Left</span>
            </li>
          </ul>
        </section>
        <section className="right">
          <ResponsiveContainer width={300} height={220}>
            <PieChart height={240}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                activeShape={renderActiveShape}
                activeIndex={active}
                data={[
                  {
                    name: "Number of Holidays Left",
                    value:
                      companyHoliday?.holidaysCount -
                      companyHoliday?.holidaysTakenCount,
                  },
                  {
                    name: "Number of Holidays Taken",
                    value: companyHoliday?.holidaysTakenCount,
                  },
                ]}
                outerRadius={90}
                innerRadius={65}
                fill="#8884d8"
                legendType="circle"
                cx="60%"
                cy="55%"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              {/* <Tooltip /> */}
            </PieChart>
          </ResponsiveContainer>
        </section>
      </Flex>
    </>
  );
}

export default Holidays;
