import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Icon } from "../ui";
import logoImg from "../assets/punchapp-logo.svg";
import { useAuth } from "../services/auth";
import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { notify, useNotify } from "./provider";
import { notificationUrl } from "../config";

const Aside = styled.aside`
  position: relative;
  width: 345px;
  transition: all 0.2s ease;
  width: ${(props) => (props.open ? "345px" : "110px")};
  &:hover {
    width: 345px;
    .text {
      display: inline-block !important;
    }
  }
  @media only print {
    display: none;
  }
  &::before {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  @media only screen and (max-width: 1024px) {
    ${(props) =>
      props.open &&
      css`
        &::before {
          opacity: 1;
          pointer-events: all;
        }
      `}
    background-color: white;
  }
  .toggle {
    position: absolute;
    top: 35px;
    right: -42px;
    cursor: pointer;
    padding: 10px;
    border-radius: 0 12px 12px 0;
    font-size: 28px;
    z-index: 99999;
    transition: all 0.4s ease;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
    ${(props) =>
      props.open &&
      css`
        width: 37px;
        height: 36px;
      `}
    div {
      width: 100%;
      height: 100%;
      border: ${(props) => props.theme.color.primary} 3px solid;
      border-left: none;
      border-right: none;
      transition: all 0.4s ease;
      ${(props) =>
        props.open &&
        css`
          border-color: white;
          transform: rotate(765deg);
          border-top: none;
          border-left: white 3px solid;
        `}
      position: relative;
      &::before {
        transition: all 0.3s ease;
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.color.primary};
        ${(props) =>
          props.open &&
          css`
            background-color: white;
            transform: rotate(-765deg);
            left: -2px;
            top: calc(50% - 3px);
            width: 140%;
          `}
      }
    }
  }
  div.nav-wrapper {
    transition: all 0.2s ease;
    position: fixed;
    width: 345px;
    height: calc(100vh);
    overflow: hidden;
    background-color: white;

    width: ${(props) => (props.open ? "345px" : "110px")};
    &:hover {
      width: 345px;
    }
    @media only screen and (max-width: 1024px) {
      width: ${(props) => (props.open ? "345px" : "120px")};
      overflow: ${(props) => (props.open ? "visible" : "hidden")};
    }
  }
  #logo {
    display: flex;
    align-items: center;
    padding-left: 45px;
    height: 120px;
    /* width: 183px; */
    img {
      width: 200px;
    }
  }
  nav {
    position: relative;
    height: calc(100vh - 220px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 40px;
    ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .logout {
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-left: 50px;
    height: 100px;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.green};
    cursor: pointer;
    font-size: 20px;

    &:hover {
      color: ${(props) => props.theme.color.primary};
      svg {
        fill: ${(props) => props.theme.color.primary} !important;
      }
    }
    span {
      line-height: 0;
      padding-right: 28px;
    }

    .text {
      display: ${(props) => (!props.open ? "none" : "inline")};
    }
    .exZune {
      width: 60px;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  font-family: "Cairo", sans-serif;
  display: flex;
  align-items: center;
  color: #999;
  margin-bottom: 32px;
  padding: 7px 0 7px 50px;
  text-decoration: none;
  position: relative;
  line-height: 0;
  svg {
    /* width: 34px; */
    margin-right: ${(props) => props.mr + "px"};
    fill: #999;
  }
  &.notify {
    svg {
      fill: red;
    }
  }
  &.selected {
    color: #7f32de;
    font-weight: bold;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      height: 100%;
      background-color: ${(props) => props.theme.color.primary};
      border-radius: 20px;
    }
    svg {
      fill: #7f32de;
    }
  }
`;

function Navbar({ navOpen, setNavOpen, handleMouseOver, handleMouseOut }) {
  const {
    state: { notify },
    dispatch: dispatch,
  } = useNotify();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAcc, setIsAcc] = useState(false);
  const [isPO, setIsPO] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles?.filter((r) => r === "Admin").map((e) => setIsAdmin(true));
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes("HR") && setIsPO(false);
    user?.roles.includes("Acc") && setIsAcc(true);
  }, [user]);
  const links = [
    !isPO &&
      !isAcc && {
        label: "Company",
        path: "companies",
        icon: "suitcase",
        w: 34,
        mr: 29,
      },
    !isPO &&
      !isAcc && {
        label: "Employees",
        path: "employees",
        icon: "user",
        w: 34,
        mr: 29,
      },
    !isPO &&
      !isAcc && {
        label: "Leave Requests",
        path: "leaves",
        icon: "calendarLeaves",
        w: 34,
        mr: 29,
      },
    !isPO &&
      !isAcc && {
        label: "Attendance",
        path: "attendance",
        icon: "attendance",
        w: 40,
        mr: 23,
      },
    !isPO &&
      !isAcc && {
        label: "Payrolls",
        path: "payrolls",
        icon: "payroll",
        w: 34,
        mr: 29,
      },
    // {
    //   label: "Holidays",
    //   path: "holidays",
    //   icon: "calendarHolidays",
    //   w: 43,
    //   mr: 20,
    // },
    isAdmin
      ? {
          label: "HR Accounts",
          path: "accounts",
          icon: "HRaccount",
          w: 34,
          mr: 29,
        }
      : null,
    isAdmin || isAcc
      ? {
          label: "accounting",
          path: "transaction",
          icon: "HRaccount",
          w: 34,
          mr: 29,
        }
      : null,

    isAdmin &&
      !isAcc && {
        label: "Public Holidays",
        path: "publicHoliday",
        icon: "calendarHolidays",
        w: 34,
        mr: 29,
      },
    !isPO && { label: "Report", path: "report", icon: "Report", w: 34, mr: 29 },
    // { label: "Notifications", path: "notification", icon: "Bell", w: 34, mr: 29},
    !isAcc && {
      label: "Purchase",
      path: "purchase",
      icon: "Purches",
      w: 34,
      mr: 29,
    },
    !isPO &&
      !isAcc && {
        label: "Currency",
        path: "currency",
        icon: "Currency",
        w: 34,
        mr: 29,
      },
    // {
    //   label: "Signers",
    //   path: "signers",
    //   icon: "attendance",
    //   w: 40,
    //   mr: 23,
    // },
    // { label: "Settings", path: "settings", icon: "settings", w: 34, mr: 29 },
  ];

  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();
  const handleLogout = () => {
    auth.signout();
  };

  var frontEndIsLoggedIn = true;
  var apiOrigin = notificationUrl; //document.location.origin; // 'https://localhost:7114';
  var hubUrl = apiOrigin + "notificationshub";
  //onsole.log(hubUrl , 'hubUrl');
  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        connection.on(
          "LeavesChange",
          function (leaveId /*, param2, param3,...*/) {
            if (frontEndIsLoggedIn) {
              // Do what you want now... fetch new data from server
              dispatch({ type: "notify", payload: true });
            }
          }
        );
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  return (
    <Aside
      open={navOpen}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="nav-wrapper">
        <div
          id="logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img src={logoImg} alt="Punchapp logo" />
        </div>
        <nav>
          <ul>
            {!isPO && !isAcc && (
              <li>
                <StyledLink
                  to="/"
                  mr={29}
                  className={location.pathname === "/" ? "selected" : undefined}
                >
                  <Icon name="home" /> <span>Home</span>
                </StyledLink>
              </li>
            )}
            {links.map((link) => {
              if (!link) return;
              return (
                <li key={link.path}>
                  <StyledLink
                    to={`/${link.path}`}
                    mr={link.mr}
                    className={
                      location.pathname.startsWith(`/${link.path}`)
                        ? "selected"
                        : undefined
                    }
                  >
                    <Icon name={link.icon} w={link.w} c="gray800" />{" "}
                    <span>{link.label}</span>
                  </StyledLink>
                </li>
              );
            })}
            {/* <li
              className={
                location.pathname.startsWith("/companies")
                  ? "selected"
                  : undefined
              }
            >
              <Link to="/companies">
                <Icon name="suitcase" /> <span>Company</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.startsWith("/employees")
                  ? "selected"
                  : undefined
              }
            >
              <Link to="/employees">
                <Icon name="user" /> <span>Employees</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.startsWith("/leaves") ? "selected" : undefined
              }
            >
              <Link to="/leaves">
                <Icon name="logout" /> <span>Leaves</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.startsWith("/attendance")
                  ? "selected"
                  : undefined
              }
            >
              <Link to="/attendance">
                <Icon name="attendance" /> <span>Attendance</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.startsWith("/payrolls")
                  ? "selected"
                  : undefined
              }
            >
              <a href="/payrolls">
                <Icon name="payroll" /> <span>Payrolls</span>
              </a>
            </li>
            <li
              className={
                location.pathname.startsWith("/accounts")
                  ? "selected"
                  : undefined
              }
            >
              <a href="/accounts">
                <Icon name="payroll" /> <span>Payrolls</span>
              </a>
            </li>
            <li>
              <a href="/">
                <Icon name="settings" /> <span>Settings</span>
              </a>
            </li> */}
          </ul>
        </nav>
        <div
          className="logout"
          onClick={handleLogout}
          style={{ width: "65px" }}
        >
          <Icon
            name="logout"
            w="65"
            c="green"
            hover="primary"
            style={{ width: "65px" }}
          />{" "}
          <span className="text">Logout</span>
        </div>
      </div>
    </Aside>
  );
}

export default Navbar;
