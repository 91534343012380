import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import SupliersList from "./SupliersList";
import Supliers from "./Supliers";
import AddSuplier from "./AddSuplier";
import EditSuplier from "./EditSuplier";


export default (
  <Route
    path="supliers"
    element={
      <RequireAuth>
        <Supliers />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <SupliersList />
        </RequireAuth>
      }
    />
    <Route
      path="new/:id"
      element={
        <RequireAuth>
          <AddSuplier />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditSuplier />
        </RequireAuth>
      }
    />
  </Route>
);
