import React from 'react'

function TotalCompensations(row) {
    if (!row) return
  return (
    <div>{
        row?.totalCompensations + ' ' + row?.currencyInfo.symbol
    }</div>
  )
}

export default TotalCompensations