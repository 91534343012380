import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Divider, Icon, Select, TextField } from "../../ui";
import Label from "../../ui/InputLabel";
import Loading from "../../ui/Loading";
import { useCompanies } from "../companies/provider";
import { useUserAccounts } from "./provider";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 120px;
  gap: 32px;
  row-gap: 0;
  align-items: flex-end;
`;

function ViewHR() {
  const { username } = useParams();
  const navigate = useNavigate();
  const {notify} = useUtils()

  const companiesId = useRef([]);

  const {
    state: { selected, status },
    fetchOne,
    editHR,
  } = useUserAccounts();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchOne(username);
  }, [fetchOne, username]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const handleChange = useCallback((v) => {
    companiesId.current = v.map((v) => v.value);
    if(companiesId.current.includes(0)) companiesId.current=null
  }, []);

  const onSubmit = async (v) => {
    await editHR(selected.id, { companyIds: companiesId.current });
    notify("HR Account has been updated successfully");
  };

  // if (!selected) return;

  return (
    <div id="set-as-hr">
      <Flex>
        <h2 style={{ marginTop: "0" }}>HR Information</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Flex>
      <Card>
        {Boolean(selected) ? <Grid>
          <TextField
           style={{marginBottom: '0'}}
            label="HR Username"
            inputProps={{
              value: selected.username,
              style: {height: "50px"},
              readOnly: true
            }}
          />
          <Select
            label="Companies"
            inputStyle={{ height: "50px" }}
            data={[{id:0 , businessName:'none'},...companies]}
            mapOptions={{ value: "id", label: "businessName" }}
            onChange={handleChange}
            multi
            defaultValue={selected?.companys.map((c) => c.id)}
            inputProps={{
              name: "companyId",
            }}
          />
          <Button
            onClick={onSubmit}
            className="small"
            loading={Boolean(status === "editing")}
          >
            Update
          </Button>
        </Grid> : <Loading />}
      </Card>
    </div>
  );
}

export default ViewHR;
