import { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
} from "recharts";
import styled from "styled-components";
import { Card, Divider } from "../../../ui";
import Select from "../../../ui/select/Select";
import { genYears } from "../../../utilities/functions";
import { useStats } from "../../stats/provider";
import Holidays from "./Holidays";
import months from "../../../data/months.json";

const data = [
  { name: "Software Engineering", value: 400 },
  { name: "UX/UI Design", value: 300 },
  { name: "QA Engineering", value: 300 },
  { name: "Theme: 04", value: 200 },
  { name: "Theme: 05", value: 278 },
  { name: "Theme: 06", value: 189 },
];

const COLORS = [
  "#ca90bb",
  "#38a8af",
  "#4a5be6",
  "#dc3472",
  "#00bf78",
  "#7f32de",
];

const StyledCard = styled(Card)`
  margin-top: 40px;
  .recharts-legend-wrapper ul {
    text-align: left !important;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0 0 10px 0;
    flex-grow: 1;
  }
`;

const renderLegendText = (value) => {
  return (
    <span
      style={{
        color: "#abafb3",
        fontSize: "15px",
        paddingRight: "9px",
        paddingLeft: "9px",
        verticalAlign: "middle",
      }}
    >
      {value}
    </span>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (innerRadius + 5) * cos;
  const sy = cy + (innerRadius + 5) * sin;

  return (
    <g>
      <Sector
        cx={sx}
        cy={sy}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function AttendancePerMonth() {
  const [active, setActive] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const {
    state: { attendance },
    fetchAttendance,
  } = useStats();

  useEffect(() => {
    fetchAttendance(year, month);
  }, [fetchAttendance, year, month]);

  const onPieEnter = (_, index) => {
    setActive(index);
  };

  return (
    <StyledCard>
      <Title>
        <h2>Attendance</h2>
        <div style={{ marginRight: "10px" }}>
          <Select
            inputStyle={{ height: "48px", width: "110px" }}
            data={genYears(2000)}
            defaultValue={year.toString()}
            onChange={(v) => {
              setYear(v.value);
            }}
            inputProps={{
              value: year.toString(),
              onChange: () => {}
            }}
          />
        </div>
        <Select
          inputStyle={{ height: "48px", width: "170px" }}
          data={months.map((m) => ({ name: m.long, value: m.index }))}
          mapOptions={{ label: "name", value: "value" }}
          defaultValue={month}
          onChange={(v) => setMonth(v.value)}
          inputProps={{
            value: month,
            onChange: () => {}
          }}
        />
      </Title>
      <ResponsiveContainer width="100%" height={230}>
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            activeShape={renderActiveShape}
            activeIndex={active}
            data={attendance.map((d) => ({
              name: attendance?.department?.departmentName,
              value: attendance?.attendancePercent,
            }))}
            outerRadius={90}
            fill="#8884d8"
            legendType="circle"
            cx="19%"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          {attendance?.department?.length && <Legend
            align="right"
            verticalAlign="middle"
            width={280}
            formatter={renderLegendText}
          />}
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <Divider h={40} border />

      <Holidays />
    </StyledCard>
  );
}

export default AttendancePerMonth;
