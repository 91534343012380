import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Card, TextField ,Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import { useAttendance } from "./provider";
import DateCell from "./DateCell";
import DateCell2 from "./DateCell2";
import { dateToLocalConverter, exporToExcle, setDateFormat } from "../../utilities/functions";
import InputDate from "../../ui/InputDate";
import Loading from "../../ui/Loading";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function EmployeeAttenddance() {
  //define stuff
  const [loading , setLoading] = useState()
  const [error, setError] = useState(null);

  //fetching company


    let params = useParams()
 

  //fetch attendance
  const {
    state: { ereportList: attendance, filters , erstatus : status},
    fetchEmployeeAttendanceList,
    remove,
    dispatch,
  } = useAttendance();

  //cols
  const cols = [

    { name: "punchIn", label: "PunchIn Time" , render:DateCell  },
    { name: "punchOut", label: "PunchOut Time" ,render:DateCell2},
    { name: "overTime", label: "Over Time"},
    { name: "delay", label: "Delay" },
    { name: "breakDuration", label: "Break Duration" },
    { name: "totalBreaks", label: "Total Breaks" },
    { name: "hourlyLeave", label: "Hourly Leave" },

    { name: "overBreak", label: "Over Break" },
    { name: "workTime", label: "Work Time" },
    { name: "shiftTime", label: "Shift Time" },
    { name: "longPres", label: "Long Press" },
    { name: "missPres", label: "Miss Press" },


  ];

  useEffect(() => {
    try{
      fetchEmployeeAttendanceList(filters.companyId , filters.departmentId, params.id, filters.FromDate , filters.ToDate);
      setError(false)
    }catch(e){
      setError(true)
    }
  }, [fetchEmployeeAttendanceList, filters , window.location.pathname]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  const getExcelObj= (x) => {

    let newObj =new Array(x.length);

    x?.attendances.map((item , idx) =>{
      newObj[idx ] = {
    'Employee Name': x?.employeeInfo?.fullName,
    'Company Name': x?.companyInfo?.companyName,
    'First PunchIn Time': dateToLocalConverter(item?.punchIn),
    'Last PunchOut Time': dateToLocalConverter(item?.punchOut),
    'Total Over Time': item?.overTime,
    'Total Delays':item?.delay,
    'Break Duration':item?.breakDuration,
    'Total Breaks':item?.totalBreaks,
    'Over Break':item?.overBreak,
    'Work Time':item?.workTime,
    'Shift Time':item?.shiftTime,
    'Long Press':item?.longPres,
    'Miss Press':item?.missPres
  }
})


 return newObj
  }
  useEffect(()=>{
    
  if(!attendance || attendance?.employeeInfo?.id != params?.id) setLoading(true);
  else setLoading(false);

  },[attendance])
  if(loading) return <Loading></Loading>
  if(error) return 'some error occurred please try again'
  return (
    <>
  

      <Grid>
        <h2>Attendance Report List</h2>
        <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => exporToExcle(getExcelObj(attendance ), attendance?.employeeInfo?.fullName +' attendance report from ' +attendance?.fromDate +' to ' +attendance?.toDate)}
          >
            Export Excel
          </Button>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        {/* <FilterForm ereport={true} fetchAttendance={fetchEmployeeAttendanceList} page={page}></FilterForm> */}
      <Grid>

        
<InputDate
            label="from"
            value={new Date(attendance?.fromDate)}
            type="date"
            pickerProps={{
              disabled: true,
            }}
          />


<InputDate
            label="to"
            value={new Date(attendance?.toDate)}
            type="date"
            pickerProps={{
              disabled: true,
            }}
          />

                        <TextField
              label="company"
              inputProps={{
                value: attendance?.companyInfo?.companyName,
                readOnly: true,
                disabled: true,
              }}
            />
                                    <TextField
              label="department"
              inputProps={{
                value: attendance?.departmentInfo?.departmentName,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="employee"
              inputProps={{
                value: attendance?.employeeInfo?.fullName,
                readOnly: true,
                disabled: true,
              }}
            />
      </Grid>


      </Card>
      <TableContainer
        empty={attendance?.attendances?.length == 0}
        status={status}
        aria-label="Attendance table"
        cols={cols}
        data={attendance?.attendances}
        checkbox
        //moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />

    </>
  );
}

export default EmployeeAttenddance;
