import styled from "styled-components";
import { Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useAttendancePatterns } from "./provider";
import { useCompanies } from "../companies/provider";
import CompanyNameCell from "../companies/listCompanies/CompanyNameCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function ListAttendancePatterns() {
  const navigate = useNavigate();
  const params = useParams();
  const { notify } = useUtils();

  const {
    state: { list, status },
    fetchList,
    remove,
    dispatch
  } = useAttendancePatterns();

  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompanies();

  useEffect(() => {
    fetchList(params.id);
  }, [fetchList, params.id]);

  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])
  
  useEffect(() => {
    if (!company || company.id !== Number(params.id)) fetchCompany(params.id);
  }, [fetchCompany, params.id, company]);

  const cols = [
    { name: "attendancePatternName", label: "Attendance Pattern Name" },
    {
      name: "campanyName",
      label: "Company",
      render: CompanyNameCell,
      renderParams: company,
    },
  ];

  const handleClick = () => {
    navigate(`/attendance-pattern/new/company/${params.id}`);
  };

  const handleClone = (id) => {
    navigate(`/attendance-pattern/clone/${id}`);
  };

  const handleView = (id) => {
    navigate(`/attendance-pattern/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/attendance-pattern/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Changes saved successfully");
    } catch (error) {
      console.log(error);
    }
  };

  if (!list) return;
  return (
    <>
      <Grid>
        <h2>Attendance Pattern List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Department table"
        cols={cols}
        data={list.map((item) => ({ ...company, ...item }))}
        checkbox
        showButton={handleView}
        moreOptions={{
          edit: handleEdit,
          clone: handleClone,
          delete: handleDelete,
        }}
      />
      {/* <Pagination count={5} /> */}
    </>
  );
}

export default ListAttendancePatterns;
