import styled, { css } from "styled-components";
import Card from "../Card";
import Dropdown from "../Dropdown";
import Icon from "../Icon";

const OptionsCard = styled(Card).attrs({ elevation: 4 })`
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width:280px;
  width:fit-content;
  ${(props) =>
    props.light &&
    css`
      box-shadow: none;
      font-size: 14px;
    `}
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${(props) => props.theme.color.dark};
      font-weight: 600;
      padding: 15px 20px;
      border-bottom: ${(props) => props.theme.color.grayBg} 1px solid;
      svg {
        margin-right: 8px;
        fill: ${(props) => props.theme.color.dark};
      }
      &.delete {
        color: ${(props) => props.theme.color.red};
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
      &:hover {
        background-color: ${(props) => props.theme.color.primary};
        color: white;
        svg {
          fill: white;
        }
        &.delete {
          background-color: ${(props) => props.theme.color.red};
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const buildCustomOption = function (id, v ,data) {
  return (
    <li key={v.label} onClick={() => v.fn(id, v.data , data)}>
      <Icon name={v.icon} w={16} /> <span>{v.label}</span>
    </li>
  );  
};


const buildOption = function (id,data, k, v) {

  switch (k) {
    case "edit":
      return buildCustomOption(id, { label: "Edit", icon: "pen", fn: v });
    case "editWithData":
        return buildCustomOption(id  , { label: "Edit", icon: "pen", fn: v },data);
    case "clone":
      return buildCustomOption(id, { label: "clone", icon: "Repeat", fn: v });
    case "delete":
      return (
        <li
          key="delete"
          className="delete"
          onClick={() => {
            if (window.confirm("Are you sure?")) v(id);
          }}
        >
          <Icon name="trash" w={16} /> <span>Delete</span>
        </li>
      );
    default:
      return buildCustomOption(id, v);
  }
};

function MoreOptions({ id , data, moreOptions }) {
  const renderOptions = (id) => {
    if (!id) return null;
    const list = [];
    Object.keys(moreOptions).forEach((k) => {
      list.push(buildOption(id ,data, k, moreOptions[k]));
    });
    return <ul>{list}</ul>;
  };

  if (Object.keys(moreOptions).length === 1)
    return <OptionsCard light>{renderOptions(id)}</OptionsCard>;

  return (
    <Dropdown content={<OptionsCard>{renderOptions(id)}</OptionsCard>}>
      <span>
        <Icon name="EllipsisVertical" hover="primary" w={5} />
      </span>
    </Dropdown>
  );
}

export default MoreOptions;
