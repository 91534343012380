import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, TextField, Select, Divider } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useCompensations } from "./provider";

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 0;
`;

// const ButtonWrapper = styled.div`
//   margin-top: 40px;
//   button {
//     float: right;
//     margin-left: 20px;
//     min-width: 180px;
//   }
// `;

function CompensationsForm({
  compensation = null,
  handleSuccess = () => {},
  id,
}) {
  const [error, setError] = useState(null);
  // const navigate = useNavigate();
  const { pId } = useParams();

  const { create, edit, createFromP, editFromP } = useCompensations();

  const yupSchema = fields.reduce(createYupSchema, {});
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: compensation,
  });

  useEffect(() => {
    if (compensation) {
      fields.forEach((f) => {
        setValue(f.name, compensation[f.name] || "");
      });
      // setValue('employeePayrollId', pid);
    }
  }, [compensation, setValue]);

  const onSubmit = async (values) => {
    //console.log(compensation);
    //return;
    try {
      if (window.location.pathname.includes("payrolls") && compensation)
        await editFromP(compensation?.id, values);
      else if (compensation) await edit(compensation?.id, values);
      else {
        if (window.location.pathname.includes("payrolls")) {
          await createFromP({
            ...values,
            employeePayrollId: Number(pId || id),
          });
        } else {
          await create({ ...values, employeePayrollId: Number(pId || id) });
        }
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `The Name "${values.fullName}" is already in use for this company.`,
        });
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card elevation={4} style={{ minWidth: "400px" }}>
        <Grid>
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            if (f.data) {
              return (
                <div key={i}>
                  <Select
                    style={{ width: "100%" }}
                    inputStyle={{ height: "60px" }}
                    label={f.label}
                    data={f.data}
                    mapOptions={f.mapData}
                    multi={f.multi || false}
                    watch={watch}
                    required={f.required}
                    onChange={(v) => {
                      setValue(f.name, f.multi ? v.map((i) => i.value) : v);
                    }}
                    error={!!errors[f.name]}
                    msg={errors[f.name]?.message || f.msg}
                    inputProps={{
                      ...register(f.name),
                    }}
                  />
                </div>
              );
            } else
              return (
                <TextField
                  key={i}
                  label={f.label}
                  name={f.name}
                  error={!!errors[f.name]}
                  msg={errors[f.name]?.message}
                  inputProps={{
                    ...register(f.name),
                    ...inputProps,
                  }}
                />
              );
          })}
        </Grid>
        <Divider h={10} />
        {/* <ButtonWrapper> */}
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        {/* <Button className="outlined small" onClick={() => navigate(-1)}>
            Cancel
          </Button> */}
        {/* </ButtonWrapper> */}
      </Card>
    </form>
  );
}

export default memo(CompensationsForm);
