import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import PayrollsForm from "./PayrollsForm";
import { usePayrolls } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddPayroll() {
  const navigate = useNavigate();
  const { id, month, year, ifVar } = useParams();
  const { notify } = useUtils();

  const {
    state: { selected },
    fetchOne,
  } = usePayrolls();

  useEffect(() => {
    fetchOne({
      companyId: id,
      month: month,
      year: year,
    });
  }, [fetchOne, id, month, year]);

  const handleSuccess = useCallback(() => {
    navigate("/payrolls");
    notify(
      ifVar === 1
        ? "a new Payroll has been created successfully."
        : "Changes has been saved successfully "
    );
  }, [navigate, notify, ifVar]);

  const setRoute = useCallback(
    ({ value, name }, resource) => {
      let path = "";

      if (resource === "companyId") path = `${value}/${year}/${month}`;
      else if (resource === "year") path = `${id}/${value}/${month}`;
      else path = `${id}/${year}/${value}`;

      navigate(`/payrolls/new/${path}`);
    },
    [navigate, id, month, year]
  );

  return (
    <div id="add-user">
      <Grid>
        {ifVar === 1 ? (
          <>
            <h2 style={{ marginTop: "0" }}>Add New Payroll</h2>
          </>
        ) : (
          <>
            <h2 style={{ marginTop: "0" }}>Edit Payroll</h2>
          </>
        )}
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <PayrollsForm
        handleSuccess={handleSuccess}
        {...{
          payrolls: selected,
          id,
          month,
          year,
          ifVar,
          handleSuccess,
          setRoute,
        }}
      />
    </div>
  );
}

export default AddPayroll;
