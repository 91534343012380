import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { createThumb } from "../../utilities/functions";

const Context = createContext();

export function useNotification() {
  return useContext(Context);
}

const initState = {
  list: [],
  topFive: [],
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
  companyID: null,
  employeeID: null,
  departmentID: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_top_five":
      return { ...state, topFive: [...action.payload] };
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "companychange":
      return { ...state, companyID: action.payload };

    case "departmentchange":
      return { ...state, departmentID: action.payload };
    case "employeechange":
      return { ...state, employeeID: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (Cid, Did, Eid, page, pageSize = 10, keyword = null) => {
      console.log("called", "resdata");

      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: state.page + 1,
        pageSize,
      };
      if (Cid) headersData.companyId = Cid;
      if (Did && Did != 0) headersData.departmentId = Did;
      if (Eid && Eid != 0) headersData.employeeId = Eid;

      console.log(headersData, "resdata");

      const resData = await req(
        `Notification/List`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.notifications });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req, state.page]
  );

  const fetchFive = useCallback(
    async (Cid, Did, Eid, page, pageSize = 5, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: state.page + 1,
        pageSize,
      };
      const resData = await req(
        `Notification/List`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_top_five", payload: resData.data.notifications });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req, state.page]
  );

  const create = useCallback(
    async (data) => {
      let resData;
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        if(data.EmployeeIds < 0  ) delete data.EmployeeIds
        if(data.departmentId == null ||data.departmentId == "") delete data.departmentId
        try {
          resData = await req(
            "Notification/Create",
            data,
            { method: "POST" },
            true
          );

          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        create,
        fetchFive,
      }}
    >
      {children}
    </Context.Provider>
  );
}
