import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";

import { useLeaves, usePurches } from "./provider";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "../../data/countries.json";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useOrders } from "../orders/provider";
import InputDate from "../../ui/InputDate";
import { setDateFormat } from "../../utilities/functions";

let day_for_hourly_absence = 0;
let final_abcence_from = 0;
let first_of_year = "2022-01-01";

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function emptyDate(value, originalValue) {
  var d = new Date(value);
  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft()
    );
  }

  if (d == "Invalid Date") {
    final_abcence_from = day_for_hourly_absence + " " + value;
    return final_abcence_from;
  }
}

const schema = Yup.object().shape({
  employeeID: Yup.number(),
  companyID: Yup.number(),
  departmentID: Yup.number(),
  date: Yup.string(),
});

const Grid = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 55px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function FilterForm({ handleSuccess, page }) {
  const types = [
    { typeId: "1", name: "annual" },
    { typeId: "2", name: "Sickness" },
    { typeId: "3", name: "Marriage" },
    { typeId: "4", name: "Maternity" },
    { typeId: "5", name: "WorkAccident" },
    { typeId: "6", name: "Death" },
    { typeId: "7", name: "Unpaid" },
    { typeId: "8", name: "Others" },
    { typeId: "9", name: "Hourly" },
  ];
  const [selectedTypeValue, setSelectedTypeValue] = useState(0);
  const [error, setError] = useState(null);
  const [isPO, setIsPO] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes('HR') && setIsPO(false); 
  }, []);
  const navigate = useNavigate();

  const {
    state: { purches, companyID, departmentID, employeeID, Fdate },
    fetchList: fetchPurches,
    dispatch,
  } = usePurches();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //fetching for filters
  const { dispatch: purchesdispatch } = useOrders();
  //fetch companies

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();
  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  useEffect(() => {
    if (companies && !companyID)
      dispatch({ type: "companychange", payload: companies[0]?.id });
  }, [companies]);

  const handleCompanyChange = useCallback((id) => {
    dispatch({ type: "companychange", payload: id.data.id });
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    !isPO && fetchCompanies();
  }, [fetchCompanies]);

  //fetch departments

  const {
    state: { list: departmentList, status },
    fetchList: fetchDepartments,
  } = useDepartments();

  useEffect(() => {
    if (!departmentID)
      dispatch({ type: "departmentchange", payload: departmentList[0]?.id });
  }, []);

  const handleDepartmentChange = useCallback(
    (id) => {
      dispatch({ type: "departmentchange", payload: id.data.id });
      purchesdispatch({ type: "departmentchange", payload: id.data.id });
      // dispatch({type:'employeechange' , payload:employees[0]?.id})
      if (!id.data.id) dispatch({ type: "employeechange", payload: null });
    },
    [departmentList]
  );

  useEffect(() => {
    if (!employeeID)
      dispatch({ type: "employeechange", payload: employees[0]?.id });
  }, []);

  const mapDepartmentOptions = useRef({ value: "id", label: "departmentName" });

  useEffect(() => {
    if(isPO)
      fetchDepartments(user?.employee?.company.id);
    else
    if (companyID) fetchDepartments(companyID);
  }, [companyID]);

  //fetch employees
  useEffect(() => {
    if (employees.length && !companyID && !employeeID)
      dispatch({ type: "employeechange", payload: employees[0]?.id });
    if (!departmentID) dispatch({ type: "employeechange", payload: null });
  }, [employees]);

  const handleEmployeeChange = useCallback((id) => {
    dispatch({ type: "employeechange", payload: id.data.id });
    purchesdispatch({ type: "employeechange", payload: id.data.id });
    if (!departmentID) dispatch({ type: "employeechange", payload: null });
  }, []);

  useEffect(() => {
    
    if(isPO)  fetchEmployees({companyId:user?.employee?.company.id,departmentId: departmentID});
    else
    if (companyID) fetchEmployees({companyId:companyID,departmentId: departmentID});
  }, [companyID, departmentID]);

  const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });

  const onSubmit = async (values) => {
    try {
      if(isPO)
       user && await fetchPurches(user?.employee?.company.id, departmentID, employeeID,setDateFormat( Fdate), page);
      else
      await fetchPurches(companyID, departmentID, employeeID,setDateFormat( Fdate), page);
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };
  const hanlePage = (values) => {
    try {
      if(isPO)
      user && fetchPurches(user?.employee?.company.id, departmentID, employeeID,setDateFormat( Fdate), page);
      else
      fetchPurches(companyID, departmentID, employeeID,setDateFormat( Fdate), page);
      reset();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      console.log("e", e);
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    hanlePage();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);
  console.log(user?.employee?.company?.companyName , 'user?.employee?.company?.companyName');
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      <Grid>
        <div>
          {isPO ? (
            <TextField
              label="company name"
              onChange={(e)=> console.log(e.target.value)}
              inputProps={{
                value: user?.employee?.company?.companyName,
                ...register("date"),
                readOnly: true,
              }}
            />
          ) : (
            <Select
              style={{
                height: "60px",
                width: "100%",
                marginBottom: "0px 75px",
              }}
              data={companies}
              mapOptions={mapCompanyOptions.current}
              onChange={handleCompanyChange}
              defaultValue={companyID}
              label={"companies"}
              inputProps={{
                value: companyID,
                readOnly: true,
              }}
            />
          )}
        </div>

        <div>
          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
            }}
            data={[{ id: null, departmentName: "All" }, ...departmentList]}
            mapOptions={mapDepartmentOptions.current}
            onChange={handleDepartmentChange}
            defaultValue={departmentID}
            label={"department"}
            inputProps={{
              value: departmentID,
              readOnly: true,
            }}
          />
        </div>

        <div>
          <Select
            style={{
              height: "60px",
              width: "100%",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            data={[{ id: null, fullName: "All" }, ...employees]}
            mapOptions={mapEmployeeOptions.current}
            onChange={handleEmployeeChange}
            defaultValue={employeeID}
            label={"Employee"}
            inputProps={{
              value: employeeID,
              readOnly: true,
            }}
          />
        </div>

        <InputDate
          style={{marginTop:'17px'}}
            label="Date"
            name="date"
            error={!!errors.date}
            msg={errors.date?.message}
            value={new Date(Fdate)}
            type="date"
            onDateChange={(v) => dispatch({ type: "Fdate", payload: v })}
          />


        <ButtonWrapper>
          <Button
            data-testid="save-button"
            type="submit"
            iconName="Eye"
            className="small"
            loading={isSubmitting}
          >
            view Data
          </Button>
        </ButtonWrapper>
      </Grid>
    </form>
  );
}

export default FilterForm;
