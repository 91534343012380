import React from 'react'
import { getValueFromEnum } from '../../../utilities/functions'
import {accountType} from '../../../data/Enums'

function AccountType(data) {
  return (
    <p>{getValueFromEnum(data?.accountType , 'id' , accountType ,'type' )}</p>
  )
}

export default AccountType