import styled from "styled-components";
import { toDateTime} from "../../utilities/functions";

const GridCell = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 17px;
  font-family: ${(props) => props.theme.fonts.openSans};
  img {
    width: 49px;
    border-radius: 15px;
  }
  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    font-size: 14px;
  }
`;

  function IsSafari() {
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
      if (ua.indexOf('chrome') > -1) {
        return false // Chrome
      } else {
        return true // Safari
      }
    }
  }
  
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
  
    newDate.setHours(hours - offset);
  
    return newDate;
  }
  
  const getLocalDate = (x) => {
    let d = new Date(x).toLocaleString();
    let date = d.split(" ")[0];
    let time = d.split(" ")[1];
    let f = (
      date.split("/")[2] +
      "-" +
      date.split("/")[1] +
      "-" +
      date.split("/")[0] +
      " " +
      time.slice(0, 5)
    ).replace(",", "");
    let dd = new Date(f);
  
    let ddd = convertUTCDateToLocalDate(dd);
    return (
      ddd.getFullYear() +
      "-" +
      (ddd.getMonth() + 1).padLeft() +
      "-" +
      ddd.getDate().padLeft() +
      " " +
      ddd.getHours().padLeft() +
      ":" +
      ddd.getMinutes().padLeft()
    );
  };
  
  const normalgetLocalDate = (x) => {
    let d = new Date(x).toLocaleString();
    let dd = new Date(d);
    let ddd = convertUTCDateToLocalDate(dd);
  
    return (
      ddd.getFullYear() +
      "-" +
      (ddd.getMonth() + 1).padLeft() +
      "-" +
      ddd.getDate().padLeft() +
      " " +
      ddd.getHours().padLeft() +
      ":" +
      ddd.getMinutes().padLeft()
    );
  };

function DateCell(data) {
  var d 
 if(!data.punchIn ) return
  if(IsSafari()){
    d = getLocalDate(data?.punchIn)
  }
  else{
   d= normalgetLocalDate(data?.punchIn )
  }
 if(!d)return 

  return (
    <>
      <section>
        {toDateTime(d)} 
      </section>
    </>
  );
}

export default DateCell;