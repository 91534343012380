import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card } from "../../../ui";
import ErrorsList from "../../../ui/ErrorsList";
import FilterForm from "../FilterForm";
import { apiUrl as baseUrl } from "../../../config";
import axios from "axios";
import "./input.css";
import upload from "../../../assets/upload.svg";

import cancle from "../../../assets/cancel.svg";
import document from "../../../assets/file.svg";
import { useUtils } from "../../../services/utilsProvider";
import TableContainer from "../../../ui/TableContainer";
import { useAccounts } from "../provider";
import AccountType from "../tableCell/AccountType";
import SpecialType from "../tableCell/SpecialType";
import AccountStatus from "../tableCell/AccountStatus";
import IsActive from "../tableCell/IsActive";
import NoteCell from "../tableCell/NoteCell";

const Grid = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  .custom-select {
    width: 100%;
  }
`;

const Grid2 = styled.div`
  max-width: 845px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  .custom-select {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 55px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function UploadExcel({}) {
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responce, setResponce] = useState(null);

  const { notify } = useUtils();

  let navigate = useNavigate();

  const cols = [
    { name: "accountName", label: "Account Name" },
    { name: "accountNumber", label: "Account Number" },
    { name: "companyName", label: "Company Name" },
    { name: "accountType", label: "Account Type", render: AccountType },
    { name: "specialType", label: "Special Type", render: SpecialType },
    { name: "accountStatus", label: "Account Status", render: AccountStatus },
    { name: "baseAccountName", label: "Base Account" },
    { name: "isActive", label: "Is Active", render: IsActive },
    { name: "startBalance", label: "Start Balance" },
    { name: "balance", label: "Balance" },
    { name: "notes", label: "notes", render: NoteCell },
  ];
  const {
    state: { filters },
    setFilters,
  } = useAccounts();

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleSuccess = () => {
    notify("File has been uploaded successfully.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("CompanyId", filters.companyId);

    await axios
      .post(`${baseUrl}/AccountingUtils/ImportChartOfAccounts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("tokens")).accessToken
          }`,
        },
      })
      .then((res) => {
        setResponce(res.data.data);
        handleSuccess();
      })
      .then((e) => console.log(e, "eeeeeeeeeeeror"))
      .catch((e) => setError({ title: e.response.data.message }));
  };

  return (
    <>
      <div>
        <h2 style={{ display: "inline" }}>Upload Excel</h2>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        {error && <ErrorsList error={error} />}

        <Card>
          <FilterForm
            hide={["companyName", "parentName", "onlyActiveAccounts"]}
          ></FilterForm>
        </Card>

        <Card style={{ marginTop: "20px" }}>
          <Grid>
            <div style={{ display: "block" }}>
              <input
                type="file"
                id="file"
                onChange={(e) => handleChange(e)}
                style={{ padding: "0 10px", margin: "0", display: "none" }}
              />
              <label
                for="file"
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#F5F5F5",
                  border: "1px dashed #c2c2c2",
                  height: "80px",
                  margin: "0",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "30%",
                    height: "75%",
                    marginTop: "10px",
                    marginLeft: "0px",
                  }}
                >
                  <img
                    src={upload}
                    alt=""
                    width="100%"
                    height="100%"
                    style={{ display: "inline" }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "0px",
                    marginTop: "12px",
                    lineHeight: "28px",
                  }}
                >
                  <p style={{ display: "inline" }}>
                    <span style={{ color: "#202020" }}>Upload Excel</span>
                    <span
                      style={{
                        display: "block",
                        color: "#a5a5a5",
                        fontSize: "14px",
                      }}
                    >
                      XLSX, XLSM, XLSB, XLTX
                    </span>
                  </p>
                </div>
              </label>
            </div>
            <div>
              {selectedFile && (
                <div style={{ display: "block", position: "relative" }}>
                  <span
                    style={{
                      width: "25px",
                      height: "25px",
                      position: "absolute",
                      top: "-8px",
                      right: "-5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  >
                    <img src={cancle} alt="cancle" />
                  </span>
                  <label
                    for="file"
                    style={{
                      borderRadius: "15px",
                      display: "flex",
                      width: "100%",
                      border: "1px solid #6418C3",
                      height: "80px",
                      margin: "0",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "30%",
                        height: "75%",
                        marginTop: "10px",
                        marginLeft: "0px",
                      }}
                    >
                      <img
                        src={document}
                        alt=""
                        width="100%"
                        height="100%"
                        style={{ display: "inline" }}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "0px",
                        marginTop: "23px",
                        lineHeight: "28px",
                      }}
                    >
                      <p style={{ display: "inline" }}>
                        <span style={{ color: "#202020", fontWeight: "bold" }}>
                          {selectedFile.name}
                        </span>
                      </p>
                    </div>
                  </label>
                </div>
              )}
            </div>
            <div></div>
            <ButtonWrapper>
              <Button
                type="submit"
                data-testid="save-button"
                iconName="Disk"
                className="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Upload
              </Button>

              <Button
                className="outlined small"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </ButtonWrapper>
          </Grid>
        </Card>
      </form>
      {responce && (
        <Card style={{ marginTop: "20px" }}>
          <h3>accounts</h3>
          <TableContainer
            empty={responce?.accounts.length === 0}
            //status={status}
            aria-label="Leaves table"
            cols={cols}
            data={responce?.accounts || []}
            checkbox
            //onChecked={setChecked}
          />
        </Card>
      )}
    </>
  );
}

export default UploadExcel;
