import { useLocation } from "react-router-dom";
import { useAuth } from "../services/auth";
import { Box, Flex, Icon, Type } from "../ui";
import Dropdown from "../ui/Dropdown";
import AddCard from "./header/AddCard";
import Notifications from "./header/Notifications";
import UserMenu from "./header/UserMenu";
import { useNotify } from "./provider";
import { useEffect, useState } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useStatus } from "../services/StatusProvider";

function Header({ navOpen, setNavOpen }) {
  const { user } = useAuth();
  let location = useLocation();
  const {
    state: { notify },
    dispatch,
  } = useNotify();
  const [isPO, setIsPO] = useState(false);
  const {
    state: {  isAcc },
    
    setIsAcc}= useStatus()

   useEffect(()=>{
    if(isAcc === null) {
      user?.roles.includes("Acc") ?
       setIsAcc(true):
       setIsAcc(false)
    }
   },[user])
  useEffect(() => {
    user?.roles.includes("PO") && setIsPO(true);
    user?.roles.includes("HR") && setIsPO(false);
  }, []);

  const toggleNav = () => {
    setNavOpen((open) => !open);
  };

  return (
    <Flex as="header" jc="space-between" sx={{ px: 30 }}>
      {/* <SearchInput /> */}
      <Box sx={{ fs: 28 }} onClick={toggleNav}>
        {navOpen ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
      </Box>
      <Flex gap={24}>
        {!isPO && !isAcc && (
          <>
            <Dropdown
              content={<Notifications dispatch={dispatch} notify={notify} />}
            >
              <div>
                <Icon hover="gray200" name="bell" c="gray500" w={20} />
                {location.pathname !== "/notification" && notify && (
                  <span className="badge"></span>
                )}
              </div>
            </Dropdown>
            <Dropdown content={<AddCard />}>
              <div>
                <Icon hover="gray200" name="addSquare" c="gray500" w={20} />
              </div>
            </Dropdown>
          </>
        )}
        
        <Dropdown content={<UserMenu />}>
          <Flex
            gap={10}
            sx={{
              cursor: "pointer",
              ":hover": { bgc: "gray100" },
              p: 10,
              br: 15,
              tr: "all .2s ease",
            }}
          >
            <Box
              sx={{
                of: "hidden",
                h: 48,
                br: 15,
              }}
            >
              <img
                src={
                  user.avatar !== ""
                    ? user.avatar
                    : `https://ui-avatars.com/api/?name=${user.displayName}`
                }
                width="48"
                height="48"
                alt=""
              />
            </Box>
            <Type sx={{ fw: 600, m: 0 }}>{user.displayName}</Type>
            <Box sx={{ gc: "3/3", gr: "1/3" }}>
              <Icon name="CaretDown" />
            </Box>
          </Flex>
        </Dropdown>
      </Flex>
    </Flex>
  );
}

export default Header;
