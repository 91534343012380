import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddEmployee from "./AddEmployee";
import AddFromExcel from "./AddFromExcel";
import Avatar from "./Avatar";
import ChangePassword from "./ChangePassword";
import CreateBank from "./CreateBank";
import EditEmployee from "./EditEmployee";
import Employee from "./Employee";
import EmplyeeBank from "./EmplyeeBank";
import ListEmployees from "./ListEmployees";
import ViewEmployee from "./ViewEmployee";

export default (
  <Route
    path="employees"
    element={
      <RequireAuth>
        <Employee />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListEmployees />
        </RequireAuth>
      }
    />
    <Route
      path="company/:id"
      element={
        <RequireAuth>
          <ListEmployees />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddEmployee />
        </RequireAuth>
      }
    />
    <Route
      path="avatar"
      element={
        <RequireAuth>
          <Avatar />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditEmployee />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewEmployee />
        </RequireAuth>
      }
    />

    <Route
      path="changepassword/:id"
      element={
        <RequireAuth>
          <ChangePassword />
        </RequireAuth>
      }
    />
    <Route
      path="addfile"
      element={
        <RequireAuth>
          <AddFromExcel />
        </RequireAuth>
      }
    />
    <Route
      path="bank/:id"
      element={
        <RequireAuth>
          <EmplyeeBank />
        </RequireAuth>
      }
    />
    <Route
      path="createbank/:id"
      element={
        <RequireAuth>
          <CreateBank />
        </RequireAuth>
      }
    />
  </Route>
);
