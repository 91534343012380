import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useYear } from "../../../year/provider";
import ErrorsList from "../../../../ui/ErrorsList";
import { Button, Card, Select, TextField } from "../../../../ui";
import TextArea from "../../../../ui/TextArea";
import { useTransaction } from "../../../transaction/provider";

import { delay } from "../../../../utilities/functions";
import { useAccounts } from "../../provider";

const schema = Yup.object().shape({
  debitAccountId: Yup.number()
    .typeError("required")
    .nullable()
    .required("required"),

  value: Yup.number().required("required").typeError("should be a number"),
});

const Grid = styled.div`
  max-width: 910px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-right: 30px;
    min-width: 180px;
  }
`;

function DebitForm({ selectedId, selected, handleSuccess }) {
  const [error, setErrors] = useState(null);
  const navigate = useNavigate();

  const {
    state: { filters, formData },
    createSimpleEntry: create,
    setFormData,
  } = useTransaction();

  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: { NamesList },
    fetchByName: fetchAccount,
    dispatch,
  } = useAccounts();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });
  const [selectMapOption, setSelectMapOption] = useState({
    value: "id",
    label: "label1",
  });
  let getOptions = (e) => {
    if (isNaN(e)) {
      //label 1
      setSelectMapOption({ value: "id", label: "label" });
    } else {
      //label 2

      setSelectMapOption({ value: "id", label: "label2" });
    }
    if (
      e.length > 2 &&
      NamesList.map(
        (s) => !s?.accountName.toLowerCase().startsWith(e.toLowerCase())[0]
      )
    )
      fetchAccount(
        { companyId: user?.employee?.company?.id, searchText: e },
        true
      );
  };

  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();

  useEffect(() => {
    if (user?.employee?.company?.id)
      fetchYears({ companyId: user?.employee?.company?.id });
  }, [user?.employee?.company?.id]);

  const handleCreditAccountChange = useCallback(
    (e) => {
      if (e.data.id == -1) {
        setFormData(getValues());
        navigate("/accounting/new");
      } else {
        setValue("creditAccountId", e.data.id);
        setValue("creditaccountName", e.data.accountName);
      }
    },
    [setValue]
  );

  const handleDeditAccountChange = useCallback(
    (e) => {
      if (e.data.id == -1) {
        setFormData(getValues());
        navigate("/accounting/new");
      } else {
        setValue("debitAccountId", e.data.id);
        setValue("debitAccountName", e.data.accountName);
      }
    },
    [setValue]
  );

  const onSubmit = async (values) => {
    setErrors({});

    if (values.debitAccountId === values.creditAccountId) {
      setError("debitAccountId", {
        type: "custom",
        message: "credit Account and debit account must be the different",
      });
      setError("creditAccountId", {
        type: "custom",
        message: "credit Account and debit account must be the different",
      });
      return;
    }

    values.financialYearId = filters.yearId;
    values.creditAccountId = selectedId;
    delete values.debitAccountName;
    delete values.creditaccountName;

    setFormData(null);
    // console.log("asdasdasd", values);

    // return;
    try {
      await create({
        ...values,
      });
      reset();

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400)
        setErrors({
          title: "somthing went wrong please check the data and try again",
        });
      if (e.status === 406)
        setErrors({
          title: "please check the account status",
        });
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          {/* <Select
            label="Credit Account"
            data={[
              ...NamesList.map((item) => ({
                ...item,
                label: item.accountName + " - " + item.accountNumber,
                label2: item.accountNumber + " - " + item.accountName,
              })),
              {
                id: -1,
                label: "add account",
                label2: "add accouunt",
                pinned: true,
              },
            ]}
            mapOptions={selectMapOption}
            defaultValue={getValues("creditAccountId")}
            error={!!errors?.creditAccountId}
            setPlaceholderText={getValues("creditaccountName")}
            msg={errors?.creditAccountId?.message}
            getTextValue={delay(getOptions)}
            onChange={handleCreditAccountChange}
          /> */}

          <Select
            label="Debit Account"
            data={[
              ...NamesList.map((item) => ({
                ...item,
                label: item.accountName + " - " + item.accountNumber,
                label2: item.accountNumber + " - " + item.accountName,
              })),
              {
                id: -1,
                label: "add account",
                label2: "add accouunt",
                pinned: true,
              },
            ]}
            mapOptions={selectMapOption}
            defaultValue={getValues("debitAccountId")}
            error={!!errors?.debitAccountId}
            setPlaceholderText={getValues("debitAccountName")}
            msg={errors?.debitAccountId?.message}
            getTextValue={delay(getOptions)}
            onChange={handleDeditAccountChange}
          />

          <TextField
            label="Value"
            name="value"
            error={!!errors?.value}
            msg={errors?.value?.message}
            style={{ marginBottom: "0px" }}
            inputProps={{
              ...register("value"),
            }}
          />
        </Grid>
        <TextArea
          style={{ maxWidth: "910px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="2"
          error={!!errors.notes}
          msg={errors.notes?.message}
          onChange={(e) => {
            setValue("notes", e.target.value);
          }}
          inputProps={{
            ...register("notes"),
          }}
        />
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
          disabled={selected?.children?.length > 0}
        >
          Save
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(DebitForm);
