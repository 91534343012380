import styled from "styled-components";
import { Button, Dropdown, Flex, Icon, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useTransaction } from "./provider";
import StartdateCell from "./tableCells/StartdateCell";
import EnddateCell from "./tableCells/EnddateCell";
import IsLockedCell from "./tableCells/IsLockedCell";
import NotesCell from "./tableCells/NotesCell";
import FilterForm from "./FilterForm";
import { useYear } from "../year/provider";
import { useCompanies } from "../companies/provider";
import { useStatus } from "../../services/StatusProvider";
import { useState } from "react";
import CreatedDateCell from "./tableCells/CreatedDateCell";
import modifiedDateCell from "./tableCells/modifiedDateCell";
import DescCell from "../accounts/tableCell/DescCell";
import { LuSettings } from "react-icons/lu";
import AdminUtils from "./AdminUtils";

const cols = [
  { name: "id", label: "id" },
  { name: "createdDate", label: "Create Date", render: CreatedDateCell },
  { name: "notes", label: "notes", render: NotesCell },
  { name: "description", label: "description", render: DescCell },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
  .back {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
`;

const Utils = styled.div`
  position: fixed;
  right: 0;
  top: 20%;
  width: 50px;
  height: 50px;
  z-index: 8888;
`;

function TransactionList() {
  const { id } = useParams();
  let user = JSON.parse(localStorage.getItem("user"));
  const [error, setError] = useState();

  const {
    state: { isAcc },
  } = useStatus();
  const [companyId, setCompanyId] = useState();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!filters?.companyId) {
      if (!companyId) {
        if (isAcc) {
          setCompanyId(user?.employee?.company?.id);
        } else {
          setCompanyId(companies[0]?.id);
        }
      }
    } else {
      setCompanyId(filters?.companyId);
    }
  }, []);

  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);

  const {
    state: { list, status, count, page, perPage, filters },
    fetchList,
    remove,
    dispatch,
    setFilters,
  } = useTransaction();

  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();

  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    if (years && companyId && !filters)
      setFilters({
        companyId: companyId,
        companyName: user?.employee?.company?.companyName,
        financialYearId: years
          .filter((year) => year.isDefaultYear)
          .map((year) => year.id)[0],
        yearTitle: years.filter((y) => y.isDefaultYear).map((y) => y.title)[0],
      });
  }, [companyId, years]);

  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);
  useEffect(() => {
    if (filters?.yearId && filters?.companyId) fetchList(filters, page);
  }, [fetchList, filters, page, perPage]);

  const handleView = (id) => {
    navigate(`/transaction/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/transaction/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("a journal has been removed successfully.");
    } catch (error) {
      if (error.status === 406)
        setError({
          title: `"${error.errors.message}" `,
        });
      console.log(error);
    }
  };
  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
  useEffect(() => {
    dispatch({ type: "set_selected", payload: null });
  }, []);

  const handleYearClick = () => {
    navigate("/year");
  };

  const handleAccountClick = () => {
    if (!filters.yearId) {
      setError({ title: "please select a year " });
      return;
    }
    navigate("/accounting");
  };

  let show = ["years"];
  // /  if (!companyId) return;
  return (
    <>
      {!isAcc && (
        <Utils>
          <Dropdown content={<AdminUtils id={companyId} />} placement="left">
            <div>
              <Button
                className="small"
                iconName="setting"
                style={{ fontSize: "26px", padding: "0 16px", height: "55px" }}
              >
                <LuSettings />
              </Button>
            </div>
          </Dropdown>
        </Utils>
      )}
      <Grid>
        <h2>Journals List</h2>
        <Button
          className="small"
          onClick={handleAccountClick}
          iconName="invoice"
        >
          Accounts
        </Button>
        <Button className="small" onClick={handleYearClick} iconName="invoice">
          Years
        </Button>
        {/* {isAcc && (
          <Button className="small" onClick={handleClick} iconName="invoice">
            New Entry
          </Button>
        )} */}
        {/* {isAcc && (
            <Button
              className="small"
              onClick={handleSimpleEntryClick}
              iconName="invoice"
            >
              New Simple Entry
            </Button>
          )} */}
      </Grid>
      <FilterForm
        setFilters={setFilters}
        companyId={companyId}
        years={years}
        isAcc={isAcc}
        setCompanyId={setCompanyId}
        error={error}
        setError={setError}
        show={show}
      />
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="journals table"
        cols={cols}
        data={list}
        showButton={handleView}
        moreOptions={isAcc && { edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default TransactionList;
