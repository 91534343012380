import React, { useEffect, useState } from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./customTree.css";
import { useNavigate } from "react-router-dom";
import { forEach } from "lodash";
import { useAccounts } from "../provider";
import styled from "styled-components";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import SearchSection from "./SearchSection";
import { useTransaction } from "../../transaction/provider";

const Style = styled.div`
  width: 100%;
  background: white;
  padding: 10px;
  overflow: scroll;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 4px 0px 19px;
  max-height: 780px;
  min-height: 780px;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 30px;
    height: 16px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6418c3;
  }
  .name {
    display: inline-block;
    margin: 8px 0;
  }
  .selected {
    color: #6418c3;

    font-weight: 700;
  }

  .tree-branch-wrapper {
    width: 250px;
  }
  .tree-node {
    width: 250px !important;
  }
  .basic {
    padding-left: 10px;
  }
  .tree-node-group {
    padding-left: 15px;
  }s
`;
function Tree({ selectedId, setSelectedId }) {
  let navigate = useNavigate();
  const [api, setApi] = useState([
    {
      id: 0,
      accountName: "root",
      baseAccountId: null,
    },
  ]);
  const {
    state: { expandedIds },
    setExpandedIds,
  } = useAccounts();

  const {
    state: { treeList },
    fetchTreeList,
  } = useAccounts();

  const {
    state: { filters },
    setFilters,
  } = useTransaction();

  useEffect(() => {
    if (filters) {
      fetchTreeList({ companyId: filters?.companyId });
    }
  }, [selectedId, setSelectedId, fetchTreeList, filters]);
  useEffect(() => {
    treeList.map((e) => {
      if (e.baseAccountId == null) e.baseAccountId = 0;
      e.name = e.accountNumber + "-" + e.accountName;
    });

    setApi([
      {
        id: 0,
        accountName: "root",
        baseAccountId: null,
      },
      ...treeList,
    ]);
  }, [treeList]);

  const hierarchy = (arr) => {
    const map = {};
    let root;
    for (const ele of arr) {
      map[ele.id] = ele;
      ele.children = [];
      ele.name = ele.accountNumber + " - " + ele.accountName;
    }

    for (const ele of arr) {
      if (map[ele.baseAccountId]) map[ele.baseAccountId].children.push(ele);
      else root = ele;
    }
    return root;
  };

  const handleClick = (id, isBranch) => {
    setSelectedId(id);

    //navigate("view/" + id);
  };

  function removeItem(array, id) {
    let index = [];
    // only splice array when item is found
    index = array.filter((index) => index != id); // 2nd parameter means remove one item only
    return index || [];
  }
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
  //   const removeChildren = (temp , el)=>{
  //     console.log(temp , 'temp');
  //     if(el?.children) {
  //       removeChildren( el?.children,expandedIds.filter((c)=> c.id == temp) )

  //     }
  //     else    return []
  //   }
  const handleNodeClick = (el, isBranch) => {
    if (!expandedIds) return;

    let temp = removeDuplicates(expandedIds);

    // if (expandedIds.length == 0) {
    //   setExpandedIds([el?.id]);
    //   return;
    // }
    const index = temp.indexOf(el?.id);

    if (index > -1) {
      temp = removeItem(temp, el.id);
      // temp = removeChildren(temp , el)
      setExpandedIds(temp);
    } else {
      temp = [...temp, el.id];
      setExpandedIds(temp);
    }
  };
  let tre = flattenTree(hierarchy(api));

  return (
    <>
      <Style className="directory">
        <SearchSection tre={tre} setSelectedId={setSelectedId} />
        <TreeView
          data={tre}
          className="basic"
          aria-label="directory tree"
          defaultExpandedIds={expandedIds}
          togglableSelect
          clickAction="EXCLUSIVE_SELECT"
          multiSelect
          nodeRenderer={({
            element,
            isBranch,
            getNodeProps,
            level,
            handleExpand,
            isExpanded,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 40 * (level - 1), width: "fit-content" }}
              >
                <p
                  onClick={() => handleNodeClick(element, isBranch)}
                  onDoubleClick={() => handleClick(element.id, isBranch)}
                  className={
                    element.id === selectedId ? "selected name" : "name"
                  }
                >
                  {isBranch ? (
                    isExpanded ? (
                      <MdKeyboardArrowDown />
                    ) : (
                      <MdKeyboardArrowRight />
                    )
                  ) : (
                    "-"
                  )}
                  {element.name}
                </p>
              </div>
            );
          }}
        />
      </Style>
    </>
  );
}

export default Tree;
