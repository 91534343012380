import styled from "styled-components";

const Input = styled.input`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  & + label {
    display: block;
    position: relative;
    padding: 0 10px;
    line-height: 0;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    &::before {
      content: "";
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 18px;
      height: 18px;
      top: 4px;
      background: white;
      border: #bebebe 1px solid;
      border-radius: 4px;
    }
  }
  &:checked + label::before {
    background: #38a8af;
    border: #38a8af 1px solid;
  }
  &:checked + label::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 13px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    height: 5px;
    width: 11px;
    transform: rotate(-45deg);
  }
  &:focus + label::before {
    outline: #5d9dd5 solid 1px;
    box-shadow: 0 0px 8px ${(props) => props.theme.color.primary};
  }
  &:disabled + label {
    color: #575757;
  }
  &:disabled + label::before {
    background: #ddd;
  }
`;

function Checkbox({
  id = "",
  value = "",
  name = "",
  label,
  inputProps,
  ...props
}) {
  return (
    <div {...props}>
      <Input
        type="checkbox"
        {...{ id: id || name, value, name, ...inputProps }}
      />
      <label htmlFor={id || name}>{label}</label>
    </div>
  );
}

export default Checkbox;
