import { useContext, useReducer, createContext, useCallback } from "react";

const Context = createContext();

export function useStatus() {
  return useContext(Context);
}

const initState = {
  isAcc: null,
  isAdmin: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_isAcc":
      return { ...state, isAcc: action.payload };
    case "set_isAdmin":
      return { ...state, isAcc: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function StatusProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);

  const setIsAcc = useCallback((isAcc) => {
    dispatch({ type: "set_isAcc", payload: isAcc });
  }, []);
  const setIsAdmin = useCallback((isAdmin) => {
    dispatch({ type: "set_isAdmin", payload: isAdmin });
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        setIsAcc,
        setIsAdmin,
      }}
    >
      {children}
    </Context.Provider>
  );
}
