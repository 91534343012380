import { memo, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, Divider, Pagination, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import { useAccounts } from "./provider";
import {
  accountsStatus,
  accountType,
  specialType,
  ActiveEnum,
} from "../../data/Enums";
import TextArea from "../../ui/TextArea";
import TableContainer from "../../ui/TableContainer";
import NoteCell from "./tableCell/NoteCell";
import { useUtils } from "../../services/utilsProvider";
import { useStatus } from "../../services/StatusProvider";
import AccountType from "./tableCell/AccountType";
import SpecialType from "./tableCell/SpecialType";
import AccountStatus from "./tableCell/AccountStatus";
import IsActive from "./tableCell/IsActive";

const childrenCols = [
  { name: "accountName", label: "Account Name" },
  { name: "accountNumber", label: "Account Number" },
  { name: "companyName", label: "Company Name" },
  { name: "accountType", label: "Account Type", render: AccountType },
  { name: "specialType", label: "Special Type", render: SpecialType },
  { name: "accountStatus", label: "Account Status", render: AccountStatus },
  { name: "baseAccountId", label: "Base Account" },
  { name: "isActive", label: "Is Active", render: IsActive },
  { name: "startBalance", label: "Start Balance" },
  { name: "balance", label: "Balance" },
  { name: "notes", label: "notes", render: NoteCell },
];

const entriesCols = [
  { name: "accountName", label: "Account Name" },
  { name: "debit", label: "debit" },
  { name: "credit", label: "credit" },
  { name: "notes", label: "notes", render: NoteCell },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  row-gap: 0;
`;

function ViewAccount({ selectedId, setSelectedId }) {
  const params = useParams();
  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();

  const {
    state: { isAcc },

    setIsAcc,
  } = useStatus();

  const {
    state: { selected },
    fetchOne,
    remove,
    setAccountName,
  } = useAccounts();

  useEffect(() => {
    if (selectedId) fetchOne(selectedId);
  }, [selectedId, fetchOne]);

  let getValueFromEnum = (value, valueEntry, dataenum, returnedValueEntry) => {
    return dataenum
      .filter((data) => data[valueEntry] === value)
      .map((data) => data[returnedValueEntry]);
  };

  const handleClick = (id) => {
    setAccountName(selected.accountName);
    navigate(`/accounting/new/${selectedId}`);
  };

  const handleView = (id) => {
    navigate(`/accounting/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/accounting/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      setSelectedId(null);

      notify("account has been removed successfull.");
    } catch (error) {
      alertUser("account has Entries or childern attached.");
    }
  };

  console.log(selected, "account");
  if (!selectedId) return <h2>Please Select an Account</h2>;
  if (!selected || selected.id !== Number(selectedId)) return;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Account Information</h2>
        {isAcc && (
          <div style={{ cursor: "pointer" }}>
            <Button
              style={{
                paddingRight: "40px",
                paddingLeft: "40px",
                marginLeft: "30px",
              }}
              iconName="pen"
              className="small"
              onClick={() => navigate(`/accounting/edit/${selectedId}`)}
            >
              Edit
            </Button>
            <Button
              style={{
                paddingRight: "40px",
                paddingLeft: "40px",
                marginLeft: "30px",
                backgroundColor: "red",
              }}
              iconName=""
              className="small"
              onClick={() => {
                handleDelete(selectedId);
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </Grid>
      <Card>
        <Grid2>
          <TextField
            required
            label="Company"
            name="companyName"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.companyName,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Account Name"
            name="accountName"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.accountName,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Base Account"
            name="baseAccountName"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.baseAccountName,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Account Status"
            name="accountStatus"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: getValueFromEnum(
                selected?.accountStatus,
                "id",
                accountsStatus,
                "status"
              ),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Account type"
            name="accountType"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: getValueFromEnum(
                selected?.accountType,
                "id",
                accountType,
                "type"
              ),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Special type"
            name="specialType"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: getValueFromEnum(
                selected?.specialType,
                "id",
                specialType,
                "type"
              ),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Account Number"
            name="accountNumber"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.accountNumber,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Active"
            name="isActive"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: getValueFromEnum(
                Number(selected?.isActive),
                "id",
                ActiveEnum,
                "type"
              ),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Start Balance"
            name="startBalance"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.startBalance,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Balance"
            name="balance"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: selected?.balance,
              disabled: true,
            }}
          />
        </Grid2>
        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="5"
          value={selected?.notes}
          inputProps={{
            disabled: true,
          }}
        />

        {/* <Divider h={20} /> */}

        {/* {selected?.entries.length == 0 && (
          <>
            <Grid>
              <h3 style={{ display: "inline" }}>Children Accounts List</h3>
              {isAcc && (
                <Button
                  className="small"
                  onClick={handleClick}
                  iconName="invoice"
                >
                  New Entry
                </Button>
              )}
            </Grid>
      
            <TableContainer
              empty={selected?.children?.length === 0}
              aria-label="Accounts table"
              cols={childrenCols}
              data={selected?.children}
              showButton={handleView}
              moreOptions={{ edit: handleEdit, delete: handleDelete }}
            />
          </>
        )} */}

        {/* <Divider h={20} /> */}

        {/* <Grid> */}
        {/* <h3 style={{ display: "inline" }}>Entries List</h3> */}
        {/* {isAcc && (
            <Button className="small" onClick={handleClick} iconName="invoice">
              New Entry
            </Button>
          )} */}
        {/* </Grid> */}
        {/* children table */}
        {/* <TableContainer 
          empty={selected?.entries?.length === 0}
          aria-label="entries table"
          cols={entriesCols}
          data={selected?.entries}
          // showButton={handleView}
          // moreOptions={{ edit: handleEdit, delete: handleDelete }}
        /> */}
      </Card>
    </div>
  );
}

export default memo(ViewAccount);
