import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Button, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import {  useNotification } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import { useState } from "react";
import { useEmployees } from "../employees/provider";
import { useDepartments } from "../departments/provider";
import { useCompanies } from "../companies/provider";
import { useNotify } from "../../layout/provider";
import TitleCell from "./tableCell/TitleCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;


const Grid2 = styled.div`
	max-width: 920px;
	padding-bottom:25px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 32px;
	row-gap: 0;
`;
function ListNotification() {
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const {
    state: { list, status , count, page, perPage , companyID , departmentID , employeeID },
    fetchList,
    remove,
    dispatch
  } = useNotification();
  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const cols = [
    { name: "title", label: "Title" , render: TitleCell },
    {
      name: "body",
      label: "Body",
    },
    //{ name: "employeeName", label: "Employee Name" },
    { name: "typeName", label: "Type" },

    {
      name: "readDate",
      label: "readDate",
    },
   
  ];

  const handleClick = () => {
    navigate("/notification/new");
  };

  const handleChangePage = (newPage) =>{
    dispatch({ type: "set_page", payload: newPage });
}

//fetch companies

const {
  state: { list: companies },
  fetchList: fetchCompanies,
} = useCompanies();
const {state : {notify : noti} ,dispatch : dispatchNotifiy} = useNotify()


const handleCompanyChange = useCallback((id) => {
  dispatch({type:'companychange' , payload:id.data.id})
  dispatch({type:'departmentchange' , payload:null})
  dispatch({type:'employeechange' , payload:null})

}, []);

const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

useEffect(() => {
  fetchCompanies();
}, [fetchCompanies]);

//fetch departments

const {
  state: { list: departmentList },
  fetchList: fetchDepartments,
} = useDepartments();

useEffect(()=>{
  if(companyID)fetchDepartments(companyID)
},[companyID])

const handleDepartmentChange = useCallback(
  (id) => {
  dispatch({type:'departmentchange' , payload:id.data.id ??  0})
  },
  [departmentList , companyID]
);

const mapDepartmentOptions = useRef({ value: "id", label: "departmentName" });

useEffect(() => {
  if (companyID) fetchDepartments(companyID);
}, [companyID]);

//fetch employees
const {
  state: { list: employees },
  fetchList: fetchEmployees,
} = useEmployees();

useEffect(() => {
  
  if (companies && !companyID) dispatch({type:'companychange' , payload:companies[0]?.id})
}, [companies]);

const handleEmployeeChange = useCallback((id) => {
  dispatch({type:'employeechange' , payload:id.data.id})
}, []);

useEffect(() => {
  if (companyID) fetchEmployees(companyID,1, 100 ,departmentID);
}, [companyID, departmentID]);

const mapEmployeeOptions = useRef({ value: "id", label: "fullName" });


const fliterData = ()=>{
  console.log('callesd');
  fetchList(companyID , departmentID ,employeeID)
}

  if(noti){
    fliterData()
    dispatchNotifiy({ type: "notify", payload: false });
  }

  // useEffect(()=>{
  //   dispatchNotifiy({ type: "set_selected", payload: null });
  // },[])
  
  if (!list ) return
  return (
    <>
      <Grid>
        <h2>Notification List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
        {/* <Grid2>
        <div>
        <Select
              inputStyle={{
                height: "60px",
                width: "100%",
                marginBottom: "20px",
              }}
              data={companies}
              mapOptions={mapCompanyOptions.current}
              onChange={handleCompanyChange}
              defaultValue={companyID}
              label={"companies"}
              inputProps={{
                value: companyID,
                readOnly: true,
              }}
            />
        </div>
        <div>
              <Select
                style={{
                  height: "60px",
                  width: "100%",
                  marginBottom: "20px",
                }}
                data={departmentList}
                mapOptions={mapDepartmentOptions.current}
                onChange={handleDepartmentChange}
                defaultValue={departmentID}
                label={"department"}
                inputProps={{
                  value: departmentID,
                  readOnly: true,
                }}
              />
            </div>
            <div>
            <Select
              style={{
                height: "60px",
                width: "100%",
                marginBottom: "20px",
              }}
              data={employees}
              mapOptions={mapEmployeeOptions.current}
              onChange={handleEmployeeChange}
              defaultValue={employeeID}
              label={"Employee"}
              inputProps={{
                value: companyID,
                readOnly: true,
              }}
            />

            
          </div>
          <div>
          <Button
                iconName="Eye"
                className="small"
                style={{
                  marginTop:'50px'
                }}
                onClick={fliterData}
              >
                view Data
              </Button>
          </div>
        </Grid2> */}
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="PublicHoliday table"
        cols={cols}
        data={list}

      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default ListNotification;
