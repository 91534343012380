import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card, Divider } from "../../../ui";

const StyledCard = styled(Card)`
  max-width: 380px;
  min-width: 380px;
  padding: 0;
  margin-bottom: 10px;
  border: 1px solid #e6e6e6;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 19px;
    padding: 6px 20px;
    span {
      display: flex;
      align-items: center;
      time {
        padding-left: 10px;
      }
    }
    img {
      border-radius: 10px;
    }
  }
  .contents {
    padding: 6px 20px;
    strong {
      font-size: 18px;
    }
    p {
      font-size: 15px;
      color: ${(props) => props.theme.color.gray400};
      font-family: ${(props) => props.theme.fonts.openSans};
      margin: 5px 0;
    }
  }
`;

function Note({ title, children, noti }) {
  let navigate = useNavigate();

  return (
    <StyledCard>
      <div className="top">
        <strong
          onClick={() => navigate("/leaves/view/" + noti?.referenceID)}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {title}
        </strong>
      </div>
      <Divider border="gray200" h={2} />
      <div className="contents">
        <p>{children}</p>
      </div>
    </StyledCard>
  );
}

export default Note;
