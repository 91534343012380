import * as Yup from "yup";

const fields = [
  {
    name: "displayName",
    type: "text",
    label: "Display Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "min",
        params: [5],
      },
    ],
    
  },
  {
    name: "username",
    type: "text",
    label: "Username",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "min",
        params: [5],
      },
    ],
  },
  {
    name: "email",
    type: "email",
    label: "Email Address",
    validationType: "string",
    validations: [
      {
        type: "email",
        params: [],
      },
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "min",
        params: [8, "Password must be 8 characters long"],
      },
      {
        type: "matches",
        params: [/[0-9]/, "Password requires a number"],
      },
      {
        type: "matches",
        params: [/[a-z]/, "Password requires a lowercase letter"],
      },
      {
        type: "matches",
        params: [/[A-Z]/, "Password requires an uppercase letter"],
      },
      {
        type: "matches",
        params: [/[^\w]/, "Password requires a symbol"],
      },
    ],
  },
  {
    name: "confirm",
    type: "password",
    label: "Confirm Password",
    validationType: "string",
    validations: [
      {
        type: "oneOf",
        params: [[Yup.ref('password'), null], 'Must match "password" field value'],
      },
    ]
  }
];

export default fields;
