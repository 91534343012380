import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import EmployeeForm from "./EmployeeForm";
import { useEmployees } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const {
    state: { selected: employee },
    fetchOne,
    dispatch,
  } = useEmployees();

  useEffect(() => {
    fetchOne(params.id);
    return () => dispatch({ type: "set_selected", payload: null });
  }, [params.id, fetchOne, dispatch]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

   if (!employee) return;

  return (
    <div id="edit-employee">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Employee</h2>
        <div style={{ cursor: "pointer" }} onClick={() =>{ navigate(-1)
        return () => dispatch({ type: "set_selected", payload: null });
        }}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      {(employee && employee.id === Number(params.id)) && <EmployeeForm employee={employee} handleSuccess={handleSuccess} />}
    </div>
  );
}

export default memo(EditProduct);
