import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Holiday() {
  // const { dispatch } = useEmployees();

  // useEffect(() => {
  //   return () => {
  //     dispatch({ type: "set_per_page", payload: 10 });
  //     dispatch({ type: "set_page", payload: 0 });
  //   };
  // }, [dispatch]);

  return <Layout><Outlet /></Layout>;
}

export default Holiday;
