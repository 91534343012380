import React, { useEffect, useState } from "react";
import TableContainer from "../../../ui/TableContainer";
import FilterForm from "../FilterForm";
import { Button, Card, Flex, Pagination, TextField } from "../../../ui";
import styled from "styled-components";
import { useYear } from "../../year/provider";
import { useTransaction } from "../provider";
import { ceil, filter, floor } from "lodash";
import { useStatus } from "../../../services/StatusProvider";
import { useCompanies } from "../../companies/provider";
import DebitCell from "./GetJournalsCells/DebitCell";
import CreditCell from "./GetJournalsCells/CreditCell";
import {
  dateToLocalConverter,
  exporToExcle,
} from "../../../utilities/functions";
import AccountNameCell from "./GetJournalsCells/AccountNameCell";
const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

const cols = [
  { name: "debit", label: "" },
  { name: "credit", label: "" },
  { name: "account", label: "Account", render: AccountNameCell },
];
function GetIncomeStatment() {
  const [error, setError] = useState();
  const [companyId, setCompanyId] = useState();
  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: {
      incomeStatment,
      incomeStatmentstatus,
      incomeStatmentcount,
      incomeStatmentpage,
      perPage,
      filters,
      Alllist,
    },
    GetIncomeStatment: fetchList,
    setFilters,
    getJournal,
    fetchAll,
  } = useTransaction();
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!years && companyId && !filters)
      setFilters({
        companyId: companyId,
        //financialYearId: years[0]?.id,
      });
  }, [companyId, years]);

  const {
    state: { isAcc },
  } = useStatus();

  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);
  let show = ["years", "quarter"];

  const [journalEntries, setJournalEntries] = useState([]);
  // const [resList, setResList] = useState([]);
  const getAllPages = async (api, filters, fullSize, pageSize = 150) => {
    let pages = parseInt(fullSize / pageSize);
    for (let i = 0; i < pages + 1; i++) {
      await api(filters, i, pageSize);
    }
  };

  useEffect(() => {
    if (filters?.years) getAllPages(fetchAll, filters, incomeStatmentcount);
  }, [filters]);

  const manipulateObj = () => {
    let newObj = new Array(
      incomeStatment?.revenue?.length + incomeStatment?.expenses?.length
    );
    let revGroub = "";
    incomeStatment?.revenue.map((is, i) => {
      if (is.group != revGroub && is.group != null) {
        newObj.push({
          debit: "",
          credit: "",
          account: is.group,
          isGroup: true,
        });
        revGroub = "";
      }

      newObj.push({
        debit: is.value,
        credit: "",
        account: is.accountName,
        isGroup: false,
      });
    });
    let exGroub = "";

    incomeStatment?.expenses.map((is, i) => {
      if (is.group != exGroub && is.group != null) {
        newObj.push({
          debit: "",
          credit: "",
          account: is.group,
          isGroup: true,
        });
        revGroub = "";
      }

      newObj.push({
        debit: "",
        credit: is.value,
        account: is.accountName,
        isGroup: false,
      });
    });

    if (incomeStatment.isProfit) {
      newObj.push({
        debit: "",
        credit: incomeStatment.netValue,
        account: "net value",
      });
    } else {
      newObj.push({
        debit: incomeStatment.netValue,
        credit: "",
        account: "net value",
      });
    }
    return newObj.filter((item) => {
      return item;
    });
  };

  const getExcelObj = (x) => {
    console.log(journalEntries, "journalEntries");
    let newObj = new Array(journalEntries.length);
    journalEntries?.map((item, idx) => {
      newObj.push({
        Debit: item.debit,
        Credit: item.credit,
        Account: item.account,
      });
    });
    return newObj.filter((item) => {
      return item;
    });
  };

  // const handleChangePage = (newPage) => {
  //   setJournalEntries([]);
  //   dispatch({ type: "set_income_statment_paged", payload: newPage });
  // };

  useEffect(() => {
    if (incomeStatment.expenses) setJournalEntries(manipulateObj());
  }, [incomeStatment]);
  useEffect(() => {
    if (filters?.yearId) fetchList(filters, incomeStatmentpage);
  }, [fetchList, filters, incomeStatmentpage, perPage]);

  return (
    <>
      <Grid>
        <h2>Get Income Statment</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            filters.yearId &&
            exporToExcle(
              getExcelObj(Alllist),
              "Income Statment  in: " +
                years.filter((y) => y.id == filters.yearId).map((y) => y.title)
            )
          }
        >
          Export Excel
        </Button>
      </Grid>

      <FilterForm
        setFilters={setFilters}
        years={years}
        isAcc={isAcc}
        error={error}
        setError={setError}
        companyId={companyId}
        show={show}
        //  setJournalEntries={setJournalEntries}
      />

      <>
        <TableContainer
          empty={journalEntries.length == 0}
          status={incomeStatmentstatus}
          aria-label="income statment table"
          cols={cols}
          data={journalEntries}
          checkbox
        />
      </>
      {/* <Pagination
        data-testid="pagination"
        count={incomeStatmentcount}
        page={incomeStatmentpage}
        rowsPerPage={10}
        onPageChange={handleChangePage}
      /> */}
    </>
  );
}

export default GetIncomeStatment;
