import { emptyStringToNull } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "amount",
    type: "number",
    label: "Amount",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
      {
        type: "min",
        params: [1, "Amount must be greater than 0"],
      },
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "notes",
    type: "text",
    required: "required",
    label: "Notes",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
];

export default fields;
