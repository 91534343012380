import * as yup from "yup";
import {
  emptyStringToNull,
  emptyStringToZero,
  parseDate,
} from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "accountName",
    type: "text",
    label: "Account Name",
    validationType: "string",
    inputProps: {
      autoFocused: true,
    },
    onlyViewName: "accountName",

    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
    ],
  },
  {
    name: "companyId",
    viewName: "company Name",
    newOnly: true,
    type: "number",
    resourceName: "companies",
    data: [],
    defaultValue: "companyId",
    mapData: { value: "id", label: "businessName" },
    label: "Company",
    validationType: "number",
    msg: " ",
    viewOnluy: true,
    onlyViewName: "companyName",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "of",
        params: (yup) => yup.number().min(1).typeError(" "),
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },

  {
    name: "accountStatus",
    viewName: "Account Status",
    type: "number",
    resourceName: "accountsStatus",
    data: [],
    defaultValue: null,
    mapData: { value: "id", label: "status" },
    label: "Account Status",
    validationType: "number",
    msg: " ",

    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToZero],
      },
    ],
  },

  {
    name: "accountType",
    viewName: "Account Type",
    type: "number",
    resourceName: "accountType",
    data: [],
    defaultValue: null,
    mapData: { value: "id", label: "type" },
    label: "Account Type",
    validationType: "number",
    msg: "",
    validations: [
      // {
      //   type: "required",
      //   params: ["This field is required"],
      // },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },

  {
    name: "specialType",
    viewName: "Special Type",
    type: "number",
    resourceName: "specialType",
    data: [],
    defaultValue: null,
    mapData: { value: "id", label: "type" },
    label: "Special Type",
    validationType: "number",
    msg: " ",
    validations: [
      {
        type: "required",
        params: ["This field is required"],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToZero],
      },
    ],
  },

  {
    name: "startBalance",
    type: "text",
    label: "Start Balance",
    validationType: "string",
    defaultValue: "startBalance",

    validations: [
      // {
      //   type: "required",
      //   params: ["This field is required"],
      // },
      // {
      //   type: "typeError",
      //   params: ["must be a number"],
      // },
      // {
      //   type: "transform",
      //   params: [emptyStringToNull],
      // },
    ],
  },
];

export default fields;
