import React from 'react'

function StatusCell(r) {
    let status = [
        {id:1 ,name: 'New'},
        {id:2 ,name: 'Processing'},
        {id:3 ,name: 'Done'},
        {id:4 ,name: 'Cancelled'},

    ]
  return (
    <div>{status.filter(e=>r.status === e.id).map(e=> e.name)}</div>
  )
}

export default StatusCell