import { memo, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import { useEmployees } from "../employees/provider";
import UserAccountForm from "./UserAccountForm";
import EmployeeForm from "./UserAccountForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddUserAccount() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const {
    state: { selected: employee },
    fetchOne,
  } = useEmployees();

  useEffect(() => {
    fetchOne(params.id);
  }, [fetchOne, params.id]);

  const handleSuccess = () => {
    navigate(-1);
    notify("a new user account has been created successfully.");
  };

  return (
    <div id="add-user-account">
      <Grid>
        <h2 style={{ marginTop: "0" }}>
          Add New User Account for employee {employee?.fullName} {" "}
          {employee?.companyName}
        </h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <UserAccountForm employeeId={params.id} handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(AddUserAccount);
