import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useAccounts() {
  return useContext(Context);
}
const DEFAULT_PAGESIZE = 10;
// to chose either 1 for list view 2 for TreeView
const DEFAULT_VIEWTYPE = 2;

const initState = {
  list: [],
  treeList: [],
  NamesList: [],
  childrenlist: [],
  expandedIds: [],
  exportData: [],

  accountName: null,
  filters: null,
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
  viewType: DEFAULT_VIEWTYPE,
  id: 0,
  treeId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_exportData":
      return { ...state, exportData: [...action.payload] };
    case "set_tree_list":
      return { ...state, treeList: [...action.payload] };
    case "set_name_list":
      return { ...state, NamesList: [...action.payload] };
    case "set_chlidren_list":
      return { ...state, childrenlist: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "deletechild":
      const childrenlist = state.childrenlist.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, childrenlist: childrenlist };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_filters":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "set_view_type":
      return { ...state, viewType: action.payload };
    case "set_expanded_nodes":
      return { ...state, expandedIds: action.payload };

    case "set_account_name":
      return { ...state, accountName: action.payload };

    case "set_id":
      return { ...state, id: action.payload };

    case "set_tree_id":
      return { ...state, treeId: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function AccountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };

      const resData = await req(
        `Accounting/ListAccounts`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.accounts });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchTreeList = useCallback(
    async (filter, page = 0) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };
      console.log(filter, "chart");

      const resData = await req(
        `Accounting/ChartOfAccounts`,
        null,
        {},
        true,
        headerData
      );
      console.log(resData, "chart");
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_tree_list", payload: resData.data.accounts });
    },
    [req]
  );

  const fetchByName = useCallback(
    async (filter, isNode, page = 0) => {
      console.log("filter", filter);
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        ...filter,
        isNode: isNode,
      };

      const resData = await req(
        `Accounting/FindAccount`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_name_list", payload: resData.data });
    },
    [req]
  );

  const fetchCildrenList = useCallback(
    async (id, filter, page = 0) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        pageNumber: page + 1,
        ...filter,
      };
      delete headerData.isActive;
      const resData = await req(
        `Accounting/ListAccountChildren/${id}`,
        null,
        {},
        true,
        headerData
      );

      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_chlidren_list", payload: resData.data.accounts });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(
          `Accounting/AccountInfo/${id}/`,
          null,
          {},
          true
        );
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          data.baseAccountId === 0 && delete data.baseAccountId;

          const resData = await req(
            "Accounting/CreateAccount",
            data,
            { method: "POST" },
            true
          );
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        console.log(id, data);
        dispatch({ type: "status", payload: `creating` });
        if (data.baseAccountId == 0) delete data.baseAccountId;
        try {
          const resData = await req(
            `Accounting/UpdateAccount/${id}`,
            data,
            { method: "PUT" },
            true
          );

          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(
        `Accounting/DeleteAccount/${id}`,
        {},
        { method: "DELETE" },
        true
      );
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const removeChild = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(
        `Accounting/DeleteAccount/${id}`,
        {},
        { method: "DELETE" },
        true
      );
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "deletechild", payload: id });
    },
    [req]
  );

  const cloneChartOfAccount = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `cloning` });
        try {
          const resData = await req(
            "AccountingUtils/CloneChartOfAccounts",
            data,
            { method: "POST" },
            true
          );
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  const setViewType = useCallback((type) => {
    dispatch({ type: "set_view_type", payload: type });
  }, []);

  const setExpandedIds = useCallback((ids) => {
    dispatch({ type: "set_expanded_nodes", payload: ids });
  }, []);

  const setAccountName = useCallback((accountName) => {
    dispatch({ type: "set_account_name", payload: accountName });
  }, []);

  const setId = useCallback((id) => {
    dispatch({ type: "set_id", payload: id });
  }, []);

  const setTreeId = useCallback((id) => {
    dispatch({ type: "set_tree_id", payload: id });
  }, []);

  const getData = useCallback(
    async (filter) => {
      dispatch({ type: "status", payload: "fetching" });
      let headerData = {
        ...filter,
      };
      const resData = await req(
        `AccountingUtils/ExportChartOfAccounts`,
        null,
        {},
        true,
        headerData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_exportData", payload: resData.data });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchCildrenList,
        fetchOne,
        create,
        edit,
        remove,
        setFilters,
        setViewType,
        setExpandedIds,
        fetchByName,
        fetchTreeList,
        setAccountName,
        cloneChartOfAccount,
        getData,
        removeChild,
        setId,
        setTreeId,
      }}
    >
      {children}
    </Context.Provider>
  );
}
