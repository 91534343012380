import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useCurrency } from "./provider";
import { Button, Card, Checkbox, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
// import UploadFile from "../../ui/UploadFile";
import FileField from "../../ui/FileField";

Number.prototype.padLeft = function (base, chr) {
	var len = String(base || 10).length - String(this).length + 1;
	return len > 0 ? new Array(len).join(chr || '0') + this : this;
};

function emptyDate(originalValue) {
  originalValue = new Date(originalValue)
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return originalValue.getFullYear() + "-" + (originalValue.getMonth()+1).padLeft() + "-" + originalValue.getDate().padLeft();
}

const schema = Yup.object().shape({
  name: Yup.string().required(),
  shortName: Yup.string().required(),
  symbol: Yup.string().required(),
});

const Grid = styled.div`
	max-width: 710px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;
	row-gap: 0;
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function CurrencyForm({ holiday = null, handleSuccess }) {
  let params = useParams()
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { create, edit } = useCurrency();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: holiday,
  });

  const onSubmit = async (values) => {
    try {
      if (holiday) await edit(holiday.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status == 409) setError({title :"Already exists"});
      console.log("e", e , error);
    }
  };
 
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
            <TextField
              label="Name"
              name="name"
              error={!!errors.name}
              msg={errors.name?.message}
              defaultValue={holiday?.name}
              inputProps={{
                ...register("name"),
                autoFocus: true,
                required: true,
              }}
            />
         
            <TextField
						required
						label='Short Name'
						name="shortName"
						error={!!errors.shortName}
						msg={errors.shortName?.message}

						inputProps={{
							...register('shortName'),
							required: true,
						}}
					/>
            <TextField
						required
						label={'Symbol'}
						name="symbol"
						error={!!errors.symbol}
						msg={errors.symbol?.message}
						inputProps={{
							...register('symbol'),
							required: true,
						}}
					/>
        
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button type="button" className="outlined small" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(CurrencyForm);
