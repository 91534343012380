import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../../services/utilsProvider";
import { Icon } from "../../../ui";
import BreakForm from "./BreakForm";
import { useAttendance } from "../provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditBreak() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const {
    state: {
      brek,
    },
    fetchBreak,
    dispatch
  } = useAttendance();

  const handleSuccess = () => {
    dispatch({type:'set_break' , payload:null})
    navigate(-1);
    notify("Changes saved successfully");
  };

 
  useEffect(()=>{
    !brek && brek?.id !== params.id && fetchBreak(params.id)
  },[])

  if(!brek) return
  return (
    <div id="edit-leave">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Break</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <BreakForm brek={brek} handleSuccess={handleSuccess} />
    </div>
  );
}

export default EditBreak;
