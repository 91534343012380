import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../Dropdown";
import Label from "../InputLabel";
import Options from "./Options";
import Selected from "./Selected";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  .msg {
    color: ${(props) => props.theme.color.grayText};
    margin-top: 5px;
    margin-left: 10px;
  }
  &.error {
    .msg {
      color: ${(props) => props.theme.color.red};
      margin-bottom: 0;
      line-height: 24px;
    }
  }
`;

function Select({
  data = [],
  mapOptions,
  label = "",
  onChange = () => {},
  inputProps,
  inputStyle,
  msg = null,
  error = false,
  defaultValue,
  disabled = false,
  multi = false,
  getTextValue,
  setPlaceholderText,
  ...rest
}) {
  const [selected, setSelected] = useState([{ name: `Select ${label}` }]);
  const [open, setOpen] = useState(0);
  const [w, setW] = useState(0);
  const [term, setTerm] = useState("");
  const selectedEl = useRef(null);
  const mappedData = useRef(null);

  mappedData.current = data.map((item) => ({
    name: mapOptions ? item[mapOptions.label] : item.toString(),
    value: mapOptions ? item[mapOptions.value] : item,
    data: item,
  }));

  useEffect(() => {
    setW(selectedEl.current.offsetWidth);
  }, []);

  const handleInput = (e) => {
    setTerm(e.target.value);
  };

  const onSelect = (item) => {
    setSelected((s) => {
      let a = s;
      if (s.length && !s[0].hasOwnProperty("data")) a.shift();

      if (!multi) return [item];

      if (a.map((i) => i.value).includes(item.value)) {
        return a.filter((i) => i.value !== item.value);
      } else return [...a, item];
    });
    setOpen(0);
    setTerm("");
  };
  useEffect(() => {
    if (!(selected.length && selected[0].hasOwnProperty("data"))) return;

    onChange(multi ? selected : selected[0], inputProps?.name);
  }, [selected, multi, onChange, inputProps?.name]);

  useEffect(() => {
    if (defaultValue)
      setSelected(
        mappedData.current.filter((item) => {
          let values = defaultValue;
          if (!multi) values = [defaultValue];
          return values?.includes(item.value);
        })
      );
  }, [defaultValue, multi]);

  return (
    <Wrapper
      className={`${error ? "error custom-select" : "custom-select"}`}
      {...rest}
    >
      {label.length ? (
        <Label htmlFor={label.replace(/\s/g, "-").toLowerCase()}>{label}</Label>
      ) : (
        ""
      )}

      <Dropdown
        space={5}
        content={
          <Options
            {...{
              data: mappedData.current,
              selected,
              onSelect,
              term,
              w,
              multi,
            }}
          />
        }
        show={open}
        setShow={setOpen}
      >
        <span>
          <Selected
            {...{
              open,
              label,
              selected,
              // defaultValue: mappedData.current.filter((item) => {
              //   let values = defaultValue;
              //   if (!multi) values = [defaultValue];
              //   return values?.includes(item.value);
              // }),
              query: term,
              handleInput,
              setPlaceholderText,
              disabled,
              getTextValue,
              style: { height: "60px", ...inputStyle },
            }}
            refEl={selectedEl}
          />
          <input {...inputProps} style={{ display: "none" }} />
        </span>
      </Dropdown>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default Select;
