import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { useProject } from "./provider";
import { useCompanies } from "../companies/provider";
import { Button, Card, Flex, Pagination, Select } from "../../ui";
import SearchInput from "../../ui/SearchInput";
import TableContainer from "../../ui/TableContainer";
import CompanyNameCell from "../companies/listCompanies/CompanyNameCell";
import FilterForm from "./FilterForm";
import TagCell from "./render/TagCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
const ProjectsList = () => {
  let theme = useTheme();
  const [search, setSearch] = useState("");

  const { cid } = useParams();
  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();

  const {
    state: { list, page, filters, status, count },
    fetchList,
    remove,
    dispatch,
    setFilters,
  } = useProject();

  const {
    state: {
      list: companies,
      // selected: { company },
    },
    fetchList: fetchCompanies,
    // dispatch,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // useEffect(() => {
  //   if (!companies.length) return;
  //   dispatch({ type: "set_selected", payload: companies[0], keep: true });
  // }, [companies, dispatch]);
  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };
  useEffect(() => {
    fetchList({ ...filters, pagenumber: page + 1 }, cid);
  }, [cid, fetchList, filters]);

  const cols = [
    { name: "projectName", label: "Project Name" },
    {
      name: "companyId",
      label: "Company",
      render: CompanyNameCell,
      renderParams: companies.find((c) => c.id === Number(cid)),
    },
    { name: "tags", label: "Tags", render: TagCell },
  ];

  const handleClick = () => {
    navigate(`/projects/company/${cid}/new`);
  };

  const handleEdit = (id) => {
    navigate(`/projects/company/${cid}/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Changes saved successfully");
    } catch (e) {
      if (e.status === 400) alertUser(e.errors);
      else if (e.status === 405)
        alertUser(
          `The department can’t be deleted because of dependent records`
        );
      console.log(e);
    }
  };

  if (!list) return;
  return (
    <>
      <Flex jc="space-between">
        <h2>Projects List</h2>

        <Flex jc="space-between" gap={"10px"}>
          <Button className="small" onClick={handleClick} iconName="invoice">
            New Entry
          </Button>
        </Flex>
      </Flex>
      <Card sx={{ mb: 40, pb: 30, mt: 20 }}>
        <FilterForm setFilters={setFilters} />
      </Card>
      <TableContainer
        empty={list?.length === 0}
        aria-label="Project table"
        cols={cols}
        status={status}
        data={list || []}
        checkbox
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />

      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={filters.pagesize}
        onPageChange={handleChangePage}
      />
    </>
  );
};

export default ProjectsList;
