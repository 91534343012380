import styled from "styled-components";
import { Button, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useAccounts } from "./provider";
import FilterForm from "./FilterForm";
import NoteCell from "./tableCell/NoteCell";
import { MdAccountTree } from "react-icons/md";
import { HiOutlineMenu } from "react-icons/hi";
import { useStatus } from "../../services/StatusProvider";
import { isActive } from "../../utilities/functions";
import AccountType from "./tableCell/AccountType";
import SpecialType from "./tableCell/SpecialType";
import AccountStatus from "./tableCell/AccountStatus";

const cols = [
  { name: "accountName", label: "Account Name" },
  { name: "accountNumber", label: "Account Number" },
  { name: "companyName", label: "Company Name" },
  { name: "accountType", label: "Account Type", render: AccountType },
  { name: "specialType", label: "Special Type", render: SpecialType },
  { name: "accountStatus", label: "Account Status", render: AccountStatus },
  { name: "baseAccountName", label: "Base Account" },
  { name: "isActive", label: "Is Active", render: isActive },
  { name: "startBalance", label: "Start Balance" },
  { name: "balance", label: "Balance" },
  { name: "notes", label: "notes", render: NoteCell },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  div {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
  .selectedIcon {
    color: black !important;
    font-size: 28px;
  }
`;

const ViewButtonWrapper = styled.span`
  display: inline;
  span {
    color: rgb(153, 153, 153);
    margin: 0 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 26px;
  }
`;

function ChildrenPage({ selectedId }) {
  const { id } = useParams();

  const {
    state: {
      childrenlist: list,
      status,
      count,
      page,
      perPage,
      filters,
      accountName,
      selected,
    },
    fetchOne,
    fetchCildrenList,
    removeChild: remove,
    dispatch,
    setFilters,
    setAccountName,
  } = useAccounts();

  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();

  const {
    state: { isAcc },

    setIsAcc,
  } = useStatus();

  let params = useParams();
  useEffect(() => {
    if (selectedId) {
      fetchCildrenList(selectedId, { ...filters });
      fetchOne(selectedId);
    }
  }, [selectedId, fetchCildrenList, filters, page, perPage]);

  const handleClick = (id) => {
    setAccountName(selected.accountName);
    navigate(`/accounting/new/${selectedId}`);
  };

  const handleView = (id) => {
    navigate(`/accounting/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/accounting/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("account has been removed successfull.");
    } catch (error) {
      if (error.status === 406) {
        alertUser("this account has children");
      }
      console.log(error);
    }
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
  useEffect(() => {
    dispatch({ type: "set_selected", payload: null });
  }, []);
  console.log(selected, "set_selected");
  if (!list) return;
  return (
    <>
      <Grid>
        <div>
          <h2 style={{ display: "inline" }}>Children Accounts List</h2>
        </div>
        {isAcc && (
          <Button
            className="small"
            onClick={handleClick}
            disabled={selected?.entries?.length > 0}
            iconName="invoice"
          >
            New Entry
          </Button>
        )}
      </Grid>
      {/* <FilterForm hide={["companyId"]} setFilters={setFilters} /> */}
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Accounts table"
        cols={cols}
        data={list}
        moreOptions={isAcc && { edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default ChildrenPage;
