import React, { useRef, useState } from "react";
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";
import ClickOutside from "./ClickOutside";
import { TbSquareRoundedPlusFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useStatus } from "../../../services/StatusProvider";

const Style = styled.div`
  position: relative;
  .searchInput {
    width: 80%;
    height: 50px;
    border-top-left-radius: 20px;
    border: none;
    flex: 4;
    .input {
      width: 100%;
      height: 50px;
      border-top-left-radius: 15px;
      padding-left: 20px;
      border: 1px solid #ddd;
      font-size: 18px;
      color: #333;
    }
  }
  .addbutton {
    flex: 1;
    .button {
      svg {
        font-size: 40px;
        margin-left: 15px;
        margin-top: 5px;
        cursor: pointer;
        &:hover {
          color: #6418c3;
        }
      }
    }
  }
  .searchresult {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 60px;
    left: 0;
    background: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: none;
    padding: 10px;
    border: 1px solid #ddd;
  }
  .close {
    position: absolute;
    top: 70px;
    right: 13px;
    display: none;
    cursor: pointer;
  }
  .d-block {
    display: block;
  }
  .d-flex {
    display: flex;
  }
`;

function SearchSection({ tre, setSelectedId }) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [searchText, setSearchText] = useState(0);
  const {
    state: { isAcc },
  } = useStatus();

  const inputref = useRef();

  const getFilteredList = () => {
    if (Number(searchText)) {
      return tre
        .filter((node) => node.id !== 0 && node.name.startsWith(searchText))
        .map((node) => (
          <>
            {console.log(node)}
            <p
              onClick={() => {
                setOpen(0);
                setSelectedId(node.id);
              }}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
            >
              - {node.name}
            </p>
          </>
        ));
    } else
      return tre
        .filter((node) => node.id !== 0 && node.name.includes(searchText))
        .map((node) => (
          <>
            <p
              onClick={() => {
                setOpen(0);
                setSelectedId(node.id);
              }}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
            >
              - {node.name}
            </p>
          </>
        ));
  };

  return (
    <Style>
      <ClickOutside onClick={setOpen}>
        <div className="d-flex">
          <div className="searchInput" onClick={() => setOpen(1)}>
            <input
              type="text"
              placeholder="search"
              className="input"
              ref={inputref}
              onChange={(e) => setSearchText(e.target.value)}
            ></input>
          </div>
          <div className="addbutton">
            {isAcc && (
              <div
                className="button"
                onClick={() => navigate("/accounting/new")}
              >
                <TbSquareRoundedPlusFilled />
              </div>
            )}
          </div>
        </div>
        <div className={open ? "searchresult d-block" : "searchresult"}>
          {searchText == 0 ? "type somthing ..." : <>{getFilteredList()}</>}
        </div>
        <div
          className={open ? "close d-block" : "close"}
          onClick={() => {
            inputref.current.value = "";
            setSearchText(0);
            setOpen(0);
          }}
        >
          <IoMdCloseCircleOutline />
        </div>
      </ClickOutside>
    </Style>
  );
}

export default SearchSection;
