import { useState } from "react";
import { Button, Card, Flex, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useEmployees } from "./provider";
import EmployeeNameCell from "./listEmployee/EmplyeeNameCell";
import EmployeeUserCell from "./listEmployee/EmployeeUserCell";
import EmployeeCompanyCell from "./listEmployee/EmployeeCompanyCell";
import FilterForm from "./FilterForm";




const cols = [
  { name: "id", label: "ID" },
  { name: "fullName", label: "Employee Name", render: EmployeeNameCell },
  {
    name: "companyName",
    label: "Company",
    render: EmployeeCompanyCell,
    props: { style: { minWidth: "250px" } },
  },
  { name: "departmentInfo.departmentName", label: "Department " },
  { name: "jobTitle", label: "Job Title " },
  { name: "directManagerInfo.managerName", label: "Direct Manager " },
  { name: "hiringDate", fn: "date", label: "Start Date" },
  { name: "remainingAnnuals", label: "Remaining Annual Leaves" },
  { name: "remainingSickness", label: "Remaining Sickness Leaves" },
  {
    name: "user",
    label: "User",
    render: EmployeeUserCell,
    props: { style: { width: "180px" } },
  },
];

function ListEmployees() {
  // const [filters, setFilters] = useState(null);
  const navigate = useNavigate();
  const { notify } = useUtils();
  const [isAdmin, setIsAdmin] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    user?.roles?.filter((r) => r === "Admin").map((e) => setIsAdmin(true));

  }, [user]);
  const {
    state: {
      list,
      status,
      count,
      page,
      filters,
    },
    fetchList,
    remove,
    dispatch,
    setFilters,
  } = useEmployees();

  useEffect(() => {
    let status = filters?.statusId
    filters.delete &&filters?.delete('statusId')
    fetchList({...filters, pagenumber: page + 1} , status );
  }, [page, fetchList, filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };

  const handleClick = () => {
    navigate("/employees/new");
  };

  const handleView = (data) => {
    navigate(`/employees/view/${data.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/employees/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Employee has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  
  const handleAllEmployeeClick = () => {
    navigate("/accounts/AllEmployees");
  };

  return (
    <>
      <Flex jc="space-between">
        <h2>Employee List</h2>

    <Flex jc="space-between" gap={'10px'} >
      
{isAdmin&&    <Button className="small" onClick={handleAllEmployeeClick} iconName="invoice">
          Employee Roles
        </Button>}
        <Button className="small" onClick={handleClick} iconName="invoice">
          Add Employee
        </Button>
    </Flex>
      </Flex>

      <Card sx={{ mb: 40, pb: 30 }}>
        <FilterForm
          setFilters={setFilters}
        />
      </Card>

      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Employee table"
        cols={cols}
        data={list}
        checkbox
        showButton={handleView}
        moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={filters.pagesize}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default ListEmployees;
