import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../ui/TableContainer";
import { useUserAccounts } from "../provider";
import { useUtils } from "../../../services/utilsProvider";
import { Pagination } from "../../../ui";

const cols = [
  { name: "displayName", label: "Display Name" },
  { name: "username", label: "Username" },
];

function ListHRs({ company }) {
  const navigate = useNavigate();
  const { notify ,alertUser } = useUtils();
  const [ch  , setCh] =useState(true)
  const {
    state: { hrs, hrStatus, count, page, perPage ,pochangeStatus},
    fetchHRs,
    setAsPO,
    editHR,
    dispatch,
    editAcc
  } = useUserAccounts();

  useEffect(() => {
    if (company.id) fetchHRs(company.id ,(page +1));
  }, [fetchHRs, company.id , page]); 

  useEffect(()=>{
    setCh(!ch)
    console.log(ch);
  },[pochangeStatus])
  const handleChangePage = useCallback(
    (event, newPage) => dispatch({ type: "set_page", payload: event }),
    [dispatch]
  );
  const handleView = ({username}) => {
    navigate(`/accounts/view/hr/${username}`);
  };
  const handleSetPO = useCallback(
    async (id) => {
      try{
        await setAsPO(id)
        notify("a Po Privilege has been granted successfully.");
      }catch(e){
        alertUser("Already granted")
      }
       
     },
     [navigate]
   );
   const handleRevokeHr = useCallback(
    async (id) => {
      try{
        await editHR(id, { companyIds: null })
        notify("HR Privilege has been Revoked successfully.");
      }catch(e){
        alertUser("Already revoked")
      }
       
     },
     [navigate]
   );


   
  const handleSetAccountant = useCallback(
    async (id) => {
      try {
        await editAcc(id, { grant: true });
        notify("an Accountant Role has been granted successfully.");
        dispatch({ type: "pochangeStatus", payload: `changed` });
        dispatch({ type: "pochangeStatus", payload: `idle` });
        dispatch({ type: "accountantsStatus", payload: `changed` });
        dispatch({ type: "accountantsStatus", payload: `idle` });
      } catch (e) {
        alertUser("Already granted");
      }
    },
    [navigate]
  );
   
  return (
    <div>
      <TableContainer
        empty={hrs?.length === 0}
        status={hrStatus}
        aria-label="Holidays table"
        cols={cols}
        data={hrs}
        
        showButton={handleView}
        moreOptions={{
          setAsPo: {
            label: "Set as PO",
            icon: "",
            fn: handleSetPO,
          },
          revokeHr: {
            label: "Revoke HR",
            icon: "",
            fn: handleRevokeHr,
          },
          setAsAcc: {
            label: "Set as Accountant",
            icon: "",
            fn: handleSetAccountant,
          },
          
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default memo(ListHRs);
