import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useDashboard() {
  return useContext(Context);
}

const initState = {
  list: [],
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_statistic":
      return { ...state, list: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function DashboardProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchStatistic = useCallback(
    async (info, page = 0, pageSize = 10, keyword = null) => {
      
      const resData = await req(
        `Statistic/Info`,
        null,
        {},
        true
      );
      dispatch({ type: "set_statistic", payload: resData.data });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchStatistic
      }}
    >
      {children}
    </Context.Provider>
  );
}
