import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Icon } from "../../ui";
import FileField from "../../ui/FileField";
import { useEmployees } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function Avatar() {
  const { user, setUser } = useAuth();

  const [preview, setPreview] = useState(
    user.avatar !== "" ? user.avatar : null
  );
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const {
    state: { status, avatar },
    setAvatar,
  } = useEmployees();
  const { notify, alertUser } = useUtils();

  const handleChange = (e) => {
    const file = e.target.files[0];
    setPreview(URL.createObjectURL(file));
    setFile(file);
  };

  const hanldeClick = async () => {
    try {
      const res = await setAvatar({ id: user.id, logo: file });
      const newUser = {...user, avatar: res.data.url}
      // user.Avatar = data.url;
      console.log('newUser', res);
      // setUser(newUser);
      notify("Image has been saved successfully.");
      navigate(-1);
    } catch (e) {
      console.log(e)
      alertUser("Error saving avatar");
    }
  };

  return (
    <div id="add-avatar">
      <Grid>
        <h2 style={{ marginTop: "0" }}>User Avatar</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <Card
        style={{
          width: "500px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          padding: "40px 0 50px 0",
        }}
      >
        <div>
          <FileField
            style={{ width: "280px", marginLeft: 0 }}
            label=""
            buttonLabel={user.avatar !== "" ? "Change Avatar" : "Upload Avatar"}
            preview={preview}
            edit={Boolean(user.avatar !== "")}
            inputProps={{
              name: "logo",
              onChange: handleChange,
              accept: "image/jpng,image/png,image/jpg,image/jpeg",
            }}
          />
          <Button
            onClick={hanldeClick}
            small
            style={{ margin: "40px 0 0 0", width: "255px" }}
            loading={status === "loading"}
          >
            Save avatar
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Avatar;
