import React, { useEffect, useRef, useState } from "react";
import ErrorsList from "../../ui/ErrorsList";
import { Button, Card, Checkbox, Select, TextField } from "../../ui";
import styled from "styled-components";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import fields from "./fields";
import { useNavigate, useParams } from "react-router-dom";
import { useCompanies } from "../companies/provider";
import InputDate from "../../ui/InputDate";
import FileField from "../../ui/FileField";
import ReactDOM from "react-dom";
import Editor from "./Editor";
import Label from "../../ui/InputLabel";
import { multiply } from "lodash";
import { useNews } from "./provider";
import { getUtc, setDateFormat } from "../../utilities/functions";
import TextArea from "../../ui/TextArea";
import { getValue } from "@testing-library/user-event/dist/utils";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 500px;
  gap: 32px;
  row-gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const schema = Yup.object().shape({
  companyId: Yup.number(),
  title: Yup.string().required("required field"),
  description: Yup.string().required("required field"),
  publishDate: Yup.date().required("must select date"),
  readingDuration: Yup.number().required("required field"),
  image: Yup.string(),
  content: Yup.string().required("required field"),
  isActive: Yup.boolean(),
});

const FormNews = ({ news, handleSuccess }) => {
  const editor = useRef(null);
  const [error, setError] = useState(null);

  const { cid, id } = useParams();
  let navigate = useNavigate();

  const [companyId, setCompanyId] = useState(cid);
  const [editorHtml, setEditorHtml] = useState(news?.content || null);

  const [preview, setPreview] = useState(news?.imageUrl || null);
  const {
    state: { list: companies, selected },
    fetchOne: fetchCompany,
  } = useCompanies();
  const {
    state: { fileName },
    // dispatch,
    _uploadImage,
    create,
    edit,
  } = useNews();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: news,
  });
  useEffect(() => {
    fetchCompany(cid);
  }, [fetchCompany, cid]);
  useEffect(() => setValue("content", editorHtml), [editorHtml, setEditorHtml]);
  useEffect(() => {
    console.log(fileName, "file name");
    console.log(getValues("image"), "image");
  });
  const onSubmit = async (values) => {
    values.companyId = cid;
    values.image = fileName;

    values.publishDate = getUtc(values.publishDate);
    delete values.imageUrl;
    try {
      if (news) {
        edit(id, values);
      } else {
        create(values);
      }
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Container>
          <div>
            <Grid>
              <TextField
                label="Company"
                value={selected?.businessName}
                style={{ marginBottom: 0 }}
                inputProps={{
                  value: selected?.businessName,
                  readOnly: true,
                  disabled: true,
                  style: { height: 60 },
                }}
              />
              {fields.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;

                inputProps.type = f.type;
                if (f.switch) {
                  return (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        id="statusId"
                        name="status"
                        inputProps={{
                          ...register(f.name),
                          defaultChecked: news ? news?.isActive : true,
                        }}
                      />
                      <Label>Active</Label>
                    </div>
                  );
                } else if (f.type === "date") {
                  return (
                    <InputDate
                      key={i}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      msg={errors[f.name]?.message}
                      size="medium"
                      type="time"
                      value={new Date(getValues(f.name))}
                      onDateChange={(v) => setValue(f.name, v)}
                      pickerProps={f.pickerProps}
                    />
                  );
                } else if (f.editor) {
                  return (
                    <>
                      <div>
                        <Label>Content</Label>
                        <Editor
                          editorHtml={editorHtml}
                          setEditorHtml={setEditorHtml}
                        />
                      </div>
                      <div />
                    </>
                  );
                } else if (f.longDescription) {
                  return (
                    <>
                      <FileField
                        id="file"
                        label="Article Image"
                        buttonLabel={news ? "Edit Image" : "Upload Image"}
                        preview={preview}
                        edit={Boolean(news)}
                        onChange={(e) => {
                          setPreview(URL.createObjectURL(e.target.files[0]));
                          _uploadImage(e.target.files[0]);
                        }}
                        inputProps={{
                          // name: "image",
                          // ...register("image"),
                          accept: "image/jpng,image/png,image/jpg,image/jpeg",
                        }}
                      />
                      <TextArea
                        key={i}
                        label={f.label}
                        name={f.name}
                        error={!!errors[f.name]}
                        msg={errors[f.name]?.message}
                        inputProps={{
                          ...register(f.name),
                          ...inputProps,
                        }}
                        rows="6"
                      />
                    </>
                  );
                } else {
                  return (
                    <TextField
                      key={i}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      msg={errors[f.name]?.message}
                      inputProps={{
                        ...register(f.name),
                        ...inputProps,
                      }}
                    />
                  );
                }
              })}
            </Grid>
          </div>
        </Container>
      </Card>
      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default FormNews;
