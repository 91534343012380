import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import CompanyProvider from "../views/companies/provider";
import DepartmentProvider from "../views/departments/provider";
import AttendancePatternProvider from "../views/attendancePatterns/provider";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import EmployeeProvider from "../views/employees/provider";
import LeavesProvider from "../views/leaves/provider";
import HolidayProvider from "../views/holidays/provider";
import UserAccountProvider from "../views/UserAccount/provider";
import AttendanceProvider from "../views/attendance/provider";
import PayrollsProvider from "../views/payrolls/provider";
import DashboardProvider from "../views/dashboard/provider";
import StatsProvider from "../views/stats/provider";
import CompensationProvider from "../views/compensations/provider";
import DeductionProvider from "../views/deductions/provider";
import PublicHolidayProvider from "../views/publicHoliday/provider";
import EmployeeCompensationProvider from "../views/employeeCompensations/provider";
import EmployeeDeductionProvider from "../views/employeeDeductions/provider";
import NotificationProvider from "../views/notifications/provider";
import EmployeeDocumentProvider from "../views/employeeDocument/provider";
import PurchesProvider from "../views/purches/provider";
import OrdersProvider from "../views/orders/provider";
import NotifyProvider from "../layout/provider";
import CurrencyProvider from "../views/currency/provider";
import SignersProvider from "../views/signers/provider";
import OfferDocumentProvider from "../views/offerdocuments/provider";
import SupliersProvider from "../views/supliers/provider";
import ReportsProvider from "../views/Reports/provider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AccountProvider from "../views/accounts/provider";
import YearProvider from "../views/year/provider";
import TransactionProvider from "../views/transaction/provider";
import StatusProvider from "./StatusProvider";
import ProjectsProvider from "../views/projects/provider";
import NewsProvider from "../views/news/provider";
export default function Providers({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <DashboardProvider>
          <UtilsProvider>
            <StatusProvider>
              <AuthProvider>
                <NotifyProvider>
                  <ReportsProvider>
                    <CompanyProvider>
                      <AttendancePatternProvider>
                        <EmployeeProvider>
                          <DepartmentProvider>
                            <HolidayProvider>
                              <UserAccountProvider>
                                <LeavesProvider>
                                  <AttendanceProvider>
                                    <PayrollsProvider>
                                      <StatsProvider>
                                        <CompensationProvider>
                                          <DeductionProvider>
                                            <PublicHolidayProvider>
                                              <EmployeeCompensationProvider>
                                                <EmployeeDeductionProvider>
                                                  <NotificationProvider>
                                                    <EmployeeDocumentProvider>
                                                      <SignersProvider>
                                                        <OfferDocumentProvider>
                                                          <PurchesProvider>
                                                            <OrdersProvider>
                                                              <CurrencyProvider>
                                                                <SupliersProvider>
                                                                  <AccountProvider>
                                                                    <YearProvider>
                                                                      <TransactionProvider>
                                                                        <ProjectsProvider>
                                                                          <NewsProvider>
                                                                            {
                                                                              children
                                                                            }
                                                                          </NewsProvider>
                                                                        </ProjectsProvider>
                                                                      </TransactionProvider>
                                                                    </YearProvider>
                                                                  </AccountProvider>
                                                                </SupliersProvider>
                                                              </CurrencyProvider>
                                                            </OrdersProvider>
                                                          </PurchesProvider>
                                                        </OfferDocumentProvider>
                                                      </SignersProvider>
                                                    </EmployeeDocumentProvider>
                                                  </NotificationProvider>
                                                </EmployeeDeductionProvider>
                                              </EmployeeCompensationProvider>
                                            </PublicHolidayProvider>
                                          </DeductionProvider>
                                        </CompensationProvider>
                                      </StatsProvider>
                                    </PayrollsProvider>
                                  </AttendanceProvider>
                                </LeavesProvider>
                              </UserAccountProvider>
                            </HolidayProvider>
                          </DepartmentProvider>
                        </EmployeeProvider>
                      </AttendancePatternProvider>
                    </CompanyProvider>
                  </ReportsProvider>
                </NotifyProvider>
              </AuthProvider>
            </StatusProvider>
          </UtilsProvider>
        </DashboardProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
