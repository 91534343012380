import React from 'react'

function IsLockedCell(data) {
  return (
    <div>
        {
            data?.isLocked ? "locked" : "notLocked"
        }
    </div>
  )
}

export default IsLockedCell