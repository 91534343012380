import React, { useEffect } from "react";
import TableContainer from "../../ui/TableContainer";
import FilterForm from "./FilterForm";
import { Card } from "../../ui";
import styled from "styled-components";
import { useCompanies } from "../companies/provider";
import { useLeaves } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

function LeaveReportList() {
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  //fetch leaves
  const {
    state: {
      list,
      status,
      count,
      page,
      perPage,
      filters
    },
    fetchReportList: fetchLeaves,
    setStatus,
    remove,
    dispatch,
    accept,
    reject,
    setFilters
  } = useLeaves();

  //cols
  const cols = [
    // { name: "number", label: "Remaining Leaves" },
    { name: "typeName", label: "Type" },
    { name: "days", label: "Days" },
    { name: "notes", label: "Notes" },

  ]; 
  var d = new Date();
  let now =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft();
  
  d.setDate(d.getDate() - 30);
  let fom =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft();
  
  useEffect(()=>{
    if(!filters.FromDate)setFilters({
      FromDate:fom,
      ToDate:now,
    })
    
  },[])
  useEffect(() => {
    if (companies )
      dispatch({ type: "compnayIDchange", payload: companies[0]?.id });
  }, [companies]);

  useEffect(() => {
    if(filters?.employeeId)  fetchLeaves(filters , filters.employeeId);
    else dispatch({ type: "set_rlist", payload:[]})
  }, [filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };
  useEffect(() => {
    dispatch({ type: "to", payload: now });
  }, []);
  if(!filters.FromDate) return
  return (
    <>
      <Grid>
        <h2>Leaves Report</h2>
      </Grid>

      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm
          hide={{AllInSelect:true}}
          setFilters={setFilters}
        ></FilterForm>
      </Card>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={list}
        checkbox
        // showButton={handleView}
      />
      {/* <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      /> */}
    </>
  );
}

export default LeaveReportList;
