import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useUtils } from "../../services/utilsProvider";

const Context = createContext();

export function usePurches() {
  return useContext(Context);
}
Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};
let d =new Date()
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();
  

const initState = {
  list: [],
  status: "idle",
  error: null,
  emps: [],
  deps: [],
  selected: [],
  companyID: null,
  employeeID: null,
  departmentID: null,
  Fdate: now,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      return { ...state, selected: { ...action.payload } };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_emps_list":
      return { ...state, emps: { ...action.payload } };
    case "set_deps_list":
      return { ...state, deps: { ...action.payload } };
    case "companychange":
      return { ...state, companyID: action.payload };

    case "departmentchange":
      return { ...state, departmentID: action.payload };
    case "employeechange":
      return { ...state, employeeID: action.payload };

    case "Fdate":
      return { ...state, Fdate: action.payload };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "approve":
      const edited = state.list.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
      return { ...state, list: edited };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function PurchesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { notify } = useUtils();
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "Leave/Create",
            data,
            { method: "POST" },
            true
          );

          dispatch({ type: "add", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchList = useCallback(
    async (Cid, Did, Eid, date, page = 0, pageSize = 10, keyword = null) => {
      console.log("fetch");
      // dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: page + 1,
        pageSize,
      };
      if (Cid) headersData.companyId = Cid;
      if (Did) headersData.departmentId = Did;
      if (Eid) headersData.employeeId = Eid;
      if (date) headersData.AtDate = date;
      console.log(headersData, "headers data", date);
      const resData = await req(
        `Purchase/PORequests`,
        null,
        {},
        true,
        headersData
      );
      // dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.requests });
    },
    [req, state.status]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        // dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Leave/Info/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        // dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        // dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Purchase/UpdateRequest/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          // dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setStatus = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Purchase/UpdateRequestStatus/${id}`,
            {
              status: data,
            },
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit", payload: resData.data });
          notify("Purchase requset status has been Changed successfull");
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Leave/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        setStatus,
        remove,
      }}
    >
      {children}
    </Context.Provider>
  );
}
