import { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Header from "./Header";
import Navbar from "./Navbar";

const Container = styled.div`
  height: 100vh;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  width: 100vw;
  ${(props) =>
    props.full &&
    css`
      display: grid;
      grid-template-columns: min-content 1fr;
      grid-template-rows: 120px 1fr;
    `}
  aside {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
    background-color: #fff;
    box-shadow: 18px 4px 35px #00000005;
    z-index: 1;
    z-index: 9999;
  }
  header {
    background-color: white;
  }
  main {
    padding: 40px 65px 40px 50px;
    width: ${(props) =>
      props.open ? "calc(100vw - 345px)" : "calc(100vw - 110px)"};

    @media only screen and (max-width: 1024px) {
      width: 100vw;
    }
  }
`;

const Full = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [width, setWidth] = useState(false);


  const handleMouseOver = () => {
    setIsHovering(true);

  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  useEffect(()=>{
    if(navOpen)
      setWidth(true)
    else
      {
        if(isHovering) setWidth(true)
        else setWidth(false)
      }

  },[isHovering])

  return (
    <Container full open={width}>
      <Navbar
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
      />
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <main>{children}</main>
    </Container>
  );
};

const Empty = ({ children }) => {
  return <Container>{children}</Container>;
};

function Layout({ children, variant = "full" }) {
  if (variant === "full") return <Full>{children}</Full>;
  else if (variant === "empty") return <Empty>{children}</Empty>;
}

export default memo(Layout);
