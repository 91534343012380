import React, { useCallback } from "react";
import { Button, Flex, Select, TextField } from "../../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useUserAccounts } from "../provider";

const schema = Yup.object().shape({
  role: Yup.string().typeError('invalid role'), 
});

function FilterForm({ setFilters }) {
  const {
    state: { filters },
  } = useUserAccounts();


  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      role: filters.role,
    },
  });

  const onSubmit = async (values) => {
    setFilters(values);
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Flex jc="flex-start" ai="flex-end" gap={20}>

      <div>
          <Select
            style={{
              maxWidth: 220,
            }}
            data={[{ id: "Employee", departmentName: "Employee"  },{ id: "HR", departmentName: "HR"  }, { id: "PO", departmentName: "PO"  }, ]}
            mapOptions={{ value: "id", label: "departmentName" }}
            label={"department"}
            onChange={useCallback(
              (data) => setValue("role", data.value),
              [setValue]
            )}
            defaultValue={getValues("role") || ""}
          />
        </div>

        <Button
          data-testid="save-button"
          type="submit"
          // iconName="Eye"
          className="small"
          loading={isSubmitting}
          style={{ height: 60 }}
        >
          Apply
        </Button>
      </Flex>
    </form>
  );
}

export default FilterForm;
