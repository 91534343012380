import React from "react";
import { Button, Card } from "../../ui";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTransaction } from "./provider";
import { useUtils } from "../../services/utilsProvider";

const Grid = styled.div`
  max-width: 810px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  row-gap: 32px;
  .button: {
    width: 100% !important;
    margin-top: 10px !important;
  }
`;

function AdminUtils({ id }) {
  const navigate = useNavigate();
  const { alertUser } = useUtils();

  const { createDefaultChart } = useTransaction();

  const handleCloneAccounts = () => {
    navigate("/accounting/clone");
  };
  const handleUPloadExcel = () => {
    navigate("/accounting/import");
  };

  const handleExportExcel = () => {
    navigate("/accounting/export");
  };

  const handlecreateDefaultChart = async () => {
    try {
      await createDefaultChart(id);
    } catch (e) {
      alertUser("there is already a default chart");
    }
  };

  return (
    <Grid>
      <Card>
        <Button
          className="small button"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handleCloneAccounts}
        >
          Clone Accounts
        </Button>
        <Button
          className="small button"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handleUPloadExcel}
        >
          Import Excel
        </Button>
        <Button
          className="small button"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handleExportExcel}
        >
          Export Excel
        </Button>

        <Button
          className="small button"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handlecreateDefaultChart}
        >
          Default Chart of Accounts
        </Button>
      </Card>
    </Grid>
  );
}

export default AdminUtils;
