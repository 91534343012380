import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useUtils } from "../../services/utilsProvider";
const Context = createContext();

export function useOrders() {
  return useContext(Context);
}

const initState = {
  list: [],
  status: "idle",
  orderS: "idle",
  error: null,
  emps: [],
  deps: [],
  selected: [],
  employeeID: null,
  departmentID: null,
  Fstate: null,
  pid: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_pid":
      return { ...state, pid: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "orderS":
      return { ...state, orderS: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      return { ...state, selected: { ...action.payload } };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_emps_list":
      return { ...state, emps: { ...action.payload } };
    case "set_deps_list":
      return { ...state, deps: { ...action.payload } };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "approve":
      const edited = state.list.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
      return { ...state, list: edited };

    case "departmentchange":
      return { ...state, departmentID: action.payload };
    case "employeechange":
      return { ...state, employeeID: action.payload };

    case "Fstate":
      return { ...state, Fstate: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function OrdersProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { notify } = useUtils();
  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        console.log(data);

        try {
          const resData = await req(
            "Purchase/CreateOrder",
            data,
            { method: "POST" },
            true
          );
          console.log(resData);
          
          dispatch({ type: "add", payload: resData.data });
          dispatch({ type: "set_pid", payload: resData.data.id });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchList = useCallback(
    async (Cid, Did, Eid, status, page = 0, pageSize = 10) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: page + 1,
        pageSize,
      };
      if (Cid) headersData.CompanyId = Cid;
      if (Did) headersData.departmentId = Did;
      if (Eid) headersData.EmployeeId = Eid;
      if (status) headersData.status = status;
      const resData = await req(
        `Purchase/ListOrders`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.pagedItems });
      dispatch({ type: "set_count", payload: resData.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Purchase/OrderInfo/${id}`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Purchase/UpdateOrder/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const setStatus = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "orderS", payload: `creating` });
        try {
          const resData = await req(
            `Purchase/UpdateOrderStatus/${id}`,
            {
              status: data,
            },
            { method: "PATCH" },
            true
          );
          notify("order status has been changed successfull");
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "orderS", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Purchase/DeleteOrder/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const sendEmailApi = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("EmailSubject", data.emailSubject);
      formData.append("purchaseOrderId", data.id);

      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Purchase/SendOrderEmailsToSign",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        setStatus,
        remove,
        sendEmailApi,
      }}
    >
      {children}
    </Context.Provider>
  );
}
