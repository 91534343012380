import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Divider, Icon, Select } from "../../ui";
import { useCompanies } from "../companies/provider";
import { useUserAccounts } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function SetHRUserAccount() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    state: { list: companies, status },
    fetchList: fetchCompanies,
  } = useCompanies();

  const { setAsHR } = useUserAccounts();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const companiesId = useRef([]);

  const handleChange = (v) => {

    companiesId.current = v.map((v) => v.value);
  };

  const onSubmit = async (v) => {
    await setAsHR(id, { companyIds: companiesId.current });
    navigate("/accounts");
  };

  if(!companies) return
  return (
    <div id="set-as-hr">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Set user as HR</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <div>
        <Select
          data={companies}
          mapOptions={{ value: "id", label: "businessName" }}
          onChange={handleChange}
          multi
          inputProps={{
            name: "companyId",
          }}
        />
        <Divider />
        <Button onClick={onSubmit} loading={Boolean(status === "creating")}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default SetHRUserAccount;
