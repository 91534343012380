import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Icon, TextField } from "../../ui";
import { useEmployees } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

function EmplyeeBank() {
  const {
    state: { selected: bank },
    fetchBank,
    removeBank,
    setBank,
  } = useEmployees();
  let { id } = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  const { notify } = useUtils();

  useEffect(() => {
    fetchBank(id);
  }, [id, fetchBank]);

  const handleDelete = async (id) => {
    try {
      await removeBank(id);
      notify("Employee Bank has been removed successfull.");
      navigate(-1)
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(()=>{
    sessionStorage.setItem('empname',location.state.fullName)
  },[])
  if(!bank) return;
  return (
    <>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Employee Bank</h2>
        <div style={{ cursor: "pointer" }}>
          {
            bank?.ibanNumber && <Button
            onClick={() => alert("Are You Sure", handleDelete(id))}
            style={{ backgroundColor: "red", marginRight: "15px" }}
          >
            Delete Bank
          </Button>
          }
          <Button
            onClick={() => navigate('/employees/createbank/' + id , {state:{bank}})}
            style={{ marginRight: "15px" }}
          >
            {
              bank?.ibanNumber ? "Edit Bank": "add Bank"
            }
            
          </Button>
          <span onClick={() => navigate(-1)}>Back</span>
          <Icon name="arrowLeft" onClick={() => navigate(-1)} />
        </div>
      </Grid>
      <Card>
        <Grid2>
        <TextField
            label='Emplyee Name'
            inputProps={{
              value:sessionStorage.getItem('empname'),
              readOnly: true,
              disabled: true,
              style: {
								background: '#fff',
								borderBottom: '2px solid #ddd',
								outline: 'none',
							},
            }}
          />
          
        </Grid2>
        <Grid2>
        <TextField
            label='Bank Name'
            inputProps={{
              value:bank.bankName,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label='Bank Address'
            inputProps={{
              value:bank.bankAddress,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label='Iban Number'
            inputProps={{
              value:bank.ibanNumber,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label='Swift Code'
            inputProps={{
              value:bank.swiftCode,
              readOnly: true,
              disabled: true,
            }}
          />

        </Grid2>
      </Card>
    </>
  );
}

export default EmplyeeBank;
