import styled, { css } from "styled-components";

const Divider = styled.div.attrs((props) => ({
  h: props.h ? props.h : 20,
  // border: (typeof props.border === 'string') ? props.border : 'grayBg',
}))`
  ${(props) =>
    props.border
      ? css`
          border-bottom: ${props.theme.color[(typeof props.border === 'string') ? props.border : 'grayBg']} 1px solid;
          margin-bottom: ${(props) => (props.h ? props.h : 0)}px;
        `
      : undefined}
  height: ${props => props.h}px;
`;

export default Divider;
