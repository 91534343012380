import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card } from '../../ui';
import ErrorsList from '../../ui/ErrorsList';
import { apiUrl as baseUrl } from '../../config';
import axios from 'axios';
import upload from '../../assets/upload.svg';
import cancle from '../../assets/cancel.svg'
import document from '../../assets/file.svg'
import { useUtils } from '../../services/utilsProvider';

const Grid = styled.div`
	max-width: 845px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;
	row-gap: 0;
	.custom-select {
		width: 100%;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 55px;
	button {
		float: right;
		margin-left: 20px;
		min-width: 180px;
	}
`;

function UploadExcel({ companyId  }) {
	const [error, setError] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const { notify  } = useUtils();

	let navigate = useNavigate();


	const handleChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};
	const handleSuccess = () => {
		navigate(-1);
		notify("File has been uploaded successfully.");
	  };

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('file', selectedFile);
		formData.append('CompanyId', companyId);


        //console.log(formData.get('companyId') , 'dataaaaaaaaaaaa');
		await axios
			.post(`${baseUrl}/Employee/Import`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('tokens')).accessToken
					}`,
				},
			})
			.then((response) => {
			})
			.then((data) => {
				handleSuccess()
			})
			.catch((e) => {
				notify('Please Choose Company First and be sure choosing a correct file type')	
				console.log(e, 'eeeeeeeeeeeror')
			});
	};

	return (
		<form onSubmit={handleSubmit} noValidate>
			{error && <ErrorsList error={error} />}

		

			<Card style={{ marginTop: '20px' }}>
				<Grid>
					<div style={{ display: 'block' }}>
						<input
							type="file"
							id="file"
							onChange={(e) => handleChange(e)}
							style={{ padding: '0 10px', margin: '0', display: 'none' }}
						/>
						<label
							for="file"
							style={{
								display: 'flex',
								width: '100%',
								background: '#F5F5F5',
								border: '1px dashed #c2c2c2',
								height: '80px',
								margin: '0',
								padding: '0 10px',
							}}
						>
							<div
								style={{
									display: 'flex',
									width: '30%',
									height: '75%',
									marginTop: '10px',
									marginLeft: '0px',
								}}
							>
								<img
									src={upload}
									alt=""
									width="100%"
									height="100%"
									style={{ display: 'inline' }}
								/>
							</div>
							<div
								style={{
									marginLeft: '0px',
									marginTop: '12px',
									lineHeight: '28px',
								}}
							>
								<p style={{ display: 'inline' }}>
									<span style={{ color: '#202020' }}>Upload Excel</span>
									<span
										style={{
											display: 'block',
											color: '#a5a5a5',
											fontSize: '14px',
										}}
									>
										XLSX, XLSM, XLSB, XLTX
									</span>
								</p>
							</div>
						</label>
					</div>
					<div>
						{selectedFile && (
							<div style={{ display: 'block', position: 'relative' }}>
								<span
									style={{
										width: '25px',
										height: '25px',
										position: 'absolute',
										top: '-8px',
										right: '-5px',
										cursor: 'pointer',
									}}
									onClick={() => {
										setSelectedFile(null);
									}}
								>
              <img src={cancle} alt="cancle" />
									
								</span>
								<label
									for="file"
									style={{
										borderRadius: '15px',
										display: 'flex',
										width: '100%',
										border: '1px solid #6418C3',
										height: '80px',
										margin: '0',
										padding: '0 10px',
									}}
								>
									<div
										style={{
											display: 'flex',
											width: '30%',
											height: '75%',
											marginTop: '10px',
											marginLeft: '0px',
										}}
									>
										<img
											src={document}
											alt=""
											width="100%"
											height="100%"
											style={{ display: 'inline' }}
										/>
									</div>
									<div
										style={{
											marginLeft: '0px',
											marginTop: '23px',
											lineHeight: '28px',
										}}
									>
										<p style={{ display: 'inline' }}>
											<span style={{ color: '#202020', fontWeight: 'bold' }}>
												{selectedFile.name}
											</span>
										</p>
									</div>
								</label>
							</div>
						)}
					</div>
				</Grid>
			</Card>
			<ButtonWrapper>
				<Button
					type="submit"
					data-testid="save-button"
					iconName="Disk"
					className="small"
					onClick={() => {
						handleSubmit();
					}}
				>
					Upload
				</Button>

				<Button className="outlined small" type='button' onClick={() => navigate(-1)}>
					Cancel
				</Button>
			</ButtonWrapper>
		</form>
	);
}

export default UploadExcel;
