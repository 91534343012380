import styled from "styled-components";

const Label = styled.label`
  font-size: 16px;
  color: ${(props) => props.theme.color.gray};
  text-transform: uppercase;
  display: block;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  margin-bottom: 14px;
  letter-spacing: 1px;
`;

export default Label;
