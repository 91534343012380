  import { Route } from "react-router-dom";

  import { RequireAuth } from "../../services/auth";

  import Payrolls from "./Payrolls";
  import PayrollsList from "./PayrollsList";
  import ViewPayroll from "./ViewPayroll";
  import EditPayroll from "./EditPayroll";
  import AddPayroll from "./AddPayroll";
  import ViewWage from "./ViewWage";
  import Pyslip from "./Pyslip";

  export default (
    <>
      <Route
        path="payrolls"
        element={
          <RequireAuth>
            <Payrolls />
          </RequireAuth>
        }
      >
        <Route
          index
          element={
            <RequireAuth>
              <PayrollsList />
            </RequireAuth>
          }
        />
        <Route
          path="view/:id/:year/:month"
          element={
            <RequireAuth>
              <ViewPayroll />
            </RequireAuth>
          }
        />
        <Route
          path="wage/:id"
          element={
            <RequireAuth>
              <ViewWage />
            </RequireAuth>
          }
        />
        <Route
          path="new/:id/:year/:month/"
          element={
            <RequireAuth>
              <AddPayroll />
            </RequireAuth>
          }
        />
        <Route
          path="edit/:id/:year/:month"
          element={
            <RequireAuth>
              <EditPayroll />
            </RequireAuth>
          }
        />
        <Route
          path="print"
          element={
            <RequireAuth>
              <Pyslip />
            </RequireAuth>
          }
        />
      </Route>
    </>
  );
