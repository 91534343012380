import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";


import { useNavigate } from "react-router-dom";
import { useCompanies } from "../../companies/provider";
import { Divider, Flex, Select } from "../../../ui";
import AllEmployeeList from "./AllEmployeeList";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  button {
    flex-grow: 1;
  }
`;

function AllEmployee() {


  return (
    <div id="list-accounts">

      <Grid>
        <h2>Accounts List</h2>
      </Grid>
      <AllEmployeeList  />  


  
    </div>
  );
}

export default AllEmployee;
