import styled from "styled-components";
import { Button, Icon } from "../../ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useAccounts } from "./provider";
import FilterForm from "./FilterForm";
import { MdAccountTree } from "react-icons/md";
import { HiOutlineMenu } from "react-icons/hi";
import ListView from "./listsections/ListView";
import { useState } from "react";
import { useStatus } from "../../services/StatusProvider";
import { rmValueFromObj } from "../../utilities/functions";
import { useCompanies } from "../companies/provider";
import CustomTreeView from "./newtreeview/CustomTreeView";
import { useTransaction } from "../transaction/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  div {
    flex-grow: 2;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
  .selectedIcon {
    color: black !important;
    font-size: 28px;
  }
`;

const ViewButtonWrapper = styled.span`
  display: inline;
  span {
    color: rgb(153, 153, 153);
    margin: 0 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 26px;
  }
`;

function ListAccount() {
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, []);
  const { id } = useParams();
  const {
    state: { list, status, count, page, perPage, viewType },
    fetchList,
    fetchTreeList,
    remove,
    dispatch,
    setViewType,
  } = useAccounts();

  const {
    state: { filters },
    setFilters,
  } = useTransaction();

  const {
    state: { isAcc },

    setIsAcc,
  } = useStatus();

  let user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (isAcc === null) {
      user?.roles.includes("Acc") ? setIsAcc(true) : setIsAcc(false);
    }
  }, [user]);
  const navigate = useNavigate();
  const { notify } = useUtils();

  useEffect(() => {
    if (filters) {
      fetchList({ ...rmValueFromObj(filters, ["", null, undefined]) }, page);
    }
  }, [fetchList, filters, page, perPage]);

  useEffect(() => {
    let employee = user?.employee;
    if (employee != null) {
      if (!filters) {
        dispatch({
          type: "set_filters",
          payload: {
            companyId: user?.employee?.company.id,
            companyName: user?.employee?.company?.companyName,
            pageSize: 10,
            isActive: true,
          },
        });
      }
    } else {
      if (companies) {
        dispatch({
          type: "set_filters",
          payload: {
            companyId: companies[0]?.id,
            companyName: companies[0]?.businessName,
            pageSize: 10,
            isActive: true,
          },
        });
      }
    }
  }, [companies]);

  const handleClick = () => {
    navigate("/accounting/new");
  };

  const handleCloneAccounts = () => {
    navigate("/accounting/clone");
  };
  const handleUPloadExcel = () => {
    navigate("/accounting/import");
  };

  const handleExportExcel = () => {
    navigate("/accounting/export");
  };

  if (!filters) return;
  return (
    <>
      {/* <FilterForm
        hide={["companyName", "parentName", "onlyActiveAccounts"]}
        setFilters={setFilters}
        isAcc={isAcc}
        viewType={viewType}
      /> */}
      <Grid>
        <div>
          <h2 style={{ display: "inline" }}>
            Accounts List for {filters.companyName} in year: {filters.yearTitle}
          </h2>
          <ViewButtonWrapper>
            {/* <span
              className={viewType === 1 && "selectedIcon"}
              onClick={() => setViewType(1)}
            >
              <HiOutlineMenu />
            </span>
            <span
              className={viewType === 2 && "selectedIcon"}
              onClick={() => setViewType(2)}
            >
              <MdAccountTree />
            </span> */}
          </ViewButtonWrapper>
        </div>
        {/* {!isAcc && (
          <Button className="small" onClick={handleCloneAccounts}>
            Clone Accounts
          </Button>
        )}
        {!isAcc && (
          <Button className="small" onClick={handleUPloadExcel}>
            Import Excel
          </Button>
        )}
        {!isAcc && (
          <Button className="small" onClick={handleExportExcel}>
            Export Excel
          </Button>
        )} */}
        {/* {isAcc && (
          <Button className="small" onClick={handleClick} iconName="invoice">
            New Entry
          </Button>
        )} */}
        <span
          className="back"
          style={{
            cursor: "pointer",
            fontSize: " 20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            lineHeight: 0,
          }}
          onClick={() => navigate(-1)}
        >
          <span>Back</span>
          <Icon
            name="arrowLeft"
            style={{
              marginLeft: "10px",
            }}
          />
        </span>
      </Grid>

      {/* {viewType === 1 ? <ListView isAcc={isAcc} /> : <CustomTreeView />} */}
      <CustomTreeView />
    </>
  );
}

export default ListAccount;
