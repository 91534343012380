import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddAccount from "./AddAccount";
import EditAccount from "./EditAccount";
import Accounts from "./Accounts";
import ListAccount from "./ListAccount";
import ChildrenPage from "./ChildrenPage";
import ViewAccount from "./ViewAccount";
import CloneAccounts from "./accountutils/CloneAccounts";
import UploadExcel from "./accountutils/UploadExcel";
import ExportExcel from "./accountutils/ExportExcel";

export default (
  <Route
    path="accounting"
    element={
      <RequireAuth>
        <Accounts />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListAccount />
        </RequireAuth>
      }
    />

    <Route
      path="new"
      element={
        <RequireAuth>
          <AddAccount />
        </RequireAuth>
      }
    />

    <Route
      path="new/:id"
      element={
        <RequireAuth>
          <AddAccount />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditAccount />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewAccount />
        </RequireAuth>
      }
    />

    <Route
      path="view/children/:id?"
      element={
        <RequireAuth>
          <ChildrenPage />
        </RequireAuth>
      }
    />
    <Route
      path="clone"
      element={
        <RequireAuth>
          <CloneAccounts />
        </RequireAuth>
      }
    />
    <Route
      path="import"
      element={
        <RequireAuth>
          <UploadExcel />
        </RequireAuth>
      }
    />
    <Route
      path="export"
      element={
        <RequireAuth>
          <ExportExcel />
        </RequireAuth>
      }
    />
  </Route>
);
