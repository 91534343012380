import { emptyStringToNull } from "../../utilities/yupSchemaCreator";

const fields = [
  {
    name: "compensationId",
    viewName: "Compensation Name",
    type: "number",
    resourceName: "compensationNameList",
    data: [],
    mapData: { value: "id", label: "title" },
    label: "Compensation Name",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "required",
        params: ["Required."],
      },
      {
        type:"typeError",
        params: ["required"]
      }
    ],
  },
  {
    name: "amount",
    type: "number",
    label: "Amount",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true], 
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "min",
        params: [1, "Amount must be greater than 0"],
      },
      {
        type:"typeError",
        params: ["must be a number"]
      }
    ],
  },

  {
    name: "notes",
    type: "text",
    required: "required",
    label: "Notes",
    validationType: "string",
    validations: [

      {
        type: "required",
        params: ["Required."],
      },

    ],
  },
];

export default fields;
