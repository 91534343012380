import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../ui/TableContainer";
import { useUserAccounts } from "../provider";
import { useUtils } from "../../../services/utilsProvider";
import { Pagination } from "../../../ui";

const cols = [
  { name: "displayName", label: "Display Name" },
  { name: "username", label: "Username" },
];

function ListPo({ company }) {
  const navigate = useNavigate();
  const {alertUser ,notify} = useUtils()

  const {
    state: { pos, poStatus, Pcount, Ppage, perPage , pochangeStatus},
    fetchPo,
    dispatch  ,
    revokePO
  } = useUserAccounts();

  useEffect(() => {
    if (company.id) fetchPo(company.id ,(Ppage +1));
  }, [fetchPo, company.id , Ppage , pochangeStatus]); 

  const handleChangePage = useCallback(
    (event, newPage) => newPage&& dispatch({ type: "set_Ppage", payload: newPage }),
    [dispatch]
  );

  const handleRevokePO = useCallback(
    async (id) => {
      try{
        await revokePO(id)
        dispatch({ type: "pochangeStatus", payload: `changed` });
        dispatch({ type: "pochangeStatus", payload: `idle` });
        notify("a Po Privilege has been revoked successfully.");
      }catch(e){
        alertUser("not a PO")
      }
       
     },
     [navigate]
   );
  return (
    <div>
      <TableContainer
        empty={pos?.length === 0}
        status={poStatus}
        aria-label="Holidays table"
        cols={cols}
        data={pos}
        moreOptions={{
          setAsPo: {
            label: "ٌRevoke PO",
            icon: "",
            fn: handleRevokePO,
          },
        }}

      />
      <Pagination
        data-testid="pagination"
        count={Pcount}
        page={Ppage}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default memo(ListPo);

