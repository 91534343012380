import styled from "styled-components";
import { Icon } from "../../../ui";
import Link from "../../../ui/Link";

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    line-height: 0;
    margin-top: 10px;
    font-weight: 600;
    font-size: 15px;
    flex-wrap: wrap;  
    svg {
        margin-right: 6px;
    }
    span{
      margin-top: 10px;
    }
`

export default function EmployeeUserCell(employee) {
  if (!employee.userInfo)
    return (
      <StyledLink to={`/accounts/new/employee/${employee.id}`}>
        <Icon name="addSquare" w={16} c="primary" /> <span>Create</span> <span>User</span>
      </StyledLink>
    );
  return (
    <div>
      {employee.userInfo.username} <br />
      <StyledLink to={`/employees/changepassword/${employee.id}`}>
       <Icon name="repeat" w={16} c="primary" /> <span>Reset</span> <span > Password</span>
      </StyledLink>
    </div>
  );
}
