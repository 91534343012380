import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import AttendancePatternForm from "./AttendancePatternForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddAttendancePattern() {
  const navigate = useNavigate();
  const params = useParams();
  const { notify } = useUtils();

  const handleSuccess = (id) => {
    navigate(`/attendance-pattern/edit/${id}`);
    notify("a new Attendance Pattern has been created successfully.");
  };

  return (
    <div id="add-attendance-pattern">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Attendance Pattern</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(`/attendance-pattern/list/company/${params.id}`)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <AttendancePatternForm handleSuccess={handleSuccess} companyId={params.id} />
    </div>
  );
}

export default memo(AddAttendancePattern);
