import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Box, Button, Card, Divider, Flex, Icon, TextField } from "../../ui";
import { usePayrolls } from "./provider";
import months from "../../data/months.json";
import { currency } from "../../utilities/functions";
import TableContainer from "../../ui/TableContainer";
import { useCompensations } from "../compensations/provider";
import { useUtils } from "../../services/utilsProvider";
import Dropdown from "../../ui/Dropdown";
import UploadDocumentForm from "./viewWage/UploadDocumentForm";
import CompensationsForm from "../compensations/CompensationsForm";
import DeductionsForm from "../deductions/DeductionsForm";
import { useDeductions } from "../deductions/provider";

const compensationsCols = [
  { name: "amount", fn: "currency", label: "Amount" },
  { name: "notes", label: "Notes" },
];

const documentsCols = [
  { name: "documentTitle", label: "Title" },
  { name: "documentUrl", fn: "link", label: "File" },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    color: ${(props) => props.theme.color.gray500};
    padding: 12px 15px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
    transition: all 0.08s ease-in-out;
    border: transparent 1px solid;
    &:hover {
      background-color: ${(props) => props.theme.color.gray100};
    }
    &:active,
    &:focus {
      border-top: ${(props) => props.theme.color.gray200} 1px solid;
      border-left: ${(props) => props.theme.color.gray200} 2px solid;
      padding: 10px 14px;
    }
    svg {
      margin-right: 8px;
    }
  }
  @media only print {
    display: block;
    width: 100%;
    span {
      display: none;
    }
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  @media only print {
    width: 90vw;
    max-width: auto;
    span {
      display: none;
    }
  }
`;

function ViewWage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    state: { wage, status, documents },
    fetchWage,
    removeDocument,
  } = usePayrolls();

  const {
    state: { list: compensations },
    removeFromP: removeCompensation,
    dispatch,
  } = useCompensations();

  const {
    state: { list: deductions },
    removeFromP: removeDeduction,
    dispatch: deductionsDispatch,
  } = useDeductions();

  const { notify } = useUtils();

  useEffect(() => {
    fetchWage(id);
  }, [fetchWage, id]);

  useEffect(() => {
    if (wage && wage.compensations.length)
      dispatch({ type: "set_list", payload: wage.compensations });
  }, [wage, dispatch]);

  useEffect(() => {
    if (wage && wage.deductions.length)
      deductionsDispatch({ type: "set_list", payload: wage.deductions });
  }, [wage, deductionsDispatch]);

  const handleUpload = () => {};

  const handleDocumentDelete = async (id) => {
    try {
      await removeDocument(id);
      notify("A decument has been deleted successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompensationDelete = async (id) => {
    try {
      await removeCompensation(id);
      notify("A compensation has been deleted successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeductionDelete = async (id) => {
    try {
      await removeDeduction(id);
      notify("A deduction has been deleted successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  if (!wage) return;

  return (
    <div>
      <Grid>
        <Flex sx={{ mb: 20 }}>
          <Box as={Link} to={-1} sx={{ mr: 17, ml: 5 }}>
            <Icon name="arrowLeft" w="21" hover="primary" />
          </Box>{" "}
          <h2 style={{ margin: "0" }}>Employee Wage</h2>
        </Flex>
        {/* <h2 style={{ marginTop: "0" }}>Employee Wage</h2> */}
        <span
          onClick={() =>
            navigate("/payrolls/print", {
              state: {
                wage: wage,
                deductions: deductions,
                compensations: compensations,
              },
            })
          }
        >
          <Icon name="print" w={18} c="gray500" /> Print
        </span>
      </Grid>
      <Card>
        <div className="floating-badge">
          Net Pay <br />{" "}
          <strong>{currency(wage.netSalary, wage?.currencyInfo.symbol)}</strong>
        </div>
        <Grid2>
          <TextField
            label={"Full name"}
            inputProps={{
              value: wage.employeeName,
              readOnly: true,
              disabled: true,
            }}
          />

          <TextField
            label={"Jod description"}
            inputProps={{
              value: wage.jobDescription,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Basic salary"}
            inputProps={{
              value: currency(wage.basicSalary, wage?.currencyInfo.symbol),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Company"}
            inputProps={{
              value: wage.companyName,
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Department"}
            inputProps={{
              value: wage.departmentName || "-",
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Date"}
            inputProps={{
              value: `${months[wage.salaryMonth - 1].long} ${wage.salaryYear}`,
              readOnly: true,
              disabled: true,
            }}
          />

          <TextField
            label={"Bonus"}
            inputProps={{
              value: currency(wage.bonus, wage?.currencyInfo.symbol),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Total compensations"}
            inputProps={{
              value: currency(
                wage.totalCompensations,
                wage?.currencyInfo.symbol
              ),
              readOnly: true,
              disabled: true,
            }}
          />
          <TextField
            label={"Total deductions"}
            inputProps={{
              value: currency(wage.totalDeductions, wage?.currencyInfo.symbol),
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid2>
      </Card>

      <Divider h={20} />

      <Grid>
        <h2>Documents</h2>
        <Dropdown
          content={<UploadDocumentForm id={wage.id} />}
          placement="left"
        >
          <div>
            <Button
              className="small"
              onClick={handleUpload}
              iconName="CloudArrowUp"
              iconWidth={20}
            >
              Upload
            </Button>
          </div>
        </Dropdown>
      </Grid>
      <TableContainer
        empty={documents.length === 0}
        status={status}
        aria-label="Documents table"
        cols={documentsCols}
        data={documents}
        moreOptions={{ delete: handleDocumentDelete }}
      />

      <Divider h={20} />

      <Grid>
        <h2>Compensations</h2>
        <Dropdown
          content={
            <CompensationsForm
              id={wage.id}
              handleSuccess={() =>
                notify("A compensation has been added successfully")
              }
            />
          }
          placement="left"
        >
          <div>
            <Button className="small" iconName="addSquare">
              Add
            </Button>
          </div>
        </Dropdown>
      </Grid>
      <TableContainer
        empty={compensations.length === 0}
        status={status}
        aria-label="Compensations table"
        cols={compensationsCols}
        data={compensations}
        moreOptions={{ delete: handleCompensationDelete }}
      />

      <Divider h={20} />

      <Grid>
        <h2>Deductions</h2>
        <Dropdown
          content={
            <DeductionsForm
              id={wage.id}
              handleSuccess={() =>
                notify("A deduction has been added successfully")
              }
            />
          }
          placement="left"
        >
          <div>
            <Button className="small" iconName="addSquare">
              Add
            </Button>
          </div>
        </Dropdown>
      </Grid>
      <TableContainer
        empty={deductions.length === 0}
        status={status}
        aria-label="Deductions table"
        cols={compensationsCols}
        data={deductions}
        moreOptions={{ delete: handleDeductionDelete }}
      />
    </div>
  );
}

export default ViewWage;
