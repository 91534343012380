import styled from 'styled-components';
import { Icon } from '.';

const Container = styled.div`
    position: relative;
    input {
        border: none;
        border-radius: 15px;
        background-color: white;
        height: 48px;
        width: 100%;
        padding-left: 60px;
        box-shadow: 0px 4px 4px #0000000A;
        &:focus, &:active {
            outline: ${props => props.theme.color.primary} 2px solid;
        }
    }
    svg {
        position: absolute;
        top: 50%;
        left: 30px;
        width: 18px;
        fill: ${props => props.theme.color.primary};
        transform: rotate(90deg) translate(-50%, 0);
    }
`;

function SearchInput({onChange, value}) {
    return <Container className="search">
        <Icon name="search" w={24} />
        <input type="text" placeholder='Search here' onChange={(e) => onChange(e.target.value)} value={value} />
    </Container>
}

export default SearchInput;