import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";
import { useAuth } from "./auth";

let refreshing = false;
let commandsQueue = [];

export const useRequest = () => {
  const auth = useAuth();

  const queue = useCallback(
    (...params) => {
      if (refreshing)
        return new Promise((resolve, reject) => {
          const command = () => {
            queue(...params).then(resolve, reject);
          };
          commandsQueue.push(command);
        });

      return new Promise(async (resolve, reject) => {
        try {
          if (params[3]) await checkToken();

          const res = await request(...params);

          if (res.status === 401) {
            if (auth) {
              auth.signout();
              window.location.replace(
                `/login?from=${window.location.pathname}`
              );
            }
            reject({ status: res.status, errors: "Unauthorised Access" });
          } else if (res.status === 403) {
            //   window.location.replace("/no-access");
            // reject({ status: res.status, errors: "Access Denied" });
          } else if (!res.ok) {
            const eData = await res.json();
            reject({ status: res.status, errors: eData });
          }
          const resData = await (res.status !== 204 ? res.json() : {});
          resolve(resData);
        } catch (error) {
          console.log("console error", error);
          reject(error);
        }
      });
    },
    [auth]
  );

  return queue;
};

export default function request(
  url,
  data = null,
  options = {},
  withAuth = false,
  headers = {}
) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...options,
  };
  try {
    if (data)
      if (defaultOptions.headers["Content-Type"] === "application/json")
        defaultOptions.body = JSON.stringify(data);
      else defaultOptions.body = data;
    if (withAuth)
      defaultOptions.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("tokens"))?.accessToken
      }`;
    return fetch(`${baseUrl}/${url}`, defaultOptions);
  } catch (error) {
    throw new Error(error);
  }
}

function checkToken() {
  return new Promise(async (resolve, reject) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (!tokens || tokens === "undefined") resolve();

    // function toTimestamp(strDate) {
    //   var datum = Date.parse(strDate);
    //   return datum / 1000;
    // }
    // let expireDate = toTimestamp(tokens?.expiration);

    const jwt = JSON.parse(atob(tokens.accessToken.split(".")[1]));
    let milliExp = jwt.exp * 1000;

    if (milliExp < Date.now()) {
      refreshing = true;
      try {
        await refreshToken(tokens);
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        refreshing = false;
        commandsQueue.forEach((command) => command());
        commandsQueue = [];
      }
    }
    resolve();
  });
}

async function refreshToken(tokens) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`Auth/refreshToken`, {
        accessToken: tokens?.accessToken,
        refreshToken: tokens?.refreshToken,
      });
      const { data } = await res.json();
      if (data?.accessToken)
        localStorage.setItem("tokens", JSON.stringify(data));
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
