import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUtils } from '../../services/utilsProvider';
import { Icon } from '../../ui';


const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;


function Company() {
  const navigate = useNavigate();
  const { notify } = useUtils();
  return (
    <div className='CompanyForm'>
        <div id="add-employee">
        <Grid>
            <h2 style={{ marginTop: "0" }}>Add New Leave</h2>
            <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <span>Back</span>
            <Icon name="arrowLeft" />
            </div>
        </Grid>

        
        </div>
    </div>
  )
}

export default Company