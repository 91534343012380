import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddOrder from "./AddOrder";
import AddLeave from "./AddOrder";

import EditLeave from "./EditLeave";

import Orders from "./Orders";
import OrdersList from "./OrdersList";
import SendEmail from "./SendEmail";
import ViewOrders from "./ViewOrders";

export default (
  <>
    <Route
      path="orders"
      element={
        <RequireAuth>
          <Orders />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <OrdersList />
          </RequireAuth>
        }
      />
      <Route
        path="new/:id"
        element={
          <RequireAuth>
            <AddOrder />
          </RequireAuth>
        }
      />
      <Route
        path="newOrder/:companyId"
        element={
          <RequireAuth>
            <AddOrder />
          </RequireAuth>
        }
      />
      <Route
        path="view/:id"
        element={
          <RequireAuth>
            <ViewOrders />
          </RequireAuth>
        }
      />
      <Route
        path="edit/:id"
        element={
          <RequireAuth>
            <EditLeave />
          </RequireAuth>
        }
      />
      <Route
        path="sendemail/:id"
        element={
          <RequireAuth>
            <SendEmail />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
