import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import SearchInput from "../../ui/SearchInput";
import CompanyNameCell from "./listCompanies/CompanyNameCell";
import { useCompanies } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import { useState } from "react";
import ParentCompanyNameCell from "./listCompanies/ParentCompanyNameCell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function ListCompanies() {
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const {
    state: { list, status },
    fetchList,
    remove,
    dispatch
  } = useCompanies();
  const navigate = useNavigate();
  const { notify ,alertUser} = useUtils();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const cols = [
    { name: "taxNumber", label: "Number", styles: { fontWeight: 600 } },
    {
      name: "businessName",
      label: "Company Name",
      render: CompanyNameCell,
    },
    { name: "registrationDate", fn: 'date', label: "Date Created" },
    {
      name: "employeesCount",
      label: "Employees",
      styles: {
        fontWeight: 700,
        color: theme.color.primary,
      },
    },
    {
      name: "parentCompanyInfo",
      label: "Parent Company",
      render: ParentCompanyNameCell,
    },
  ];

  const handleClick = () => {
    navigate("/companies/new");
  };

  const handleView = ({id}) => {
    navigate(`/companies/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/companies/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("Item has been removed");
    } catch (error) {
      alertUser(error.errors.message + ": cant be deleted");
      console.log(error);
    }
  };

  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
  },[])
  
  const handleAddPattern = (id) => {
    navigate(`/attendance-pattern/list/company/${id}`);
  };

  const handleViewHolidays = (id) => {
    navigate(`/holidays/company/${id}`);
  }

  const handleViewDeps = (id) => {
    navigate(`/departments/company/${id}`);
  }

  return (
    <>
      <Grid>
        <h2>Companies List</h2>
        <SearchInput onChange={setSearch} value={search} />
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button>
      </Grid>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Company table"
        cols={cols}
        data={list.filter(item => item.businessName.toLowerCase().includes(search.toLowerCase(), 0))}
        checkbox
        showButton={handleView}
        moreOptions={{
          edit: handleEdit,
          delete: handleDelete,
          AddPattern: {
            label: "Attendance Patterns",
            icon: "repeat",
            fn: handleAddPattern,
          },
          viewHolidays: {
            label: "Holidays",
            icon: "calendarHolidays",
            fn: handleViewHolidays,
          },
          viewDeps: {
            label: "Department",
            icon: "eye",
            fn: handleViewDeps,
          },
        }}
      />
    </>
  );
}

export default ListCompanies;
