import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Divider, Select, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useTransaction } from "./provider";
import { useStatus } from "../../services/StatusProvider";
import { useCompanies } from "../companies/provider";
import { useCallback } from "react";
import { useYear } from "../year/provider";
import { toDateTime } from "../../utilities/functions";
import TextArea from "../../ui/TextArea";
import FilterForm from "./FilterForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const ShiftsWrap = styled.div`
  > div {
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0px;
    position: relative;
    height: 65px;
    .grids {
      width: 1200px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 24px;
      gap: 5px;
      position: relative;
      .acc {
        input {
          border: 1px solid
            ${(props) => (props.sumError ? "red" : props.theme.color.grayText)};
        }
      }
      span.remove {
        margin-top: 30px;
        display: block;
        justify-self: center;
        cursor: pointer;
        svg {
          fill: ${(props) => props.theme.color.grayText};
        }
        &:hover {
          svg {
            fill: ${(props) => props.theme.color.red};
          }
        }
      }
      span.add {
        cursor: pointer;
        &:hover {
          svg {
            fill: ${(props) => props.theme.color.primary};
          }
        }
      }
      h4 {
        margin: 0;
      }
      > div {
        margin-bottom: 0;
      }
      &:first-child::before {
        content: none;
      }
      &:last-child::before {
        content: none;
      }
    }
  }
`;

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid3 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

function ViewTransaction() {
  const params = useParams();
  const navigate = useNavigate();
  const [debitSum, setDebitSum] = useState(0);
  const [creditSum, setCreditSum] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [error, setError] = useState();
  let show = ["years"];

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: years },
    fetchList: fetchYears,
  } = useYear();
  const {
    state: { selected, filters },
    fetchOne,
    setFilters,
  } = useTransaction();
  useEffect(() => {
    if (companyId) fetchYears({ companyId: companyId });
  }, [companyId]);

  useEffect(() => {
    if (isAcc && filters.financialYearId)
      fetchOne({
        journalEntryId: params.id,
        companyId: user?.employee?.company?.id,
      });
    else if (filters.financialYearId)
      fetchOne({ journalEntryId: params.id, companyId: filters.companyId });
  }, [params.id, fetchOne, filters]);

  const {
    state: { list: companies },
  } = useCompanies();

  const handleCompanyChange = useCallback((data) => console.log(data));
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  const normalgetLocalDate = (x) => {
    let d = new Date(x).toLocaleString();
    let dd = new Date(d);
    let ddd = convertUTCDateToLocalDate(dd);

    return (
      ddd.getFullYear() +
      "-" +
      (ddd.getMonth() + 1).padLeft() +
      "-" +
      ddd.getDate().padLeft() +
      " " +
      ddd.getHours().padLeft() +
      ":" +
      ddd.getMinutes().padLeft()
    );
  };

  useEffect(() => {
    let c = 0,
      d = 0;
    if (selected) {
      selected?.entries?.map((s, i) => (c += Number(s?.debit)));
      selected?.entries?.map((s, i) => (d += Number(s?.credit)));
    }
    setDebitSum(d);
    setCreditSum(c);
  }, [selected]);
  function displayEntriesFields() {
    return selected?.entries?.map((item, i) => (
      <div key={i} data-count={i + 1}>
        <div className="grids">
          <TextField
            label=""
            inputProps={{
              value: item?.debit,
              type: "string",
              readOnly: true,
              diabled: true,
            }}
          />
          <TextField
            label=""
            inputProps={{
              value: item?.credit,
              type: "string",
              readOnly: true,
              diabled: true,
            }}
          />
          <TextField
            label=""
            inputProps={{
              value: item?.accountName,
              type: "string",
              readOnly: true,
              diabled: true,
            }}
          />
          <TextField
            label=""
            name="notes"
            inputProps={{
              value: item?.notes,

              disabled: true,
            }}
          />
          <div></div>
        </div>
      </div>
    ));
  }
  if (!filters.financialYearId) {
    return (
      <FilterForm
        setFilters={setFilters}
        companyId={companyId}
        years={years}
        isAcc={isAcc}
        setCompanyId={setCompanyId}
        error={error}
        setError={setError}
        show={show}
      />
    );
  }
  if (!selected || selected.id !== Number(params.id)) return;

  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>journal Information</h2>
        <div style={{ cursor: "pointer" }}>
          {isAcc && (
            <Button
              style={{
                paddingRight: "40px",
                paddingLeft: "40px",
                marginLeft: "30px",
              }}
              iconName="pen"
              className="small"
              onClick={() => navigate(`/transaction/edit/${params.id}`)}
            >
              Edit
            </Button>
          )}
        </div>
      </Grid>
      <Card>
        <Grid2>
          <TextField
            label="Company"
            name="company"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: isAcc
                ? user?.employee?.company?.companyName
                : filters?.companyName,
              disabled: true,
            }}
          />

          <TextField
            required
            label="Year"
            name="year"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: years
                .filter((y) => y.id == selected?.financialYearId)
                .map((y) => y.title),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Create Date"
            name="createDate"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: toDateTime(normalgetLocalDate(selected?.createdDate), {
                time: true,
              }),
              disabled: true,
            }}
          />

          <TextField
            required
            label="Modified Date"
            name="modifiedDate"
            style={{ marginBottom: "10px" }}
            inputProps={{
              value: toDateTime(normalgetLocalDate(selected?.modifiedDate), {
                time: true,
              }),
              disabled: true,
            }}
          />
        </Grid2>
        <TextArea
          style={{ maxWidth: "1200px", marginTop: "20px" }}
          label="notes"
          name="notes"
          rows="2"
          value={selected?.notes}
          inputProps={{
            disabled: true,
          }}
        />

        <div>
          <ShiftsWrap>
            <div>
              <div className="grids">
                <h4>debit</h4>
                <h4>credit</h4>
                <h4>account</h4>
                <h4>notes</h4>
              </div>
            </div>
            {displayEntriesFields()}
          </ShiftsWrap>
        </div>
        <Divider></Divider>
        <Grid3>
          <TextField
            label="Debit Sum"
            name="debitSum"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: debitSum,
              disabled: true,
            }}
          />

          <TextField
            label="Credit Sum"
            name="creditSum"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: creditSum,
              disabled: true,
            }}
          />

          <TextField
            label="Total"
            name="total"
            style={{ marginBottom: "0px" }}
            inputProps={{
              value: creditSum - debitSum,
              disabled: true,
            }}
          />
        </Grid3>
      </Card>
    </div>
  );
}

export default memo(ViewTransaction);
