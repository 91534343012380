import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import { useTransaction } from "./provider";
import TransactionForm from "./TransactionForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditTransaction() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: { selected: journal },
    fetchOne,
    dispatch,
  } = useTransaction();

  useEffect(() => {
    fetchOne({
      journalEntryId: params.id,
      companyId: user?.employee?.company?.id,
    });
    return () => dispatch({ type: "set_selected", payload: null });
  }, [params.id, fetchOne, dispatch]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

  if (!journal || journal.id !== Number(params.id)) return;

  return (
    <div id="edit-year">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit journal</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <TransactionForm journal={journal} handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(EditTransaction);
