import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import ListDepartment from "./ListDepartments";
import Department from "./Departments";
import AddDepartment from "./AddDepartment";
import EditDepartment from "./EditDepartment";
import ViewDepartment from "./ViewDepartment";

export default (
  <Route
    path="departments/company/:cid"
    element={
      <RequireAuth>
        <Department />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListDepartment />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddDepartment />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditDepartment />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewDepartment />
        </RequireAuth>
      }
    />
  </Route>
);
