import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Payrolls() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Payrolls;
