import React, { useState } from "react";
import { Button, Card, Checkbox, Icon, TextField } from "../../ui";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUserAccounts } from "./provider";
import { useUtils } from "../../services/utilsProvider";
import ErrorsList from "../../ui/ErrorsList";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const schema = Yup.object().shape({
  displayName: Yup.string(),
  username: Yup.string(),
  email: Yup.string().email("must be a valid email"),
  password: Yup.string(),
});

function CreateAdmin() {
  const navigate = useNavigate();
  const { createAdmin } = useUserAccounts();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("a new admin has been created successfully.");
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    try {
      await createAdmin(values);
      reset();

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400)         setError({
        title: `please check the data submitted`,
      });
      else if (e.status == 409)
        setError({
          title: `The Name "${values.username}"  already an admin for this company.`,
        });
      console.log("e", e);
    }
  };

  return (
    <div id="add-user">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Create Admin</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

        <Card>
          <Grid2>
            <TextField
              style={{ marginBottom: 0 }}
              label="displayName"
              name="displayName"
              error={!!errors.displayName}
              msg={errors.displayName?.message}
              inputProps={{
                ...register("displayName"),
                style: { height: 60 },
              }}
            />

            <TextField
              style={{ marginBottom: 0 }}
              label="username"
              name="username"
              error={!!errors.username}
              msg={errors.username?.message}
              inputProps={{
                ...register("username"),
                style: { height: 60 },
              }}
            />

            <TextField
              style={{ marginBottom: 0 }}
              label="email"
              name="email"
              error={!!errors.email}
              msg={errors.email?.message}
              inputProps={{
                ...register("email"),
                style: { height: 60 },
              }}
            />

            <TextField
              style={{ marginBottom: 0 }}
              label="password"
              name="password"
              error={!!errors.password}
              msg={errors.password?.message}
              inputProps={{
                ...register("password"),
                style: { height: 60 },
                type: "password",
              }}
            />

            <Checkbox
              style={{ marginTop: "20px" }}
              id="isActive"
              name="isActive"
              label="isActive"
              inputProps={{
                ...register("isActive"),
              }}
            />
          </Grid2>
        </Card>
        <ButtonWrapper>
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
            loading={isSubmitting}
          >
            Save
          </Button>
          <Button
            type="button"
            className="outlined small"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </form>
    </div>
  );
}

export default CreateAdmin;
