import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import CompensationsForm from "./DeductionsForm";
import { useCompensations, useEmployeeDeduction } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EmployeeDeductions() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const {
    state: { selected },
    fetchOne,
    dispatch,
  } = useEmployeeDeduction();

  useEffect(() => {
    fetchOne(params.id);
    return () => dispatch({ type: "set_selected", payload: null });
  }, [params.id, fetchOne, dispatch]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Changes saved successfully");
  };

  if (!selected || selected.id !== Number(params.id)) return;

  return (
    <div id="edit-compensation">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit compensation</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <CompensationsForm compensation={selected} handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(EmployeeDeductions);
