import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Tabs = styled.div`
  ul {
    display: flex;
    margin-buttom: 8px;
    li {
      flex: 2;
      text-align: center;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .marker {
    width: ${(props) => props.width}px;
    margin-left: ${(props) => props.left}px;
  }
`;
function TabSection({ selectedId, setSelectedId, isAcc }) {
  const [id, setId] = useState(0);
  let selected = document.getElementById("red");
  let left;
  left = selected?.offsetWidth * selectedId + 140;
  return (
    <Tabs left={left} width={selected?.offsetWidth - 200 || 138}>
      <ul>
        {
          <li onClick={() => setSelectedId(0)} id="red">
            Simple Entry
          </li>
        }
        {<li onClick={() => setSelectedId(1)}>New Entry</li>}
        <li onClick={() => setSelectedId(2)}>Account Info</li>
        <li onClick={() => setSelectedId(3)}>Sub Account</li>
      </ul>
      <div className="marker"></div>
    </Tabs>
  );
}

export default TabSection;
