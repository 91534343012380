import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEmployees } from './provider';
import { useUtils } from '../../services/utilsProvider';
import { Button, Card, TextField } from '../../ui';
import ErrorsList from '../../ui/ErrorsList';
const schema = Yup.object().shape({
	newPassword: Yup.string().required(),
});

const Grid = styled.div`
	max-width: 710px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;
	row-gap: 0;
`;

const ButtonWrapper = styled.div`
	margin-top: 40px;
	button {
		float: right;
		margin-left: 20px;
		min-width: 180px;
	}
`;
function ChangePassword() {
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const { notify } = useUtils();
	const params = useParams();
    const [newPass , setNewPass] = useState(null)
	const {
		state: { selected },
		fetchOne: fetchEmployee,
		changepassword,
	} = useEmployees();
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleSuccess = () => {
		notify('Password has been changed successfully.');
		navigate(-1);
	};

	useEffect(() => {
		if (params.id) fetchEmployee(params.id);
	}, [fetchEmployee]);

	const onSubmit = async () => {
		try {
			await changepassword(selected.userInfo.id , {newPassword:newPass});
			reset();
			handleSuccess();
		} catch (e) {
			setError({title:"Passwords must be at least 6 characters , at least one non alphanumeric character , at least one digit ('0'-'9') and at least one uppercase ('A'-'Z')."})
			window.scrollTo(0, 0);
			console.log('e', e);
		}
	};
	if (!selected) return;
	return (
		<>
			<Grid>
				<h2>Reset Password</h2>
			</Grid>
			<Card>
				{' '}
				<TextField
					label="Employee Name"
					name="fullName"
					style={{ width: '50%' }}
					inputProps={{
						value: selected.fullName,
						disabled: true,
					}}
				/>
			</Card>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
			{error && <ErrorsList error={error} />}

				<Card style={{ marginBottom: '20px', padding: '20px 40px 20px 40px' }}>
					<TextField
						label="New Password"
						name="newPassword"
						error={!!errors.newPassword}
						msg={errors.newPassword?.message}
						style={{ width: '50%' }}
                        onChange={(v)=>setNewPass(v.target.value)}
						inputProps={{
							...register('newPassword'),
						}}
					/>
				</Card>
				<ButtonWrapper>
					<Button
						type="submit"
						data-testid="save-button"
						iconName="Disk"
						className="small"
						loading={isSubmitting}
                        onClick={()=>onSubmit()}
					>
						Save
					</Button>

					<Button className="outlined small" onClick={() => navigate(-1)}>
						Cancel
					</Button>
				</ButtonWrapper>
			</form>
		</>
	);
}

export default ChangePassword;
