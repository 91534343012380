const { useState, useEffect, useRef } = require("react");

function useGenPagerList(total, pageIndex) {

  const [list, setList] = useState([]);
  const tempList = useRef([]);

  useEffect(() => {
    tempList.current = Array.from(
      { length: total < 7 ? total : 7 },
      (_, i) => i + 1
    );
    const lastIndex = tempList.current.length - 1;

    if (total > 7) {
      const pageNum = pageIndex + 1;
      const isMiddle = pageNum >= 5 && pageNum <= total - 3;
      const isLeft = pageNum <= 5;
      const isRight = pageNum >= total - 3;

      tempList.current[1] = isRight || isMiddle ? "..." : 2;

      if (isMiddle) {
        tempList.current[2] = pageNum - 1;
        tempList.current[3] = pageNum;
        tempList.current[4] = pageNum + 1;
      } else {
        tempList.current[2] = isLeft ? 3 : total - 4;
        tempList.current[3] = isLeft ? 4 : total - 3;
        tempList.current[4] = isLeft ? 5 : total - 2;
      }

      tempList.current[lastIndex - 1] = isLeft || isMiddle ? "..." : total - 1;
      tempList.current[lastIndex] = total;
    }

    setList(tempList.current);
  }, [pageIndex, total]);

  return list;
}

export default useGenPagerList;
