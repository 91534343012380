import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";

function Year() {


  return <Layout><Outlet /></Layout>;
}

export default Year;
