import React, { useEffect, useState } from "react";
import TableContainer from "../../../ui/TableContainer";
import FilterForm from "../FilterForm";
import { Button, Card, Flex, Pagination, TextField } from "../../../ui";
import styled from "styled-components";
import { useStatus } from "../../../services/StatusProvider";
import {
  dateToLocalConverter,
  exporToExcle,
  getValueFromEnum,
} from "../../../utilities/functions";
import { useCompanies } from "../../companies/provider";
import AccountType from "../tableCell/AccountType";
import SpecialType from "../tableCell/SpecialType";
import AccountStatus from "../tableCell/AccountStatus";
import IsActive from "../tableCell/IsActive";
import NoteCell from "../tableCell/NoteCell";
import { useTransaction } from "../../transaction/provider";
import { useAccounts } from "../provider";
import { accountType, accountsStatus, specialType } from "../../../data/Enums";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
const cols = [
  { name: "baseAccountNumber", label: "Base Account Number" },

  { name: "baseAccountName", label: "Base Account Name" },

  { name: "accountName", label: "Account Name" },
  { name: "accountNumber", label: "Account Number" },
  { name: "accountType", label: "Account Type", render: AccountType },
  { name: "specialType", label: "Special Type", render: SpecialType },
  { name: "accountStatus", label: "Account Status", render: AccountStatus },
  { name: "isActive", label: "Is Active", render: IsActive },
  { name: "notes", label: "notes", render: NoteCell },
];
function ExportExcel() {
  const [error, setError] = useState();
  const [companyId, setCompanyId] = useState();
  let user = JSON.parse(localStorage.getItem("user"));

  const {
    state: { exportData, filters },
    setFilters,
    getData,
  } = useAccounts();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    if (!companyId) {
      if (isAcc) {
        setCompanyId(user?.employee?.company?.id);
      } else {
        setCompanyId(companies[0]?.id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (companyId && !filters)
      setFilters({
        companyId: companyId,
        //financialYearId: years[0]?.id,
      });
  }, [companyId]);

  const {
    state: { isAcc },
  } = useStatus();

  let hide = ["parentName"];
  const getExcelObj = (x) => {
    let newObj = new Array(exportData.length);
    exportData?.map((i, idx) => {
      newObj.push({
        baseAccountNumber: i.baseAccountNumber,
        baseAccountName: i.baseAccountName,
        accountName: i.accountName,
        accountNumber: i.accountNumber,
        accountType: getValueFromEnum(
          i.accountType,
          "id",
          accountType,
          "type"
        )[0],
        specialType: getValueFromEnum(
          i.specialType,
          "id",
          specialType,
          "type"
        )[0],
        accountStatus: getValueFromEnum(
          i.accountStatus,
          "id",
          accountsStatus,
          "status"
        )[0],
        isActive: i.isActive,
        notes: i.notes,
      });
    });

    return newObj.filter((item) => {
      return item;
    });
  };

  useEffect(() => {
    if (filters?.companyId) getData(filters);
  }, [getData, filters]);
  console.log(exportData, "exportData");
  return (
    <>
      <Grid>
        <h2>Export Chart of Accounts</h2>
        <Button
          style={{
            paddingRight: "40px",
            paddingLeft: "40px",
            marginLeft: "30px",
          }}
          iconName="pen"
          className="small"
          onClick={() =>
            filters.companyId &&
            exporToExcle(
              getExcelObj(exportData),
              "chart of accounts for : " + filters.companyId
            )
          }
        >
          Export Excel
        </Button>
      </Grid>

      <FilterForm
        setFilters={setFilters}
        isAcc={isAcc}
        error={error}
        setError={setError}
        companyId={companyId}
        hide={["companyName", "parentName"]}
      />
      <>
        <TableContainer
          empty={exportData?.length == 0}
          //status={jstatus}
          aria-label="journal table"
          cols={cols}
          data={exportData}
          checkbox
        />
      </>
    </>
  );
}

export default ExportExcel;
