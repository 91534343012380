import styled from "styled-components";
import List from "./List";

const Wrapper = styled.div`
    p {
        margin: 0;
    }
    dl {
        padding-top: 0;
    }
`;

function parseObjErrors(errors) {
    const fieldErrorsList = (errors) => {
      return errors.map((e,i) => <dd key={i}>{e}</dd>);
    };

    const fields = Object.keys(errors);
    return fields.map((f, i) => (
      <List key={i}>
        <dt>
          <strong>{f}</strong>
        </dt>
        {fieldErrorsList(errors[f])}
      </List>
    ));
  }

function ErrorsList({error}) {
    return <Wrapper className="error">
    <p>{error.title}</p>
    {error.errors && parseObjErrors(error.errors)}
  </Wrapper>
}

export default ErrorsList;