import styled from "styled-components";
import Icon from "./Icon";
import Loading from "./Loading";

const StyledButton = styled.button`
  width: ${(props) => props.width + "px" || "100%"};
  background-color: ${(props) =>
    props.bg ? props.theme.color[props.bg] : props.theme.color.primary};
  color: white;
  border: none;
  height: 60px;
  padding: 0 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
  font-size: 17px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  svg {
    line-height: 0;
    fill: white;
    margin-right: 5px;
  }
  &.small {
    height: 46px;
    font-size: 16px;
    border-radius: 16px;
    text-transform: none;
  }
  &:disabled {
    background-color: #e5e5e5;
  }
  span.loading {
    margin-top: 0px;
  }
  &.transparent {
    background-color: white;
    color: ${(props) => props.theme.color.grayLight};
    box-shadow: 0px 4px 8px #0000001f;
  }
  &.outlined {
    background-color: transparent;
    color: ${(props) => props.theme.color.grayLight};
    /* box-shadow: 0px 4px 8px #0000001f; */
    border: ${(props) => props.theme.color.dark} 1px solid;
  }
`;

function Button({
  children,
  loading = false,
  disabled,
  iconName = null,
  iconWidth,
  ...props
}) {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {iconName && !loading && <Icon c="white" name={iconName} w={iconWidth || 16} />}
      {loading ? <Loading /> : children}
    </StyledButton>
  );
}

export default Button;
