import { memo, useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Checkbox, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
import { useAttendance } from "./provider";
const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function MachienFrom({ record , handleSuccess }) {
  const [error, setError] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues:record
  });

    // fetch attendance
    const {
      editMachine,
    } = useAttendance();

  const onSubmit = async (values) => { 

    try {
      await   editMachine( params.id, { typeId: values.typeId , notes : values.notes });
      //handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status == 400) setError({ title: e.errors.title });
      console.log("e", e, error);
    }
  };

  console.log(record , 'record');
  //if(!holiday) return
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <Select
            style={{ width: "100%", marginBottom: "20px" }}
            inputStyle={{ height: "60px", width: "100%" }}
            label={'Type'}
            data={ [
                {id:1 , name : 'punchIn'},
                {id:2 , name : 'punchOut'},
                {id:3 , name : 'BreakOut'},
                {id:4 , name : 'BreakIn'},

        
        ]}
            mapOptions={{value : 'id' , label : 'name'}}
            defaultValue={record?.typeId}
            error={!!errors.typeId}
            onChange={(v)=> setValue('typeId' ,v.data.id)}
            msg={errors['typeId']?.message }
            inputProps={{
              ...register('typeId'),
            }}
          />

          <TextField
            required
            label="Notes"
            name="notes"
            error={!!errors.notes}
            defaultValue={record?.notes}
            msg={errors.notes?.message}
    
            inputProps={{
              ...register("notes"),
              required: true,
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(MachienFrom);
