import React from 'react'
import { useNavigate } from 'react-router-dom';

function TitleCell(row) {
    let navigate = useNavigate()
    if(!row) return
    return (
    <div onClick={()=>navigate('/leaves/view/' + row.referenceID)} style={{textDecoration:'underline' , cursor:'pointer'}}>{row?.title}</div>
  )
}

export default TitleCell