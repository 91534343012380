import { useEffect } from "react";
import styled from "styled-components";
import { Box, Button, Card, Flex, Icon, TextField } from "../../ui";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePayrolls } from "./provider";
import TableContainer from "../../ui/TableContainer";
import TotalDeductions from "./TableCell/TotalDeductions";
import TotalCompensations from "./TableCell/TotalCompensations";
import NetSalary from "./TableCell/NetSalary";
import BasicSalary from "./TableCell/BasicSalary";

const cols = [
  { name: "employeeId", label: "Id" },
  { name: "employeeName", label: "Name" },
  { name: "basicSalary", label: "Basic Salary" ,render: BasicSalary },
  { name: "netSalary", label: "net Salary" , render: NetSalary },
  {
    name: "totalCompensations",
    label: "Total Compensations",
    render: TotalCompensations
  },
  { name: "totalDeductions", label: "Total Deductions" , render: TotalDeductions },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

function ViewPayroll() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { selected },
    fetchOne,
  } = usePayrolls();

  useEffect(() => {
    fetchOne({ companyId: params.id, month: params.month, year: params.year });
  }, [params.id, fetchOne, params.month, params.year]);

  const handleViewWage = ({ id }) => {
    navigate(`/payrolls/wage/${id}`);
  };

  if (!selected) return;

  return (
    <div>
      <Grid>
      <Flex sx={{mb: 20}}>
        <Box as={Link} to="/payrolls" sx={{mr: 17, ml: 5}}>
          <Icon name="arrowLeft" w="21" hover="primary" />
        </Box> <h2 style={{ margin: "0" }}>Payroll Information</h2>
      </Flex>
        <div style={{ cursor: "pointer" }}>
          <Button
            iconName="pen"
            className="small"
            onClick={() =>
              navigate(
                `/payrolls/new/${params.id}/${selected.year}/${selected.month}`,
                { state: "edit" }
              )
            }
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <TextField
            label={"company"}
            inputProps={{
              value: selected.companyName,
              readOnly: true,
              disabled: true,
            }}
          />

          <TextField
            label={"month"}
            inputProps={{
              value: selected.month,
              readOnly: true,
              disabled: true,
            }}
          />

          <TextField
            label={"year"}
            inputProps={{
              value: selected.year,
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid2>
      </Card>
      <Card>
        <TableContainer
          empty={selected.employeePayrolls?.length === 0}
          aria-label="Payroll wages table"
          cols={cols}
          data={selected.employeePayrolls}
          showButton={handleViewWage}
        />
      </Card>
    </div>
  );
}

export default ViewPayroll;
