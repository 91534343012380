import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserAccounts } from "./provider";
import { Button, Card, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import { createYupSchema } from "../../utilities/yupSchemaCreator";

const yupSchema = fields.reduce(createYupSchema, {});
const schema = Yup.object().shape(yupSchema);

const Grid = styled.div`
  max-width: 550px;
  display: block;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function UserAccountForm({
  userAccount = null,
  employeeId = null,
  handleSuccess,
}) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { create, edit } = useUserAccounts();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userAccount,
  });

  const onSubmit = async (values) => {
    // console.log('asdasdasd', values)
    // return;
    try {
      if (userAccount) await edit(userAccount.id, values);
      else {
        await create({ ...values, employeeId: Number(employeeId), isActive: true });
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      else if (e.status === 409)
        setError({
          title: `The Name "${values.fullName}" is already in use for this company.`,
        });
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          {fields.map((f, i) => {
            const inputProps = {};
            if (f.required) inputProps.required = true;
            if (i === 0) inputProps.autoFocus = true;
            inputProps.type = f.type;
            return (
              <TextField
                key={i}
                label={f.label}
                name={f.name}
                error={!!errors[f.name]}
                msg={errors[f.name]?.message}
                inputProps={{
                  ...register(f.name),
                  ...inputProps,
                }}
              />
            );
          })}
        </Grid>
      </Card>

      <ButtonWrapper>
        {
          <Button
            type="submit"
            data-testid="save-button"
            iconName="Disk"
            className="small"
            loading={isSubmitting}
          >
            Save
          </Button>
        }
        <Button type="button" className="outlined small" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(UserAccountForm);
