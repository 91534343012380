import React from 'react'

function BasicSalary(row) {
    if(!row) return 

  return (
    <div>
        {
        row?.basicSalary + ' ' + row?.currencyInfo.symbol
    }
    </div>
  )
}

export default BasicSalary