import React from "react";

function MachineDateCell(data) {
    
function convertUTCDateToLocalDate(date) {

    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
  
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
  
    newDate.setHours(hours - offset);
  
    return newDate;   
  }
  const getLocalDate = (x) => {
    let d = new Date(x).toLocaleString();
    let dd = new Date(d);
    let ddd = convertUTCDateToLocalDate(dd);

    return (
      ddd.getDate().padLeft()+
      "-" +
      (ddd.getMonth() + 1).padLeft() +
      "-" +
      ddd.getFullYear() +
      " " +
      ddd.getHours().padLeft() +
      ":" +
      ddd.getMinutes().padLeft()
    );
  };

  var d;
  if (!data.transactionDate) return;
  d = getLocalDate(data.transactionDate);
  return (
    <>
      <section>{d}</section>
    </>
  );
}

export default MachineDateCell;
