import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import image from "../assets/user-multitasking-on-laptop.png";
import { Type, TextField, Icon, Checkbox, Button } from "../ui";
import { useAuth } from "../services/auth";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required("Required")
    .min(5, "Too Short, username with at least 5 letters is required!"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
});

const Grid = styled.div`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 65% 35%;
    height: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LeftCol = styled.div`
  background-color: #fff2d2;
  text-align: center;
  img {
    max-width: 90%;
  }
`;
const RightCol = styled.div`
  background-color: white;
  form {
    width: 100%;
    padding: 0 20px;
    @media (min-width: 600px) {
      padding: 0 75px;
    }
    .title {
      text-align: center;
      padding-bottom: 60px;
    }
    input {
      background-color: #f5f5f5;
      outline: none;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  a {
    padding-right: 8px;
  }
`;

function Login() {
  const [hide, setHide] = useState(true);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const toggleHide = () => {
    setHide((hide) => !hide);
  };

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (auth.user) {
      if(user?.roles.includes('PO') && !user?.roles.includes('HR') ){
        navigate('/purchase')
      }
      else if (user?.roles.includes('Acc'))
        navigate('/transaction')
      else
      navigate(from, { replace: true });
    }
  }, [auth, from, navigate]);

  const onSubmit = async (values) => {
    try {
      let resData = await auth.signin({
        username: values["username"],
        password: values["password"],
      });


    } catch (error) {
      console.log("error", error.status);
      if (error.status === 401) setError("Wrong email/password combination!");
    }
  };

  return (
    <Layout variant="empty">
      <Grid>
        <LeftCol>
          <div>
            <Type var="display">
              Welcome Back to <br /> <em>HR Portal</em>
            </Type>
            <img src={image} alt="user multitasking on laptop" />
          </div>
        </LeftCol>
        <RightCol>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <h1 className="title">Login To Your Account</h1>

            {error && <p className="error">{error}</p>}

            <TextField
              error={!!errors.username}
              msg={errors.username?.message}
              label="Username / Email"
              inputProps={{
                ...register("username"),
                autoFocus: true,
              }}
            />
            <TextField
              error={!!errors.password}
              msg={errors.password?.message}
              label="Password"
              style={{ marginBottom: "15px" }}
              icon={
                <span onClick={toggleHide}>
                  <Icon name={hide ? "hide" : "eye"} />
                </span>
              }
              inputProps={{
                type: hide ? "password" : "text",
                ...register("password"),
              }}
            />
            <Flex>
              <Checkbox
                id="remember-me"
                name="remember-me"
                value="remember-me"
                label="Remember me"
              />
              
            </Flex>
            <Button
              style={{ width: "100%" }}
              loading={isSubmitting}
              type="submit"
            >
              Login
            </Button>
          </form>
        </RightCol>
      </Grid>
    </Layout>
  );
}

export default Login;

