import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Icon } from "../../../ui";

const StyledCard = styled(Card)`
  position: relative;
  text-align: center;
  .options {
    display: none !important;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    border-radius: 50%;
    &:hover {
      background-color: ${(props) => props.theme.color.grayBg};
    }
    svg {
      fill: ${(props) => props.theme.color.grayLight};
      width: 6px;
    }
  }
  .img {
    position: relative;
    display: inline-block;
    line-height: 0;
    .imgWrap {
      width: 105px;
      height: 105px;
      overflow: hidden;
      border-radius: 20%;
      position: relative;
      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    .badge {
      position: absolute;
      bottom: 0;
      right: -19px;
      width: 47px;
      height: 47px;
      border-radius: 15px;
      background-color: ${(props) => props.theme.color.green};
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 13px;
      font-weight: 900;
      font-family: "Open Sans", sans-serif;
    }
  }
  h4 {
    margin: 5px 0 4px 0;
  }
  p.subtitle {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color.grayLight};
    letter-spacing: 0.05em;
    strong {
      color: ${(props) => props.theme.color.primary};
      display: block;
    }
  }
  .salary {
    padding: 14px;
    background-color: ${(props) => props.theme.color.green};
    border-radius: 15px;
    width: 150px;
    margin: 10px auto 30px auto;
    color: white;
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: 700;
    font-size: 15px;
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  ul {
    list-style: none;
    margin: 20px 0 20px 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 10px;
      line-height: 0;
      > span:first-child {
        margin-right: 12px;
        height: 36px;
        width: 36px;
        background-color: ${(props) => props.theme.color.primaryBg};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        svg {
          width: 14px;
          fill: ${(props) => props.theme.color.primary};
        }
      }
      > span:last-child {
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
      }
    }
  }
  button {
    margin: 0 auto;
  }
`;

function Item({ data, forPayroll = false }) {
  const navigate = useNavigate();

  const handleViewDetails = (id) => navigate(`/employees/view/${id}`);
  return (
    <StyledCard>
      <div className="options">
        <Icon name="ellipsisVertical" />
      </div>
      <div className="img">
        <div className="imgWrap">
          <img
            src={data.photoUrl || "https://via.placeholder.com/150"}
            alt="random"
          />
        </div>
        <span className="badge">{ parseInt( data.onTime * 100) || 0}%</span>
      </div>
      <h4>{data.fullName}</h4>
      <p className="subtitle">
        {data.jobDescription} at
        <strong>{data.companyInfo.companyName}</strong>
      </p>
      {!forPayroll ? (
        <ul>
          <li className="phone">
            <span>
              <Icon name="phone" />
            </span>
            <span>{data.mobile}</span>
          </li>
          <li className="email">
            <span>
              <Icon name="envelope" />
            </span>
            <span>{data.email}</span>
          </li>
        </ul>
      ) : (
        <div className="salary">
          {data.basicSalary} <span style={{fontWeight:'800' , fontSize:"16px"}}>{data?.currencyInfo?.symbol}</span> 
        </div>
      )}
      <Button
        className="small transparent"
        width={186}
        onClick={() => handleViewDetails(data.id)}
      >
        Profile Details
      </Button>
    </StyledCard>
  );
}

export default Item;
