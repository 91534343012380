const theme = {
  color: {
    primary: "#6418c3",
    primaryBg: "#F6EEFF",
    pink: "#E328AF",
    rose: "#ca90bb",
    blueSky: "#5ECFFF",
    blue: "#4a5be6",
    green: "#38a8af",
    //gray shads
    gray50: "#f8f8f8",
    gray100: "#f5f5f5",
    gray200: "#eeeeee",
    gray300: "#dddddd",
    gray400: "#cccccc",
    gray500: "#999999",
    gray600: "#666666",
    gray700: "#444444",
    gray800: "#222222",
    gray900: "#1d1d1d",
    gray1k: "#111111",
    gray2k: "#090909",
    grayLight: "#a5a5a5",
    gray: "#C4C4C4",
    grayText: "#8f8f8f",
    grayBg: "#eee",
    dark: "#202020",
    red: "#DC3472",
  },
  fonts: {
    popins: "'Poppins', sans-serif",
    openSans: "'Open Sans', sans-serif",
  },
};

export default theme;
