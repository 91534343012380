import styled, { css } from "styled-components";
import { Card, Type } from "../../ui";
import image from "../../assets/user-monitoring-dashboard.png";
import imageClose from "../../assets/close.png";
import imageNote from "../../assets/notification-widget.png";
import { useState } from "react";

const StyledCard = styled(Card).attrs({ elevation: 4 })`
  position: relative;
  display: flex;
  height: 200px;
  width: 100%;
  @media only screen and (max-width: 1350px) {
    height: auto;
  }
  @media only screen and (max-width: 600px) {
    display: block;
  }
  div.text {
    flex-grow: 1;
  }
  img {
    margin: 0 30px;
    margin-top: -10px;
    &:nth-child(2) {
      @media only screen and (max-width: 1610px) {
        display: none;
      }
      @media only screen and (max-width: 1610px) {
        display: none;
      }
    }
    &:nth-child(3) {
      margin-top: -50px;
      @media only screen and (max-width: 1200px) {
        height: 200px;
      }
      @media only screen and (max-width: 600px) {
        margin-top: 20px;
      }
    }
  }
  .close img {
    margin-right: -20px;
    margin-top: -10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding-left: 18px;
    font-size: 20px;
    font-weight: bold;
    color: #999;
    line-height: 33px;
    &::before {
      content: "";
      background-color: ${(props) => props.theme.color.primary};
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
`;

function Welcome() {
  const [hide, setHide] = useState(false);

  const handleClose = () => {
    setHide(true);
  };

  return (
    <StyledCard hide={hide} elevation={4}>
      <div className="text">
        <h2 style={{ marginTop: 0 }}>Welcome back Admin!</h2>
        <Type var="subtitle" style={{ marginBottom: 0 }}>
          Since your last login on the system, there were:
        </Type>
        <List>
          <li>Your request with number 43583 has been approved</li>
          <li>The date of next Holiday is 25/9/2022</li>
        </List>
      </div>
      <img
        src={imageNote}
        className="first"
        width="192"
        height="177"
        alt="Notification Widget"
      />
      <img
        src={image}
        width="255"
        height="255"
        alt="User monitoring dahsboard"
      />
      <div className="close" onClick={handleClose}>
        <img src={imageClose} alt="Close" />
      </div>
    </StyledCard>
  );
}

export default Welcome;
