import { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import CompensationsForm from "./CompensationsForm";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddCompensations() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(-1);
    notify("a new compensation has been created successfully.");
  };

  return (
    <div id="add-compensation">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Compensation</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <CompensationsForm handleSuccess={handleSuccess} />
    </div>
  );
}

export default memo(AddCompensations);
