import React, { useEffect, useState } from "react";

import "./customTree.css";

import styled from "styled-components";

import TabSection from "./TabSection";
import Tree from "./Tree";
import SimpleEntry from "./SimpleEntry";
import AddTransaction from "../../transaction/AddTransaction";
import ViewAccount from "../ViewAccount";
import ChildrenPage from "../ChildrenPage";
import { useAccounts } from "../provider";
import { Divider } from "../../../ui";
import TableContainer from "../../../ui/TableContainer";
import NoteCell from "../tableCell/NoteCell";
import { useNavigate } from "react-router-dom";
import { useStatus } from "../../../services/StatusProvider";

const Template = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 32px;
  row-gap: 0;
  ul {
    list-style: none;
    margin-bottom: 10px;
  }

  .body {
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 4px 0px 19px;
  }
`;

function CustomTreeView() {
  const navigate = useNavigate();
  const {
    state: { isAcc },
  } = useStatus();

  const entriesCols = [
    { name: "debit", label: "debit" },
    { name: "credit", label: "credit" },
    { name: "notes", label: "notes", render: NoteCell },
  ];

  const {
    state: { id, treeId, selected },
    setId,
    setTreeId,
    fetchOne,
  } = useAccounts();

  useEffect(() => {
    if (treeId) {
      fetchOne(treeId);
    }
  }, [fetchOne, treeId]);
  const handleView = (id) => {
    navigate(`/transaction/view/${id.id}`);
  };

  return (
    <Template>
      <Tree selectedId={treeId} setSelectedId={setTreeId} />
      <div className="body">
        <TabSection selectedId={id} setSelectedId={setId} isAcc={isAcc} />

        {treeId && (
          <>
            <div className="selected">
              {id === 0 && (
                <SimpleEntry
                  selectedId={treeId}
                  selected={selected}
                  isAcc={isAcc}
                />
              )}
              {id === 1 && (
                <div style={{ marginTop: "30px" }}>
                  {" "}
                  <AddTransaction noBack isAcc={isAcc} />
                </div>
              )}
              {id === 2 && (
                <div style={{ marginTop: "30px" }}>
                  <ViewAccount selectedId={treeId} setSelectedId={setTreeId} />{" "}
                </div>
              )}
              {id === 3 && (
                <div style={{ marginTop: "30px" }}>
                  <ChildrenPage selectedId={treeId} selected={selected} />
                </div>
              )}

              {selected?.entries?.length > 0 && (
                <>
                  <Divider h={50} />
                  <h2> Account Entries</h2>
                  <TableContainer
                    empty={selected?.entries?.length === 0}
                    aria-label="entries table"
                    cols={entriesCols}
                    data={selected?.entries}
                    showButton={isAcc && handleView}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Template>
  );
}

export default CustomTreeView;
