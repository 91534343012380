import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import SuplierForm from './SuplierForm'

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function AddSuplier() {
  const navigate = useNavigate();
  const { notify } = useUtils();
  let { id } = useParams()
  const handleSuccess = () => {
    navigate(-1);
    notify("a new Suplier has been created successfully.");
  };

  if(!id) return 
  return (
    <div id="add-user">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Add New Supplier</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      <SuplierForm handleSuccess={handleSuccess} cid={id} />
    </div>
  );
}

export default memo(AddSuplier);
