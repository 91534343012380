import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import image from "../assets/user-multitasking-on-laptop.png";
import { Type, TextField, Icon, Checkbox, Button } from "../ui";
import { useAuth } from "../services/auth";

const loginSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),

  newPassword: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!").matches('^([a-zA-Z0-9@*#$ ]{4,30})$' , 'password must contain at least a capital letter  number and a spacial charcter'),
});

const Grid = styled.div`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 65% 35%;
    height: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LeftCol = styled.div`
  background-color: #fff2d2;
  text-align: center;
  img {
    max-width: 90%;
  }
`;
const RightCol = styled.div`
  background-color: white;
  form {
    width: 100%;
    padding: 0 20px;
    @media (min-width: 600px) {
      padding: 0 75px;
    }
    .title {
      text-align: center;
      padding-bottom: 60px;
    }
    input {
      background-color: #f5f5f5;
      outline: none;
    }
    button{
      margin-top: 40px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  a {
    padding-right: 8px;
  }
`;

function ChangePassword() {
  const [hide, setHide] = useState(true);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const toggleHide = () => {
    setHide((hide) => !hide);
  };

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  const onSubmit = async (values) => {
    try {
      await auth.changePass({
        oldPassword: values["oldPassword"],
        newPassword: values["newPassword"],
      });

      navigate('/');
    } catch (error) {
      console.log("error", error.status);
      if (error.status === 400) setError(error.errors.message );
      if (error.status === 401) setError("Wrong old password");
    }
  };

  return (
    <Layout variant="empty">
      <Grid>
        <LeftCol>
          <div>
            <Type var="display">
              <br /> <em>HR Portal</em>
            </Type>
            <img src={image} alt="user multitasking on laptop" />
          </div>
        </LeftCol>
        <RightCol>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <h1 className="title">Change Password</h1>

            {error && <p className="error">{error}</p>}

            <TextField
              error={!!errors.oldPassword}
              msg={errors.oldPassword?.message}
              label="Old Password"
              inputProps={{
                ...register("oldPassword"),
                autoFocus: true,
              }}
            />
            <TextField
              error={!!errors.newPassword}
              msg={errors.newPassword?.message}
              label="New Password"
              style={{ marginBottom: "15px" }}
              inputProps={{
                ...register("newPassword"),
              }}
            />
            <Button
              style={{ width: "100%"  }}
              loading={isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </form>
        </RightCol>
      </Grid>
    </Layout>
  );
}

export default ChangePassword;
