import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCompanies } from "./provider";
import { Button, Card, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import countries from "../../data/countries.json";
import ErrorsList from "../../ui/ErrorsList";
// import UploadFile from "../../ui/UploadFile";
import FileField from "../../ui/FileField";
import InputDate from "../../ui/InputDate";

function emptyDate(value, originalValue) {
  var d = new Date(value);
  if (d != "Invalid Date") {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft()
    );
  }
}

const digitsOnly = (value) => /^\d+$/.test(value)
const schema = Yup.object().shape({
  businessName: Yup.string().required("Required"),
  taxNumber: Yup.string(),
  webSite: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-zA-Z0-9#]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!'
)
.required('Please enter website'),
  registrationDate: Yup.string().required().transform(emptyDate),
  //location: Yup.number(),
  businessType: Yup.string(),
  wageTax: Yup.number().typeError('must be a number').min(0 , 'greater than 0').max(100, 'smaller than 100'),
  phone: Yup.string().typeError('must be a number')
  .test('Digits only', 'The field should have digits only', digitsOnly)
  .required("Required"),
  totalPaidSick: Yup.string().required("Required"),
  totalPaidAnnual: Yup.string().required("Required"),
  parentCompanyId: Yup.string().nullable().required("Required")
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function CompanyForm({ company = null, handleSuccess }) {
  const [preview, setPreview] = useState(company?.logoUrl || null);
  const [contractFile, setContractFile] = useState(
    company?.contract
      ? { name: company?.contract, url: company?.contractUrl }
      : null
  );
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // get company
  const {
    state: { list: companies, selected: selectedCompany },
    dispatch,
    fetchList,
    create, edit
  } = useCompanies();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    if (company)
      setValue('parentCompanyId' , company.parentCompanyId)
  }, [companies, dispatch]);


  const setCompany = useCallback(
    (company) => {
      setValue('parentCompanyId' , company.data.id)
    },
    [dispatch]
  );

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });



  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: company,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "logo") setPreview(URL.createObjectURL(value["logo"][0]));
      if (name === "contractUpload")
        setContractFile(value["contractUpload"][0]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (values) => {
    // console.log(values , 'values');
    // return 
    try {
      if (company) await edit(company.id, values);
      else {
        await create(values);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      //setError({title :  e.errors});
      console.log("e", e.errors);
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <Grid>
            <TextField
              label="Business Name"
              name="businessName"
              error={!!errors.businessName}
              msg={errors.businessName?.message}
              inputProps={{
                ...register("businessName"),
                autoFocus: true,
                required: true,
              }}
            />
            {/* <TextField
              required
              label="Email Address"
              name="email"
              error={!!errors.email}
              msg={errors.email?.message}
              inputProps={{
                ...register("email"),
                type: "email",
                required: true,
              }}
            /> */}
            <TextField
              required
              label="Tax Number"
              name="taxNumber"
              error={!!errors.taxNumber}
              msg={errors.taxNumber?.message}
              inputProps={{
                ...register("taxNumber"),
                type: "number",
              }}
            />
            <TextField
              required
              label="Website"
              name="webSite"
              error={!!errors.webSite}
              msg={errors.webSite?.message}
              inputProps={{
                ...register("webSite"),
              }}
            />
            <InputDate
              label="Registration Date"
              name="registrationDate"
              error={!!errors.registrationDate}
              msg={errors.registrationDate?.message}
              value={new Date(getValues('registrationDate'))}
              onDateChange={(v) => setValue('registrationDate', v)}
            />
            <div>
              <Select
                label="Location"
                data={countries}
                mapOptions={{ value: "code", label: "name" }}
                required={true}
                defaultValue={getValues("countryCode")}
                onChange={(e) => {
                  setValue("countryCode", e.value);
                }}
                style={{ height: "40px", width:'100%' }}

                error={!!errors["countryCode"]}
                msg={errors["countryCode"]?.message}
                inputProps={{
                  ...register("countryCode"),
                }}
              />
            </div>
            <TextField
              label="Industry"
              name="businessType"
              error={!!errors.businessType}
              msg={errors.businessType?.message}
              inputProps={{
                ...register("businessType"),
                required: true,
              }}
            />
            <TextField
              required
              label="Wage Tax"
              name="wageTax"
              error={!!errors.wageTax}
              msg={errors.wageTax?.message}
              inputProps={{
                ...register("wageTax"),
              }}
            />
            <TextField
              required
              label="Phone Number"
              name="phone"
              error={!!errors.phone}
              msg={errors.phone?.message}
              inputProps={{
                ...register("phone"),
              }}
            />
            <TextField
              label="Number of Annual Leaves"
              name="totalPaidAnnual"
              error={!!errors.totalPaidAnnual}
              msg={errors.totalPaidAnnual?.message}
              inputProps={{
                ...register("totalPaidAnnual"),
                required: true,
              }}
            />
            <TextField
              label="Number of Sick Leaves"
              name="totalPaidSick"
              error={!!errors.totalPaidSick}
              msg={errors.totalPaidSick?.message}
              required={true}
              inputProps={{
                ...register("totalPaidSick"),
                required: true,
              }}
            />

            <div style={{ marginBottom: "30px" , width:'100%' }}>
              <Select
                data={company ? [{id:0  , businessName : 'no Parent Company'} ,...companies.filter((c)=>c.id!= company.id)] :[{id:0  , businessName : 'no Parent Company'} ,...companies]}
                label="Parent Company"
                mapOptions={mapCompanyOptions.current}
                error={!!errors.parentCompanyId}
                msg={errors.parentCompanyId?.message}
                onChange={setCompany}
                defaultValue={company?.parentCompanyId}
                style={{ height: "40px", width:'100%' }}
                inputProps={{
                  value: company?.id || "",
                  ...register('parentCompanyId'),
                  readOnly: true,
                }}
              />
            </div>

            <div />
              {/* <FileField
                style={{
                  gridColumn: "1/3",
                }}
                label="Contract"
                buttonLabel={company ? "Upload new contract" : "Upload contract"}
                document
                documentFile={contractFile}
                onClose={() => {
                  setValue("contractUpload", "");
                  setValue("contract", "");
                  setContractFile(null);
                }}
                inputProps={{
                  name: "contractUpload",
                  ...register("contractUpload"),
                  accept:
                    ".pdf,.doc,.docx,.ppt,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
              /> */}
          </Grid>
          <FileField
            label="Company Logo"
            buttonLabel={company ? "Edit Logo" : "Upload Logo"}
            preview={preview}
            edit={Boolean(company)}
            inputProps={{
              name: "logo",
              ...register("logo"),
              accept: "image/jpng,image/png,image/jpg,image/jpeg",
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          data-testid="save-button"
          type="submit"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(CompanyForm);
