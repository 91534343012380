import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Divider, Select, TextField } from "../../ui";
import ErrorsList from "../../ui/ErrorsList";
import { useCompanies } from "../companies/provider";
import { useAttendancePatterns } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 0;
`;

const ShiftsWrap = styled.div`
  max-width: 710px;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    h4 {
      margin: 0 0 6px 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.gray};
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

function AttendancePatternClone() {
  const params = useParams();
  const navigate = useNavigate();
  let companyId = params.id;
  const { notify } = useUtils();
  const [error, setError] = useState(null);
  const [companyIds, setCompanyIds] = useState();

  const { register, setValue, handleSubmit, reset } = useForm();

  const {
    state: { selected },
    fetchOne,
    clone,
  } = useAttendancePatterns();

  const companiesId = useRef([]);
  const {
    state: { list: companies, status },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
    //setValue("companyIds", companyId);
  }, [params.id, fetchOne]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const onSubmit = async (values) => {
    try {
      await clone(params.id, values);
      reset();
      notify("Attendance Patterns Created Successfully");
      navigate(-1);
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError({ title: e.errors.message });
      if (e.status === 400 && e.errors.response == 19)
        setError({ title: "This Company Already Have This Pattern" });

      console.log("e", e);
    }
  };

  if (!selected?.attendanceShifts) return;

  function displayShiftsFields() {
    return selected.attendanceShifts.map((item, i) => (
      <div key={i} data-count={i + 1}>
        <TextField
          label=""
          inputProps={{
            value: new Date(item.startDate.replace(/-/g, "/") + "z")
              .toLocaleString()
              .replace("z", ""),
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
        <TextField
          label=""
          inputProps={{
            value: new Date(item.endDate.replace(/-/g, "/") + "z")
              .toLocaleString()
              .replace("z", ""),
            type: "string",
            readOnly: true,
            diabled: true,
          }}
        />
      </div>
    ));
  }


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid>
          <h2 style={{ marginTop: "0" }}>Attendance Pattern Information</h2>
        </Grid>
        {error && <ErrorsList error={error} />}

        <Card>
          <Grid2>
            <Select
              label="Companies"
              data={companies.filter((c) => c.id != companyId)}
              mapOptions={{ value: "id", label: "businessName" }}
              onChange={(v) => {
                setValue(
                  "companyIds",
                  v.map((v) => v.value)
                );
              }}
              multi
              inputProps={{
                name: "companyIds",
                ...register("companyIds"),
              }}
            />

            <TextField
              label="Original Company"
              inputProps={{
                value: selected.companyName,
                readOnly: true,
                disabled: true,
              }}
            />
            <TextField
              label="Pattern Name"
              inputProps={{
                value: selected.attendancePatternName,
                readOnly: true,
                disabled: true,
              }}
            />
          </Grid2>

          <div>
            <h3>Shifts</h3>
            <ShiftsWrap>
              <div>
                <h4>Start Date</h4>
                <h4>End Date</h4>
              </div>
              {displayShiftsFields()}
            </ShiftsWrap>
          </div>

          <Grid2>
            <div>
              <Divider var="spacer" h={24} />
              <TextField
                label="Next Start Date"
                inputProps={{
                  type: "string",
                  value: new Date(
                    selected.nextStartDate.replace(/-/g, "/") + "z"
                  )
                    .toLocaleString()
                    .replace("z", ""),
                  readOnly: true,
                  disabled: true,
                }}
              />
            </div>
          </Grid2>
        </Card>

        <ButtonWrapper>
          <Button iconName="Disk" className="small" onClick={() => onSubmit}>
            Save
          </Button>
          <Button className="outlined small" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </ButtonWrapper>
      </form>
    </div>
  );
}

export default AttendancePatternClone;
