import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import AddLeave from "./AddLeave";
import Company from "./Company";
import EditLeave from "./EditLeave";

import Leaves from "./Leaves";
import LeavesList from "./LeavesList";
import ViewLeave from "./ViewLeave";




export default (
  <>
    <Route
      path="leaves"
      element={
        <RequireAuth>
          <Leaves />
        </RequireAuth>
      }
    >
    <Route
      index
      element={
        <RequireAuth>
          <LeavesList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddLeave />
        </RequireAuth>
      }
    />
    <Route
      path="view/:id"
      element={
        <RequireAuth>
          <ViewLeave />
        </RequireAuth>
      }
    />
    <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditLeave />
        </RequireAuth>
      }
    />
    </Route>
  </>  
);
