import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import OrdersForm from "./OrdersForm";
import LeaveForm from "./OrdersForm";
import { useOrders } from "./provider";
import { useSupliers } from "../supliers/provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditLeave() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const [supplierId, setSupplierId] = useState(null);

  const handleSuccess = () => {
    notify("Changes saved successfully");
  };

  const {
    state: { list: leave, emps: employee, selected,deps: departments, page },
    create,
    fetchOne,
    edit,
  } = useOrders();


  useEffect(() => {
    if (params.id) fetchOne(params?.id);
  }, [fetchOne]);

  useEffect(()=>{
    setSupplierId(selected?.supplierId)
  },[selected])

  const {
    state: { list},
    fetchList:fetchSupliers,
  } = useSupliers();

  //fetch companies

  useEffect(()=>{
  if(selected) fetchSupliers(selected?.companyId);
  },[selected])


  console.log(supplierId , list );
  if(list?.length == 0) return
  if(!supplierId) return 
  if(!selected.taxRate)return 

  return (
    <div id="edit-leave">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Order</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <OrdersForm forEdit={params.id} selected={selected} setSupplierId={setSupplierId} supplierId={supplierId} handleSuccess={handleSuccess} />
    </div>
  );
}

export default EditLeave;
