import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Icon } from "../../ui";
import Loading from "../../ui/Loading";
import { useCompanies } from "../companies/provider";
import AttendancePatternForm from "./AttendancePatternForm";
import { useAttendancePatterns } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  h2 {
    margin: 0;
  }
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

function EditAttendancePattern() {
  const navigate = useNavigate();
  const params = useParams();
  const { notify } = useUtils();
  const {
    state: { selected: attendancePattern, status },
    fetchOne,
    dispatch,
  } = useAttendancePatterns();

  // const {
  //   state: { status },
  // } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
    return () => dispatch({ type: "set_selected", payload: null });
  }, [fetchOne, params.id, dispatch]);
  const handleSuccess = (id) => {
    navigate(-1);
    notify("Changes saved successfully.");
  };

  if (!attendancePattern || attendancePattern.id !== Number(params.id)) return;
  return (
    <div id="edit-attendance-pattern">
      <Grid>
        <h2 style={{ marginTop: "0" }}>Edit Attendance Pattern</h2>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>

      {status === "idle" ? (
        <AttendancePatternForm
          handleSuccess={handleSuccess}
          attendancePattern={attendancePattern}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default memo(EditAttendancePattern);
