import React from 'react'
import { getValueFromEnum } from '../../../utilities/functions'
import {specialType} from '../../../data/Enums'

function SpecialType(data) {
  return (
    <p>{getValueFromEnum(data?.specialType , 'id' , specialType ,'type' )}</p>
  )
}

export default SpecialType