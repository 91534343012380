import countries from '../../data/countries.json'

function emptyDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return originalValue.split("T")[0];
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return Number(value);
}

const fields = [
  {
    name: "description",
    type: "text",
    label: "Description",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  }, 
];

export default fields;
