import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Card, Pagination, Button } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import FilterForm from "./FilterForm";
import { useAttendance } from "./provider";
import CompanyNameCell from "./CompanyNameCell";
import DateCell from "./DateCell";
import DateCell2 from "./DateCell2";
import { dateToLocalConverter, exporToExcle } from "../../utilities/functions";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;
const StatusCell = (data) => {
  switch (data.lastTransaction) {
    case 0:
      return "Unknown";
    case 1:
      return "punch In";
    case 2:
      return "punch Out";
    case 3:
      return "Break Out";
    case 4:
      return "Break In";
  }
};
function CurrentAttendace() {
  //fetch attendance
  const {
    state: { creportList: attendance, rcount, rpage, companyID, departmentID },
    fetchCurrentAttendance: fetchReportAttendance,
    remove,
    dispatch,
  } = useAttendance();

  //cols
  const cols = [
    { name: "employeeName", label: "Employee Name" },
    { name: "employeeName", label: "Status", render: StatusCell },
    {
      name: "lastTransactionDate",
      label: "Last Transaction Date",
      render: (data) => {
        
        if(data.lastTransactionDate ) return dateToLocalConverter(data.lastTransactionDate);
      },
    },
  ];

  useEffect(() => {
    fetchReportAttendance(companyID, departmentID);
  }, []);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_rpage", payload: newPage });
  };

  if (!attendance) return;
  return (
    <>
      <Grid>
        <h2>Current Attendance Report</h2>
      </Grid>
      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm
          report={true}
          fetchAttendance={fetchReportAttendance}
          page={rpage}
          currentReport
        ></FilterForm>
      </Card>
      <TableContainer
        empty={attendance?.length == 0}
        //status={status}
        aria-label="Attendance table"
        cols={cols}
        data={attendance}
        checkbox
        //moreOptions={{ edit: handleEdit, delete: handleDelete }}
      />
      <Pagination
        data-testid="pagination"
        count={rcount}
        page={rpage}
        rowsPerPage={10}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default CurrentAttendace;
