import React, { Children, useCallback, useEffect } from "react";
import { Button, Card, Checkbox, Flex, Select, TextField } from "../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useDepartments } from "../departments/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import { useAccounts, useEmployees, useYear } from "./provider";
import { useState } from "react";
import { useStatus } from "../../services/StatusProvider";

const schema = Yup.object().shape({
  companyId: Yup.number().nullable().transform(emptyStringToNull),
  isActive: Yup.string(),
});

function FilterForm({ hide, setFilters }) {
  let user = JSON.parse( localStorage.getItem('user'))
  const {
    state: {  isAcc },
    
    }= useStatus()
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { filters },
    dispatch
  } = useYear();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {...filters},
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(()=>{
    if(isAcc){
      setValue('companyId' , user?.employee?.company?.id)
    }else{
      setValue('companyId' , companies[0]?.id)
      
    }
  },[])

  const onSubmit = async (values) => {
    setFilters(values);
  };

  const FilterHiddenInputs = (props) => {
    if (props?.hide?.includes(props?.children?.props?.name)) return <></>;
    else return <>{props?.children}</>;
  };

  const handleCompanyChange = useCallback(
    (data) => setValue("companyId", data.value),
    [setValue]
  );
  return (
    <Card style={{ marginBottom: "10px" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex jc="flex-start" ai="flex-end" gap={20}>

          <FilterHiddenInputs hide={hide}>
            {
              isAcc ?           <TextField
              label="Company"
              name="company"
              style={{marginBottom:'0px'}}  
              inputProps={{
                value : user?.employee?.company?.companyName,
                disabled: true,
              }}
            />:<Select
              style={{
                maxWidth: 220,
              }}
              data={companies}
              name="companyId"
              mapOptions={{ value: "id", label: "businessName" }}
              onChange={handleCompanyChange}
              defaultValue={getValues("companyId") || companies[0]?.id}
              label={"company"}
            />
            }
          </FilterHiddenInputs>
{!isAcc&&
          <Button
            data-testid="save-button"
            type="submit"
            // iconName="Eye"
            className="small"
            loading={isSubmitting}
            style={{ height: 60 }}
          >
            Apply
          </Button>}
        </Flex>
      </form>
    </Card>
  );
}

export default FilterForm;
