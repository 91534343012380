import React, { useCallback, useEffect } from "react";
import { Button, Flex, Select, TextField } from "../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useDepartments } from "../departments/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import { useEmployees } from "./provider";

const schema = Yup.object().shape({
  companyId: Yup.number().nullable().transform(emptyStringToNull),
  departmentId: Yup.number().nullable().transform(emptyStringToNull),
  search: Yup.string(),
  pagesize: Yup.number().nullable().transform(emptyStringToNull),
});

function FilterForm({ setFilters }) {
  const {
    state: { filters },
  } = useEmployees();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { list: departmentList },
    fetchList: fetchDepartments,
  } = useDepartments();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyId: filters.companyId,
      departmentId: null,
      search: "",
      pagesize: 150,
    },
  });

  const watchCompanyId = watch("companyId");

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(()=>{
    if(!getValues('companyId') && companies ) setValue('companyId' , companies[0]?.id)
  },[companies])

  useEffect(() => {
    if (watchCompanyId) {
      fetchDepartments(getValues("companyId"));
      setValue('departmentId', '');
    }
  }, [watchCompanyId, fetchDepartments, getValues, setValue]);

  const onSubmit = async (values) => {
    setFilters(values);
  };


  let statusEnum = [
    {id:0 , status:'All'},
    {id:1 , status:'Active'},
    {id:2 , status:'InActive'},
    {id:3 , status:'Leave Out'},
    {id:4  , status:'Deleted'},

  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Flex jc="flex-start" ai="flex-end" gap={20}>
        <div>
          <Select
            style={{
              maxWidth: 220,
            }}
            data={companies}
            mapOptions={{ value: "id", label: "businessName" }}
            onChange={useCallback(
              (data) => setValue("companyId", data.value),
              [setValue]
            )}
            defaultValue={getValues("companyId") || ""}
            label={"company"}
          />
        </div>

        <div>
          <Select
            style={{
              maxWidth: 220,
            }}
            data={[{ id: null, departmentName: "All" }, ...departmentList]}
            mapOptions={{ value: "id", label: "departmentName" }}
            label={"department"}
            onChange={useCallback(
              (data) => setValue("departmentId", data.value),
              [setValue]
            )}
            defaultValue={getValues("departmentId") || ""}
          />
        </div>

        <TextField
          style={{ marginBottom: 0 }}
          label="Search"
          name="search"
          error={!!errors.search}
          msg={errors.search?.message}
          inputProps={{
            ...register("search"),
            style: { height: 60 },
          }}
        />


        <Select
              style={{
                width: "220px",
              }}
              data={statusEnum}
              mapOptions={{ value: "id", label: "status" }}
              onChange={useCallback(
                (data) => setValue("statusId", data.value),
                [setValue]
              )}
              label={"status"}
              inputProps={{
                readOnly: true,
              }}
            />


        <div>
          <Select
            style={{
              maxWidth: 120,
            }}
            data={[10, 50, 100, 150]}
            label="Display"
            onChange={useCallback(
              (data) => setValue("pagesize", data.value),
              [setValue]
            )}
            defaultValue={getValues("pagesize") || ""}
          />
        </div>



        <Button
          data-testid="save-button"
          type="submit"
          // iconName="Eye"
          className="small"
          loading={isSubmitting}
          style={{ height: 60 }}
        >
          Apply
        </Button>
      </Flex>
    </form>
  );
}

export default FilterForm;
