import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Card, Select } from "../../../ui";
import { genYears } from "../../../utilities/functions";
import { useStats } from "../../stats/provider";
import months from "../../../data/months.json";

const chartData = [
  {
    name: "$ 1K",
    total: 3000,
  },
  {
    name: "$1K - 2K",
    total: 2000,
  },
  {
    name: "$2K - 5K",
    total: 2780,
  },
  {
    name: "$5K - 7K",
    total: 1890,
  },
  {
    name: "$7K-9K",
    total: 1890,
  },
];

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    flex-grow: 1;
  }
`;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-chart-tooltip">
        <p className="label">{`population : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function EmployeeBySalary() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const {
    state: { numberSalaries },
    fetchNumberSalaries,
  } = useStats();

  useEffect(() => {
    fetchNumberSalaries(year, month);
  }, [fetchNumberSalaries, year, month]);

  return (
    <div>
      <Title>
        <h2>Number of Employees by Monthly Salary</h2>
        <div style={{ marginRight: "10px" }}>
          <Select
            inputStyle={{ height: "48px", width: "110px" }}
            data={genYears(2000)}
            defaultValue={year.toString()}
            onChange={(v) => {
              setYear(v.value);
            }}
            inputProps={{
              value: year.toString(),
              onChange: () => {}
            }}
          />
        </div>
        <Select
          inputStyle={{ height: "48px", width: "170px" }}
          data={months.map((m) => ({ name: m.long, value: m.index }))}
          mapOptions={{ label: "name", value: "value" }}
          defaultValue={month}
          onChange={(v) => setMonth(v.value)}
        />
      </Title>
      <Card>
        <ResponsiveContainer width="100%" height={660}>
          <BarChart
            // width={500}
            height={300}
            data={numberSalaries.map((d) => ({
              name: d.salary,
              total: d.salaryCount,
            }))}
            margin={{
              top: 60,
              right: 0,
              left: 5,
              bottom: 220,
            }}
            barCategoryGap={11}
            layout="vertical"
          >
            <CartesianGrid
              strokeDasharray="0 0"
              style={{ stroke: "#e5e5e5" }}
            />
            <XAxis
              axisLine={{ stroke: "#e5e5e5" }}
              tickLine={false}
              type="number"
              style={{
                fontSize: "14px",
                fill: "#abafb3",
                fontWeight: "bold",
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              x={10}
              dataKey="name"
              type="category"
              reversed={true}
              style={{
                fontSize: "14px",
                fill: "#abafb3",
                fontWeight: "bold",
              }}
            />
            <Tooltip
              wrapperStyle={{
                backgroundColor: "#a5a5a5",
                border: "none",
                outline: "none",
                padding: "0 20px",
                borderRadius: "10px",
              }}
              content={<CustomTooltip />}
            />
            <Bar dataKey="Theme: 01" fill="#7f32de" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
}

export default EmployeeBySalary;
