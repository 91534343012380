import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function usePayrolls() {
  return useContext(Context);
}

const initState = {
  list: [],
  wage: null,
  documents: [],
  selected: null,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_documents":
      return { ...state, documents: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "add_document":
      const newDocs = [{ ...action.payload }, ...state.documents];
      return { ...state, documents: newDocs };
    case "edit":
      return { ...state, list: { ...action.payload } };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_wage":
      return { ...state, wage: { ...action.payload } };
    case "set_emps_list":
      return { ...state, emps: { ...action.payload } };
    case "set_deps_list":
      return { ...state, deps: { ...action.payload } };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "deleteDocument":
      const filteredDocs = state.documents.filter(
        (p) => p.id !== action.payload
      );
      return { ...state, documents: filteredDocs };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function PayrollsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (info = {}, page = 0, pageSize = 10) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: page + 1,
        pageSize,
        ...info,
      };
      const resData = await req(
        `CompanyPayroll/List`,
        null,
        {},
        true,
        headersData
      );
      console.log(resData , 'resData');
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchOne = useCallback(
    (info) => {
      return new Promise(async (resolve, reject) => {

        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`CompanyPayroll/Info`, null, {}, true, info);
        console.log(resData , 'result' , info);

        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const fetchWage = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(
          `CompanyPayroll/WageInfo/${id}`,
          null,
          {},
          true
        );
        dispatch({ type: "set_wage", payload: resData.data });
        dispatch({ type: "set_documents", payload: resData.data.documents });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "CompanyPayroll/Update",
            data,
            { method: "PUT" },
            true
          );
         // dispatch({ type: "add", payload: resData.data });
          // dispatch({ type: "add_document", payload: resData.data.documents });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Leave/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  //fetch employee list
  const fetchEmpList = useCallback(
    async (cid, page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1},page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(`Employee/List`, null, { query }, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_emps_list", payload: resData.data.employees });
    },
    [req]
  );

  const fetchDEPSList = useCallback(
    async (cid, page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(
        `Department/List/${cid}?${query}`,
        null,
        {},
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_deps_list", payload: resData.data });
      // dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Leave/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const uploadDocument = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("file", data.document[0]);
      formData.append("id", data.id);
      formData.append("title", data.title);

      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: "uploading" });
          const resData = await req(
            "Upload/WageDocument",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
          dispatch({ type: "add_document", payload: {id: resData.data.id, documentTitle: data.title, documentUrl: resData.data.url} });
          dispatch({ type: "status", payload: "idle" });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const removeDocument = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting document ${id}` });
      await req(`CompanyPayroll/DeleteWageDocument/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "deleteDocument", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        fetchEmpList,
        fetchDEPSList,
        remove,
        fetchWage,
        uploadDocument,
        removeDocument,
      }}
    >
      {children}
    </Context.Provider>
  );
}
