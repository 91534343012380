import styled from "styled-components";
import { Button, Icon, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "../../services/utilsProvider";
import { useYear } from "./provider";
import StartdateCell from "./tableCells/StartdateCell";
import EnddateCell from "./tableCells/EnddateCell";
import IsLockedCell from "./tableCells/IsLockedCell";
import NotesCell from "./tableCells/NotesCell";
import FilterForm from "./FilterForm";
import { useStatus } from "../../services/StatusProvider";
import { useCompanies } from "../companies/provider";

const cols = [
  { name: "start", label: "start", render: StartdateCell },
  { name: "end", label: "end", render: EnddateCell },
  { name: "isLocked", label: "isLocked", render: IsLockedCell },
  { name: "notes", label: "notes", render: NotesCell },
];

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
  .back {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
`;

function YearList() {
  const { id } = useParams();
  let user = JSON.parse(localStorage.getItem("user"));
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list, status, count, page, perPage, filters },
    fetchList,
    remove,
    dispatch,
    setFilters,
    lockYear,
  } = useYear();

  useEffect(() => {
    if (isAcc) {
      setFilters({ companyId: user?.employee?.company?.id });
    } else {
      setFilters({ companyId: companies[0]?.id });
    }
  }, []);
  const navigate = useNavigate();
  const { notify, alertUser } = useUtils();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    if (filters?.companyId > 0) fetchList(filters);
  }, [fetchList, filters]);

  const handleClick = () => {
    navigate("/year/new");
  };

  const handleView = (id) => {
    navigate(`/year/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/year/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("a Year has been removed successfull.");
    } catch (error) {
      if (error.status === 406) {
        alertUser("this year has entries attached");
      }
      console.log(error);
    }
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
  useEffect(() => {
    dispatch({ type: "set_selected", payload: null });
  }, []);

  useEffect(() => {
    let employee = user?.employee;
    if (employee != null) {
      if (!filters) {
        dispatch({
          type: "set_filters",
          payload: {
            companyId: user?.employee?.company.id,
          },
        });
      }
    } else {
      if (companies) {
        dispatch({
          type: "set_filters",
          payload: {
            companyId: companies[0]?.id,
          },
        });
      }
    }
  }, [companies]);

  let handleLockYear = async (id) => {
    try {
      await lockYear(id);
      notify("a Year has been Locked successfully.");
    } catch (e) {
      if (e.status == 404) alertUser("Gain and Loss account not found ");
      else alertUser("somthing went wrong please try again");
    }
  };
  // if (!list) return;
  return (
    <>
      <Grid>
        <h2>Years List</h2>
        {isAcc && (
          <Button className="small" onClick={handleClick} iconName="invoice">
            New Entry
          </Button>
        )}
        <div
          className="back"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <span>Back</span>
          <Icon name="arrowLeft" />
        </div>
      </Grid>
      <FilterForm setFilters={setFilters} />
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Year table"
        cols={cols}
        data={list}
        showButton={handleView}
        moreOptions={
          isAcc && {
            edit: handleEdit,
            Lock: {
              label: "Lock Year",
              icon: "repeat",
              fn: handleLockYear,
            },
            delete: handleDelete,
          }
        }
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default YearList;
