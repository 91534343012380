import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import ListNotification from "./ListNotification";
import Notification from "./Notification";
import AddNotifidcation from "./AddNotifidcation";

export default (
  <Route
    path="notification"
    element={
      <RequireAuth>
        <Notification />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ListNotification />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddNotifidcation />
        </RequireAuth>
      }
    />
   
  </Route>
);
