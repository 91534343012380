import { memo, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, TextField } from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useCompensations, useEmployeeDeduction } from "./provider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

function ViewDeduction() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { selected },
    fetchOne,
  } = useEmployeeDeduction();

  useEffect(() => {
    fetchOne(params.id);
  }, [params.id, fetchOne]);

  if (!selected || selected.id !== Number(params.id)) return;

  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Compensation Information</h2>
        <div style={{ cursor: "pointer" }}>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/employee/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          {fields
            .filter((f) => !f.viewDisabled)
            .map((f) => {
              const name = f.viewName || f.name;
              let value =
                f.type === "date"
                  ? new Date(selected[name]).toLocaleDateString("fr-FR")
                  : selected[name];
              // if (name === "departments")
              //   value = value.map((v) => v.departmentName).join(", ");
              return (
                <TextField
                  key={name}
                  label={f.label}
                  inputProps={{
                    value,
                    readOnly: true,
                    disabled: true,
                  }}
                />
              );
            })}
        </Grid2>
      </Card>
    </div>
  );
}

export default memo(ViewDeduction);
