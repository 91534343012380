import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUtils } from "../../services/utilsProvider";
import { Button, Card, Pagination } from "../../ui";
import TableContainer from "../../ui/TableContainer";
import { useCompanies } from "../companies/provider";
import DateCell from "./DateCell";
import DateCell2 from "./DateCell2";
import FilterForm from "./FilterForm";
import EmployeeNameCell from "./leaveList/EmployeeNameCell";
import { useLeaves } from "./provider";
import Typecell from "./leaveList/Typecell";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  .search {
    flex-grow: 1;
  }
`;

function LeavesList() {
  //define stuff
  const navigate = useNavigate();
  const { notify } = useUtils();
  //fetching company
  const [companyId, setCompanyId] = useState(null);

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  useEffect(() => {
    if (companies.length) setCompanyId(companies[0].id);
  }, [companies]);

  const handleCompanyChange = useCallback((id) => {
    setCompanyId(id);
  }, []);

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // handle add new leave button
  const handleClick = () => {
    navigate("/leaves/new");
  };

  //fetch leaves
  const {
    state: { list, status, count, page, perPage, filters, selected },
    fetchList: fetchLeaves,
    setStatus,
    remove,
    dispatch,
    accept,
    reject,
    setFilters,
  } = useLeaves();

  //cols
  const cols = [
    { name: "id", label: "ID Number" },
    { name: "employeeName", label: "Employee Name", render: EmployeeNameCell },
    { name: "absenceFrom", label: "Absence From", render: DateCell },
    { name: "absenceTo", label: "Absence To", render: DateCell2 },
    { name: "notes", label: "notes" },
    { name: "typeId", label: "Type", render: Typecell },
    // { name: "number", label: "Remaining Leaves" },
    { name: "statusName", label: "Status" },
  ];

  const handleView = (id) => {
    navigate(`/leaves/view/${id.id}`);
  };

  const handleEdit = (id) => {
    navigate(`/leaves/edit/${id}`);
  };

  const handleApprove = async (id) => {
    try {
      await accept(id);
      notify("leave request accepted");
    } catch (e) {
      console.log(e.message);
    }
  };
  const handleReject = async (id) => {
    try {
      await reject(id);
      notify("leave request rejected");
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      notify("leave has been removed successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filters?.companyId)
      fetchLeaves({ ...filters, pagenumber: page + 1 || 1 });
  }, [page, fetchLeaves, filters]);

  const handleChangePage = (newPage) => {
    dispatch({ type: "set_page", payload: newPage });
  };
  var d = new Date();
let now =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

d.setDate(d.getDate() - 30);
let fom =
  d.getFullYear() +
  "-" +
  (d.getMonth() + 1).padLeft() +
  "-" +
  d.getDate().padLeft();

  useEffect(()=>{
    dispatch({ type: "set_selected", payload: null });
    if(!filters.FromDate)setFilters({
      FromDate:fom,
      ToDate:now,
    })
  },[fetchLeaves])
  
  if(!filters.FromDate) return
  return (
    <>
      <Grid>
        <h2>Leaves List</h2>
        <Button className="small" onClick={handleClick} iconName="invoice">
          New Leave
        </Button>
      </Grid>

      <Card style={{ marginBottom: "20px", padding: "20px 40px 20px 40px" }}>
        <FilterForm
          hide={{ employeeId: true }}
          setFilters={setFilters}
        ></FilterForm>
      </Card>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        aria-label="Leaves table"
        cols={cols}
        data={list}
        checkbox
        // showButton={handleView}
        moreOptions={{
          edit: handleEdit,
          Approve: {
            label: "Approve",
            icon: "repeat",
            fn: handleApprove,
          },
          Reject: {
            label: "Reject",
            icon: "repeat",
            fn: handleReject,
          },
          delete: handleDelete,
        }}
      />
      <Pagination
        data-testid="pagination"
        count={count}
        page={page}
        rowsPerPage={perPage}
        onPageChange={handleChangePage}
      />
    </>
  );
}

export default LeavesList;
