import countries from '../../data/countries.json'

function emptyDate(value, originalValue) {
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  ) {
    return "";
  }
  return originalValue.split("T")[0];
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return Number(value);
}

const fields2 = [


  {
    name: "inDate",
    type: "text",
    label: "In Date",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },

  {
    name: "punchIn",
    type: "text",
    label: "Punch In",
    validationType: "string",
    validations: [
      {
        type: "date",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "outDate",
    type: "text",
    label: "Out Date",
    validationType: "string",
    validations: [
      {
        type: "date",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "punchOut",
    type: "text",
    label: "Punch Out",
    validationType: "string",
    validations: [
      {
        type: "date",
        params: ["this field is required"],
      },
    ],
  },

 
];

export default fields2;
