import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useDepartments } from "./provider";
import { Button, Card, Icon, TextField } from "../../ui";
import Select from "../../ui/select/Select";
import { useCompanies } from "../companies/provider";
import ErrorsList from "../../ui/ErrorsList";
import { useEmployees } from "../employees/provider";
import AddDepartment from "./AddDepartment";

const schema = Yup.object().shape({
  companyId: Yup.number().required("Required"),
  // departmentNames: Yup.array().of(
  //   Yup.object().shape({
  //     departmentName: Yup.string().required("Required"),
  //     headId: Yup.string().required("Required"),
  //   })
  // ),
});

const Grid = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ShiftsWrap = styled.div`
  > div {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    gap: 20px;
    border-bottom: ${(props) => props.theme.color.grayBg} 2px solid;
    padding-bottom: 24px;
    margin-bottom: 8px;
    position: relative;
    span.remove {
      margin-top: 30px;
      display: block;
      justify-self: center;
      cursor: pointer;
      svg {
        fill: ${(props) => props.theme.color.grayText};
      }
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
    }
    span.add {
      cursor: pointer;
      &:hover {
        svg {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
    h4 {
      margin: 0;
    }
    > div {
      margin-bottom: 0;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::before {
      content: none;
    }
    &::before {
      content: attr(data-count);
      position: absolute;
      top: -26px;
      left: -10px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.grayText};
    }
  }
`;

function DepartmentForm({ department = null, handleSuccess }) {
  const { id, cid } = useParams();
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(cid);
  const [departmentNames, setDepartmentNames] = useState(
    Array(1)
      .fill(0)
      .map(() => ({
        departmentName: "",
        companyId: companyId,
        headEmployeeId: "",
      }))
  );

  const navigate = useNavigate();

  const { create, edit } = useDepartments();
  const {
    state: {
      list: companies,
      // selected: { company },
    },
    // dispatch,
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: department,
  });

  const {
    state: { list: employees },
    fetchList: fetchEmployees,
  } = useEmployees();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    setValue("companyId", Number(cid || companies[0].id));
  }, [cid, companies]);

  useEffect(() => {
    fetchEmployees(companyId , null , 100);
  }, [companyId]);
  // useEffect(() => {
  //   if (!companies.length) return;
  //   dispatch({ type: "set_selected", payload: companies[0], keep: true });
  // }, [companies, dispatch]);
  const addShiftDay = () => {
    setDepartmentNames((d) => {
      d.push({ departmentName: "", companyId: companyId, headEmployeeId: "" });
      return [...d];
    });
  };

  const removeShift = (i) => {
    setDepartmentNames((department) =>
      departmentNames.filter((s, i2) => i !== i2)
    );
  };

  useEffect(() => {
    setCompanyId(cid);
  }, [cid]);

  function displayShiftsFields() {
    return departmentNames.map((shift, i) => (
      <div key={i} data-count={i + 1}>
        <TextField
          label=""
          error={!!errors?.departmentName?.[i]}
          msg={errors?.departmentName?.[i]}
          inputProps={{
            onChange: (e) => {
              setDepartmentNames((department) => {
                department[i].departmentName = e.target.value;
                setValue("departments", [...departmentNames]);
                return [...departmentNames];
              });
            },
            required: true,
          }}
        />
        <div>
          <Select
            style={{ marginTop: "12px", height: "60px", width: "100%" }}
            data={[{ id: null, fullName: "Not Selected" }, ...employees]}
            mapOptions={{ value: "id", label: "fullName" }}
            error={!!errors.headEmployeeId}
            msg={errors.headEmployeeId?.message}
            defaultValue={Number(department?.headEmployeeId || 0)}
            onChange={(e) => {
              setDepartmentNames((department) => {
                department[i].headEmployeeId = e.value;
                setValue("departments", [...departmentNames]);
                return [...departmentNames];
              });
            }}
            inputProps={{
              required: true,
            }}
          />
        </div>

        <Icon
          className="remove"
          onClick={() => removeShift(i)}
          name="trash"
          w={16}
        />
      </div>
    ));
  }
  const onSubmit = async (v) => {
    try {
      if (department)
        await edit(department.id, {
          companyId: v["companyId"],
          headEmployeeId: v["headEmployeeId"],
          departmentName: v["departmentName"],
        });
      else {
        delete v.companyId;
        await create(v);
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400)
        setError({ title: "please make sure to fill all fields Correctly " });
        if (e.status === 409)
        setError({ title: "Duplicate Department Name" });
      console.log("e", e);
    }
  };

  const AddDepartment = () => {
    departmentNames.push(" ");
    setDepartmentNames(departmentNames);
  };

  if (!companies.length) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <div>
            <Select
              style={{ height: "60px", width: "100%" }}
              label="Company"
              data={[{ id: "", businessName: "" }, ...companies]}
              mapOptions={{ value: "id", label: "businessName" }}
              watch={watch}
              defaultValue={Number(cid || companies[0].id)}
              onChange={(v) => {
                setValue("companyId", v.value);
                setCompanyId(v.value);
              }}
              error={!!errors.companyId}
              msg={errors.companyId?.message}
              inputProps={{
                ...register("companyId"),
              }}
            />
          </div>
        </Grid>
        <Grid style={{ marginTop: "20px" }}>
          {window.location.pathname.includes("edit") &&
            departmentNames.map((name, idx) => {
              return (
                <>
                  <TextField
                    required
                    label={"Department  Name"}
                    name="departmentName"
                    error={!!errors.departmentName}
                    msg={errors.departmentName?.message}
                    // onChange={(e) => {
                    //   setDepartmentNames([departmentNames, e.value]);
                    // }}
                    inputProps={{
                      ...register("departmentName"),
                      autoFocus: true,
                      required: true,
                    }}
                  />

                  <Select
                    data={[
                      { id: null, fullName: "Not Selected" },
                      ...employees,
                    ]}
                    label={"Head Employee"}
                    mapOptions={{ value: "id", label: "fullName" }}
                    error={!!errors.headEmployeeId}
                    msg={errors.headEmployeeId?.message}
                    defaultValue={Number(department?.headEmployeeId || 0)}
                    onChange={(e) => {
                      setValue("headEmployeeId", e.value);
                    }}
                    inputProps={{
                      required: true,
                    }}
                  />
                </>
              );
            })}
        </Grid>
        {!window.location.pathname.includes("edit") && (
          <>
            <div>
              <ShiftsWrap>
                <div>
                  <h4>Department Name</h4>
                  <h4>Head Of Department</h4>
                </div>
                {displayShiftsFields()}

                <div
                  style={{ textAlign: "right", border: "none" }}
                >
                  <span />
                  <span />
                  <Icon 
                  
                  onClick={addShiftDay}
                  name="addSquare" w={24} className="add" />
                </div>
              </ShiftsWrap>
            </div>
          </>
        )}
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(DepartmentForm);
