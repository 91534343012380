import React, { Children, useCallback, useEffect } from "react";
import { Button, Card, Checkbox, Flex, Select, TextField } from "../../ui";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompanies } from "../companies/provider";
import { useDepartments } from "../departments/provider";
import { emptyStringToNull } from "../../utilities/yupSchemaCreator";
import { useAccounts, useEmployees } from "./provider";
import { useState } from "react";
import { useStatus } from "../../services/StatusProvider";

const schema = Yup.object().shape({
  companyId: Yup.number().nullable().transform(emptyStringToNull),
  isActive: Yup.string().nullable(),
});

function FilterForm({ hide, setFilters, viewType }) {
  let user = JSON.parse(localStorage.getItem("user"));
  const {
    state: { isAcc },
  } = useStatus();
  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    state: { filters },
    dispatch,
  } = useAccounts();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...filters },
  });

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const onSubmit = async (values) => {
    console.log(values, "Submit");
    setFilters(values);
  };

  const FilterHiddenInputs = (props) => {
    if (props.hide.includes(props?.children.props.name)) return <></>;
    else return <>{props.children}</>;
  };

  const handleCompanyChange = useCallback(
    (data) => setValue("companyId", data.value),
    [setValue]
  );

  return (
    <Card style={{ marginBottom: "10px" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex jc="flex-start" ai="flex-end" gap={20}>
          <FilterHiddenInputs hide={hide}>
            <TextField
              required
              style={{ marginBottom: "0px" }}
              label="Parent"
              name="parentName"
              error={!!errors.parentName}
              msg={errors.parentName?.message}
              inputProps={{
                ...register("parentName"),
                disabled: true,
              }}
            />
          </FilterHiddenInputs>

          <FilterHiddenInputs hide={hide}>
            <TextField
              required
              label="Company"
              name="companyName"
              style={{ marginBottom: "0px" }}
              error={!!errors.companyName}
              msg={errors.companyName?.message}
              inputProps={{
                ...register("companyName"),
                disabled: true,
              }}
            />
          </FilterHiddenInputs>

          <FilterHiddenInputs hide={hide}>
            {isAcc ? (
              <TextField
                label="Company"
                name="company"
                style={{ marginBottom: "0px" }}
                inputProps={{
                  value: user?.employee?.company?.companyName,
                  disabled: true,
                }}
              />
            ) : (
              <Select
                style={{
                  maxWidth: 220,
                }}
                data={companies}
                name="companyId"
                mapOptions={{ value: "id", label: "businessName" }}
                onChange={handleCompanyChange}
                defaultValue={getValues("companyId") || companies[0]?.id}
                label={"company"}
              />
            )}
          </FilterHiddenInputs>
          <FilterHiddenInputs hide={hide}>
            <Checkbox
              style={{ height: "42px" }}
              id="onlyActiveAccounts"
              name="onlyActiveAccounts"
              label="only Active Accounts"
              inputProps={{
                ...register("onlyActiveAccounts"),
              }}
            />
          </FilterHiddenInputs>

          {viewType === 1 && (
            <Select
              style={{
                maxWidth: 220,
              }}
              data={[
                {
                  id: 0,
                  name: "ALL",
                },
                {
                  id: 1,
                  name: "Active",
                },
                {
                  id: 2,
                  name: "notActive",
                },
              ]}
              name="companyId"
              mapOptions={{ value: "id", label: "name" }}
              onChange={(e) => {
                if (e.value >= 1)
                  e.value == 1
                    ? setValue("isActive", true)
                    : setValue("isActive", false);
                else setValue("isActive", null);
              }}
              defaultValue={1}
              label={"Status"}
            />
          )}

          {!isAcc && (
            <Button
              data-testid="save-button"
              type="submit"
              // iconName="Eye"
              className="small"
              loading={isSubmitting}
              style={{ height: 60 }}
            >
              Apply
            </Button>
          )}
        </Flex>
      </form>
    </Card>
  );
}

export default FilterForm;
