import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../ui";
import { useCompanies } from "../companies/provider";
import { useEmployees } from "../employees/provider";
import { ToWords } from "to-words";
const Grid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;

  @media only print {
    width: 100vw;
    max-width: auto;
    span {
      display: none;
    }
  }
`;

const Grid2 = styled.div`
  h4 {
    margin: 0px !important;
    line-height: 25px !important;
  }
  @media only print {
    width: 100vw;
  }
`;

const Grid3 = styled.div`
  max-width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
    `;
function Pyslip() {
  const toWords = new ToWords({
    localeCode: "en-US",
  });

  let location = useLocation();
  const {
    state: { selected },
    fetchOne,
    deleteFile,
  } = useEmployees();

  useEffect(() => {
    fetchOne(location.state?.wage.employeeId);
  }, []);

  //company
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompanies();

  useEffect(() => {
    fetchCompany(selected?.companyInfo.id);
  }, [location.state?.wage.employeeId, fetchCompany]);
  console.log(location.state?.wage, "wage");
  return (
    <Grid2>
      <div>
        <h3 style={{ textAlign: "center", width: "80%" }}>
          Payslip
          <p
            style={{
              fontWeight: "normal",
              fontSize: "24px",
              marginTop: "0px",
            }}
          >
            {selected?.companyInfo.companyName}
          </p>
          <p
            style={{
              fontWeight: "normal",
              fontSize: "24px",
              marginTop: "0px",
            }}
          >
            {company?.location}
          </p>
        </h3>
      </div>
      <div>
        <Grid style={{ marginTop: "10px" }}>
          <h4>
            Date of Joining :{"  "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {selected?.hiringDate}
            </p>
          </h4>
          <h4>
            Employee Name :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.employeeName}
            </p>
          </h4>
          <h4>
            Pay Period :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.salaryMonth} /{" "}
              {location.state?.wage.salaryYear}
            </p>
          </h4>
          <h4>
            Job Title :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {selected?.jobTitle}
            </p>
          </h4>
          <h4>
            Worked Days :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {selected?.workedDays}
            </p>
          </h4>
          <h4>
            Department :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.departmentName}
            </p>
          </h4>
          <h4>
            Currency :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.currencyInfo.symbol}
            </p>
          </h4>
        </Grid>
      </div>
      <div>
        <Grid3>
          <h3 className="left" style={{ margin: " 0px" }}>
            Earnings{" "}
          </h3>
          <h3 className="right" style={{ margin: " 0px" }}>
            Amount{" "}
          </h3>
        </Grid3>
        <hr
          style={{
            marginTop: "0px",
            background: "black",
            border: "2px solid #333",
            width: "90%",
            marginLeft: "0",
          }}
        ></hr>
        <Grid3>
          <h4 style={{ marginTop: "20px", marginBottom: "40px" }}>Basic : </h4>
          <h4
            className="right"
            style={{
              fontWeight: "400",
              display: "inline",
            }}
          >
            {location.state?.wage.basicSalary}
          </h4>

          <h4 style={{ marginTop: "20px", marginBottom: "40px" }}>Bonus : </h4>
          <h4
            className="right"
            style={{
              fontWeight: "400",
              display: "inline",
            }}
          >
            {location.state?.wage.bonus}
          </h4>
          {location.state?.compensations.map((c, i) => {
            return (
              <>
                <h4 className={"left"}>{c.notes} : </h4>
                <h4
                  className="right"
                  style={{ fontWeight: "400", display: "inline" }}
                >
                  {c.amount}
                </h4>
              </>
            );
          })}
          <h4></h4>

          <h4 className="right">
            Total Earnings :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.totalCompensations}
            </p>
          </h4>
        </Grid3>

        <Grid3>
          <h3 className="left" style={{ margin: " 0px" }}>
            Deductions{" "}
          </h3>
          <h3 className="right" style={{ margin: " 0px" }}>
            Amount{" "}
          </h3>
        </Grid3>
        <hr
          style={{
            marginTop: "0px",
            background: "black",
            border: "2px solid #333",
            width: "90%",
            marginLeft: "0",
          }}
        ></hr>
        <Grid3>
          {location.state?.deductions.map((c, i) => {
            return (
              <>
                <h4 className={"left"}>{c.notes} : </h4>
                <h4
                  className="right"
                  style={{ fontWeight: "400", display: "inline" }}
                >
                  {c.amount}
                </h4>
              </>
            );
          })}
          <h4></h4>

          <h4 className="right">
            Total Deductions :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.totalDeductions}
            </p>
          </h4>
          <h4></h4>
          <h4 className="right">
            Net Pay :{" "}
            <p style={{ fontWeight: "400", display: "inline" }}>
              {location.state?.wage.netSalary}
            </p>
          </h4>
        </Grid3>
      </div>

      <div>
        <h3
          style={{
            fontSize: "24px",
            textAlign: "center",
            width: "80%",
          }}
        >
          {location.state?.wage.netSalary}
          <p
            style={{
              fontSize: "20px",
              marginTop: "0px",
            }}
          >
            {toWords.convert(location.state?.wage.netSalary)}
          </p>
        </h3>
      </div>

      <Grid>
        <div style={{ padding: "20px" }}>
          <h4 style={{ paddingLeft: "45px" }}>Employer Signature</h4>
          <hr
            style={{
              marginTop: "80px",
              background: "black",
              border: "1px solid #333",
              width: "80%",
              marginLeft: "0",
            }}
          ></hr>
        </div>
        <div style={{ padding: "20px" }}>
          <h4 style={{ paddingLeft: "45px" }}>Employee Signature</h4>
          <hr
            style={{
              marginTop: "80px",
              background: "black",
              border: "1px solid #333",
              width: "80%",
              marginLeft: "0",
            }}
          ></hr>
        </div>
      </Grid>
      <Grid style={{ marginTop: "50px" }}>
        <Button
          style={{ width: "280px" }}
          iconName="print"
          className="small"
          onClick={window.print}
        >
          print
        </Button>
      </Grid>
    </Grid2>
  );
}

export default Pyslip;
