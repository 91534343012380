import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAttendance, useDepartments } from "../provider";
import { Button, Card, TextField } from "../../../ui";
import { useCompanies } from "../../companies/provider";
import ErrorsList from "../../../ui/ErrorsList";
import {
  dateToLocalConverter,
  toDateTime,
  transformDate,
} from "../../../utilities/functions";
import InputDate from "../../../ui/InputDate";
const schema = Yup.object().shape({});

const Grid = styled.div`
  max-width: 845px;
  padding-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const Grid1 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;
const Grid2 = styled.div`
  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Sbutton = styled.div`
  margin-top: 45px;
  width: undefinedpx;
  background-color: #6418c3;
  color: white;
  border: none;
  height: 60px;
  padding: 0 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
  font-size: 17px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
`;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const getToday = () => {
  let d = new Date();
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).padLeft() +
    "-" +
    d.getDate().padLeft()
  );
};

const getLocalDate = (x) => {
  let d = new Date(x + "z").toLocaleString();
  let dd = new Date(d);
  console.log(
    dd.getDate().padLeft() +
      "-" +
      (dd.getMonth() + 1).padLeft() +
      "-" +
      dd.getFullYear() +
      " " +
      dd.getHours().padLeft() +
      ":" +
      dd.getMinutes().padLeft()
  );
  return (
    dd.getDate().padLeft() +
    "-" +
    (dd.getMonth() + 1).padLeft() +
    "-" +
    dd.getFullYear() +
    " " +
    dd.getHours().padLeft() +
    ":" +
    dd.getMinutes().padLeft()
  );
};

const getShortLocalDate = (x) => {
  let d = new Date(x + "z").toLocaleString();
  let dd = new Date(d);
  return (
    dd.getFullYear() +
    "-" +
    (dd.getMonth() + 1).padLeft() +
    "-" +
    dd.getDate().padLeft() +
    "T" +
    dd.getHours().padLeft() +
    ":" +
    dd.getMinutes().padLeft()
  );
};

function BreakForm({ brek, handleSuccess }) {
  const [error, setError] = useState(null);

  //define data
  const [employeeId, setEmployeeId] = useState();
  const [breakIn, setBreakIn] = useState();
  const [breakOut, setBreakOut] = useState();

  useEffect(() => {
    setBreakIn(dateToLocalConverter(brek?.breakIn));
    setBreakOut(dateToLocalConverter(brek?.breakOut));
  }, []);

  const navigate = useNavigate();
  let params = useParams();

  //eums
  const types = [
    { typeId: "1", name: "Regular" },
    { typeId: "2", name: "Overtime" },
    { typeId: "3", name: "Absence" },
    { typeId: "7", name: "Ignored" },
  ];

  const types2 = [
    { typeId: "1", name: "Regular" },
    { typeId: "2", name: "Overtime" },
    { typeId: "3", name: "Absence" },
    { typeId: "4", name: "Leave" },
    { typeId: "5", name: "HourlyLeave" },
    { typeId: "6", name: "Officialholiday" },
    { typeId: "7", name: "Ignored" },
  ];

  const types3 = [
    { typeId: "0", name: "All" },
    { typeId: "1", name: "Punch In" },
    { typeId: "2", name: "Punch Out" },
    { typeId: "3", name: "Break Out " },
    { typeId: "4", name: "Break In" },
  ];
  // fetch attendance
  const {
    state: {
      list: leave,
      emps: employee,
      deps: departments,
      selected,
      punchList,
    },
    createBreak: create,
    fetchOne,
    editBreak: edit,
    dispatch,
    fetchPunchList,
  } = useAttendance();

  const {
    state: { list: companies },
    fetchList: fetchCompanies,
  } = useCompanies();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //get utc
  const getUtc = (x) => {
    let d = new Date(x);
    return (
      d.getUTCFullYear() +
      "-" +
      (d.getUTCMonth() + 1).padLeft() +
      "-" +
      d.getUTCDate().padLeft() +
      " " +
      d.getUTCHours().padLeft() +
      ":" +
      d.getUTCMinutes().padLeft()
    );
  };

  const onSubmit = async (values) => {
    let bin, bout;
    bin = getUtc(breakIn);
    bout = getUtc(breakOut);

    try {
      if (!brek)
        await create({
          attendanceId: params.id,
          breakIn: bin,
          breakOut: bout,
        });
      else {
        await edit(params.id, {
          attendanceId: brek.attendanceId,
          breakIn: bin,
          breakOut: bout,
        });
        reset();
      }
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError({title:'please provide a currect date'});
      console.log("e", e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}

      <Card>
        <Grid>
          <InputDate
            label="Break Out"
            name="breakOut"
            error={!!errors.breakOut}
            msg={errors.breakOut?.message}
            value={new Date(breakOut)}
            type="time"
            onDateChange={(v) => {
              setBreakOut(v);
            }}
          />

          <InputDate
            label="Break In"
            name="breakIn"
            error={!!errors.breakIn}
            msg={errors.breakIn?.message}
            value={new Date(breakIn)}
            type="time"
            onDateChange={(v) => {
              setBreakIn(v);
            }}
          />
        </Grid>
      </Card>

      <ButtonWrapper>
        <Button
          type="submit"
          data-testid="save-button"
          iconName="Disk"
          className="small"
          loading={isSubmitting}

        >
          Save
        </Button>

        <Button
          type="button"
          className="outlined small"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </form>
  );
}

export default memo(BreakForm);
