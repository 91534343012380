import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Divider, Flex, Select } from "../../ui";
import { useCompanies } from "../companies/provider";
import ListEmployees from "./ListAccounts/ListEmployees";
import ListHR from "./ListAccounts/ListHRs";
import ListPo from "./ListAccounts/ListPo";
import { useNavigate } from "react-router-dom";
import ListAdmins from "./ListAccounts/ListAdmins";
import ListAccountant from "./ListAccounts/ListAccountant";

const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  h2 {
    flex-grow: 4;
    margin-top: 0;
  }
  button {
    flex-grow: 1;
  }
`;

function ListAccounts() {
  let navigate = useNavigate();

  const {
    state: { list: companies, selected: company },
    dispatch,
    fetchList,
  } = useCompanies();

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    if (companies.length)
      dispatch({ type: "set_selected", payload: companies[0] });
    return () => dispatch({ type: "set_selected", payload: null });
  }, [companies, dispatch]);

  // const handleClick = () => {
  //   navigate("/accounts/new");
  // };

  const handleClick = () => {
    navigate("/accounts/AllEmployees");
  };

  const handleCreateAdmin = () => {
    navigate("/accounts/createAdmin");
  };

  const setCompany = useCallback(
    (company) => {
      // return;
      dispatch({ type: "set_selected", payload: company.data });
    },
    [dispatch]
  );

  const mapCompanyOptions = useRef({ value: "id", label: "businessName" });

  return (
    <div id="list-accounts">
      <Flex jc="space-between">
        <div style={{ marginBottom: "30px" }}>
          <strong>Company: </strong>
          <Select
            data={companies}
            mapOptions={mapCompanyOptions.current}
            onChange={setCompany}
            defaultValue={companies[0]?.id}
            style={{ height: "40px", maxWidth: "280px" }}
            inputProps={{
              value: company?.id || "",
              readOnly: true,
            }}
          />
        </div>
        <div>
          <Flex >
            <Button className="small" onClick={handleClick} iconName="invoice">
              All Employees
            </Button>
          </Flex>
        </div>
      </Flex>

      <Divider border />
      <Grid>
        <h2>HR Accounts List</h2>
        {/* <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button> */}
      </Grid>
      {Boolean(company) && <ListHR company={company} />}
      <Divider h={60} />

      <Grid>
        <h2>Po Employees List</h2>
        {/* <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button> */}
      </Grid>
      {Boolean(company) && <ListPo company={company} />}
      <Divider h={60} />


      <Grid>
        <h2>Accountants List</h2>
        {/* <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button> */}
      </Grid>
      {Boolean(company) && <ListAccountant company={company} />}
      <Divider h={60} />

      <Grid>
        <h2>Employees Accounts List</h2>
        {/* <Button className="small" onClick={handleClick} iconName="invoice">
          New Entry
        </Button> */}
      </Grid>
      {Boolean(company) && <ListEmployees company={company} />}

      <Divider h={60} border />

      <Grid>
        <h2>Admins Accounts List</h2>
        <Flex>
        <Button
          className="small"
          onClick={handleCreateAdmin}
          iconName="invoice"
        >
          Create Admin
        </Button>
        </Flex>
      </Grid>
      {Boolean(company) && <ListAdmins company={company} />}
    </div>
  );
}

export default ListAccounts;
