import countries from "../../data/countries.json";
import { addDays, currency, subtractYears } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
  {
    name: "title",
    type: "text",
    label: "Title",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "description",
    type: "text",
    label: "Description",
    longDescription: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "content",
    type: "text",
    label: "Content",
    editor: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "publishDate",
    type: "date",
    label: "Publish Date",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },

  {
    name: "readingDuration",
    type: "number",
    label: "Reading Duration",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
      {
        type: "min",
        params: [1, "Basic Salary must be greater than 0"],
      },
    ],
  },
  {
    name: "isActive",
    type: "number",
    label: "Status",
    switch: true,
    validationType: "boolean",
  },
];

export default fields;
