import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../layout/Layout";
import {  useOrders } from "./provider";

function Orders() {


  return <Layout><Outlet /></Layout>;
}

export default Orders;
